import React, { useEffect, useRef } from 'react'
import {
    useIonActionSheet,
	IonButton,
	IonContent,
	IonHeader,
    IonIcon,
	IonLabel,
	IonMenu,
	IonTitle,
	IonToolbar,
} from '@ionic/react'
import { getFirebaseAuth, clubUserLogin } from './firebase'
import { signOut } from 'firebase/auth'
import ModalWithCancel from './ModalWithCancel'
import { SignInButton } from './Intro'
import { DisplayPlan, DisplayUpgradeOffer, RestorePurchases } from './Plan'
import SignOns from './SignOns'
import Club from './Club'
import { getProvider } from './providers'
import {
	useActiveTab,
    useClub,
	useUser,
} from './data'
import Icon from './Icon'
import { userLoginObject } from './util'
import './profile.css'
import {ProfileContext} from './ProfileContext'
import {AskToVerifyEmail} from './MyTeams'

const SHOW_CLUB     = true

const Profile = () => {

    const user = useUser()
	const activeTab = useActiveTab()

	const menuRef = useRef<HTMLIonMenuElement>(null)

	const closeSideMenu = () => menuRef.current?.close()

	//
	// If we switch to "Join Club", get rid of any open menus,
	// so we can actually see it.
	//
	useEffect(() => {
		if (activeTab === 'join-club') {
			closeSideMenu()
		}
	}, [ activeTab ])

	return (
		<ProfileContext.Provider value={closeSideMenu}>
			<IonMenu side="start" contentId="main" menuId="profileMenu" ref={menuRef}>
				<IonHeader>
					<IonToolbar>
						<IonTitle>Profile</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent className="ion-padding">

					{
						user ? (
							<>
								<DisplayUser />

								<AskToVerifyEmail />

								<DisplaySignOns />

								<DisplayPlan />

								<DisplayClub />

								<DisplayUpgradeOffer isClub={false} />

								<RestorePurchases />

								<SignOutButton />
							</>
						) : (
							<>
								<div className="intro-header">
									<h1>
										AutoRoster
									</h1>
									<h2>
										for Netball
									</h2>
									<Icon />
								</div>

								<SignInButton />
							</>
						)
					}

				</IonContent>
			</IonMenu>
		</ProfileContext.Provider>
	)
}

export const DisplayUser = () => {

    const user = useUser()

    if (!user)
        return null

    return (
        <div
            className="display-user-block"
        >
            <IonLabel>
                Account
            </IonLabel>
            <IonLabel className="user-photo">
                <UserPhoto />
            </IonLabel>
            <IonLabel className="user-name">
				<UserName />
            </IonLabel>
        </div>
    )
}

const UserName = () => {

	const user = useUser()

	if (!user)
		return null

	const { displayName, email, phoneNumber, uid } = user

	const str = displayName || email || phoneNumber || uid

	return (
		<>
			{str}
		</>
	)
}

export const UserPhoto = () => {

    const user = useUser()

	if (!user)
		return null

	const { photoUrl } = user

	if (!photoUrl)
		return null

	return (
		<img
			className="user-photo"
			src={photoUrl}
			alt=""
			referrerPolicy="no-referrer"
		/>
	)
}

/*
	if (showFallback) {
		return (
            <IonIcon
                icon={personCircleOutline}
            />
        )
    }
*/

const DisplaySignOns = () => {

    const user = useUser()

    // console.log('DisplaySignOns', user)

    if (!user?.providerData?.length)
        return null

    const myProviders = user.providerData.map(provider => provider.providerId)

    return (
        <div
            className="display-user-block signins"
        >
            <IonLabel>
                Sign-ons
            </IonLabel>
            <IonLabel>
                <IonButton
                    id="trigger-signons"
                    fill="clear"
                >
                    Manage...
                </IonButton>
            </IonLabel>
            <IonLabel>
                {
                    myProviders.map(providerId => (
                        <IonIcon
                            key={providerId}
                            icon={getProvider(providerId)?.icon}
                        />
                    ))
                }
            </IonLabel>

            <ModalWithCancel
                trigger="trigger-signons"
                title="Sign-Ons"
				cancelText="Done"
            >
                <SignOns />
            </ModalWithCancel>

        </div>
    )
}

const DisplayClub = () => {

	const user = useUser()
    const club = useClub()

	useEffect(() => {
		if (user && club?.id) {

			// This is async
			clubUserLogin({
				clubId: club.id,
				user: userLoginObject(user),
			})

			// const { data } = await clubUserLogin({ ...
			// console.log('DisplayClub registerLogin() result:', data)
		}
	}, [ user, club?.id ])

	if (!SHOW_CLUB)
		return null

	return (
		<div
			className="display-user-block display-club"
		>
			<IonLabel>
				Club
			</IonLabel>
			<IonLabel>
				<IonButton
					className={club ? 'have-club' : undefined}
					id="trigger-club"
					fill="clear"
				>
					{
						club ? club.name : 'Not Set'
					}
				</IonButton>
			</IonLabel>

			<ModalWithCancel
				trigger="trigger-club"
				title="Club"
				cancelText="Done"
			>
				<Club />
			</ModalWithCancel>

		</div>
	)
}

const SignOutButton = () => {

    const [ present ] = useIonActionSheet()

    const onClick = () => {
        console.log('clicked signout')

        present({
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'Sign Out',
                    role: 'confirm',
                    handler: async () => {

                        const auth = getFirebaseAuth()
                        console.log('signout with auth!')
                        await signOut(auth)
                    },
                },
            ],
        })
    }

    return (
        <IonButton
            expand="block"
            fill="outline"
            onClick={onClick}
        >
            Sign Out
        </IonButton>
    )
}

export default Profile
