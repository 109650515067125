import React from 'react'
import {
    IonButton,
    IonIcon,
    IonLabel,
} from '@ionic/react'
import { trashOutline } from 'ionicons/icons'

type DeleteButtonProps = {
	show: boolean,
	text?: string,
	onClick: () => void
}

const DeleteButton = (props: DeleteButtonProps) => {

	if (!props.show)
		return null

	const buttonText = props.text || 'Erase'

	return (
		<IonButton
            className="button-delete button-react-icon button-mixed"
            onClick={props.onClick}
            fill="clear"
            color="danger"
        >
            <IonIcon icon={trashOutline} slot="start" />
            <IonLabel
                className="roster-button-text"
            >
                {buttonText}
            </IonLabel>
        </IonButton>
    )
}

export default DeleteButton
