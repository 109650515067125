import React, { useState, useEffect } from 'react'
import ProgressBar from './ProgressBar'

type LoadingBarProps = {
	loading: boolean
	variant: 'determinate' | 'indeterminate'
}

const LoadingBar = (props: LoadingBarProps) => {

    const { loading, variant } = props

	const [ loadingProgress, setLoadingProgress ] = useState(0)
	const [ mounted, setMounted ] = useState(Date.now())

    useEffect(() => {
        setMounted(Date.now())
        setLoadingProgress(0)
    }, [ loading ])

	useEffect(() => {
		let timer: ReturnType<typeof setInterval> | undefined = undefined

        if (loading) {
            timer = setInterval(() => {
                const newLoadingProgress = 100 - (10000 / (Date.now() + 10 - mounted))
                if (newLoadingProgress < 99) {
                    setLoadingProgress(newLoadingProgress)
                }
            }, 150)
        } else if (timer) {
            clearInterval(timer)
        }

        return () => clearInterval(timer)
    }, [ loading, mounted ])

	return (
		<ProgressBar
			variant={loading ? variant : undefined}
			value={loading ? loadingProgress : 100}
		/>
	)
}

export default LoadingBar
