import { IonIcon, IonRouterLink } from '@ionic/react'
import { home, shareOutline } from 'ionicons/icons'
import React from 'react'

import { APP_STORE_URL } from './definitions'
import { MoreInfoLink, NavItem } from './Help'
import { PlanName } from './Plan'
import { loadFromLocalStorage } from './util'
import DownloadOnTheAppStoreImage from './media/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import comboImg from './media/combos.png'
import subDemoImg from './media/sub-demo.gif'

export const newsItems: NewsItem[] = [
	{
		date: '3 January 2025',
		content: () => (
			<>
				<b>New in version 2.7.0:</b> Subs! Yes, it's now possible to create rosters with substitutions,
				where a player comes off the bench to replace someone else mid-quarter.
				<br /><br />
				<img src={subDemoImg} alt="" className="subDemoImg" />
				<br /><br />
				To create a sub, enter <NavItem label="Edit" /> mode and tap a position twice so that it
				displays an arrow animation. Wait a second or two, and the position will split in two.
				To reverse this (i.e. turn a split position back into a full-quarter one), do the
				same thing again. <MoreInfoLink helpSection={"tips-subs"} text="More about Subs..." />
			</>
		),
	},
	{
		date: '12 November 2024',
		content: () => (
			<>
				<b>New in version 2.6.0:</b> Support for 5- and 6-player games. This is mainly useful for Indoor
				Netball and New Zealand's <em>futureFERNS</em>. You can choose your Format when creating a new
				season.
				<ul>
					<li>
						<p>
							Standard: Seven players
						</p>
					</li>
					<li>
						<p>
							6-a-side: Two each of <b>A</b>, <b>C</b>, and <b>D</b>
						</p>
					</li>
					<li>
						<p>
							5-a-side: Two each of <b>A</b> and <b>D</b>, plus one <b>C</b>
						</p>
					</li>
					<li>
						<p>
							FAST5: No wings! Only GS, GA, C, GD, GK
						</p>
					</li>
				</ul>
			</>
		),
	},

	{
		date: '19 October 2024',
		content: () => (
			<>
				<b>New in version 2.5.0:</b> A big collection of bugfixes, tweaks and improvements, including:
				<ul>
					<li>
						<p>
							Season Fixture Sync (beta) - AutoRoster can now automatically pull
							quarter-by-quarter scores from NetballConnect, so you don't need to
							manually enter them. Quarter-by-quarter scores are key to most of the
							analysis AutoRoster can do, so this makes many stats much more accessible
							to coaches. (Only available when you've configured Fixture Sync in your
							Season settings for teams in NetballConnect-supported leagues.)
						</p>
					</li>
					<li>
						<p>
							Season Fixture Sync (beta) - Fix bug that could become confused by
							tournaments where teams play multiple games in quick succession.
						</p>
					</li>
					<li>
						<p>
							Clubs - Club admin can properly view Stats for club teams.
						</p>
					</li>
					<li>
						<p>
							Improved support for more devices.
						</p>
					</li>
					<li>
						<p>
							Code migration for improved reliability and futureproofing.
						</p>
					</li>
				</ul>
			</>
		),
	},

	{
		date: '22 April 2024',
		content: () => (
			<>
				<b>New in version 2.4.3:</b> Minor improvements to sharing and team selection.
				<br /><br />
				There's now a handy <IonIcon icon={shareOutline} /> Share link at the bottom of your Games list,
				and for busy coaches, AutoRoster will remember the last few teams/seasons you've viewed
				and offer them at the top of your team selector via the <IonIcon icon={home} /> Home button.
			</>
		),
	},

	{
		date: '3 April 2024',
		content: () => (
			<>
				<b>New in version 2.4.0:</b> Online fixture sync! If you play in a supported league,
				AutoRoster can automatically import your fixture and keep it up to date.
				<br /><br />
				Currently only the <b>NetballConnect</b> platform is supported, which mostly covers netball leagues
				in eastern Australia. But if that's you, it's super handy. Set it up via Season
				settings at the bottom of your <NavItem tab="games" />, or directly when creating
				a new season.
				<br /><br />
				Also:
				<br /><br />
				* If you toggle Positions between "Prefer" and "Require," AutoRoster will
				remember your choice in the future.
				<br /><br />
				* If you delete a season, AutoRoster won't
				let you add back games and players to it, to prevent your rosters becoming
				half-deleted zombies.
				<br /><br />
				* Fixed some minor bugs, including one where the dialog box would automatically
				close after entering a new player or game, which made it a chore to add lots of them.
			</>
		),
	},

	{
		date: '9 March 2024',
		content: () => (
			<>
				<b>New in version 2.3.4:</b> A fix for a bug that wouldn't tell you why it wasn't deleting
				your team, and one that could prevent people from joining your club.
				<br /><br />
				Some people have asked about rolling subs or tactical changes &mdash; how are you supposed to
				make rosters where players change mid-quarter? This is pretty tricky to support without making
				the app quite a lot more complicated, so unfortunately I don't have a great answer! I will
				probably look to add support for 5- and 6-player games (for Indoor Netball and New Zealand's
				futureFERNS Years 5 and 6 program) before tackling subs.
			</>
		),
	},

	{
		date: '11 December 2023',
		content: () => (
			<>
				<b>New in version 2.3.2:</b> Show the total number of quarters benched for each player by tapping the
				right-most column in <NavItem tab="stats" /> → <b>Positions Played</b>.
				<br /><br />
				Provide a subtle visual hint that a player should be marked as unavailable when they're
				assigned zero on-court roles in a roster.
			</>
		),
	},

	{
		date: '1 December 2023',
		content: () => (
			<>
				<b>New in version 2.3.1:</b> A bunch of small improvements and fixes, including squashing
				a bug that could crash on startup for rosters that aren't 4 quarters.
			</>
		),
	},
	{
		date: '12 November 2023',
		content: ({ setHelpTab }) => (
			<>
				<b>New in version 2.3.0:</b> Support for games that aren't 4 quarters.
				<br /><br />
				Quite a few people asked if they could customize the number of periods in a game.
				Now you can! Select from 1 to 6	periods by modifying your season settings,
				now conveniently available at the bottom of your Games list.
				<br /><br />
				For more info, see
				{' '}
				<IonRouterLink onClick={() => setHelpTab('tips')}>
					Tips & Tricks
				</IonRouterLink>
				{' '}
				→ <b>Match Format</b>.
				<br /><br />
				Additionally, when clubs create invitation links, those now last for 6 months
				before expiring, rather than 7 days, because I realized no-one is that organized.
			</>
		),
	},
	{
		date: '26 October 2023',
		content: () => (
			<>
				<b>New in version 2.2.0</b>: AutoRoster generates even better high-performance
				rosters by using data on combinations of players who work particularly well together.
				<br /><br />
				Previously, AutoRoster would only consider each player in isolation when
				scoring and generating rosters. Now it will actively seek out rosters that
				include pairs and trios that correlate with high performance, and avoid weaker ones.
				<br /><br />
				You can control how important AutoRoster considers stats
				with the <b>Optimize team performance</b> slider in your <NavItem tab="settings" />.
				<br /><br />
				For <PlanName /> users, you can now see the strength of relevant Pairs and Trios
				directly on rosters, not just in <NavItem tab="stats" /> → <b>Combinations</b> like before.
				And if that's too many numbers, you can hide them in <NavItem tab="settings" />.
				<br /><br />
				Thanks for using AutoRoster, and good luck with your teams! &mdash; Max.
				<br /><br />
				<img
					className="comboImg"
					src={comboImg}
					alt="Combinations"
				/>
			</>
		),
	},
	{
		date: '24 July 2023',
		content: () => (
			<>
				I get a little map showing where this app is being used, and it's been very cool
				the last few months to see it spread across Australia, the UK, New Zealand and South Africa.
				<br /><br />
				Yet to make a meaningful impact in Jamaica, Malawi, Tonga, or Uganda, but maybe that will
				change with the release of the <b>Android version</b>! That should happen in
				the next week.
				<br /><br />
				Thanks for everyone who has taken the time to email me or rate the app on the App Store.
				The most commonly requested new features are:
				<ul>
					<li>Ability to enter game stats (e.g. shots taken by GA/GS)</li>
					<li>Variable number of game periods (e.g. 2 for tournaments rather than 4 qtrs)</li>
				</ul>
				At the moment I'm focused on making the app stable and bug-free, but after that,
				those will probably be the first I'll look at.
				<br /><br />
				Thanks for using and good luck with your teams! &mdash; Max.
			</>
		),
	},
	{
		date: '25 April 2023',
		content: () => (
			<>
				The site has undergone a big redesign to make it more mobile-friendly for
				court-side coaches. And there's an app!
				<br /><br />
				<a href={APP_STORE_URL}>
					<img src={DownloadOnTheAppStoreImage} alt="Download on the App Store" />
				</a>
				<br /><br />
				If you need the old site, you can find it <a href="https://v1.autoroster.io">here</a>.
				Please <a href="mailto:max_barry@maxbarry.com">email me</a> with any problems.
				<br /><br />
				This site and the app are still free to use, but you can subscribe to <PlanName /> for
				more data storage and extra stats.
				<br /><br />
				Thanks for using! &mdash; Max.
			</>
		),
	},
	{
		date: '13 August 2022',
		content: () => (
			<>
				You can now edit rosters in traditional roster format, with position names
				down the side and player names in columns. Click <b>TOGGLE VIEW</b> and <b>EDIT</b>.
			</>
		),
	},
	{
		date: '7 June 2022',
		content: () => (
			<>
				The <b>POSITIONS</b> button now lets you set <em>preferred</em> positions
				for each player, rather than operating strictly as a this-person-must-play-here
				rule. I also fixed a bug that could produce stubborn, sub-optimal rosters
				that wouldn't improve.
			</>
		),
	},
	{
		date: '27 May 2022',
		content: () => (
			<>
				<b>NetStats!</b> Enter your game scores and let AutoRoster analyze which
				rosters delivered your best team performances. With this data, it can even generate rosters that optimize
				team strength.
			</>
		),
	},
]

export const newestNewsItemDate = newsItems[0].date

export const haveUnreadNewsItem = () => {
	const lastViewedNewsPage = loadFromLocalStorage('viewedNewsPage')
	if (typeof lastViewedNewsPage === 'string' || typeof lastViewedNewsPage === 'number') {
		return new Date(lastViewedNewsPage) < new Date(newestNewsItemDate)
	}
	return true
}
