import React, { useState, useEffect } from 'react'
import {
    IonRippleEffect,
    IonRouterLink,
} from '@ionic/react'
import PlayerName from './PlayerName'
import { DisplayScore } from './DisplayScores'
import {
	useMyGameFormat,
    useMyGames,
	useMyNumPeriods,
    useMyRosters,
} from './data'
import { calculateQtrGoalDiffs } from './functions'
import { labelRound, querySub } from './util'
import { deepCopy } from './util'
import {queryFieldPositions} from './definitions'
import GridCellPosition from './GridCellPosition'

type BestQtrsProps = {
	type: 'bestQtrs' | 'attack' | 'defence'
}

const BestQtrs = (props: BestQtrsProps) => {

	const { type } = props

    const games = useMyGames()
    const rosters = useMyRosters()
	const numPeriods = useMyNumPeriods()
	const gameFormat = useMyGameFormat()

	if (!type || !games || !rosters)
		return null

	let gamesData: Games

	if (type === 'bestQtrs') {
		gamesData = games
	} else {
		gamesData = deepCopy(games)
		Object.keys(gamesData).forEach(gameId => {
			const game = gamesData[gameId]
			const { result } = game
			if (result) {
				if (type === 'attack') {
					// ignore conceded goals
					result.them = new Array(numPeriods).fill(0)
				} else if (type === 'defence') {
					// ignore scored goals
					result.me = new Array(numPeriods).fill(0)
				}
			}
		})
	}

	const qtrGoalDiffs = calculateQtrGoalDiffs({
        games: gamesData,
        raw: true,
		numPeriods,
    })

	// console.log('qtrGoalDiffs', qtrGoalDiffs, 'using gamesData', gamesData)

	const qtrs: Array<{
		gameId: GameId
		qtr: number
		diff: number
	}> = [ ]

	Object.keys(qtrGoalDiffs).forEach(gameId => {
		qtrGoalDiffs[gameId].forEach((diff, qtr) => {
            if (diff !== null) {
                qtrs.push({
                    gameId,
                    qtr,
                    diff,
                })
            }
		})
	})

	const sortedQtrs = qtrs.sort((a, b) => b.diff - a.diff)

    return (
        <div className="stats-best-qtrs">
            <div className="stats-best-qtrs-list">
                {
                    sortedQtrs.map((item, index) => {
                        const key = `${item.gameId}-${item.qtr}`
                        return (
                            <BestQtrRow
                                key={key}
                                index={index}
                                gameId={item.gameId}
                                game={games[item.gameId]}
                                roster={rosters[item.gameId]?.roster}
								subs={rosters[item.gameId]?.subs}
                                nulled={rosters[item.gameId]?.nulled}
                                players={rosters[item.gameId]?.players}
                                diff={item.diff}
                                qtr={item.qtr}
								numPeriods={numPeriods}
								gameFormat={gameFormat}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

type BestQtrRowProps = {
	gameId: GameId
	index: number
	game: Game
	diff: number
	qtr: number
	roster: RosterData
	subs: Subs
	nulled: RosterPositionIndex[]
	players: Array<PlayerId>
	numPeriods: NumPeriods
	gameFormat: GameFormat
}

const BestQtrRow = (props: BestQtrRowProps) => {

	const { gameId, index, game, diff, qtr, roster: rosterData, nulled, subs, players, numPeriods, gameFormat } = props

    const [ reveal, setReveal ] = useState(false)

    useEffect(() => {
		let timer: ReturnType<typeof setTimeout>

        if (!reveal) {
            const delay = (index <= 6 ? index * 100 : index * 150)
            timer = setTimeout(() => setReveal(true), delay)
        }

        return () => clearTimeout(timer)
    }, [ index, reveal ])

	const fieldPositions = queryFieldPositions(gameFormat)

    if (!reveal) {
        return (
            <IonRouterLink
                className="stats-game placeholder"
                routerLink={`/games/${gameId}`}
            >
                <div className="bestqtr" />
				{
					fieldPositions.map((_pos, index) => (
						<div
							key={index}
							className="cell cell-position"
						/>
					))
				}
                <div className="stats-total-diff" />
            </IonRouterLink>
        )
    }

    const { round, opponent, result } = game

    // If there's scores but no roster, we want to show an empty row
    const roster = rosterData || new Array(fieldPositions.length * numPeriods).fill(null)

    const playersPerQtr = roster.length / numPeriods

    const qtrRoster = playersPerQtr ?
        roster.slice(qtr * playersPerQtr, qtr * playersPerQtr + fieldPositions.length)
        : new Array(fieldPositions.length).fill(null)

    // console.log('roster', roster, '=> qtrRoster', qtrRoster, qtr, playersPerQtr, props)

    const opponentClasses = [ 'bestqtr-opponent' ]
    if (opponent.length >= 17) {
        opponentClasses.push('very-long-name')
    } else if (opponent.length >= 12) {
        opponentClasses.push('long-name')
    }

    const roundName = labelRound(round)

    return (
        <IonRouterLink
            className="stats-game"
            routerLink={`/games/${gameId}`}
        >
            <div className="bestqtr">
                <div className="bestqtr-round">
                    <div className="bestqtr-round-round">
                        {roundName}
                    </div>
                    <div className="bestqtr-round-qtr">
						{(!numPeriods || numPeriods === 4) ? 'Q' : 'P'}{qtr + 1}
                    </div>
                </div>
                <div className={opponentClasses.join(' ')}>
                    {opponent}
                </div>
                <DisplayScore
                    className="bestqtr-result"
                    result={result}
                    qtr={qtr}
					numPeriods={numPeriods}
                />
            </div>
            {
                qtrRoster.map((playerId, index) => {
					const posIndex = index + qtr * playersPerQtr
					const positionName = fieldPositions[index]
					const isAvailable = players?.includes(playerId)
					const isNulled = nulled?.includes(posIndex)
					const { subPosIndex, subStatus } = querySub({
						subs,
						i: index + qtr * playersPerQtr,
					})
					const subPlayerId = subPosIndex !== null ? roster[subPosIndex] : null
					const subPlayerIsAvailable = !!subPlayerId && players?.includes(subPlayerId)
					const content = (
						<PlayerName
							playerId={playerId}
							allowBlank={true}
							subPlayerId={subPlayerId}
						/>
					)
					return (
						<GridCellPosition
							key={playerId || index}
							i={posIndex}
							content={content}
							positionName={positionName}
							isAvailable={isAvailable}
							isSubAvailable={subPlayerIsAvailable}
							subStatus={subStatus}
							isNullPosition={isNulled}
						/>
					)
                })
            }
            <div className="stats-total-diff">
                {diff >=0 ? '+' : ''}{diff}
            </div>
            <IonRippleEffect></IonRippleEffect>
        </IonRouterLink>
    )
}

export default BestQtrs
