import React from 'react'
import {
	IonButton,
    IonContent,
    IonFooter,
    IonHeader,
} from '@ionic/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import LoadingSpinner from './LoadingSpinner'
import FadeText from './FadeText'
import Icon from './Icon'
import {
	useSetShowLogin,
    useUser,
} from './data'
import introRosterMp4 from './media/intro-roster.mp4'
import introStats from './media/intro-stats.png'
import introPositions from './media/intro-positions.jpg'
import introGames from './media/intro-games.jpg'
import 'swiper/css'
import "swiper/css/pagination"
import './intro.css'

const Intro = () => {

    const user = useUser()

    //
    // When user has been checked, it will be null or a user object.
    // Undefined means we're in the process of querying Firebase.
    //
    const amLoadingUser = user === undefined

    return (
        <>
            <IonHeader>
                <div
                    className="intro-header"
                >
                    <h1>
                        <FadeText>
                            AutoRoster
                        </FadeText>
                    </h1>
                    <h2>
                        <FadeText>
                            for Netball
                        </FadeText>
                    </h2>
                    <FadeText isBlock={true} contentKey="icon">
                        <Icon />
                    </FadeText>
                </div>
            </IonHeader>

            {
                amLoadingUser ? <LoadingSpinner /> : <Slides />
            }
        </>
    )
}

const Slides = () => {

    return (
        <>
            <IonContent>

                <Swiper
                    pagination={true}
                    slidesPerView="auto"
                    centeredSlides={true}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    modules={[ Pagination ]}
                >
                    <SwiperSlide>
                        <div className="intro-slide-content">
                            <h1>
                                Make smart rosters with ease.
                            </h1>

                            <video playsInline autoPlay muted>
                                <source src={introRosterMp4} type="video/mp4" />
                            </video>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="intro-slide-content">
                            <h1>
                                Manage a season.
                            </h1>
                            <img src={introGames} alt="" className="intro-image" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="intro-slide-content">
                            <h1>
                                Track who's played where.
                            </h1>
                            <img src={introPositions} alt="" className="intro-image" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="intro-slide-content">
                            <h1>
                                Analyze team performance.
                            </h1>
                            <img src={introStats} alt="" className="intro-image" />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </IonContent>

            <IonFooter className="ion-padding">
                <div className="intro-signin">
                    <SignInButton />
                </div>
            </IonFooter>
        </>
    )
}

export const SignInButton = () => {

    const setShowLogin = useSetShowLogin()

    return (
        <IonButton
            expand="block"
            onClick={() => setShowLogin(true)}
        >
            Sign in / Create account
        </IonButton>
    )
}

export default Intro
