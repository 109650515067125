import React, { useState, useEffect } from 'react'
import { App } from '@capacitor/app'
import { getFirebaseAuth } from './firebase'
import { signInWithPhoneNumber as FASignInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth"
import {
    IonButton,
    IonItem,
    IonIcon,
    IonInput,
    IonProgressBar,
	IonSegment,
	IonSegmentButton,
	IonLabel,
	IonInputPasswordToggle,
} from '@ionic/react'
import { InputInputEventDetail, IonInputCustomEvent } from '@ionic/core'
import { Capacitor } from '@capacitor/core'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import ReactInputVerificationCode from 'react-input-verification-code'
import {
	createUserWithEmailAndPassword,
	linkWithPhoneNumber,
    providers,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPhoneNumber,
} from './providers'
import ModalWithCancel from './ModalWithCancel'
import LoadingBar from './LoadingBar'
import {
	useSetShowLogin,
	useSetUser,
    useShowLoginState,
    useUser,
} from './data'
import PhoneInput, { Country as CountryCode, Value as E164Number } from 'react-phone-number-input'

import flags from 'react-phone-number-input/flags'
import 'react-phone-number-input/style.css'
import './login.css'
import {userObject} from './util'
import {call, mail} from 'ionicons/icons'

const PHONE_VERIFICATION_CODE_LENGTH = 6

const Login = () => {

    const [ show, setShow ] = useShowLoginState()

    const [ loading, setLoading ] = useState(false)
	const [ showSubModal, setShowSubModal ] = useState<string|null>(null)

    const user = useUser()

    useEffect(() => {
        if (user) {
            setShowSubModal(null)
        }
    }, [ user ])

    return (
        <ModalWithCancel
            show={show}
            setShow={setShow}
        >

            <h1>
                Sign in / Create account
            </h1>

            <LoadingBar loading={loading} variant="indeterminate" />

            <div className="social-login-buttons">

                {
                    providers.map(provider =>

                        <SocialLoginButton
                            key={provider.id}
                            id={provider.id}
                            label={provider.name}
                            icon={provider.icon}
                            onClick={provider.signIn}
                            disabled={loading || !!provider.disabled}
                            setLoading={setLoading}
                            setShowSubModal={setShowSubModal}
                        />
                    )
                }

            </div>

            <EmailModal
                show={showSubModal === 'password'}
				setShow={(v: boolean) => setShowSubModal(v ? 'password' : null)}
            />

            <PhoneNumberModal
                show={showSubModal === 'phone'}
				setShow={(v: boolean) => setShowSubModal(v ? 'phone' : null)}
            />

            <div id="recaptcha-container" />

        </ModalWithCancel>
    )
}

type SocialLoginButtonProps = {
	id: string
	label: string
	icon: string
	onClick?: () => Promise<void>
	disabled: boolean
	setShowSubModal: (v: string|null) => void
	setLoading: (v: boolean) => void
}

const SocialLoginButton = (props: SocialLoginButtonProps) => {
	const { id, label, icon, disabled, setShowSubModal, setLoading } = props

    const myOnClick = async () => {
		if (props.onClick) {
			setLoading(true)
			await props.onClick()
			setLoading(false)
		} else {
			setShowSubModal(id)
		}
	}

    return (
        <IonButton
            expand="block"
            fill="solid"
            onClick={myOnClick}
            disabled={disabled}
        >
            <IonIcon icon={icon} slot="start" />
            {label}
        </IonButton>
    )
}

type PhoneNumberModalProps = {
	show: boolean
	setShow: (v: boolean) => void
	amLinking?: boolean
	setErr?: (v: string|null) => void
}

export const PhoneNumberModal = (props: PhoneNumberModalProps) => {

    const { show, setShow } = props

	const setUser = useSetUser()

	const [ phoneNumber, setPhoneNumber ] = useState<E164Number|undefined>()     // Example: "+12133734253".
	const [ lastCheckedPhoneNumber, setLastCheckedPhoneNumber ] = useState<E164Number|undefined>()

	const [ err, setErr ] = useState<string|null>(null)
    const [ amLoading, setAmLoading ] = useState(false)

	const [ recaptchaVerifier, setRecaptchaVerifier ] = useState<RecaptchaVerifier|undefined>()

	const [ verificationId, setVerificationId ] = useState<string>()
    const [ showCodeInput, setShowCodeInput ] = useState(false)
	const [ showCodeInputDelayed, setShowCodeInputDelayed ] = useState(false)

    const PHONE_VERIFICATION_CODE_PLACEHOLDER = '·'

    useEffect(() => {

        if (!Capacitor.isNativePlatform() && show && !recaptchaVerifier) {

            console.log('setup rv')

            //
            // The 'recaptcha-container' must be already rendered, which means
            // either put it outside this component, or force a delay with setTimeout.
            //

            const auth = getFirebaseAuth()

            const rv = new RecaptchaVerifier(auth, 'recaptcha-container', {
                size: 'invisible',
            })

            setRecaptchaVerifier(rv)
        }

    }, [ recaptchaVerifier, show ])

    useEffect(() => {
		let timer: ReturnType<typeof setTimeout>

        setShowCodeInput(!!verificationId)

		//
		// Delay the display of <ReactInputVerificationCode> so autoFocus works.
		//
		if (verificationId) {
			timer = setTimeout(() => setShowCodeInputDelayed(true), 250)
		} else {
			setShowCodeInputDelayed(false)
		}

		return () => clearTimeout(timer)
	}, [ verificationId ])

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

    const [ tzFirst ] = tz.split('/')
    const map = {
        'Africa': 'ZA',
        'Australia': 'AU',
        'Pacific': 'NZ',
        'Europe': 'GB',
        'America': 'US',
    } as const

	let defaultCountry: CountryCode | undefined
	if (tzFirst in map) {
		defaultCountry = map[tzFirst as keyof typeof map]
	} else {
		// Handle the case where tzFirst is not a recognized key
		defaultCountry = 'US' // or any other default value
	}

	const onClick = async () => {

		setErr(null)
		setAmLoading(true)

		console.log('Checking number', phoneNumber)

		if (!phoneNumber) {
			setErr('Please enter a phone number')
			setAmLoading(false)
			return
		}

		//
		// If we already sent the SMS and the user typoed it and they're trying
		// the same phone number, we won't get a 'phoneCodeSent' event below,
		// so just open up the input.
		//
		if (verificationId && phoneNumber === lastCheckedPhoneNumber) {

			setShowCodeInput(true)

		} else {

			setLastCheckedPhoneNumber(phoneNumber)

			try {

				let result

				if (Capacitor.isNativePlatform()) {

					//
					// iOS / Android device
					//
					// NOTE: This only works on real iOS devices. In the simulator, it returns a
					// "Token mismatch" error.
					//

					console.log('Setting listener for phoneCodeSent event')

					await FirebaseAuthentication.addListener('phoneCodeSent', async event => {
						console.log('triggered phoneSentCode event', event)
						setVerificationId(event?.verificationId)
					})

					result = await FirebaseAuthentication.signInWithPhoneNumber({
						phoneNumber,
					})

				} else {

					//
					// Web
					//

					console.log('Web-based phone authentication')

					const auth = getFirebaseAuth()

					if (!recaptchaVerifier) {
						setErr('Failed to load recaptchaVerifier')
						return
					}

					result = await FASignInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
				}

				console.log('result from FASignInWithPhoneNumber:', result)

				if (result?.verificationId) {
					setVerificationId(result.verificationId)
				}

			} catch (err: any) {
				console.log('err', err.code, err.message)
				if (err?.code === 'auth/invalid-app-credential' && window.location.hostname === 'localhost') {
					console.error("Phone authentication doesn't work on localhost because of Google being weird -- try on http://127.0.0.1 instead. See https://github.com/firebase/firebase-js-sdk/issues/8387")
				}
				setErr(err?.code || err?.errorMessage || 'Unknown error')
			}
		}
	}

	const setCode = async (verificationCode: string) => {
		console.log('new code value', verificationCode)

		if (verificationId && verificationCode && !verificationCode.match(PHONE_VERIFICATION_CODE_PLACEHOLDER)) {

			console.log("Let's try to sign in with the user's code...", verificationCode)

			setShowCodeInput(false)

			try {

				if (props.amLinking) {
					const newUser = await linkWithPhoneNumber({
						verificationId,
						verificationCode,
						setErr: props.setErr || setErr, // yes it's different!
					})
					if (newUser) {
						console.log('Successfully called linkWithPhoneNumber()', newUser)
						//
						// We need to manually update the user object, since we had
						// to exit the usual flow to display the verification code input.
						//
						console.log('userObject', userObject(newUser))
						setUser(userObject(newUser))
					} else {
						console.log("Failed to linkWithPhoneNumber()")
						// The error is already set, so no need to do anything else.
					}

				} else {
					await signInWithPhoneNumber({
						verificationId,
						verificationCode,
					})
					console.log('Successfully called signInWithPhoneNumber()')
				}

				setShow(false)

			} catch (err: any) {
				if (err?.code === 'auth/invalid-verification-code') {
					setErr('Incorrect code. Please try again.')
				} else if (err?.code === 'auth/code-expired') {
					setErr('Code expired. Please try again.')
				} else {
					console.log("Strange error", err)
				}
			}

			setAmLoading(false)
		}
	}

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			onClick()
		}
	}

	return (
		<ModalWithCancel
			show={show}
			setShow={setShow}
			className="login-phone-modal"
		>
			<h1>
				<IonIcon icon={call} />
				Sign in with SMS
			</h1>

			<IonProgressBar
				type={amLoading ? "indeterminate" : "determinate"}
				value={0}
			/>

			<div className="login-input-area">

				<PhoneInput
					flags={flags}
					defaultCountry={defaultCountry}
					placeholder="Enter phone number"
					value={phoneNumber}
					onChange={setPhoneNumber}
					onKeyDown={onKeyDown}
				/>

			</div>

			<IonButton
				disabled={amLoading || !phoneNumber}
				onClick={onClick}
				expand="block"
			>
				Verify
			</IonButton>

			<ErrorMessage err={err} />

			<ModalWithCancel
				show={showCodeInput}
				setShow={setShowCodeInput}
			>
				<p>
					Please enter the verification code sent to your phone.
				</p>
				{
					showCodeInputDelayed ? (
						<div className="phone-input-verification-code">
							<ReactInputVerificationCode
								autoFocus={true}
								length={PHONE_VERIFICATION_CODE_LENGTH}
								placeholder={PHONE_VERIFICATION_CODE_PLACEHOLDER}
								onChange={value => setCode(value)}
							/>
						</div>
					) : null
				}
			</ModalWithCancel>

		</ModalWithCancel>
	)
}

type EmailModalProps = {
	show: boolean
	setShow: (v: boolean) => void
}

const EmailModal = (props: EmailModalProps) => {

	const setUser = useSetUser()
	const setShowLogin = useSetShowLogin()

	const [ email, setEmail ] = useState<string>()
	const [ password, setPassword ] = useState<string>()
	const [ confirmPassword, setConfirmPassword ] = useState<string>()

	const [ err, setErr ] = useState<string|null>(null)
    const [ amLoading, setAmLoading ] = useState(false)

	const [ mode, setMode ] = useState<'signIn'|'createAccount'>('signIn')

	const [ showForgotPassword, setShowForgotPassword ] = useState(false)

    const onClick = async () => {

		if (!email) {
			setErr('Please enter an email address')
			return
		}

		if (!password) {
			setErr('Please enter a password')
			return
		}

		if (mode === 'createAccount' && password !== confirmPassword) {
			setErr('Passwords do not match')
			return
		}

        setErr(null)
        setAmLoading(true)

		if (mode === 'signIn') {
			await signInWithEmailAndPassword({
				email,
				password,
				setErr,
			})
		} else {
			const newUser = await createUserWithEmailAndPassword({
				email,
				password,
				setErr,
			})
			if (newUser) {
				console.log('Successfully called createUserWithEmailAndPassword()', newUser)
				//
				// We need to manually update the user object, since we had
				// to exit the usual flow to display the verification code input.
				//
				console.log('new userObject', userObject(newUser))
				setUser(userObject(newUser))
				setShowLogin(false)
			} else {
				console.log("Failed to createUserWithEmailAndPassword()")
				// The error is already set, so no need to do anything else.
			}
		}

        setAmLoading(false)
    }

    return (
        <ModalWithCancel
            show={props.show}
            setShow={props.setShow}
			className="login-email-modal"
        >
            <h1>
				<IonIcon icon={mail} />
                Email + Password
            </h1>

            <IonProgressBar
                type={amLoading ? "indeterminate" : "determinate"}
                value={0}
            />

			<IonSegment
				value={mode}
				color="primary"
				className="login-segment"
				onIonChange={e => {
					setMode(e.detail.value as 'signIn'|'createAccount')
				}}
			>
				<IonSegmentButton
					value="signIn"
				>
					<IonLabel>
						Sign In
					</IonLabel>
				</IonSegmentButton>
				<IonSegmentButton value="createAccount">
					<IonLabel>
						Create Account
					</IonLabel>
				</IonSegmentButton>
			</IonSegment>

			<div className="login-input-area">

				<IonItem>
					<IonInput
						required
                        placeholder="Enter email"
                        name="email"
                        type="email"
                        value={email}
						onIonInput={(e: IonInputCustomEvent<InputInputEventDetail>) => setEmail(e.detail.value ?? undefined)}
                    />
                </IonItem>

                <IonItem>
                    <IonInput
                        required
                        type="password"
                        placeholder="Enter password"
                        value={password}
						onIonInput={(e: IonInputCustomEvent<InputInputEventDetail>) => setPassword(e.detail.value ?? undefined)}
                    >
						<IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
					</IonInput>
                </IonItem>

				{
					mode === 'createAccount' ? (
						<IonItem>
							<IonInput
								required
								type="password"
								placeholder="Confirm password"
								value={confirmPassword}
								onIonInput={(e: IonInputCustomEvent<InputInputEventDetail>) => setConfirmPassword(e.detail.value ?? undefined)}
							>
								<IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
							</IonInput>
						</IonItem>
					) : null
				}

			</div>

			<ErrorMessage err={err} />

			<IonButton
				expand="block"
				disabled={amLoading || !email || !password || (mode === 'createAccount' && password !== confirmPassword)}
				onClick={onClick}
			>
				{mode === 'createAccount' ? 'Create Account' : 'Sign In'}
			</IonButton>

			{
				mode === 'signIn' ? (
					<div className="forgot-password">
						<IonButton
							fill="clear"
							onClick={() => setShowForgotPassword(!showForgotPassword)}
						>
							Forgot password?
						</IonButton>
						<ForgotPassword
							show={showForgotPassword}
							setShow={setShowForgotPassword}
							email={email}
							onCancel={() => setShowForgotPassword(false)}
						/>
					</div>
				) : null
			}
		</ModalWithCancel>
	)
}

type ErrorMessageProps = {
	err: string|null
}

export const ErrorMessage = (props: ErrorMessageProps) => {

    if (!props.err)
        return null

	const errMessages: Record<string, string> = {
		'auth/wrong-password': 'Incorrect password. Please try again.',
		'auth/email-already-in-use': 'This email address is already in use. Please sign in or use a different email address.',
		'auth/too-many-requests': 'Too many requests. Please try again later.',
		'auth/user-not-found': 'No user found with that email address.',
		'auth/invalid-email': "This email address doesn't appear valid.",
	}

	const msg = errMessages[props.err] || props.err

    return (
        <p className="error">
            Error: {msg}
        </p>
    )
}

type ForgotPasswordProps = {
	show: boolean
	setShow: (v: boolean) => void
	email: string | undefined
	onCancel: () => void
}

const ForgotPassword = (props: ForgotPasswordProps) => {

	const [ email, setEmail ] = useState<string | undefined>(undefined)
	const [ err, setErr ] = useState<string | null>(null)
	const [ done, setDone ] = useState(false)

	// Update the email field if the parent component changes it.
	useEffect(() => setEmail(props.email), [ props.email ])

	const { onCancel } = props

	//
	// If the app is opened via an URL, it's probably because the user
	// was redirected after resetting their password, so close this modal.
	//
	useEffect(() => {
		App.addListener('appUrlOpen', data => {
			if (done) {
				console.log('Closing ForgotPassword modal because app opened with URL:', data)
				onCancel()
			}
		})
		return () => {
			App.removeAllListeners()
		}
	}, [ done, onCancel ])

	const onClick = async () => {
		if (email) {
			const success = await sendPasswordResetEmail({
				email,
				setErr,
			})
			if (success) {
				setDone(true)

				// It's better if we don't clear this -- the user can always
				// back right out to the original signin screen and try again
				// if they really want.
				// setTimeout(() => setDone(false), 15000)
			}
		}
	}

	return (
		<ModalWithCancel
			show={props.show}
			setShow={props.setShow}
			className="forgot-password-modal"
		>
			<h1>
				Forgot Password
			</h1>
			<p>
				A password reset link will be sent to your email address.
			</p>
			<IonItem>
				<IonInput
					required
					placeholder="Enter email"
					name="email"
					type="email"
					value={email}
					onIonInput={(e: IonInputCustomEvent<InputInputEventDetail>) => setEmail(e.detail.value ?? undefined)}
				/>
			</IonItem>

			<ErrorMessage err={err} />

			<IonButton
				expand="block"
				onClick={onClick}
				disabled={done || !email || !email.includes('@') || !email.includes('.')}
			>
				Send Reset Link
			</IonButton>

			{
				done ? (
					<p>
						Password reset email sent! Please check your inbox.
					</p>
				) : null
			}
		</ModalWithCancel>
	)
}

export default Login
