import React, { useState } from 'react'
import {
    useIonAlert,
	IonButton,
    IonIcon,
	IonLabel,
} from '@ionic/react'
import { Purchases } from '@revenuecat/purchases-capacitor'
import { Capacitor } from '@capacitor/core'
import { logEvent } from './firebase'
import NativePurchasing from './NativePurchasing'
import { WebPurchasing, WebSubscriptionManagement } from './WebPurchasing'
import ModalWithCancel from './ModalWithCancel'
import {
    useClub,
    useClubHasPro,
	useStoreCustomerInfo,
	useUser,
    useUserHasPro,
    useUserHasProPersonally,
} from './data'
import { analyticsSharp, calendar  } from 'ionicons/icons'
import { players } from './media/teamIcon'
import './plan.css'

//
// Current Pricing - should be reflected in both Stripe (web) and Apple Store Connect (ios) accounts.
// Using Australian dollars:
//
// Basic - free, but only 1 season per user, no advanced stats
//
// Pro 	- $ 6.99/month				(USD  4.99)
//     	- $59.99/year				(USD 40.99)
//
// Club Basic - free with usual limitations - up to 40 coaches + admin tools
//
// Club Pro
// 		- $ 59.99/mo				(USD  40.99)
// 		- $599.99/year				(USD 399.99)
// 		- $299.99/6 months			(USD 199.99) - Android only, because Google Play refuses annual pricing as being too high
// 		- multiple teams/seasons + extra stats - up to 40 coaches
//
// Club Enterprise - contact for pricing
//
//
// Club flow:
// - Admin purchases club account
// - Admin sends out invitation links
// - User clicks link, gets added as 'coach' role user to 'clubs'
// - Admin can remove users, change role to 'admin' (who can manage other users)
// - Admin can upload logo
// - Admin & Supervisor can view all teams of users, seasons, stats, etc - coaches can't view other coaches
// - Note users could be Club + Pro or Club + Basic
// - Existing Pro users - prompted to cancel
// - Existing users if Club subscription lapses - prompted to upgrade
//

export const DisplayPlan = () => {

    const club = useClub()

    const userHasPro = useUserHasPro()
    const userHasProPersonally = useUserHasProPersonally()
    const clubHasPro = useClubHasPro()

    let warning
    if (userHasProPersonally && clubHasPro) {
        warning = <DuplicatePlanWarning />
    }

    return (
        <>
            <div className="display-user-block">
                <IonLabel>
                    Plan
                </IonLabel>
                {
                    userHasProPersonally && <SubscriptionManagement isClub={false} />
                }
                <IonLabel>
                    {
                        userHasPro ? (
                            <PlanName isClub={!userHasProPersonally && !!club} name={userHasPro ? 'Pro' : 'Basic'} />
                        ) : 'None'
                    }
                </IonLabel>
            </div>
            {warning}
        </>
    )
}

export const DuplicatePlanWarning = () => (
    <div className="warning">
        ⚠️   You are personally subscribed to <PlanName />,
        but also receive these benefits from your club. You may wish to
        cancel your personal subscription.
    </div>
)

type DisplayUpgradeOfferProps = {
	isClub?: boolean
}

export const DisplayUpgradeOffer = (props: DisplayUpgradeOfferProps) => {

	const { isClub } = props

    const userHasPro = useUserHasPro()
	const clubHasPro = useClubHasPro()

    const [ amPurchasing, setAmPurchasing ] = useState(false)

    if ((isClub && clubHasPro) || (!isClub && userHasPro))
        return null

    const isNative = Capacitor.isNativePlatform()

	const onClick = () => {
		setAmPurchasing(true)
		logEvent('considerPurchase')
	}

	let content
	if (isClub) {
		content = (
			<IonButton
				onClick={onClick}
				fill="outline"
				className="club-upgrade-offer"
			>
				Get
				<PlanName isClub={true} />
			</IonButton>
		)
	} else {
		content = (
			<div
				className="upgrade-offer"
				onClick={onClick}
			>
				<h2>
					<PlanName />
				</h2>
				<div className="selling-points">
					<div className="selling-point">
						<IonIcon icon={players} />
						<p>
							Multiple teams
						</p>
					</div>
					<div className="selling-point">
						<IonIcon icon={calendar} />
						<p>
							Multiple seasons
						</p>
					</div>
					<div className="selling-point">
						<IonIcon icon={analyticsSharp} />
						<p>
							Extra stats
						</p>
					</div>
				</div>
				<p>
					Get Now
				</p>

			</div>
		)
	}

	return (
		<>
			{content}
			<ModalWithCancel
				show={amPurchasing}
				setShow={setAmPurchasing}
			>
				{
					isNative ? (
						<NativePurchasing
							isClub={isClub}
						/>
					) : (
						<WebPurchasing
							isClub={isClub}
						/>
					)
				}
			</ModalWithCancel>
		</>
	)
}

export const RestorePurchases = () => {

	const onClick = async () => {

		console.log('restorePurchases()')

		if (Capacitor.isNativePlatform()) {
			try {
				const customerInfo = await Purchases.restorePurchases()
				console.log("Restored purchases? TODO", customerInfo)
				//... check customerInfo to see if entitlement is now active
			} catch (error) {
				console.error("Error restoring purchases", error)
			}
		} else {
			// Reload app to force recheck with RevenueCat
			window.location.reload()
		}
	}

	return (
		<IonButton
			className="restore-purchases-button"
			fill="clear"
			onClick={onClick}
		>
			Restore Purchase
		</IonButton>
	)
}

type SubscriptionManagementProps = {
	isClub: boolean
}

//
// We need to give the user some management options if:
// (a) they're on the web - either provide a link to Stripe or tell
//     them how to cancel
// (b) they're on a device, but they subscribed via Stripe
//
export const SubscriptionManagement = (props: SubscriptionManagementProps) => {

	const { isClub } = props

	const user = useUser()
	const club = useClub()

	const [ presentAlert ] = useIonAlert()

	const id = isClub ? club?.id : user?.uid
	const info = useStoreCustomerInfo(id || '')

	console.log('SubscriptionManagement for id', id, 'is', info)

	const [ subscription ] = Object.values(info?.subscriptions || [{ }])

	if (!subscription) {
		return null
	}

	const isNative = Capacitor.isNativePlatform()

	let onClick

	if (isNative) {
		const storeText = {
			'stripe': "Your subscription was acquired directly from AutoRoster, and can't be cancelled or changed here under Apple's Terms & Conditions. Please visit our website to manage your       subsription.",
			'promotional': "You are currently receiving a free promotional subscription.",
			'apple': "View & modify your subscription in your device Settings → Your Apple ID → Subscriptions",
			'app_store':  "View & modify your subscription in your device Settings → Your Apple ID → Subscriptions",
		} as const

		const message = storeText[subscription.store as keyof typeof storeText] || 'Unknown subscription store'
		if (message) {
			onClick = () => {
				presentAlert({
					header: 'Your Subscription',
					message,
					buttons: [ 'OK' ],
				})
			}
		} else {
			console.log('Unknown subscription store!', subscription.store)
		}

	} else {
		// Web options
		if (subscription.store === 'apple') {
			onClick = () => {
				presentAlert({
					header: 'Apple Subscription',
					message: "Your subscription was acquired via Apple. You can manage it via your Apple device or by logging on to iCloud.",
					buttons: [ 'OK' ],
				})
			}
		} else {
			return <WebSubscriptionManagement />
		}
	}

	if (!onClick)
		return null

	return (
		<IonLabel>
			<IonButton
				fill="clear"
				onClick={onClick}
			>
				Manage...
			</IonButton>
		</IonLabel>
	)
}

type PlanNameProps = {
	isClub?: boolean
	name?: string
}

export const PlanName = (props: PlanNameProps) => {

    const planName = props.name || 'Pro'

	const prefix = props.isClub ? 'Club - ' : null

    return (
        <span className="plan-name">
            {prefix}<span className="offer-appname">AutoRoster</span> <span className="offer-planname">{planName}</span>
        </span>
    )
}
