//
// Definitions (Poses)
//
import React from 'react'
import posed from 'react-pose'

export const FadeInBox = posed.div({
       visible: {
               opacity: 1,
       },
       hidden: {
               opacity: 0,
       }
})

export const DraggableItem = posed.div({
    draggable: true,
    dragBounds: { left: '-40%', right: '40%' },
	init: { scale: 1.0, zIndex: 1 },
	drag: { scale: 1.05, zIndex: 10 },
})

export const FixedItem = posed.div({
    draggable: false,
})

export const DraggableItemPlayer = posed.div({
	draggable: true,
	dragBounds: { left: '-20%', right: '20%' },
})

type PositionPreferenceProps = {
	className?: string
}

export const PositionPreference = (props: React.PropsWithChildren<PositionPreferenceProps>) => {
	return (
		<div className={`flat-position-preference posed-element ${props.className || ''}`}>
			{props.children}
		</div>
	)
}
