import React from 'react'
import {
    IonBackButton,
	IonButton,
	IonButtons,
	IonHeader,
	IonIcon,
    IonMenuButton,
	IonTitle,
	IonToolbar,
} from '@ionic/react'
import {
	useMySeason,
	useShowTeamSelectionState,
	useMyTeam,
} from './data'
import { UserPhoto } from './Profile'
import ShareButton from './ShareButton'
import { home } from 'ionicons/icons'
import './header.css'

type HeaderProps = {
	allowTeamChange?: boolean
	showShareButton?: boolean
}

const Header = (props: HeaderProps) => {

    const { allowTeamChange, showShareButton } = props

    const team = useMyTeam()
    const season = useMySeason()

    return (
        <IonHeader>
            <IonToolbar>
				<IonButtons slot="start">
					{
						allowTeamChange ?
							<AccountButton />
							:
							<IonBackButton />
					}
				</IonButtons>
				<IonButtons slot="primary">
					{ allowTeamChange && <TeamChangeButton /> }
					{ showShareButton && <ShareButton size="large" /> }
				</IonButtons>
				<IonTitle>
					<span className="is-title">
						{team?.name || 'Welcome'}
					</span>
					<br />
					<span className="is-subtitle">
						{season?.name}
					</span>
				</IonTitle>
            </IonToolbar>
        </IonHeader>
    )
}

const TeamChangeButton = () => {
    const [ showTeamSelection, setShowTeamSelection ] = useShowTeamSelectionState()

    return (
        <IonButtons slot="primary">
            <IonButton onClick={() => setShowTeamSelection(!showTeamSelection)}>
                <IonIcon icon={home} />
            </IonButton>
        </IonButtons>
    )
}

const AccountButton = () => {

    return (
        <IonButtons slot="secondary">
            <IonMenuButton menu="profileMenu">
                <UserPhoto />
            </IonMenuButton>
        </IonButtons>
    )
}

export default Header
