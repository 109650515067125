import {
    IonButton,
} from '@ionic/react'
import React, { useEffect, useState } from 'react'

import EditField from './EditField'

// Need <input type="submit" /> for Enter key to work:
// https://github.com/ionic-team/ionic-framework/issues/19368#issuecomment-943119316

//
// 'mode' must be 'add' or 'edit
//
// 'editMode' can be set to a string of a particular field if we only want to allow edits of that field
//

type EditItemProps<T> = {
	mode: 'add' | 'edit'
	type: ItemTypeDefinition<T>
	item: T
	items?: Array<T>
	editMode?: boolean | string
	className?: string
	isSeasonSettings?: boolean
	submitButtonContent?: string | JSX.Element
	onSubmit: (item: T) => void
	onCancel: () => void
}

const EditItem = <T extends Item>(props: EditItemProps<T>) => {

    const { mode, type, item, items, submitButtonContent, isSeasonSettings } = props

	const [ fieldData, setFieldData ] = useState<T>()

    const [ haveMadeChanges, setHaveMadeChanges ] = useState(false)

	useEffect(() => {
		// console.log('item changed', item)
		setFieldData(item || { })
	}, [ item ])

	//
	// On mount, make sure the element is visible.
	//
    useEffect(() => {
		const timer = setTimeout(() => {
			if (item.id) {
				const element = document.getElementById(`editable-item-id-${item.id}`)

				if (element) {
					element.scrollIntoView({
						behavior: 'smooth',
						block: 'end',
					})
				}
			}
		}, 120)
		return () => clearTimeout(timer)
	}, [ item.id ])

	const mySetFieldData = (obj: T) => {
		//
		// Delete any optional fields that have an empty value
		//
		if (obj) {
			Object.keys(obj).forEach(key => {
				const field = key as keyof T
				if (isEmptyValue(obj[field]) && type.optionalFields?.includes(field)) {
					delete obj[field]
				}
			})
		}
		setFieldData(obj)
		if (!haveMadeChanges) {
			setHaveMadeChanges(true)
		}
	}

	const { editOnlyFields, isInvalid } = type

	const fields = type.fields.slice()

	const missingFields = fields.filter(field => !type.optionalFields?.includes(field) && (!fieldData || isEmptyValue(fieldData[field])))

	const haveMissingFields = missingFields.length ? true : false

	const formIsInvalid = fieldData && Object.keys(fieldData).find(key => {
		const field = key as keyof T
		if (isInvalid?.[field]) {
			return isInvalid?.[field]({
				value: fieldData[field],
				items,
			})
		}
		return false
	})

	const canSubmitForm = !haveMissingFields && !formIsInvalid && haveMadeChanges

	// console.log('canSubmitForm?', canSubmitForm, 'haveMissingFields?', haveMissingFields, missingFields, 'haveMadeChanges?', haveMadeChanges, 'formIsInvalid?', formIsInvalid)

	//
	// We add on the optional fields here -- AFTER the missingFields check above.
	//
	if (mode === 'edit' && editOnlyFields) {
		fields.push(...editOnlyFields)
	}

	const doSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		if (!canSubmitForm) {
			console.log('Refusing to submit form.', canSubmitForm, formIsInvalid, missingFields)
			return
		}

		if (!fieldData) {
			console.log('Not submitting empty object')
			return
		}

		setFieldData(undefined)
		props.onSubmit(fieldData)
		setHaveMadeChanges(false)
	}

	const myClasses = [ ]
	if (props.className) {
		myClasses.push(props.className)
	}

	// console.log("EditItem: item", item, 'fieldData', fieldData)

	return (
		<div
			id={item?.id && `editable-item-id-${item.id}`}
			className={myClasses.join(' ')}
		>
			<form
				onSubmit={doSubmit}
			>
				{
					fields.map(field => {

						//
						// Item is disabled if type.disableFields() says so, or if
						// 'editMode' has been set not to true but instead to a string
						// field that we're permitting editing of.
						//
						const isDisabled = type.disableFields && type.disableFields({ field, item, mode, isSeasonSettings })
						const isNonEditSelected = props.editMode && props.editMode !== true && props.editMode !== field
						const disabled = !!(isDisabled || isNonEditSelected)

						return (
							<EditField
								key={String(field)}
								field={field}
								type={type}
								setFieldData={mySetFieldData}
								fieldData={fieldData}
								disabled={disabled}
								isSingleItem={false}
								isSeasonSettings={!!isSeasonSettings}
								mode={mode}
								items={items}
							/>
						)
					})
				}
				<div className="edit-buttons">

					<input type="submit" style={{
						display: 'none'
						}} />

					<IonButton
						className="button-update-item button-react-icon"
						type="submit"
						disabled={!canSubmitForm}
						// onClick={doSubmit}
					>
						{submitButtonContent || 'Submit'}
					</IonButton>
					<IonButton
						className="button-cancel"
						onClick={props.onCancel}
						fill="clear"
						color="medium"
					>
						Cancel
					</IonButton>
				</div>
			</form>
		</div>
	)
}

function isEmptyValue(value: any) {
	if (value === undefined)
		return true
	if (value === '')
		return true
	if (value === null)
		return true
	return false
}

export default EditItem
