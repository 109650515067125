import { atom, atomFamily, selector, selectorFamily, useRecoilState, useRecoilValue, useSetRecoilState, useRecoilCallback, AtomEffect, CallbackInterface, DefaultValue } from 'recoil'
import { deleteDoc, doc, collection, getDocs, query, where, onSnapshot, setDoc, DocumentData } from "firebase/firestore"

import {
	BENCHED,
  LOCAL_STORAGE_KEY_SETTINGS,
  NORMALIZE_MIN_QTRS_FOR_PAIRS,
  PAIR_MODES,
  PRESET_CUSTOM,
  ROSTER_NOT_AVAILABLE,
  dbids,
  defaultNumPeriods,
  queryFieldPositions,
} from './definitions'
import { db } from './firebase'
import { deepCopy, findMyPositions, sanitizeBest, saveToLocalStorage, ucFirst } from './util'
import {
	findPairings,
	generateAllNetStats,
	initializeSettings,
} from './functions'
import { WhereFilterOp } from '@firebase/firestore-types'
import { PurchasesOfferings } from '@revenuecat/purchases-capacitor'

//
// syncWithFirebase()
//
// Fetches data from Firebase, listens for changes, and writes upon state change
//
type syncWithFirebaseOptions = {
	where?: [ string, WhereFilterOp, '__ID__' ]
}

const syncWithFirebase = <T>(opt: syncWithFirebaseOptions = { }): AtomEffect<T> => ({ node, setSelf, trigger }) => {

	const { key } = node

	const { name, id } = keyToId(key)

    // console.log('syncWithFirebase -> init', key, trigger, 'name', name, 'id', id, opt)

	if (!id || !name) {
		// Ignore bogus calls for data like team__NaN
        // console.log('syncWithFirebase - ignore bogus')
		return
	}

    let unsubscribe

	//
    // Load initial value from Firebase && setup listener
    //
	if (trigger === 'get') {

        // console.log('loadInitial()', key)

        const { isCollection, cid, docId } = metadata({
            name,
            id,
        })

        if (isCollection) {
            const collectionRef = collection(db, cid)

            let q

            if (opt?.where) {
				const [ fieldPath, opStr, value ] = opt.where
				const replacedValue = value.replace('__ID__', id)
				q = query(collectionRef, where(fieldPath, opStr, replacedValue))
			} else {
				q = query(collectionRef)
			}

			unsubscribe = onSnapshot(q, querySnapshot => {

				const data: {
					[key: string]: DocumentData
				} = { }

				// Insert 'id'
                querySnapshot.forEach(doc => {
					data[doc.id] = {
						...doc.data(),
						id: doc.id,
					}
				})

                // console.log('Collection data update', cid, data)

				setSelf(data as T)
            })

        } else {
            // console.log("Fetching", cid, docId, id)
            unsubscribe = onSnapshot(doc(db, cid, docId || id), (doc) => {
                const data = doc.data()

                // console.log('Document data update', cid, docId || id, data)

				// Insert 'id'
				setSelf({
					...data,
				   id,
				} as T)
			})
		}
	}

	return unsubscribe
}

async function saveRosterToFirebase(args: CallbackInterface, gameId: GameId, newState: Best) {

    console.log('saveRosterToFirebase()', gameId, newState)

    const { snapshot } = args

    const s = await snapshot.getPromise(section)

    if (!s || !s.team || !s.season) {
        throw new Error("Missing team or season")
    }

    const { cid } = metadata({
        name: 'roster',
        id: `${s.team}__${s.season}`,
    })

    if (!cid) {
        throw new Error("No cid")
    }

    const data: Roster = {
        roster: newState.roster,
        players: Object.keys(newState.available).filter(player => newState.available[player]),
        orangeman: newState.orangeman,
        nulled: newState.nulled,
		subs: newState.subs,
        created: Date.now(),
    }

    console.log('saveRosterToFirebase() --->', cid, data)

    //
    // Create document if it doesn't already exist, otherwise merge in new data.
    //
    await setDoc(doc(db, cid, gameId), data, { merge: true })
}

export const useSaveRoster = () => useRecoilCallback(args => (gameId: GameId, newState: Best) => saveRosterToFirebase(args, gameId, newState))

//
// Load & save 'settings' to/from localStorage
//

const saveLocalSettings = (): AtomEffect<Settings> => ({ onSet, setSelf, trigger }) => {

	console.log('saveLocalSettings()', trigger)

	// If there's a persisted value - set it on load
	const loadPersisted = async () => {
		const newValue = initializeSettings()
		console.log('loadPersisted()', newValue)
		setSelf(newValue)
	}

	if (trigger === 'get') {
		loadPersisted()
	}

	//
	// Subscribe to state changes and persist them to the db
	//
	const savePersisted = (newValue: Settings, oldValue: Settings | DefaultValue) => {
		console.log('savePersisted effect', newValue, oldValue)

		if (newValue && Object.keys(newValue).length) {
			// If we're using a standard setting, don't save anything except 'preset', 'showNetStats', 'showNetStatsCombos', 'playerPreferencesMode'
			const savedValues = (newValue.preset === PRESET_CUSTOM ? newValue : {
				preset: newValue.preset,
				showNetStats: newValue.showNetStats,
				showNetStatsCombos: newValue.showNetStatsCombos,
				preferenceType: newValue.preferenceType,
			})
			console.log("Saving to local storage", savedValues)
			saveToLocalStorage(LOCAL_STORAGE_KEY_SETTINGS, savedValues)
		}
	}

	onSet(savePersisted)
}


//
// user
//
const user = atom<User | null | undefined>({
	key: 'user',
	default: undefined,
})

export const useUser = () => useRecoilValue(user)
export const useSetUser = () => useSetRecoilState(user)
export const useUserState = () => useRecoilState(user)

//
// section
//
export const section = atom<Section>({
	key: 'section',
	default: { },
})

export const useSection = () => useRecoilValue(section)
export const useSetSection = () => useSetRecoilState(section)
export const useSectionState = () => useRecoilState(section)

const recent = atom<RecentTeams | null>({
	key: 'recent',
	default: null,
})

export const useRecent = () => useRecoilValue(recent)
export const useRecentState = () => useRecoilState(recent)

//
// newTeam
//
// Used when selecting a new team - we need to remember who they are
// for a second before we select a season.
//
export const newTeamId = atom<TeamId | null>({
	key: 'newTeamId',
	default: null,
})

export const useNewTeamId = () => useRecoilValue(newTeamId)
export const useNewTeamIdState = () => useRecoilState(newTeamId)
export const useSetNewTeamId = () => useSetRecoilState(newTeamId)

const amAddingNewSeason = atom<boolean>({
	key: 'amAddingNewSeason',
	default: false,
})

export const useAmAddingNewSeason = () => useRecoilValue(amAddingNewSeason)
export const useSetAmAddingNewSeason = () => useSetRecoilState(amAddingNewSeason)

//
// newSeasonConnector
//
export const newSeasonConnector = atom<Connector | null>({
	key: 'newSeasonConnector',
	default: null,
})

export const useNewSeasonConnectorState = () => useRecoilState(newSeasonConnector)

const myRelevantConnector = selector<Connector|null>({
	key: 'myRelevantConnector',
	get: ({ get }) => {
		if (get(newTeamId)) {
			return get(newSeasonConnector)
		}
		return get(mySeason)?.connector || null
	},
})

export const useMyRelevantConnector = () => useRecoilValue(myRelevantConnector)

const shouldSyncFixture = atom<boolean>({
	key: 'shouldSyncFixture',
	default: false,
})

export const useSetShouldSyncFixture = () => useSetRecoilState(shouldSyncFixture)
export const useShouldSyncFixtureState = () => useRecoilState(shouldSyncFixture)

//
// team
//
const team = atomFamily<Team | null, TeamId>({
    key: 'team',
    default: null,
    effects: [
        syncWithFirebase(),
    ],
})

export const useTeam = (teamId: TeamId) => useRecoilValue(team(teamId))

//
// myTeam
//
export const myTeam = selector<Team | null>({
    key: 'myTeam',
    get: ({ get }) => {
		const s = get(section)
		const teamId = s.team
		if (!teamId)
			return null
        return get(team(teamId))
    },
})

export const useMyTeam = () => useRecoilValue(myTeam)

//
// myRelevantTeamId
//
// If we're selecting a new team/season, returns that team, otherwise
// returns the currently loaded team.
//
// i.e. if 'newTeamId', then that, otherwise 'myTeam' (from 'section')
//
const myRelevantTeamId = selector<TeamId | null>({
	key: 'myRelevantTeamId',
	get: ({ get }) => {
		const tId = get(newTeamId)
		if (tId) {
			return tId
		}
		const s = get(section)
		return s?.team || null
	},
})

//
// myRelevantTeam
//
const myRelevantTeam = selector({
	key: 'myRelevantTeam',
	get: ({ get }) => {
		const teamId = get(myRelevantTeamId)
		if (!teamId)
			return null
		return get(team(teamId))
	}
})

export const useMyRelevantTeam = () => useRecoilValue(myRelevantTeam)

//
// userTeams
//
// Send uid of user, get an object of teams belonging to that user
//
const userTeams = atomFamily<Teams, UserId>({
    key: 'userTeams',
    default: { },
    effects: [
        syncWithFirebase({
            where: [ 'managers', 'array-contains', '__ID__' ],
        }),
    ],
})

export const useUserTeams = (uid: UserId) => useRecoilValue(userTeams(uid))

//
// myUserTeams
//
// Object of teams belonging to the current user
//
const myUserTeams = selector({
    key: 'myUserTeams',
    get: ({ get }) => {
        const u = get(user)
        if (!u)
            return null
        return get(userTeams(u.uid))
    },
})

export const useMyUserTeams = () => useRecoilValue(myUserTeams)

//
// userClubs
//
const userClubs = atomFamily<Clubs, ClubId>({
    key: 'userClubs',
    default: { },
    effects: [
        syncWithFirebase({
            where: [ 'users', 'array-contains', '__ID__' ],
        })
    ],
})

//
// clubs
//
const clubs = atomFamily<Clubs, ClubId>({
    key: 'clubs',
    effects: [
        syncWithFirebase()
    ],
})

//
// thisClub -- For loading another club not necessarily connected
// to the current user, specified by clubId.
//
const thisClub = selectorFamily<Club | null, ClubId>({
    key: 'thisClub',
    get: id => ({ get }) => {
        const obj = get(clubs(id))
		const club = obj[id]
        if (!club) {
            return null
        }
        return club
    },
})

export const useThisClub = (clubId: ClubId) => useRecoilValue(thisClub(clubId))

//
// club
//
const club = selector<Club | null>({
    key: 'club',
    get: ({ get }) => {
        const u = get(user)
        if (u) {
            const [ myClub ] = Object.values(get(userClubs(u.uid)))
			console.log("looking up my clubs...", u.uid, myClub)
			return myClub || null
        }
		return null
    },
})

export const useClub = () => useRecoilValue(club)

//
// clubUser
//
const clubUser = atomFamily<ClubUser, ClubUserId>({
    key: 'clubUser',
	default: undefined,
    effects: [
        syncWithFirebase(),
    ],
})

// export const useSetClubUser = (uid) => useSetRecoilState(clubUser(uid))

//
// myClubUser
//
const myClubUser = selectorFamily<ClubUser | null, UserId>({
    key: 'myClubUser',
	get: uid => ({ get }) => {
        const c = get(club)
		if (!c) {
			return null
		}
		return get(clubUser(`${c.id}__${uid}`))
	}
})

export const useMyClubUser = (uid: UserId) => useRecoilValue(myClubUser(uid))

const myClubUsersSorted = selector<Array<UserId>>({
	key: 'myClubUsersSorted',
	get: ({ get }) => {
		const c = get(club)
		if (!c) {
			return [ ]
		}
		const userLastLogins: {
			[key: string]: number
		} = { }
		c.users.forEach(uid => {
			const u = get(myClubUser(uid))
			userLastLogins[uid] = u?.lastLogin || 0
		})
		return c.users.slice().sort((a, b) => userLastLogins[b] - userLastLogins[a])
	},
})

export const useMyClubUsersSorted = () => useRecoilValue(myClubUsersSorted)

//
// seasons
//
// Send teamId, get an object of all seasons belonging to this team
//
const teamSeasons = atomFamily<Seasons | null, TeamId>({
    key: 'teamSeasons',
    default: null,
    effects: [
        syncWithFirebase(),
    ],
})

export const useTeamSeasons = (teamId: TeamId) => useRecoilValue(teamSeasons(teamId))

/*
//
// mySeasons
//
// Get an object of all seasons for the current selected team
//
const mySeasons = selector({
    key: 'mySeasons',
    get: ({ get }) => {
        const { team: teamId } = get(section)
        return get(teamSeasons(teamId))
    }
})

export const useMySeasons = () => useRecoilValue(mySeasons)
*/

//
// mySeasonId
//
// Object of the current selected season for the current selected team
//
const mySeasonId = selector({
    key: 'mySeasonId',
    get: ({ get }) => {
        const { season: seasonId } = get(section)
        return seasonId
    }
})

export const useMySeasonId = () => useRecoilValue(mySeasonId)

//
// mySeason
//
// Object of the current selected season for the current selected team
//
const mySeason = selector<Season | null>({
    key: 'mySeason',
    get: ({ get }) => {
        const { team: teamId, season: seasonId } = get(section)
		if (teamId && seasonId) {
			const ts = get(teamSeasons(teamId))
			return ts?.[seasonId] || null
		}
		return null
	},
})

export const useMySeason = () => useRecoilValue(mySeason)

const haveValidSeason = selector<boolean>({
	key: 'haveValidSeason',
	get: ({ get }) => {
		const s = get(mySeason)
		if (s && Object.keys(s).length) {
			return true
		}
		return false
	},
})

export const useHaveValidSeason = () => useRecoilValue(haveValidSeason)

const mySeasonStatus = selector<string>({
	key: 'amLoadingSeason',
	get: ({ get }) => {
		const { team: teamId, season: seasonId } = get(section)
		if (teamId && seasonId) {
			const ts = get(teamSeasons(teamId))
			if (ts === null) {
				return 'loading'
			}
			if (ts?.[seasonId]) {
			   return 'loaded'
			}
			return 'no-season'
		}
		return 'no-seasonid'
	}
})

export const useMySeasonStatus = () => useRecoilValue(mySeasonStatus)

//
// myNumPeriods
//
// For the current season, the number of periods per game
//
const myNumPeriods = selector({
	key: 'numPeriods',
	get: ({ get }) => {
		const s = get(mySeason)
		return s?.numPeriods || defaultNumPeriods
	},
})

export const useMyNumPeriods = () => useRecoilValue(myNumPeriods)

//
// myNumPeriodsLoaded
//
// Like 'myNumPeriods', but will return 'null' rather than '4' if
// we haven't loaded this value yet.
//
const myNumPeriodsLoaded = selector({
	key: 'numPeriodsLoaded',
	get: ({ get }) => {
		const s = get(mySeason)
		if (!s)
			return null
		return s.numPeriods || defaultNumPeriods
	},
})

export const useMyNumPeriodsLoaded = () => useRecoilValue(myNumPeriodsLoaded)

const myGameFormat = selector({
	key: 'myGameFormat',
	get: ({ get }) => {
		const s = get(mySeason)
		return s?.gameFormat || 0
	},
})

export const useMyGameFormat = () => useRecoilValue(myGameFormat)

//
// myFieldPositions
//
// For the current season, the fieldPositions
//
const myFieldPositions = selector({
	key: 'myFieldPositions',
	get: ({ get }) => {
		const f = get(myGameFormat)
		return queryFieldPositions(f || 0)
	},
})

export const useMyFieldPositions = () => useRecoilValue(myFieldPositions)

//
// games
//
const teamGames = atomFamily<Games | null, TeamSeasonId>({
    key: 'teamGames',
    default: null,
    effects: [
        syncWithFirebase(),
    ],
})

//
// myGames
//
// Get an object of all games for the current selected team & season
//
export const myGames = selector({
    key: 'myGames',
    get: ({ get }) => {
        const { team: teamId, season: seasonId } = get(section)
        const id = `${teamId}__${seasonId}`
        return get(teamGames(id))
    }
})

export const useMyGames = () => useRecoilValue(myGames)

const myGame = selector<Game | null>({
    key: 'myGame',
    get: ({ get }) => {
        const g = get(myGames)
        if (!g)
            return null
        const gameId = get(myGameId)
		if (!gameId)
			return null
        return g[gameId] || null
    },
})

export const useMyGame = () => useRecoilValue(myGame)

const myGameId = selector({
    key: 'myGameId',
    get: ({ get }) => {
        const { game: gameId } = get(section)
        return gameId
    },
})

export const useMyGameId = () => useRecoilValue(myGameId)

//
// players
//
const teamPlayers = atomFamily<Players | null, TeamSeasonId>({
    key: 'teamPlayers',
    default: null,
    effects: [
        syncWithFirebase(),
    ],
})

//
// myPlayers
//
// Get an object of all games for the current selected team & season
//
const myPlayers = selector<Players | null>({
    key: 'myPlayers',
    get: ({ get }) => {
        const { team: teamId, season: seasonId } = get(section)
        const id = `${teamId}__${seasonId}`
        const r = get(teamPlayers(id))
        return r
    }
})

export const useMyPlayers = () => useRecoilValue(myPlayers)

//
// myPlayer
//
// Get a single player object
//
const myPlayer = selectorFamily<Player | null, PlayerId>({
    key: 'myPlayer',
    get: playerId => ({ get }) => {
        const p = get(myPlayers)
        if (!p)
            return null
        return p[playerId] || null
    },
})

export const useMyPlayer = (playerId: PlayerId) => useRecoilValue(myPlayer(playerId))

//
// myPlayerId
//
// Extracted from 'section'
//
const myPlayerId = selector({
    key: 'myPlayerId',
    get: ({ get }) => {
        const { player: playerId } = get(section)
        return playerId
    },
})

export const useMyPlayerId = () => useRecoilValue(myPlayerId)

//
// myPlayerNames
//
// This has an optimization to prevent unncessary re-renders. We
// use the stringified function as a stopper to prevent a flow
// of re-rendering.
//
const myPlayerNamesStringified = selector<string|null>({
    key: 'myPlayerNamesStringified',
    get: ({ get }) => {
        const p = get(myPlayers)
        if (!p)
            return null
		const playerNames: {
			[key: string]: string
		} = { }
	   	Object.keys(p).forEach(playerId => {
            playerNames[playerId] = p[playerId].name
        })
        return JSON.stringify(playerNames)
    },
})

const myPlayerNames = selector({
    key: 'myPlayerNames',
    get: ({ get }) => {
        const json = get(myPlayerNamesStringified)
        if (!json)
            return null
        return JSON.parse(json)
    },
})

export const useMyPlayerNames = () => useRecoilValue(myPlayerNames)

const myPlayerName = selectorFamily<string, PlayerId>({
    key: 'myPlayerName',
    get: playerId => ({ get }) => {
        const p = get(myPlayerNames)
        if (!p)
            return null
        return p[playerId]
    },
})

export const useMyPlayerName = (playerId: PlayerId) => useRecoilValue(myPlayerName(playerId))

//
// Can be better than calling Object.keys(myPlayers) because this
// only updates when id or name changes.
//
const myPlayerIds = selector<PlayerId[]>({
    key: 'myPlayerIds',
    get: ({ get }) => {
        const pn = get(myPlayerNames)
        if (!pn)
            return pn
        return Object.keys(pn)
    },
})

export const useMyPlayerIds = () => useRecoilValue(myPlayerIds)

//
// teamRosters
//
const teamRosters = atomFamily<Rosters, TeamSeasonId>({
    key: 'teamRosters',
    default: { },
    effects: [
        syncWithFirebase(),
    ],
})

export const useTeamRosters = (id: TeamSeasonId) => useRecoilValue(teamRosters(id))

//
// myRosters
//
// Get an object of all games for the current selected team & season
//
const myRosters = selector({
    key: 'myRosters',
    get: ({ get }) => {
        const { team: teamId, season: seasonId } = get(section)
        const id = `${teamId}__${seasonId}`
        return get(teamRosters(id))
    }
})

export const useMyRosters = () => useRecoilValue(myRosters)

//
// myRoster
//
const myRoster = selector<Roster | null>({
    key: 'myRoster',
    get: ({ get }) => {
        const r = get(myRosters)
        const { game: gameId } = get(section)
		if (!gameId)
			return null
        return r[gameId] || null
    },
})

export const useMyRoster = () => useRecoilValue(myRoster)

//
// myOnCourtRosters - returns a roster array but with 'null' if
// the scheduled player was unavailable or nulled, and with each
// position being an array of players who played there.
//
// Used only by findPairings().
//
const myOnCourtRosters = selector({
    key: 'myOnCourtRosters',
    get: ({ get }) => {
        const r = get(myRosters)
		const obj: OnCourtRosters = { }
        Object.keys(r).forEach(gameId => {

            const { roster, players, nulled, subs } = r[gameId]

			const availablePlayers: {
				[key: string]: boolean
			} = { }

			players.forEach(playerId => {
				availablePlayers[playerId] = true
			})

            const onCourtRoster: RosterDataWithNullablesAndSubs = roster.map(playerId => (playerId && availablePlayers[playerId]) ? [ playerId ] : [ null ])

            nulled?.forEach(positionIndex => onCourtRoster[positionIndex] = [ null ])

			if (subs) {
				// console.log('adding subs', subs)
				for (let i = 0; i < subs.length; i += 2) {
					const [ sub1, sub2 ] = subs.slice(i, i + 2)
					onCourtRoster[sub1].push(onCourtRoster[sub2][0])
					onCourtRoster[sub2].push(onCourtRoster[sub1][0])
				}
			}

            obj[gameId] = onCourtRoster
        })
		return obj
    }
})

export const useMyOnCourtRosters = () => useRecoilValue(myOnCourtRosters)

const amDragging = atom({
	key: 'amDragging',
	default: false,
})

export const useAmDragging = () => useRecoilValue(amDragging)
export const useSetAmDragging = () => useSetRecoilState(amDragging)

//
// best - A local copy of key data, because we don't want to wait
// for a round trip to Firebase to update what the user is seeing.
//
function defaultBest(): Best {
	return {
		created: 0,
		roster: [ ],
		available: { },
		players: [ ],
		nulled: [ ],
		subs: [ ],
		orangeman: null,
	}
}

const best = atomFamily<Best, GameId>({
    key: 'best',
    default: defaultBest(),
})

const myBest = selector<Best>({
    key: 'myBest',
    get: ({ get }) => {
        const id = get(myGameId)
		if (id) {
			const ret = get(best(id))
			if (ret) {
				return ret
			}
		}
        return defaultBest()
    },
    set: ({ get, set }, newValue) => {
        const id = get(myGameId)
        if (id) {
            return set(best(id), newValue)
        }
    },
})

export const useMyBest = () => useRecoilValue(myBest)
export const useMyBestState = () => useRecoilState(myBest)

const madeChanges = atomFamily({
    key: 'madeChanges',
    default: false,
})

const myMadeChanges = selector({
    key: 'myMadeChanges',
    get: ({ get }) => {
        const id = get(myGameId)
        return get(madeChanges(id)) || false
    },
    set: ({ get, set }, newValue) => {
        const id = get(myGameId)
        return set(madeChanges(id), newValue)
    },
})

export const useSetMyMadeChanges = () => useSetRecoilState(myMadeChanges)
export const useMyMadeChangesState = () => useRecoilState(myMadeChanges)

//
// locks
//
const locks = atomFamily<Array<boolean>, GameId>({
	key: 'locks',
	default: [ ],
})

const myLocks = selector({
	key: 'myLocks',
	get: ({ get }) => {
		const id = get(myGameId) || ''
		return get(locks(id)) || [ ]
	},
	set: ({ get, set }, newValue) => {
		const id = get(myGameId) || ''
		return set(locks(id), newValue)
	},
})

export const useMyLocks = () => useRecoilValue(myLocks)
export const useMyLocksState = () => useRecoilState(myLocks)

//
// subSplit
//
const subSplit = atom<null|RosterPositionIndex>({
	key: 'subSplit',
	default: null,
})

export const useSubSplit = () => useRecoilValue(subSplit)
export const useSubSplitState = () => useRecoilState(subSplit)

type RecentSubSplit = Array<number> | null

const recentSubSplit = atom<RecentSubSplit>({
	key: 'recentSubSplit',
	default: null,
})

export const useRecentSubSplit = () => useRecoilValue(recentSubSplit)

//
// History
//
const rosterHistory = atomFamily<Array<Best>, GameId>({
    key: 'rosterHistory',
    default: [ ],
})

const rosterHistoryIndex = atomFamily({
    key: 'rosterHistoryIndex',
    default: 0,
})

const canUndo = selector({
    key: 'canUndo',
    get: ({ get }) => {
        if (!get(ready)) {
            return false
        }
        const id = get(myGameId)
        const i = get(rosterHistoryIndex(id))
        return i > 1
    },
})

export const useCanUndo = () => useRecoilValue(canUndo)

const canRedo = selector({
    key: 'canRedo',
    get: ({ get }) => {
        if (!get(ready)) {
            return false
        }
        const id = get(myGameId)
		if (!id)
			return false
        const history = get(rosterHistory(id))
        const i = get(rosterHistoryIndex(id))
        return i < history.length
    },
})

export const useCanRedo = () => useRecoilValue(canRedo)

async function addToHistory(args: CallbackInterface, newState?: Best, isNewHistory?: boolean, isErase?: boolean) {

    const { snapshot, set } = args

    const id = await snapshot.getPromise(myGameId)

	if (!id)
		return

    const stateToAdd = newState || await snapshot.getPromise(best(id))
    const currentBestHistory = await snapshot.getPromise(rosterHistory(id))
    const currentBestHistoryIndex = await snapshot.getPromise(rosterHistoryIndex(id))

	//
	// Every time new data is received by Roster::calculate_initial_values(), it sends
	// a request here via startNewHistory(). This is often triggered by autosave, so
	// we want to ignore it. However, if we're loading the roster for the first time,
	// we probably got sent an empty roster before it was fully loaded, and we want
	// to let that be overwritten by the actual data.
	//
	// If the user is erasing a roster, though, let's record that as valid history.
	//
	if (isNewHistory && !isErase) {
		if (newState && currentBestHistory.length === 1 && currentBestHistory[0].roster.length === 0) {
			//
			// This is the correctly loaded initial state, which should be allowed
			// to overwrite the single existing history.
			//
			// console.log('Replacing empty history.')
			set(rosterHistory(id), [ newState ])
		}
		if (currentBestHistory.length) {
			// console.log("Not adding initial history because we already have some.")
			return
		}
	}

    const newBestHistory = currentBestHistory.slice(0, currentBestHistoryIndex)
    newBestHistory.push(stateToAdd)

    const newBestHistoryIndex = newBestHistory.length

    set(rosterHistory(id), newBestHistory)

    set(rosterHistoryIndex(id), newBestHistoryIndex)
}

export const useAddToHistory = () => useRecoilCallback(args => (newState?: Best) => addToHistory(args, newState))
export const useStartHistory = () => useRecoilCallback(args => (newState: Best, isErase?: boolean) => addToHistory(args, newState, true, isErase), [ ]) // This caching is important to prevent infinite loops in Roster.js -> calculateInitialValues()

async function undo(args: CallbackInterface, undoAll?: boolean) {
    const { snapshot, set } = args

    const id = await snapshot.getPromise(myGameId)
	if (!id)
		return

	// Cancel any pending subsplit
	setSubSplit(args, null)

    const currentBestHistory = await snapshot.getPromise(rosterHistory(id))
    const currentBestHistoryIndex = await snapshot.getPromise(rosterHistoryIndex(id))

    if (currentBestHistoryIndex > 1) {
        if (undoAll) {
            set(best(id), currentBestHistory[0])
            set(rosterHistoryIndex(id), 1)
        } else {
            set(best(id), currentBestHistory[currentBestHistoryIndex - 2])
            set(rosterHistoryIndex(id), currentBestHistoryIndex - 1)
        }
        set(madeChanges(id), true)
    }
}

export const useUndo = () => useRecoilCallback(args => () => undo(args), [ ])
export const useUndoAll = () => useRecoilCallback(args => () => undo(args, true), [ ])

async function redo(args: CallbackInterface, redoAll?: boolean) {
    const { snapshot, set } = args

    const id = await snapshot.getPromise(myGameId)
	if (!id)
		return

	// Cancel any pending subsplit
	setSubSplit(args, null)

	const currentBestHistory = await snapshot.getPromise(rosterHistory(id))
    const currentBestHistoryIndex = await snapshot.getPromise(rosterHistoryIndex(id))

    if (currentBestHistoryIndex < currentBestHistory.length) {
        if (redoAll) {
            set(best(id), currentBestHistory[currentBestHistory.length - 1])
            set(rosterHistoryIndex(id), currentBestHistory.length)
        } else {
            set(best(id), currentBestHistory[currentBestHistoryIndex])
            set(rosterHistoryIndex(id), currentBestHistoryIndex + 1)
        }
        set(madeChanges(id), true)
    }
}

export const useRedo = () => useRecoilCallback(args => () => redo(args))
export const useRedoAll = () => useRecoilCallback(args => () => redo(args, true), [ ])

async function performSubSplit(args: CallbackInterface) {
	const { snapshot, set } = args

	const ss = await snapshot.getPromise(subSplit)
	if (ss === null)
		return

	console.log('performSubSplit', subSplit)
	const id = await snapshot.getPromise(myGameId)
	if (!id)
		return

	const b = await snapshot.getPromise(best(id))
	const n = await snapshot.getPromise(myNumPeriods)
	const f = await snapshot.getPromise(myGameFormat)

	const subs = b.subs?.slice() || [ ]

	let newSubs: Subs

	const index = subs.findIndex(posIndex => posIndex === ss)
	if (index === -1) {
		const partner = findSubSplitPartner(ss, b, n, f)
		if (partner === null) {
			console.error("Couldn't find a partner for", ss)
			return
		}
		newSubs = [
			...subs,
			ss,
			partner,
		]
		console.log("Adding new sub pair", newSubs)
		set(recentSubSplit, [ 1, ss, partner ])

	} else {
		const idx = index % 2 === 0 ? index : index - 1
		console.log("Remove this sub pair", subs[idx], subs[idx + 1])
		newSubs = subs.toSpliced(idx, 2)
		set(recentSubSplit, [ 0, subs[idx], subs[idx + 1] ])
	}

	const newState = sanitizeBest({
		...b,
		subs: newSubs,
	}, n, f)

	set(best(id), newState)

	await addToHistory(args, newState)

	set(madeChanges(id), true)
	set(subSplit, null)

	console.log('Finished subsplitting: subs is now', newSubs)

	setTimeout(() => {
		console.log("Removing subAnimation")
		set(recentSubSplit, null)
	}, 1000)
}

export const usePerformSubSplit = () => useRecoilCallback(args => () => performSubSplit(args))

const helpTab = atom({
	key: 'helpTab',
	default: '',
})

export const useHelpTabState = () => useRecoilState(helpTab)
export const useSetHelpTab = () => useSetRecoilState(helpTab)

async function swapPositions(args: CallbackInterface, id: GameId, from: RosterPositionIndex, to: RosterPositionIndex) {

    const { set, snapshot } = args

    console.log('swapPositions', from, to)

	if (typeof from !== 'number' || typeof to !== 'number' || isNaN(from) || isNaN(to)) {
		console.error("Refusing to perform swap between non-numerical positions", from, to)
		return
	}

	const numPeriods = await snapshot.getPromise(myNumPeriods)

    set(best(id), prevState => {
        const newState = deepCopy(prevState)
        const temp = newState.roster[from]

		// Sanity check: Make sure from/to quarters match
		const playersPerQtr = newState.roster.length / numPeriods
		if (Math.floor(from / playersPerQtr) !== Math.floor(to / playersPerQtr)) {
			console.error("Illegal attempt to swap", from, to, "with roster", newState)
			return prevState
		}

        newState.roster[from] = newState.roster[to]
        newState.roster[to] = temp
        // console.log('new best', newState.roster)

        void addToHistory(args, newState)

        return newState
    })

    set(madeChanges(id), true)
}

export const useSwapPositions = (id: GameId) => useRecoilCallback(args => (from: RosterPositionIndex, to: RosterPositionIndex) => swapPositions(args, id, from, to))

function swapPlayers(args: CallbackInterface, id: GameId, from: PlayerId, to: PlayerId) {

	const { set } = args

	console.log('swapPlayers', from, to)

	if (typeof from !== 'string' || typeof to !== 'string') {
		console.error("Refusing to perform swap between non-string players", from, to)
		return
	}

	const swapPlayer = (player: PlayerId) => {
		if (player === from)
			return to
		else if (player === to)
			return from
		return player
	}

	set(best(id), prevState => {
		const newState = deepCopy(prevState)

		newState.players = newState.players.map(player => swapPlayer(player))
		newState.roster = newState.roster.map(player => swapPlayer(player))

		void addToHistory(args, newState)

		return newState
	})

	set(madeChanges(id), true)
}

export const useSwapPlayers = (id: GameId) => useRecoilCallback(args => (from: PlayerId, to: PlayerId) => swapPlayers(args, id, from, to))

async function swapQtrs(args: CallbackInterface, id: GameId, from: number, to: number) {

    const { snapshot, set } = args

	console.log('swapQtrs', from, '-->', to)

	const b = await snapshot.getPromise(best(id))
	const n = await snapshot.getPromise(myNumPeriods)

	const qtrLength = b.roster.length / n

    set(best(id), prevState => {
        const newState = deepCopy(prevState)
		const { roster, nulled } = prevState

		for (let i = 0; i < qtrLength; i += 1) {
			const fromI = from * qtrLength + i
			const toI = to * qtrLength + i

			newState.roster[fromI] = roster[toI]
			newState.roster[toI] = roster[fromI]
		}

		const newNulled = nulled.map(i => {
			if (i >= from * qtrLength && i < (from + 1) * qtrLength) {
				return to * qtrLength + (i % qtrLength)
			} else if (i >= to * qtrLength && i < (to + 1) * qtrLength) {
				return from * qtrLength + (i % qtrLength)
			}
			return i
		})

		console.log("Mapping nulled", nulled, "to", newNulled, 'with', from, to)

		newState.nulled = newNulled

        void addToHistory(args, newState)

        return newState
    })

	set(locks(id), prevState => {
		const newState = prevState.slice()

		for (let i = 0; i < qtrLength; i += 1) {
			const fromI = from * qtrLength + i
			const toI = to * qtrLength + i

			newState[fromI] = prevState[toI]
			newState[toI] = prevState[fromI]
		}

		return newState
	})

    set(madeChanges(id), true)
}

export const useSwapQtrs = (id: GameId) => useRecoilCallback(args => (from: number, to: number) => swapQtrs(args, id, from, to))

//
// Respond to the user tapping a position. This will cycle through available states:
// 1. Normal
// 2. Locked
// 3. SubSplit (if available)
// 4. Nulled (if available)
//
async function toggleLock(args: CallbackInterface, id: GameId, i: RosterPositionIndex) {

	const { snapshot } = args

	const b = await snapshot.getPromise(best(id))
	const l = await snapshot.getPromise(locks(id))
	const ss = await snapshot.getPromise(subSplit)

	const isLocked = l[i]
	const isNulled = b.nulled.includes(i)
	const isSubSplitting = ss === i
	const currentState = isLocked ? 'locked' : isNulled ? 'nulled' : isSubSplitting ? 'subsplit' : 'normal'

	const n = await snapshot.getPromise(myNumPeriods)
	const f = await snapshot.getPromise(myGameFormat)

	//
	// You can toggle the sub status of this position if:
	// (a) it's already a sub; or
	// (b) it's an on-court position and there's a partner available.
	//
	const canSubSplit = b.subs?.find(posIndex => posIndex === i) || !!findSubSplitPartner(i, b, n, f)
	const { date } = await snapshot.getPromise(myGame) || { }
	const canNull = !!(date && date < Date.now())

	const states = [ 'normal', 'locked' ]
	if (canSubSplit) {
		states.push('subsplit')
	}
	if (canNull) {
		states.push('nulled')
	}

	const currentIndex = states.indexOf(currentState)
	const nextIndex = (currentIndex + 1) % states.length
	const nextState = states[nextIndex]

	console.log('Toggling lock', i, 'from', currentState, 'to', nextState)

	switch (nextState) {
		case 'locked': {
			await setLock(args, id, i, true)
			break
		}
		case 'subsplit': {
			await setLock(args, id, i, false)
			setSubSplit(args, i)
			break
		}
		case 'nulled': {
			await setLock(args, id, i, false)
			setSubSplit(args, null)
			await setNulled(args, id, i, true)
			break
		}
		default: {
			await setLock(args, id, i, false)
			await setNulled(args, id, i, false)
			setSubSplit(args, null)
			break
		}
	}
}

export const useToggleLock = (id: GameId) => useRecoilCallback(args => (i: RosterPositionIndex) => toggleLock(args, id, i))

async function setLock(args: CallbackInterface, id: GameId, i: RosterPositionIndex, value: boolean) {

	const { set } = args

	set(locks(id), prevData => {
		const newLocks = prevData.slice()
		newLocks[i] = value
		return newLocks
	})
}

function setSubSplit(args: CallbackInterface, i: RosterPositionIndex|null) {
	const { set } = args
	set(subSplit, i)
}

async function setNulled(args: CallbackInterface, id: GameId, i: RosterPositionIndex, value: boolean) {

	const { set, snapshot } = args

	const b = await snapshot.getPromise(best(id))
	const n = await snapshot.getPromise(myNumPeriods)
	const f = await snapshot.getPromise(myGameFormat)

	let nulled
	if (value) {
		//
		// Add to nulled
		//
		nulled = Array.from(new Set([
			...b.nulled,
			i,
		]))
	} else {
		//
		// Remove from nulled
		//
		nulled = b.nulled.filter(value => value !== i)
	}

	const newState = sanitizeBest({
		...b,
		nulled,
	}, n, f)

	set(best(id), newState)

	await addToHistory(args, newState)

	set(madeChanges(id), true)
}

function findSubSplitPartner(i: RosterPositionIndex, best: Best, numPeriods: NumPeriods, gameFormat: GameFormat) {

	const playersPerQtr = best.roster.length / numPeriods
	const qtr = Math.floor(i / playersPerQtr)
	const start = qtr * playersPerQtr

	const fieldPositions = queryFieldPositions(gameFormat)
	const numFieldPositions = fieldPositions.length

	if (i > start + numFieldPositions) {
		console.error("Asked for illegal findSubSplitPartner for", i, "with start", start, numFieldPositions)
		return null
	}

	if (i >= start + numFieldPositions) {
		console.log("Can't split a bench position.", i, "with start", start, numFieldPositions)
		return null
	}

	const potentialPartnerPosIndexes = [ ]

	for (let j = start + numFieldPositions; j < start + playersPerQtr; j += 1) {
		if (best.subs?.findIndex(posIndex => posIndex === j) !== -1) {
			console.log("Skipping potential subSplit partner posIndex", j, "because it's already a sub")
		} else {
			console.log("Found potential subSplit partner posIndex", j, "for", i, best.roster[j])
			potentialPartnerPosIndexes.push(j)
		}
	}

	if (potentialPartnerPosIndexes.length) {
		// console.log("These bad boys", potentialPartnerPosIndexes, "are available for subSplit", best)
		const sorted = potentialPartnerPosIndexes.sort((pos1, pos2) => {
			const p1 = best.roster[pos1]
			const p2 = best.roster[pos2]
			const availabilityScore = Number(best.available[p2]) - Number(best.available[p1])
			const nulledScore = Number(best.nulled.includes(pos1)) - Number(best.nulled.includes(pos2))

			// console.log('bad boy score', availabilityScore || nulledScore, pos1, 'vs', pos2, availabilityScore, nulledScore)
			return availabilityScore || nulledScore
		})
		// console.log('sorted bad boys', sorted)
		return sorted[0]
	}

	console.error("Failed to find subSplit partner for", i, "with start", start, numFieldPositions, fieldPositions)
	return null
}

const includeFutureRosters = atom({
	key: 'includeFutureRosters',
	default: false,
})

export const useIncludeFutureRostersState = () => useRecoilState(includeFutureRosters)

function keyToId(str: string) {

    const arr = str.split('__').map(bit => bit.replace(/"/g, ''))

    const name = arr.shift()

    // Make sure each component is valid
    const id = (arr.filter(bit => !bit || bit === 'null' || bit === 'undefined').length === 0 && arr.join('__')) || undefined

    return {
        name,
        id,
    }
}

//
// This is a bit slippery because it can be true when we have a user.
// At the moment I'm being careful in App.tsx and Login.tsx to update
// it whenever the user might login.
//
const showLogin = atom({
	key: 'showLogin',
	default: false,
})

export const useSetShowLogin = () => useSetRecoilState(showLogin)
export const useShowLoginState = () => useRecoilState(showLogin)

//
// Should we be showing the team/season selector popup?
//
const showTeamSelection = atom<boolean | null>({
	key: 'showTeamSelection',
	default: null,		// Not false because Teams.js wants to detect when we're starting up with no team selected
})

export const useShowTeamSelection = () => useRecoilValue(showTeamSelection)
export const useSetShowTeamSelection = () => useSetRecoilState(showTeamSelection)
export const useShowTeamSelectionState = () => useRecoilState(showTeamSelection)

const rosterIsStale = atomFamily<boolean, GameId>({
    key: 'rosterIsStale',
    default: false,
})

const myRosterIsStale = selector<boolean>({
    key: 'myRosterIsStale',
    get: ({ get }) => {
        const id = get(myGameId)
        return get(rosterIsStale(id || '')) || false
    },
    set: ({ get, set }, newValue) => {
        const id = get(myGameId)
        return set(rosterIsStale(id || ''), newValue)
    },
})

export const useMyRosterIsStale = () => useRecoilValue(myRosterIsStale)
export const useSetMyRosterIsStale = () => useSetRecoilState(myRosterIsStale)

const settings = atom<Settings>({
    key: 'settings',
    default: { },
	effects: [
		saveLocalSettings(),
    ],
})

export const useSettings = () => useRecoilValue(settings)
export const useSettingsState = () => useRecoilState(settings)

//
// updateSectionFromUrl
//
async function updateSectionFromUrl(args: CallbackInterface, pathname?: string, search?: string) {

    const { set, snapshot } = args

    console.log('updateSectionFromUrl()', pathname, search)

    const prevSection = await snapshot.getPromise(section)
    const newSection: Section = { }

    if (search) {
        search.split(/[?&]/).filter(v => v).forEach(str => {
            const [ key, value ] = str.split('=')
            if (key === 'team' || key === 'season') {
                newSection[key] = value
            }
        })
    }

    if (pathname) {
        const params = pathname.split('/').filter(v => v)
        if (params.length) {
            if (params[0] === 'games' && params[1]) {
                // If we have a new gameId, set that.
                // Don't null this out because it flashes a 'game does not exist'
                // error when you back out of a game page.
                newSection.game = params[1]
            } else if (params[0] === 'players') {
                newSection.player = params[1]
            }
        }
    }

    if (Object.keys(newSection).length) {
        let changedSomething = false
        Object.keys(newSection).forEach(key => {
            if (newSection[key as keyof Section] !== prevSection[key as keyof Section]) {
                changedSomething = true
            }
        })
        if (changedSomething) {
            console.log("Updating section from Url", pathname, search, newSection)
            set(section, prevSection => ({
                ...prevSection,
                ...newSection
            }))
        }
    }
}

export const useUpdateSectionFromUrl = () => useRecoilCallback(args => (pathname?: string, search?: string) => updateSectionFromUrl(args, pathname, search), [ ])

const ready = atom({
    key: 'ready',
    default: true,
})

export const useReady = () => useRecoilValue(ready)
export const useSetReady = () => useSetRecoilState(ready)

//
// preferences - Roster position preferences for players in particular positions
//

const myPreferences = selector<PlayersPrefs>({
    key: 'myPreferences',
    get: ({ get }) => {
		const prefs: {
			[key: PlayerId]: PlayerPrefs | undefined
		} = { }
		const p = get(myPlayers)
		if (p) {
			Object.entries(p).forEach(([ playerId, player ]) => {
				prefs[playerId] = player.prefs
			})
		}
		return prefs
    },
})

export const useMyPreferences = () => useRecoilValue(myPreferences)

const showPositionPreferences = atom({
    key: 'showPositionPreferences',
    default: false,
})

export const useShowPositionPreferencesState = () => useRecoilState(showPositionPreferences)

//
// netStats
//
const myNetStats = selector({
    key: 'netStats',
    get: ({ get }) => {
        const g = get(myGames)
        const p = get(myPlayerNamesStringified)
		const players = p ? JSON.parse(p) as Players : undefined
		const n = get(myNumPeriods)
		const f = get(myGameFormat)
		const r = get(myRosters)
        return generateAllNetStats({
            games: g || undefined,
			rosters: r,
            players,
			numPeriods: n,
			gameFormat: f,
        })
    },
})

export const useMyNetStats = () => useRecoilValue(myNetStats)

//
// comboStats
//
const myComboStats = selector<ComboStats>({
    key: 'comboStats',
    get: ({ get }) => {
		const combo: ComboStats = {
			pairs: { },
			trios: { },
		}
		const g = get(myGames)
		const r = get(myOnCourtRosters)
		const n = get(myNumPeriods)
		const f = get(myGameFormat)
		if (g) {
			const tmp = {
				pairs: findPairings({
					games: g,
					onCourtRosters: r,
					numPeriods: n,
					gameFormat: f,
					findTrios: false,
					userHasPro: true,
				}),
				trios: findPairings({
					games: g,
					onCourtRosters: r,
					numPeriods: n,
					gameFormat: f,
					findTrios: true,
					userHasPro: true,
				})
			}

			PAIR_MODES.forEach(mode => {
				tmp[mode].forEach(row => {

					const { id, diff, n } = row

					let value = diff / n

					const norm = NORMALIZE_MIN_QTRS_FOR_PAIRS[mode]

					if (n < norm) {
						value *= 0.80 / Math.pow(norm - n, 1.5)
					}

					combo[mode] = {
						...combo[mode],
						[id]: value,
					}
				})
			})
		}
		return combo
	},
})

export const useMyComboStats = () => useRecoilValue(myComboStats)

const editMode = atom({
    key: 'editMode',
    default: false,
})

export const useEditMode = () => useRecoilValue(editMode)
export const useEditModeState = () => useRecoilState(editMode)

const activeTab = atom<string | null>({
    key: 'activeTab',
    default: null,
})

export const useActiveTab = () => useRecoilValue(activeTab)
export const useActiveTabState = () => useRecoilState(activeTab)

const amActiveTab = selectorFamily<boolean, string>({
    key: 'amActiveTab',
    get: id => ({ get }) => {
        const t = get(activeTab)
        return t === id
    },
})

export const useAmActiveTab = (id: string) => useRecoilValue(amActiveTab(id))

const newsNotification = atom({
	key: 'newsNotification',
	default: false,
})

export const useNewsNotification = () => useRecoilValue(newsNotification)
export const useNewsNotificationState = () => useRecoilState(newsNotification)
export const useSetNewsNotification = () => useSetRecoilState(newsNotification)

/*
 *
 * Selectors!
 *
 */


//
// showNetStats - if user has permission and hasn't toggled them off in settings
//
const showNetStats = selector({
    key: 'showNetStats',
    get: ({ get }) => {
        if (get(canViewNetStats)) {
            const p = get(settings)
            if (p?.showNetStats) {
                return true
            }
        }
        return false
    },
})

export const useShowNetStats = () => useRecoilValue(showNetStats)

//
// showNetStatsCombos - true if toggled on in Settings + 'showNetStats' is also true
//
const showNetStatsCombos = selector({
    key: 'showNetStatsCombos',
    get: ({ get }) => {
        if (get(showNetStats)) {
            const p = get(settings)
            if (p?.showNetStatsCombos) {
                return true
            }
        }
        return false
    },
})

export const useShowNetStatsCombos = () => useRecoilValue(showNetStatsCombos)

//
// true if logged-in user is viewing team they are allowed to manage
//
const amViewingOwnTeam = selector({
    key: 'amViewingOwnTeam',
    get: ({ get }) => {
        const u = get(user)
        if (u) {
            const t = get(myTeam)
            if (t?.managers?.includes(u.uid)) {
                return true
            }
        }
        return false
    },
})

export const useAmViewingOwnTeam = () => useRecoilValue(amViewingOwnTeam)

//
// true if user is a club admin for the current team
//
const amClubAdmin = selector({
	key: 'amClubAdmin',
	get: ({ get }) => {
		const u = get(user)
		if (u) {
			const c = get(club)
			if (c?.admin?.includes(u.uid)) {
				//
				// I'm a club admin, but is this team in the club?
				//
				const t = get(myTeam)
				if (t?.managers?.some(id => c?.users?.includes(id))) {
					// At least one of this team's managers is in the club,
					// so club admin have the right to manage this team.
					return true
				}
				return true
			}
		}
		return false
	},
})

export const useAmClubAdmin = () => useRecoilValue(amClubAdmin)

//
// You see NetStats if you are:
// * a team manager; or
// * a club admin
//
const canViewNetStats = selector<boolean>({
    key: 'canViewNetStats',
    get: ({ get }) => get(amViewingOwnTeam) || get(amClubAdmin),
})

export const useCanViewNetStats = () => useRecoilValue(canViewNetStats)

//
// You can edit items if you're a team manager, or if you're viewing
// a list of your teams.
//
const canEditItem = selectorFamily<boolean, string | undefined>({
    key: 'canEditItem',
    get: itemUnit => ({ get }) => {

		// console.log("Can I edit this?", itemUnit)

        if (get(amViewingOwnTeam)) {
            return true
        }

        //
        // User viewing "My Teams" page
        //

        const u = get(user)
		const amViewingMyTeams = get(showTeamSelection)
        if (u && amViewingMyTeams && (itemUnit === 'team' || itemUnit === 'season')) {
            return true
        }

        return false
    },
})

export const useCanEditItem = (itemType?: string) => useRecoilValue(canEditItem(itemType))

const canAddItem = selectorFamily({
    key: 'canAddItem',
    get: itemUnit => ({ get }) => {
        const u = get(user)

		// console.log("Can I add?", itemUnit)

        if (u) {

			if (itemUnit === 'team') {
                //
                // Any user can create a team except free email+password only
				// accounts that haven't been verified.
                //
				if (get(userMustVerifyEmail)) {
					return false
				}

				return true
			}

			if (itemUnit === 'club') {
				//
				// Any user can create a club.
				//
				return true
			}

			if (itemUnit === 'season') {
				//
				// Only permit if we're viewing a team that we can manage
				//

				const t = get(myRelevantTeam)
				if (t?.managers?.includes(u.uid)) {
					return true
				}
			}

            if (get(amViewingOwnTeam)) {
                return true
            }
        }

        return false
    },
})

export const useCanAddItem = (itemUnit: string) => useRecoilValue(canAddItem(itemUnit))

const userMustVerifyEmail = selector({
	key: 'userMustVerifyEmail',
	get: ({ get }) => {
		const u = get(user)

		if (u) {
			//
			// If it's a paid account, we know it's not spam, so let
			// them add stuff.
			//
			if (get(userHasPro)) {
				return false
			}

			//
			// If this is an email + password account only (not an Apple or Google
			// account), they need to verify.
			//
			if (!u.emailVerified) {
				if (u.providerData?.length === 1 && u.providerData[0]?.providerId === 'password') {
					return true
				}
			}
		}

		return false
	},
})

export const useUserMustVerifyEmail = () => useRecoilValue(userMustVerifyEmail)

//
// Store stuff
//

const storeOfferings = atom<PurchasesOfferings>({
	key: 'storeOfferings',
})

export const useStoreOfferings = () => useRecoilValue(storeOfferings)
export const useSetStoreOfferings = () => useSetRecoilState(storeOfferings)

const storeCustomerInfo = atomFamily<StoreCustomerInfo | null, UserId>({
	key: 'storeCustomerInfo',
	default: null,
})

export const useStoreCustomerInfo = (id: UserId) => useRecoilValue(storeCustomerInfo(id))
export const useSetStoreCustomerInfo = (id: UserId) => useSetRecoilState(storeCustomerInfo(id))

/*
const storeUserInfo = selector({
	key: 'storeUserInfo',
	get: ({ get }) => {
		const u = get(user)
		if (!u)
			return null
		return get(storeCustomerInfo(u.uid))
	}
})

export const useStoreUserInfo = () => useRecoilValue(storeUserInfo)
*/

const customerHasPro = selectorFamily<boolean, UserId>({
	key: 'customerHasPro',
	get: id => ({ get }) => {

		if (!id)
			return false

		const info = get(storeCustomerInfo(id))
		if (!info)
			return false

		console.log('customerHasPro?', id, info)

		//
		// REST web API-style has 'pro' as a key of the 'entitlements'
		// object, if we're an active/past subscriber
		//
		// https://www.revenuecat.com/docs/api-v1
		//
		if (typeof info?.entitlements?.pro === 'object') {
			console.log('examining entitlement', info.entitlements.pro)
			const { expires_date } = info.entitlements.pro
			if (!expires_date) {
				console.error('No expires_date in info.entitlements.pro')
				return false
			}
			const isActive = new Date(expires_date) > new Date()
			console.log('isActive?', isActive)
			return isActive
		}

		// REST web API-style but no subscriptions
		if (!info?.entitlements?.active) {
			console.log('REST web API-style but no active entitlements')
			return false
		}

		console.log("Device-style purchaserInfo")

		// @ts-ignore
		if (info?.entitlements?.active?.pro) {
			throw new Error("Device-style with pro entitlement? Haven't I migrated completely to REST API? This should be unpossible.")
			// return true
		} else {
			console.error("Device-style but no active pro? That's weird.")
		}

		return false
	},
})

const userHasProPersonally = selector<boolean>({
	key: 'userHasProPersonally',
	get: ({ get }) => {
		const u = get(user)
		if (!u)
			return false
		return get(customerHasPro(u.uid))
	}
})

export const useUserHasProPersonally = () => useRecoilValue(userHasProPersonally)

const clubHasPro = selector<boolean>({
	key: 'clubHasPro',
	get: ({ get }) => {
		const c = get(club)
		if (!c)
			return false
		return get(customerHasPro(c.id))
	}
})

export const useClubHasPro = () => useRecoilValue(clubHasPro)

//
// True if they have it personally or via club
//
const userHasPro = selector({
    key: 'userHasPro',
	get: ({ get }) => {
		return get(userHasProPersonally) || get(clubHasPro)
	}
})

export const useUserHasPro = () => useRecoilValue(userHasPro)

const showIntroScreen = selector({
	key: 'showIntroScreen',
	get: ({ get }) => {
		if (get(user)) {
			return false
		}
		const s = get(section)
		if (Object.keys(s).length) {
			return false
		}
		const a = get(activeTab)
		if (a && a !== 'join-club') {
			return false
		}
		return true
	},
})

export const useShowIntroScreen = () => useRecoilValue(showIntroScreen)

//
// metadata
//
// For Firebase
//

type MetadataType = {
	isCollection: boolean
	cid: string
	docId?: string
}

type MetadataTypes = 'teams' | 'userTeams' | 'userClubs' | 'clubs' | 'clubUser' | 'teamSeasons' | 'teamGames' | 'teamPlayers' | 'teamRosters' | 'team' | 'season' | 'game' | 'player' | 'roster'


const metadata = ({ name, id }: { name: string, id: string }) => {

    // console.log("metadata()", name, id)

	if (!name) {
		console.error("Required parameter missing", name)
	}

	const [ teamId, seasonId ] = id.split(/__/)

	const types: {
		[key in MetadataTypes]: MetadataType
	} = {
		'teams': {
			isCollection: true,
			cid: `/teams`,
		},
		'userTeams': {
			isCollection: true,
			cid: `/teams`,
		},
		'userClubs': {
			isCollection: true,
			cid: `/clubs`,
		},
		'clubs': {
			isCollection: true,
			cid: `/clubs`,
		},
		'clubUser': {
			isCollection: false,
			cid: `/clubs/${teamId}/userInfo`,			// teamId is actually clubId
			docId: seasonId,							// docId is actually userId
		},
		'teamSeasons': {
			isCollection: true,
			cid: `/teams/${teamId}/seasons`,
		},
		'teamGames': {
			isCollection: true,
			cid: `/teams/${teamId}/seasons/${seasonId}/games`,
		},
		'teamPlayers': {
			isCollection: true,
			cid: `/teams/${teamId}/seasons/${seasonId}/players`,
		},
		'teamRosters': {
			isCollection: true,
			cid: `/teams/${teamId}/seasons/${seasonId}/rosters`,
		},

		'team': {
			isCollection: false,
			cid: `/teams`,
		},
		'season': {
			isCollection: false,
			cid: `/teams/${id}/seasons`,
		},
		'game': {
			isCollection: false,
			cid: `/teams/${teamId}/seasons/${seasonId}/games`,
		},
		'player': {
			isCollection: false,
			cid: `/teams/${teamId}/seasons/${seasonId}/players`,
		},
		'roster': {
			isCollection: false,
			cid: `/teams/${teamId}/seasons/${seasonId}/rosters`,
		},
	}

    //
    // Collections: 'teams', 'seasons', 'games', 'players', 'rosters'
    //
	const value = types[name as keyof typeof types]

    if (!value) {
        throw new Error('Unknown metadata type: ' + name)
    }

    return value
}

export function queryDbid(name: keyof typeof dbids, section: Section) {
	const dbid = dbids[name]
    return dbid.replace(/__(\w+)__/g, (_, p1) => {
        const field = p1.toLowerCase() as keyof Section
        return section[field] || '??'
    })
}

/*
export function typeToDbid(type: { dbid: string }, section: Section) {
    return type.dbid.replace(/__(\w+)__/g, (_, p1) => {
        const field = p1.toLowerCase() as keyof Section
        return section[field] || '??'
    })
}

// Shortcut for the above.
// Uses some naughty duplication of 'dbid' field from Players.jsx
export function playerDbid(section: Section) {
	const type = {
		dbid: `/teams/__TEAM__/seasons/__SEASON__/players`,
	}
	return typeToDbid(type, section)
}
*/

/*
 * functions
 */

type DeleteItemProps<T> = {
	type: ItemTypeDefinition<T>
	item: T
}

const deleteItem = async <T extends Item>(args: CallbackInterface, props: DeleteItemProps<T>) => {

	const { snapshot } = args

	const { type, item } = props

	if (type.unit === 'coach') {
		throw new Error("Cannot delete a coach.")
	}

	console.log('deleteItem', type, item)

	const header = ('name' in item && item.name) || `Delete ${ucFirst(type.unit)}`

	const sec = await snapshot.getPromise(section)
	const dbid = queryDbid(type.unit, sec)

	let hasRoster = false
	const rosters = await snapshot.getPromise(myRosters)

	if (type.unit === 'game') {
		hasRoster = !!rosters?.[item.id]?.roster?.length
	} else if (type.unit === 'player') {
		if (rosters) {
			const numPeriods = await snapshot.getPromise(myNumPeriods)
			const gameFormat = await snapshot.getPromise(myGameFormat)
			hasRoster = Object.values(rosters).some(r => {
				const myPositions = findMyPositions({
					playerId: item.id,
					roster: r.roster,
					players: r.players,
					nulled: r.nulled,
					subs: r.subs,
					numPeriods,
					gameFormat,
				})
				const ret = myPositions.some(obj => obj !== ROSTER_NOT_AVAILABLE && obj.positionName !== BENCHED && !obj.subStatus)
				console.log("Checking rosters for player", ret, myPositions)
				return ret
			})
		}
	}

	if (type.unit === 'season') {
		return {
			header,
			message: "Deleting a season will remove all associated games, rosters, and players. THIS IS PERMANENT and cannot be undone. Are you sure?",
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Delete everything',
					role: 'destructive',
					handler: () => {
						//
						// Recursively delete everything
						//
						[ 'rosters', 'games', 'players' ].forEach(collectionName => {
							const id = dbid + '/' + item.id + '/' + collectionName
							console.log("ID is", id)
							const collectionRef = collection(db, id)
							const q = query(collectionRef)
							getDocs(q).then(data => {
								console.log("collectionName", collectionName, data.size)
								if (data.size) {
									data.forEach(doc => {
										console.log('delete doc', collectionName, id, doc.id)
										deleteDocument({
											colId: id,
											docId: doc.id,
										})
									})
								}
							})
						})
						console.log("Delete season", dbid, item.id)
						deleteDocument({
							colId: dbid,
							docId: item.id,
						})
					},
				},
			],
		}
	} else if (type.unit === 'team') {
		// check whether this team has any seasons. If it does, say you have to delete the seasons first.
		const collectionRef = collection(db, `/teams/${item.id}/seasons`)
		const q = query(collectionRef)
		return getDocs(q).then(data => {
			if (data.size > 0) {
				return {
					header: 'Team Has Existing Season',
					subHeader: header,
					message: "Before you can delete a team, you must first delete its seasons.",
					buttons: [ 'OK' ],
				}
			} else {
				console.log("Deleting team.")
				deleteDocument({
					colId: dbid,
					docId: item.id,
				})
				return {
					header: 'Team deleted',
					buttons: [ 'OK' ],
				}
			}
		})
	} else if (type.unit === 'player' && hasRoster) {
		return {
			header,
			message: "You can't delete this player because they are in an on-court position in at least one roster. " +
				"Modify all rosters to bench this player first.",
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
			],
		}
	} else {
		let message = `Are you sure you want to permanently delete this ${type.unit}?`
		if (hasRoster) {
			if (type.unit === 'game') {
				message += " This will also remove its roster."
			} else if (type.unit === 'player') {
				message += " This will distort any rosters in which they are included."
			}
		}
		return {
			header,
			message,
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Delete',
					role: 'destructive',
					handler: () => {
						deleteDocument({
							colId: dbid,
							docId: item.id,
						})
						if (hasRoster && type.unit === 'game') {
							const colId = queryDbid('roster', sec)
							deleteDocument({
								colId,
								docId: item.id,
							})
						}
					},
				},
			],
		}
	}
}

export const useDeleteItem = <T extends Item>() => useRecoilCallback(args => (props: DeleteItemProps<T>) => deleteItem(args, props))

type DeleteDocumentProps = {
	colId: string
	docId: string
}

function deleteDocument(props: DeleteDocumentProps) {
	console.log("Deleting", props)
	deleteDoc(doc(db, props.colId, props.docId))
	return true
}
