import './pairs.css'

import {
    IonFab,
    IonFabButton,
    IonIcon,
    IonRippleEffect,
    IonRouterLink,
} from '@ionic/react'
import { chevronBackSharp, chevronForwardSharp } from 'ionicons/icons'
import React, { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from 'react'

import { DisplayScore } from './DisplayScores'
import {
	MIN_NUM_QTRS_PER_COMBO,
	NORMALIZE_MIN_QTRS_FOR_PAIRS,
} from './definitions'
import { MoreInfoLink } from './Help'
import { findPairings } from './functions'
import { labelRound } from './util'
import {
    useMyGames,
    useMyOnCourtRosters,
	useMyNumPeriods,
    useUserHasPro,
	useMyFieldPositions,
	useMyGameFormat,
} from './data'
import DecimalNumber from './DecimalNumber'
import PlayerName from './PlayerName'

type PairsProps = {
	mode: PairMode
}

const Pairs = (props: PairsProps) => {

    const { mode } = props

    const games = useMyGames()
    const onCourtRosters = useMyOnCourtRosters()
	const numPeriods = useMyNumPeriods()
	const gameFormat = useMyGameFormat()

    const userHasPro = useUserHasPro()

	const containerRef = useRef<HTMLDivElement | null>(null)
	const navRef = useRef<HTMLDivElement | null>()

    // const [ showNavButtons, setShowNavButtons ] = useState(false)

    // If we switch between pairs and trios, scroll back the start of the list
    useEffect(() => {
        containerRef?.current?.scroll({
            left: 0,
            behavior: 'smooth'
        })
    }, [ mode ])

	if (!games)
		return null

    const pairs = findPairings({
        games,
        onCourtRosters,
		numPeriods,
		gameFormat,
        findTrios: mode === 'trios',
        userHasPro,
    })

	const showNavButtons = pairs.length > 2 && (containerRef?.current?.scrollWidth || 0) > 880

	const maxGames = pairs.reduce((max, pair) => {
        const numGames = Object.keys(pair.games).length
        if (numGames > max) {
            return numGames
        }
        return max
    }, 0)

    const minHeight = (mode === 'trios' ? 15 : 13) + (maxGames * 4.5)

    console.log('Pairs', mode, pairs, games, 'maxGames', maxGames, showNavButtons, containerRef?.current?.scrollWidth, pairs)

	const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
		if (navRef.current) {
			navRef.current.onscroll?.(event.nativeEvent) // Optionally invoke onscroll
		}
	}

	return (
		<div className="pairings">
			<div
				className="pairings-list"
				style={{
					minHeight: `${minHeight}em`,
                }}
                ref={containerRef}
                onScroll={handleScroll}
            >
                {
                    pairs.map((pair, index) => (
                        <Pair
                            key={pair.id}
                            pair={pair}
                            index={index}
                            mode={mode}
                        />
                    ))
                }
            </div>

			{
				!pairs.length && (
					<p className="stats-header-text">
						Results will appear here once the same combination of players has been used in at least {MIN_NUM_QTRS_PER_COMBO} quarters.
					</p>
				)
			}

			<p className="info">
				Combinations on the left are effective and used often. Those on the far right tend to be sub-optimal and used often.
				Shown for combinations who have played at least two quarters in adjacent roles.
				{' '}
				<MoreInfoLink helpSection="stats" helpSubSection="pairings" text="More info..." />
			</p>
			<p className="info">
				* Average is based on a small number of quarters. AutoRoster will scale down this number internally.
			</p>

            {
                showNavButtons && (
                    <DesktopNavButtons
                        ref={navRef}
                        containerRef={containerRef}
                    />
                )
            }

        </div>
    )
}

//
// Don't render immediately, because there are a lot of them, and
// it causes slowdowns.
//
type PairProps = {
	pair: Pair
	index: number
	mode: PairMode
}

const Pair = (props: PairProps) => {

    const { pair, index, mode } = props

    const [ reveal, setReveal ] = useState(false)

    useEffect(() => {
		let timer: ReturnType<typeof setTimeout>

        if (!reveal) {
            const delay = (index <= 6 ? index * 100 : index * 150)
            timer = setTimeout(() => setReveal(true), delay)
        }

        return () => clearTimeout(timer)
    }, [ index, reveal ])

    if (!reveal) {
        return (
            <div className="pair placeholder">
                <div className="cell cell-position" />
                <div className="cell cell-position" />
            </div>
        )
    }

    const { dummy, playerId1, positionIndex1, playerId2, positionIndex2, playerId3, positionIndex3, diff, avg, n, numPeriods, games } = pair

    if (dummy) {
        return (
            <div className="pair pair-dummy">
                <div className="cell cell-position cell-position--">
                    ???
                </div>
                <div className="cell cell-position cell-position--">
                    ???
                </div>
                {
                    mode === 'trios' && (
                        <div className="cell cell-position cell-position--">
                            ???
                        </div>
                    )
                }
                <div className="pair-dummy-offer">
                    <span className="offer-appname">AutoRoster</span> <span className="offer-planname">Pro</span>
                </div>
            </div>
       )
    }

    return (
        <div className="pair">
            <PairPosition
                playerId={playerId1}
                positionIndex={positionIndex1}
            />
           <PairPosition
                playerId={playerId2}
                positionIndex={positionIndex2}
            />
            {
                mode === 'trios' &&
                    <PairPosition
                        playerId={playerId3}
                        positionIndex={positionIndex3}
                    />
            }
            <DecimalNumber
                className="pair-diff"
                value={diff}
                isComparison={true}
            />
            <DecimalNumber
				className={`pair-avg ${n < NORMALIZE_MIN_QTRS_FOR_PAIRS[mode] ? 'pair-avg-asterisk' : ''}`}
                value={avg}
                isComparison={true}
            />
            <div className="pairs-games">
                {
                    Object.values(games).sort((a, b) => b.diff - a.diff).map(game =>
                        <PairGame
                            key={`${game.id}__${game.qtr}`}
                            game={game}
							numPeriods={numPeriods}
                        />
                    )
                }
            </div>
        </div>
    )

}

type PairPositionProps = {
	playerId: PlayerId
	positionIndex: number
}

const PairPosition = (props: PairPositionProps) => {

    const { playerId, positionIndex } = props

	const fieldPositions = useMyFieldPositions()
    const positionName = fieldPositions[positionIndex]

    return (
        <div className={`cell cell-position cell-position-${positionName}`}>
            <span className="position-name">
                {positionName}
            </span>
            <PlayerName
                playerId={playerId}
            />
        </div>
    )
}

type PairGameProps = {
	game: GamePair
	numPeriods: number
}

const PairGame = (props: PairGameProps) => {
    const { game, numPeriods } = props

    const { id, qtr, opponent, round, result } = game

    const opponentClasses = [ 'bestqtr-opponent' ]
    if (opponent.length >= 17) {
        opponentClasses.push('very-long-name')
    } else if (opponent.length >= 12) {
        opponentClasses.push('long-name')
    }

    const roundName = labelRound(round)

    return (
        <IonRouterLink
            className="stats-game bestqtr"
            routerLink={`/games/${id}`}
        >
                <div className="bestqtr-round">
					<div className="bestqtr-round-round">
						{roundName}
					</div>
					<div className="bestqtr-round-qtr">
						{(!numPeriods || numPeriods === 4) ? 'Q' : 'P'}{qtr + 1}
					</div>
				</div>
				<div className={opponentClasses.join(' ')}>
					{opponent}
				</div>

                <DisplayScore
                    result={result}
                    qtr={qtr}
					numPeriods={numPeriods}
                />

            <IonRippleEffect></IonRippleEffect>
        </IonRouterLink>
    )
}

type DesktopNavButtonsProps = {
	containerRef: React.RefObject<HTMLDivElement>
}

const DesktopNavButtons = forwardRef((props: DesktopNavButtonsProps, ref) => {

    const { containerRef } = props

    const [ scrollTarget, setScrollTarget ] = useState(0)

    const [ canScrollLeft, setCanScrollLeft ] = useState(false)
    const [ canScrollRight, setCanScrollRight ] = useState(false)


    // const canScrollLeft = ref?.current?.scrollLeft // element && scrollLeft
    // const canScrollRight = true // element && scrollLeft < element.scrollWidth

	const scrollPairs = (direction: number) => {
        const el = containerRef?.current
        if (el) {

            const { clientWidth, scrollLeft } = el

            const scrollAmt = 0.85 * clientWidth

            let newTarget = scrollLeft + (direction * scrollAmt)

            // If we're already scrolling, take that into account
            if (scrollLeft !== scrollTarget) {
                const diff = scrollTarget - scrollLeft
                if ((diff > 0 && direction > 0) || (diff < 0 && direction < 0)) {
                    // More scrolling in the same direction!
                    newTarget += diff
                } else {
                    // Reverse direction! - Ignore current target
                }
            }

            el.scroll({
                left: newTarget,
                behavior: 'smooth',
            })

            setScrollTarget(newTarget)
        }
    }

    const onScroll = useCallback(() => {

		if (!containerRef.current) {
			return
		}

        const { scrollLeft, clientWidth, scrollWidth } = containerRef.current

        if (scrollLeft === 0) {
            setCanScrollLeft(false)
        } else if (!canScrollLeft) {
            setCanScrollLeft(true)
        }

        if (scrollLeft + clientWidth >= scrollWidth - 2) {
            setCanScrollRight(false)
        } else if (!canScrollRight) {
            setCanScrollRight(true)
        }

        // console.log('onScroll!', clientWidth, scrollWidth, scrollLeft, scrollLeft + clientWidth)
    }, [ containerRef, canScrollLeft, canScrollRight ])

    useEffect(onScroll, [ containerRef, onScroll ])

    useImperativeHandle(ref, onScroll)

    return (
        <>
            <IonFab vertical="center" horizontal="start" slot="fixed">
                <IonFabButton
                    disabled={!canScrollLeft}
                    onClick={() => scrollPairs(-1)}
                >
                    <IonIcon icon={chevronBackSharp} />
                </IonFabButton>
            </IonFab>

            <IonFab vertical="center" horizontal="end" slot="fixed">
                <IonFabButton
                    disabled={!canScrollRight}
                    onClick={() => scrollPairs(1)}
                >
                    <IonIcon icon={chevronForwardSharp} />
                </IonFabButton>
            </IonFab>
        </>
    )
})

DesktopNavButtons.displayName = 'DesktopNavButtons'

export default Pairs
