import "./games.css"

import { FaCalendarTimes } from 'react-icons/fa'
import { IoIosAlarm } from 'react-icons/io'
import {
    IonButton,
    IonPage,
    IonContent,
} from '@ionic/react'
import React, { useState, useEffect } from 'react'
import TimeAgo from 'react-timeago'

import { formatDate, gameDateClass, getUrl, labelRound } from './util'
import {
	formatResult,
	scrollGamesTab,
} from './functions'
import {
  useAmViewingOwnTeam,
  useMyGames,
  useMyNumPeriods,
  useMyPlayers,
  useMyRosters,
  useMySeasonId,
  useSection,
  useShowNetStats,
} from './data'
import FadeText from './FadeText'
import Header from './Header'
import Icon from './Icon'
import List from './List'
import ShareButton from './ShareButton'
import orange from './orange.svg'
import {isValidResult} from "./util"

const Games = () => {

    const data = useMyGames()
    const seasonId = useMySeasonId()
	const numPeriods = useMyNumPeriods()

    //
    // When we view "Games" for the first time for a season,
    // and it's a live season, scroll down to where we currently
    // are.
    //
    // When our local season value changes, if we have any games
    // in the future or present (including very recent past),
    // scroll down to show the most recently completed game.
    //
    // Also do this if we tap the Games tab, so you can use it
    // to quickly scroll to the current game.
    //
    // We don't want to trigger a scroll when 'data' changes (e.g. when adding a new
    // game), but we do need to make sure we have some before doing anything.
    const haveData = !!data

    useEffect(() => {
		let timer: ReturnType<typeof setTimeout>

        if (haveData && seasonId) {

            //
            // If we're responding to a re-tap of the Games tab button, scroll
            // immediately; otherwise delay a bit, which looks nicer as we let
            // a few things load.
            //

            timer = setTimeout(scrollGamesTab, 500)
        }

        return () => clearTimeout(timer)
    }, [ seasonId, haveData ])

	const type: ItemTypeDefinition<Game> = {
        name: 'games',
        unit: 'game',
		maxWithoutPro: 30,
        fields: [
            'round',
            'opponent',
            'date',
            'venue',
        ],
        editOnlyFields: [ 'result' ],
        disableFields: ({ field, item }) => {
            return field === 'result' && item.date > Date.now()
        },
		fieldTooltips: field => {
			if (field === 'result') {
				if (numPeriods === 1) {
					return "This season has only 1 period per game, so just enter the final score."
				} else if (numPeriods === 2) {
					return "Enter progressive scores: the score at half-time, then full-time."
				} else if (numPeriods === 4) {
					return "Enter progressive scores: the score at quarter-time, half-time, 3/4 time, and full time."
				} else {
					return "Enter progressive scores: the score at the end of each period."
				}
			}
			return ''
		},
        linkToItem: true,
		requireValidSeason: true,
        formatField: props => <GameField {...props} />,
        ExtraContent,
        quickAdding: true,
		showEditLinkTo: 'season',
        sortFunction: (a, b) => a.date - b.date,
        defaultValue: ({ field, items }) => {
            if (field === 'round') {
                const lastItem = items?.[items.length - 1]
                if (lastItem) {
                    // If last round was "Round 5 Special", return "Round 6 Special"
                    const match = String(lastItem.round).match(/(.*?)(\d+)(.*)/)
                    if (match) {
                        const [ , before, round, after ] = match
                        return before + (Number(round) + 1) + after
                    }
                    // We had an entry for last round but don't know what it was,
                    // so don't supply anything.
                } else {
                    return 1
                }
            } else if (field === 'date') {
                const lastItem = items?.[items.length - 1]
                if (lastItem?.date) {
                    return lastItem.date + (7 * 86400000)
                } else {
                    const now = new Date().getTime()
                    return now + 3600000 - (now % 3600000)
                }
            }
            return ''
        },
        isInvalid: {

			//
			// This can be sent an entire 'results' array (by EditItem.js -> formIsInvalid()),
			// which we should check properly, or a single qtr value (by EditField.js ->
			// onIonInput), which should be ignored because thats checked by EditField.js ->
			// validateResult() instead.
			//
			result: ({ value }) => {

				if (typeof value !== 'object') {
					// Don't bother checking individual qtr numbers because that's done
					// by EditField.js -> validateResult() instead.
					return false
				}

				// No error message, because EditItem just wants true/false and that's the
				// only function that calls this.
				return !isValidResult(value, numPeriods)
			},
		},
	}

	return (
		<IonPage>
			<IonContent>
				<List<Game>
					data={data}
					type={type}
				/>
				<ShareSeasonButton />
				</IonContent>
			<Header allowTeamChange={true} />
		</IonPage>
	)
}

const ShareSeasonButton = () => {

	const section = useSection()
	const myGames = useMyGames()

	//
	// Don't offer to Share until we've loaded some games, because
	// it's a weird look.
	//
	if (!myGames) {
		return null
	}

	const url = getUrl({
		page: '/games',
		section,
	})

	return (
		<div className="season-share">
			<FadeText contentKey={myGames ? 'yes' : 'no'}>
				<ShareButton url={url}>
					<span className="season-share-label">
						Share
					</span>
				</ShareButton>
			</FadeText>
		</div>
	)
}

//
// 'item' is only required if you want to let the user enter scores
//
export const GameField = (props: FormatItemFieldProps<Game>) => {

    const { field, value, setEditMode } = props

    const amViewingOwnTeam = useAmViewingOwnTeam()
	const numPeriods = useMyNumPeriods()

    let content
    let dateClass

    if (field === 'round') {
        content = labelRound(value as string)
    } else if (field === 'date') {
		const t = value as number
        content = formatDate(t, 'long')

        dateClass = gameDateClass(t as number)

        if (dateClass === 'present') {
            content = (
                <>
                    <div className="game-current">
                        <IoIosAlarm />
                        <TimeAgo
							date={t}
							className="game-start"
						/>
                    </div>
                    {content}
                </>
            )
        }
    } else if (field === 'result') {
		const r = value as Result
		const result = formatResult(r, numPeriods)

		const myFinalScore = result.me[numPeriods - 1]
		const theirFinalScore = result.them[numPeriods - 1]

        if (myFinalScore !== null && theirFinalScore !== null) {

            const resultStr =
				myFinalScore > theirFinalScore ? 'Won' :
                myFinalScore < theirFinalScore ? 'Lost' : 'Drew'

            content = (
                <>
                    {resultStr}
                    {' '}
                    <b>
                        {myFinalScore}
                    </b>
                    {' '}
                    -
                    {' '}
                    <b>
                        {theirFinalScore}
                    </b>
                </>
            )

			if (props.isFixtureChange) {
				content = (
					<>
						{content}
						<br />
						<table className="results-table fixture-change-results">
							<tbody>
								{
									result.me.map((me, qtr) => (
										<tr key={qtr}>
											<td>{qtr === numPeriods - 1 ? 'Final' : `${numPeriods === 4 ? 'Q' : 'P'}${qtr + 1}`}</td>
											<td>{me}</td>
											<td>{result.them[qtr]}</td>
										</tr>
									))
								}
							</tbody>
						</table>
					</>
				)
			}
        } else if (amViewingOwnTeam && setEditMode && props.item) {
            content = (
				<ConsiderOfferingEnterScores
					gameDate={props.item.date}
					setEditMode={setEditMode}
				/>
			)
        }
    } else if (typeof value === 'object') {
		console.error("wtf")
	} else {
        content = value
    }

    return (
        <div className={dateClass && `date-${dateClass}`}>
            {content}
        </div>
    )
}

type ConsiderOfferingEnterScoresProps = {
	gameDate: number
	setEditMode: (mode: string) => void
}

const ConsiderOfferingEnterScores = (props: ConsiderOfferingEnterScoresProps) => {

	const { gameDate, setEditMode } = props

	const msUntilGame = gameDate - Date.now()

	const show = msUntilGame < 0

	// To force a re-render from the setTimeout()
	const [ , setChecked ] = useState(0)

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>

		if (!show) {
			const t = msUntilGame + 10000
			if (t < 2147483647) {	// Avoid overflow
				// console.log("Score entry in ", t, "ms or", Math.round(t / (1000 * 3600)), "hours")
				timer = setTimeout(() => setChecked(Date.now()), t)
			}
		}

		return () => clearTimeout(timer)
	}, [ show, msUntilGame ])

	if (!show)
		return null

	return (
		<EnterScoresLine setEditMode={setEditMode} />
	)
}

type EnterScoresLineProps = {
	setEditMode: (mode: string) => void
}

const EnterScoresLine = (props: EnterScoresLineProps) => {

    const { setEditMode } = props

    const showNetStats = useShowNetStats()

    if (!showNetStats)
        return null

    return (
        <IonButton
            className="enter-scores-button"
            fill="clear"
            size="small"
            onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                setEditMode('result')
                return false
            }}
        >
            Enter scores
        </IonButton>
    )
}

const ExtraContent = (props: ExtraContentProps<Game>) => {

    const { item } = props

    const players = useMyPlayers()
    const rosters = useMyRosters()

    const obj = rosters && rosters[item.id]

    let lastUpdated = null
    if (obj?.created) {
        lastUpdated = obj.created && (
            <div className="field-created">
                Updated <TimeAgo date={obj.created} className="roster-created"/>
            </div>
        )
    }

    let roster = <div />
        if (obj?.roster?.length) {
            roster = (
                <div className="field-roster">
                    <Icon />
                </div>
            )
        }

    let absent = <div />
        if (players && obj?.players) {
            const missingPlayers = Object.assign({ }, players)
            obj.players.forEach(playerId => delete missingPlayers[playerId])
            if (Object.keys(missingPlayers).length) {
                const absentStr = Object.values(missingPlayers).map(player => player.name).sort().join(', ')
                absent = (
                    <div className="field-absent">
                        <FaCalendarTimes className="roster-no" />
                        {absentStr}
                    </div>
                )
            }
        }

    let orangeman = <div />
        if (players && obj?.orangeman) {
            const player = players[obj.orangeman]

            if (player) {
                // Make sure orangeman isn't absent
                const warning = absent && obj.players.indexOf(obj.orangeman) === -1 && 'field-orangeman-illegal'

                orangeman = (
                    <div className={`field-orangeman ${warning}`}>
                        <img className="orange-image" src={orange} alt="Oranges" />
                        {player.name}
                    </div>
                )
            } else {
                console.log('Orangeman does not exist on this team any more:', obj.orangeman)
            }
        }

    return (
        <div className="extra-content">
            <div className="extra-content-bar">
                {roster}
                {orangeman}
                {absent}
            </div>
            {lastUpdated}
        </div>
    )
}

export default Games
