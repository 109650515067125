import './positionpreferencessetting.css'

import {
    IonButton,
    IonSelect,
    IonSelectOption,
} from '@ionic/react'
import { doc, writeBatch, deleteField } from "firebase/firestore"
import React from 'react'

import { ALL_PREFS } from './definitions'
import { db } from './firebase'
import {
	queryDbid,
  useEditMode,
  useMyPlayerIds,
  useSection,
  useSetMyRosterIsStale,
  useSettingsState,
} from './data'
import {IonSelectCustomEvent, SelectChangeEventDetail} from '@ionic/core'

type PositionPreferencesSettingProps = {
	showPositionPreferences?: boolean
}

const PositionPreferencesSetting = (props: PositionPreferencesSettingProps) => {
	const { showPositionPreferences } = props

	const [ settings, setSettings ] = useSettingsState()

    const editMode = useEditMode()

	if (!editMode || !showPositionPreferences)
		return null

	return (

		<div className="position-preferences-setting-box">

            <ClearPrefsButton />

            <div className="position-preferences-setting">
                <IonSelect
                    interface="action-sheet"
                    value={settings.preferenceType}
					onIonChange={ (e: IonSelectCustomEvent<SelectChangeEventDetail>) => {
						const { value } = e.detail
						setSettings({
							...settings,
							preferenceType: value,
						})
					}}
                >
                    {
                        ALL_PREFS.map(name =>
                            <IonSelectOption key={name} value={name}>
                                {name}
                            </IonSelectOption>
                        )
                    }
                </IonSelect>
                <span className="position-preferences-trailing-text">
                    these positions
                </span>
            </div>
        </div>
    )
}

const ClearPrefsButton = () => {

    const playerIds = useMyPlayerIds()

    const section = useSection()

    const setMyRosterIsStale = useSetMyRosterIsStale()

    const onClick = async () => {
        console.log('bing')

        const batch = writeBatch(db)

        await Promise.all(playerIds.map(async playerId => {

            const dbid = queryDbid('player', section)

            const docRef = doc(db, dbid, playerId)

            batch.update(docRef, {
                prefs: deleteField(),
            })

            console.log('Prepared batch delete of prefs field for player', playerId)
        }))

        await batch.commit()

        console.log('Successfully committed batch.')

        setMyRosterIsStale(true)
    }

    return (
        <IonButton
            onClick={onClick}
            fill="clear"
            color="danger"
        >
            Clear
        </IonButton>
    )
}

export default PositionPreferencesSetting
