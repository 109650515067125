import React from 'react'
import {
    IonButton,
} from '@ionic/react'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import {
    useCanRedo,
    useCanUndo,
    useRedo,
    useRedoAll,
    useUndo,
    useUndoAll,
} from './data'
import { FaUndo, FaRedo } from 'react-icons/fa'
import { IconType } from 'react-icons'

const UndoRosterButtons = () => {

    const undo = useUndo()
    const redo = useRedo()

    const undoAll = useUndoAll()
    const redoAll = useRedoAll()

    const canUndo = useCanUndo()
    const canRedo = useCanRedo()

    return (
        <>
            <UndoRosterButton
                enabled={canUndo}
                label="Undo"
                Icon={FaUndo}
                undoFunction={undo}
                undoAllFunction={undoAll}
            />
            <UndoRosterButton
                enabled={canRedo}
                label="Redo"
                Icon={FaRedo}
                undoFunction={redo}
                undoAllFunction={redoAll}
            />
        </>
    )
}

type UndoRosterButtonProps = {
	enabled: boolean
	label: string
	Icon: IconType
	undoFunction: () => void
	undoAllFunction: () => void
}

const UndoRosterButton = (props: UndoRosterButtonProps) => {

    const { enabled, label, Icon, undoFunction } = props

	const onClick = (e: React.MouseEvent) => {
		if (!enabled)
			return

        console.log('onClick', e, enabled)

		console.log('regular press', label)
		Haptics.impact({ style: ImpactStyle.Medium })
		undoFunction()
    }

    return (
        <IonButton
            className="button-undo button-react-icon"
			onClick={onClick}
            //press={() => console.log('press!')}
            //onPress={() => console.log('onPress!')}
            //onIonPress={() => console.log('onIonPress!')}
            //ionPress={() => console.log('ionPress!')}
            //onPressStart={() => console.log('onPressStart')}
            //onPressEnd={() => console.log('onPressEnd')}
            //onIonPressStart={() => console.log('onIonPressStart')}
            //ionPressStart={() => console.log('onPressStart')}
            //onMouseDown={() => console.log('onMouseDown!')}
            //onMouseUp={() => console.log('onMouseUp!')}
            //onChange={() => console.log('onChange!')}
            //ionChange={() => console.log('ionChange!')}
            //onIonChange={() => console.log('onIonChange!')}
            disabled={!enabled}
            title="Undo"
            color="light"
        >
            <Icon />
            <span className="roster-button-text">
                {label}
            </span>
        </IonButton>
    )
}

export default UndoRosterButtons
