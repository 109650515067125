import React, { useState, useEffect } from 'react'
import './fadetext.css'

//
// Text that fades out as it enters and exits.
//
// Example:
//
//	<FadeText>Hi there</FadeText>
//
// ... or for <div> instead of <span>:
//
//	<FadeText isBlock={true}>
//		<p>etc</p>
//		<p>etc</p>
//	</FadeText>
//
// Optional: Supply a 'key' so we don't need to calculate one.
//

const DURATION = 160

type FadeTextProps = {
	children: any
	className?: string
	isBlock?: boolean
	contentKey?: string
}

const FadeText = (props: React.PropsWithChildren<FadeTextProps>) => {

	const { children, className, contentKey } = props

	// console.log('FadeText', className, contentKey, typeof children)

	const [ hide, setHide ] = useState(true)
	const [ displayedContent, setDisplayedContent ] = useState('')
	const [ key, setKey ] = useState('')

    useEffect(() => {

        const newKey = contentKey || jsxToString(children)

        if (key !== newKey) {
            setKey(newKey)
            setHide(true)
        }

    }, [ children, key, contentKey ])

    useEffect(() => {

		let timer: ReturnType<typeof setTimeout>

		if (key !== '') {

			timer = setTimeout(() => {
                setHide(false)
			}, DURATION)
		}

		return () => clearTimeout(timer)
	}, [ key ])

    // When we reveal, update content.
    // Put it on a tiny delay because we don't want this to activate in the same
    // render as the setKey() change.
    useEffect(() => {
		let timer: ReturnType<typeof setTimeout>
        if (!hide) {
            timer = setTimeout(() => {
                const newContent = (children === undefined || children === null || children === false) ? '' : children
                setDisplayedContent(newContent)
            }, 0)
        }
        return () => clearTimeout(timer)
    }, [ hide, children ])

    const myClasses = [ 'fade-text' ]
    if (hide) {
        myClasses.push('hide')
    }
    if (className) {
        myClasses.push(className)
    }

    const myClassName = myClasses.join(' ')

    if (props.isBlock) {
        return (
            <div className={myClassName}>
                {displayedContent}
            </div>
        )
    } else {
        return (
            <span className={myClassName}>
                {displayedContent}
            </span>
        )
    }
}

function jsxToString(jsx: any): string {

	if (jsx === null || jsx === undefined) {
		return ''
	}

	if (typeof jsx === 'string') {
		return jsx
	}

	if (typeof jsx === 'number') {
		return String(jsx)
	}

    if (typeof jsx === 'boolean') {
        return jsx ? '1' : '0'
    }

	if (typeof jsx === 'object') {
		const isArray = Array.isArray(jsx)
		if (isArray) {
			return jsx.map(item => jsxToString(item)).join(' ')
		}
		if (jsx.props) {
			if (jsx.props.value !== undefined) {
				if (jsx.props.value === null) {
					return ''
				}
				return jsx.props.value
			}
			if (jsx.props.children) {
				return jsxToString(jsx.props.children)
			}
		}
	}

	console.error("FadeText needs a manually supplied contentKey, because we can't derive one from this", typeof jsx, jsx)
	return ''
}

export default FadeText
