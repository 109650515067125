import React from 'react'
import GridCell from './GridCell'
import { TiPin } from 'react-icons/ti'
import { FaCloudShowersHeavy } from 'react-icons/fa'
import { FaArrowLeftLong } from 'react-icons/fa6'

type GridCellPositionProps = {
	content: string | JSX.Element | null
	richContent?: string | JSX.Element
	i?: I
	isAvailable?: boolean
	isSubAvailable?: boolean
	isLocked?: boolean
	isNullPosition?: boolean
	subStatus: SubStatus
	isSubSplitting?: boolean
	subAnimation?: SubAnimation
	onClick?: (i: I) => void
	positionName?: string
}

const GridCellPosition = (props: React.PropsWithChildren<GridCellPositionProps>) => {

	const icons = [ ]

	if (props.isSubSplitting) {
		icons.push(
			<span className="cell-subsplitting">
				<FaArrowLeftLong className="cell-subsplitting-1" />
				<FaArrowLeftLong className="cell-subsplitting-2" />
			</span>
		)
	} else if (props.isNullPosition) {
        icons.push(<FaCloudShowersHeavy />)
    } else if (props.isLocked) {
		icons.push(<TiPin className="cell-locked" />)
	}

	return (
		<GridCell
			type="position"
			content={props.content}
			richContent={props.richContent}
			icons={icons}
			isAvailable={props.isAvailable}
			isSubAvailable={props.isSubAvailable}
			isNullPosition={props.isNullPosition}
			subStatus={props.subStatus}
			subAnimation={props.subAnimation}
			i={props.i}
			positionName={props.positionName}
			onClick={props.onClick}
		>
			{props.children}
		</GridCell>
	)
}

export default GridCellPosition
