import React from 'react'
import {
    IonPage,
    IonContent,
} from '@ionic/react'
import Header from './Header'
import FadeText from './FadeText'
import Roster from './Roster'
import Icon from './Icon'
import {
    useMyGame,
} from './data'
import { formatDate, labelRound } from './util'
import './game.css'

const Game = () => {

	return (
        <IonPage>
            <IonContent>
                <GameHeader />
                <Roster />
            </IonContent>
            <Header showShareButton={true} />
        </IonPage>
	)
}

const GameHeader = () => (
    <header className="header">
        <div className="header-title">
            <Icon />
            <div className="header-title-content">
                <OpponentText />
                <UserData />
            </div>
        </div>
    </header>
)

const OpponentText = () => {

    const game = useMyGame()

    if (!game?.opponent)
        return null

    const { opponent } = game

    const htLength = opponent.length || 0
    const htClass = (
        htLength > 24 ? 'gigantic' :
        htLength > 20 ? 'huge' :
        htLength > 15 ? 'large' :
        htLength > 10 ? 'medium' :
        htLength > 6 ? 'small' : 'tiny'
    )

    return (
        <FadeText
            contentKey={opponent}
            className="header-title-block"
        >
            vs
            <div className={`header-title-opponent textlength-${htClass}`}>
                {opponent}
            </div>
        </FadeText>
    )
}

const UserData = () => {

	const game = useMyGame()

    if (!game)
        return null

    const gameRound = (game.round !== undefined && game.round !== null) && (
        <div className="header-game-name">
            {labelRound(game.round)}
        </div>
    )

   const gameVenue = game.venue && (
        <div className="header-game-venue">
            {game.venue}
        </div>
    )

    const gameTime = game.date && (
        <div className="header-game-date">
            {
                formatDate(game.date, 'long')
            }
        </div>
    )

    return (
        <FadeText className="header-userdata">
            {gameRound}
            {gameTime}
            {gameVenue}
        </FadeText>
    )
}

export default Game
