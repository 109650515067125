import { useEffect, useCallback, useMemo } from 'react'
import { Capacitor } from '@capacitor/core'
import { Purchases } from '@revenuecat/purchases-capacitor'
import {
    useClub,
    useSetStoreOfferings,
    useSetStoreCustomerInfo,
	useUser,
} from './data'
import {fetchJson} from './util'

const REVENUECAT_API_URL = 'https://api.revenuecat.com/v1' as const

const REVENUECAT_PUBLIC_API_KEYS = {
	ios: 'appl_XGvBjGRDDUeNObAXpYkBnbfgGpx',
	android: 'goog_PNrwTetoIiFXGXYaQTDFnwYVGyH',
	web: 'strp_rNdPEHfgbcrZldXWZHnSFZiTxZR',
} as const

const MyPurchases = () => {

	const user = useUser()
	const club = useClub()

	const setStoreOfferings = useSetStoreOfferings()

	const setStoreCustomerInfoUser = useSetStoreCustomerInfo(user?.uid || '')
	const setStoreCustomerInfoClub = useSetStoreCustomerInfo(club?.id || '')

	const platform = Capacitor.getPlatform()
	const apiKey = REVENUECAT_PUBLIC_API_KEYS[platform as keyof typeof REVENUECAT_PUBLIC_API_KEYS]
	if (!apiKey) {
		console.error("No API key for platform!", platform)
	}

	//
	// Initialize the CapacitorPurchases plugin.
	//
	// We don't send any user info yet.
	//
	const setupPurchasesOnDevice = useCallback(async () => {

		console.log('Initializing Purchases plugin for', platform)

        await Purchases.configure({
            apiKey,
            // appUserID: uid,
            // observorMode: true,
        })

		console.log('Loading offerings...')
		const offerings = await Purchases.getOfferings()
		console.log('RevenueCat says offerings are:', offerings)
		setStoreOfferings(offerings)

	}, [ setStoreOfferings, apiKey, platform ])

	useEffect(() => {
		if (Capacitor.isNativePlatform()) {
			setupPurchasesOnDevice()
		}
	}, [ setupPurchasesOnDevice ])

	useEffect(() => {
		if (Capacitor.isNativePlatform() && user?.uid) {
			console.log('Logging in user for CapacitorPurchases', user?.uid)
			try {
				Purchases.logIn({
					appUserID: user.uid,
				})
			} catch (err) {
				console.error("ERROR: Failed to log in user for CapacitorPurchases")
			}
		}
	}, [ user?.uid, setupPurchasesOnDevice ])

	//
	// When user/club changes, fetch RevenueCat CustomerInfo, so we can
	// tell whether they have purchased AutoRoster Pro.
	//

	const options = useMemo(() => ({
		method: 'GET',
		headers: {
			accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${apiKey}`,
		}
	}), [ apiKey ])

	const fetchCustomerInfoForUserOnWeb = useCallback(async () => {

		console.log("updatePurchasesForUserOnWeb()", user?.uid)

		if (user?.uid) {
			try {
				const response = await fetchJson<RevenueCatAPIV1<RevenueCatCustomerInfoAPIV1>>(`${REVENUECAT_API_URL}/subscribers/${user.uid}`, options)
				console.log("RevenueCat response for regular user", response)
				setStoreCustomerInfoUser(response.subscriber)
			} catch (err) {
				console.error('Purchaser info error from api', err)
			}
		}
	}, [ user?.uid, setStoreCustomerInfoUser, options ])

	const fetchCustomerInfoForClubOnWeb = useCallback(async () => {

		console.log("updatePurchasesForClubOnWeb()", club?.id)

		if (club?.id) {
			try {
				const response = await fetchJson<RevenueCatAPIV1<RevenueCatCustomerInfoAPIV1>>(`${REVENUECAT_API_URL}/subscribers/${club.id}`, options)
				console.log("RevenueCat response for club user", response)
				setStoreCustomerInfoClub(response.subscriber)
			} catch (err) {
				console.error('Purchaser info (club) error from api', err)
			}
		}
	}, [ club?.id, setStoreCustomerInfoClub, options ])

	/*
	//
	// On native, there doesn't seem to be a good way to fetch user & club info
	// separately, like we do for web.
	//
	// We have to always check both.
	//
	// And this is so wonky, I'm going to try to avoid it altogether.
	//
	// < Used to be a bunch of code here for fetchCustomerInfoForUserOnDevice() >
	*/

	//
	// Call the relevant one of the above functions when user.uid or club.id changes.
	//
	useEffect(() => {
		fetchCustomerInfoForUserOnWeb()
	}, [ fetchCustomerInfoForUserOnWeb ])

	useEffect(() => {
		fetchCustomerInfoForClubOnWeb()
	}, [ fetchCustomerInfoForClubOnWeb ])

	return null
}

export default MyPurchases
