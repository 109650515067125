import React, { useState, useEffect } from 'react'
import { collection, addDoc } from "firebase/firestore"
import { db } from './firebase'
import {
    IonButton,
	IonIcon,
} from '@ionic/react'
import EditItem from './EditItem'
import { PlanName } from './Plan'
import { DisplayUpgradeOffer } from './Plan'
import FadeText from './FadeText'
import {
	queryDbid,
    useCanAddItem,
	useNewTeamIdState,
    useSection,
    useSetAmAddingNewSeason,
    useSetShowTeamSelection,
    useUserHasPro,
} from './data'
import { getUrl } from './util'
import "./additem.css"
import {useHistory} from 'react-router-dom'
import {checkmarkSharp} from 'ionicons/icons'

type AddItemProps<T> = {
	onCancel: () => void
	items: Array<T>
	type: ItemTypeDefinition<T>
} & AddItemContentProps<T>

const AddItem = <T extends Item>(props: AddItemProps<T>) => {

    const { onCancel, items, type } = props

    const userHasPro = useUserHasPro()

	//
	// Don't permit adding a second item if the user doesn't have AutoRoster Pro
	//
	if (!userHasPro && type.maxWithoutPro && items.length >= type.maxWithoutPro) {
        return (
            <FadeText
				className="add-item-display-upgrade-offer"
				isBlock={true}
				contentKey="upgradeOffer"
			>
                <DisplayUpgradeOffer />
                <p>
                    You can use AutoRoster for free to manage one team & season
                    at a time.
                </p>
                <p>
                    To add more, either delete your existing data, or upgrade
                    to <PlanName />.
                </p>
                <IonButton
                    className="button-cancel"
                    onClick={onCancel}
                    fill="clear"
                    color="medium"
                >
                    Done
                </IonButton>
            </FadeText>
        )
    }

    return (
        <AddItemContent {...props} />
    )
}

type AddItemContentProps<T> = {
	onCancel: () => void
	onSubmit?: (result: { data: T, id: string | undefined }) => void
	items: Array<T>
	type: ItemTypeDefinition<T>
	quickAdding?: boolean
}

const AddItemContent = <T extends Item>(props: AddItemContentProps<T>) => {

    const { onSubmit, items, type, quickAdding } = props

    const section = useSection()
	const [ newTeamId, setNewTeamId ] = useNewTeamIdState()
	const history = useHistory()
	const setShowTeamSelection = useSetShowTeamSelection()

    const canAddItem = useCanAddItem(type?.unit)
	const setAmAddingNewSeason = useSetAmAddingNewSeason()

    const [ show, setShow ] = useState(false)

    // Provide fade-in effect.
    useEffect(() => {
        setShow(true)
    }, [ setShow ])

	//
	// On mount, make sure the element is visible.
	//
    useEffect(() => {
        const timer = setTimeout(() => {
            const [ element ] = document.getElementsByClassName('add-item')

            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
					block: 'end',
                })
            }
        }, 120)

        return () => clearTimeout(timer)
    }, [ ])

	if (!canAddItem) {
		console.log("Not allowed to add item.", type?.unit)
		return null
	}

	const onCancel = () => {
		setAmAddingNewSeason(false)
		props.onCancel()
	}

	const handleSubmit = async (data: T) => {

		data.created = data.created || Date.now()

		if (type.onAdd) {
			const extraFields = type.onAdd()
			Object.entries(extraFields).forEach(([ key, value ]) =>
				data[key as keyof typeof data] = value
			)
		}

		//
		// If we're adding a season, we need to reference
		// the teamId that we've just chosen even if we haven't
		// switched to it yet.
		//
		const s = { ...section }
		if (type.unit === 'season' && newTeamId) {
			s.team = newTeamId
		}

		if (type.unit === 'coach') {
			throw new Error('Adding a coach is not supported.')
		}

        const dbid = queryDbid(type.unit, s)

        console.log('Calculated my dbid as', dbid, 'from', type, s)

        let docRef

        try {
            docRef = await addDoc(collection(db, dbid), data)
            console.log('doc written', docRef?.id)

			//
			// Usually we want to get rid of the Add Item form now.
			//
            if (!quickAdding) {
                onCancel()
            }

			//
			// If we just added a new season, jump right to it.
			//
			if (type.unit === 'season') {
				const url = getUrl({
					relative: true,
					page: 'games',
					section: {
						...s,
						season: docRef.id,
					},
				})
				console.log("Jumping to", url)

				// Close team/season selector
				setAmAddingNewSeason(false)
				setShowTeamSelection(false)

				history.push(url)
			}

			//
			// If we just added a new team, skip along to the season selector
			//
			else if (type.unit === 'team') {
				console.log("Setting team id", docRef.id, "for new team")
				setNewTeamId(docRef.id)
			}

		}	catch (err) {
            console.error('Failed to write doc!', err)
        }

        if (onSubmit) {
            onSubmit({
                data,
                id: docRef?.id,
            })
        }
    }

	const item: Partial<T> = { }

	const { defaultValue } = type
    if (defaultValue) {
        type.fields.forEach(field => {
            const dv = defaultValue({
				field,
                items,
            })
            if (dv !== undefined && dv !== null && dv !== '') {
                item[field] = dv
            }
        })
    }

    return (
		<EditItem<T>
            mode="add"
            type={type}
            item={item as T}
            items={items}
            className={`add-item ${show ? 'show' : ''}`}
            submitButtonContent={
				<>
					<IonIcon icon={checkmarkSharp} /> Submit
				</>
			}
            onSubmit={handleSubmit}
            onCancel={onCancel}
        />
    )
}

export default AddItem
