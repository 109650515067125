import React from 'react'
import { Capacitor } from '@capacitor/core'
import { db } from './firebase'
import { collection, getDocs, query, orderBy, startAt, endAt, documentId } from 'firebase/firestore'
import { User as FirebaseUser } from "@firebase/auth"
import { User as CapacitorUser } from "@capacitor-firebase/authentication"
import { BENCHED, HOME_URL, SIDES, queryFieldPositions } from './definitions'
import { UserCredential } from 'firebase/auth'
import { validateResult } from './functions'
import { ROSTER_NOT_AVAILABLE } from './definitions'

export function ucFirst(s: string) {
	return s.charAt(0).toUpperCase() + s.substring(1)
}

const dateOptions = {
	default: {
		month: 'short',
		day: 'numeric',
	},
	long: {
		month: 'short',
		day: 'numeric',
	},
	full: {
		weekday: 'short',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	},
} as const

const timeOptions = {
	default: {
		hour: 'numeric',
		minute: 'numeric',
	},
	long: {
		hour: 'numeric',
		minute: 'numeric',
	},
	full: {
		hour: 'numeric',
		minute: 'numeric',
	},
} as const

export function formatDate(t: string | number, format?: keyof typeof dateOptions) {

	const myFormat = format || 'default'

	const d = new Date(t)

	const dateString = d.toLocaleDateString('en-AU', dateOptions[myFormat]).replace(/,/, '')
	const timeString = timeOptions[myFormat] && d.toLocaleTimeString('en-AU', timeOptions[myFormat])

	if (timeString) {
		return (
			<>
				<span className="date-date">
					{dateString}
				</span>
				<span className="date-time">
					{timeString}
				</span>
			</>
		)
	}

	return dateString
}

//
// Send a time; returns 'past', 'present' or 'future'
//
export function gameDateClass(t: number) {
	const secondsDiff = (t - Date.now()) / 1000

	if (secondsDiff < -86400) {
		return 'past'
	}
	if (secondsDiff < 6 * 86400) {
		return 'present'
	}
	return 'future'
}

//
// Moved from Stats.js
//
// 'data.players' can be an object of playerNames or the full player object (with 'created' and 'prefs' fields),
//                since all we care about is the list of playerIds
//
export function parseData(data: ParseableData, includeFuture?: boolean, lastGameIsBefore?: number): AllPlayerStats {

	// console.log('parseData', data)

	//
	// Build a stats array.
	//
	// Will ignore any dummy players.
	//
	const stats: {
		[key: PlayerId]: PlayerStats
	} = { }

	const fieldPositions = queryFieldPositions(data.gameFormat)

	if (data.players) {
		Object.keys(data.players).forEach(playerId =>
			stats[playerId] = {
				positions: new Array(fieldPositions.length).fill(0),
				orangeman: 0,
				orangemanGames: [ ],
				absent: 0,
				absentGames: [ ],
				games: { },
				lastGame: null,
                lastGamePositions: [ ],
				qtrsBenched: 0,
			}
		)

		const t = Date.now()

		const { rosters, games, numPeriods } = data

		if (rosters && games) {

			if (!numPeriods) {
				console.error("Missing numPeriods")
			}

			Object.keys(games).sort((a, b) => games[a].date - games[b].date).forEach(gameId => {
				if (rosters[gameId]) {
					if (includeFuture || t > games[gameId].date) {
						//
						// Who played in which position?
						//
						const { roster, players } = rosters[gameId]
						const nulled = rosters[gameId].nulled || [ ]
						const subs = rosters[gameId].subs || [ ]

						if (roster && roster.length) {
							const numPositions = roster.length / numPeriods
							for (let qtr = 0; qtr < numPeriods; qtr += 1) {
								const offset = qtr * numPositions
								for (let i = 0; i < numPositions; i += 1) {

									const rosterPositionIndex = offset + i

									if (nulled.indexOf(rosterPositionIndex) === -1) {
										const playerId = roster[rosterPositionIndex]

										//
										// Don't record stats if a player was assigned a position on the
										// roster but were also marked absent (e.g. team was short on players).
										//
										if (stats[playerId] && players.includes(playerId)) {
											let posIndex = i

											//
											// Combine stats for when gameFormat allows duplicate positions,
											// e.g. 2 x "A"
											//
											if (fieldPositions[i] === fieldPositions[i + 1]) {
												posIndex += 1
											}

											//
											// Am I subbing on/off?
											//
											// If so, only count half a qtr for this position.
											//
											const { subPosIndex } = querySub({ i: rosterPositionIndex, subs })

											const amt = subPosIndex === null ? 1 : 0.5

											const amBenched = i >= fieldPositions.length
											if (amBenched) {
												stats[playerId].qtrsBenched += amt
											} else {
												stats[playerId].positions[posIndex] += amt
											}

											//
											// Record the positions each player played in each game,
											// so we can easily access their most recent game.
											//
											if (!stats[playerId].games[gameId]) {
												stats[playerId].games[gameId] = new Array(numPeriods).fill([ ])
											}

											stats[playerId].games[gameId][qtr] = [ amBenched ? null : posIndex ]

											// console.log(gameId, qtr, playerId, posIndex, stats[playerId].games[gameId][qtr].join(', '))

											if (subPosIndex !== null) {
												//
												// We're a sub, so figure out what the other 0.5 is for.
												//
												// Note we only need to worry about what THIS position is doing;
												// the other half of the sub will be handled when we get to that.
												//
												let subPos = subPosIndex % numPositions
												const subIsBenched = subPos >= fieldPositions.length

												// Combine stats for gameFormats with duplicate positions, e.g. 2 x "A"
												if (!subIsBenched && fieldPositions[subPos] === fieldPositions[subPos + 1]) {
													subPos += 1
												}

												stats[playerId].games[gameId][qtr].push(subIsBenched ? null : subPos)

												if (subIsBenched) {
													stats[playerId].qtrsBenched += 0.5
												} else {
													stats[playerId].positions[subPos] += 0.5
												}
											}

											//
											// If this is a benched position and not a sub, no need to record anything else.
											//
											if (amBenched && subPosIndex === null) {
												continue
											}

											if (!stats[playerId].lastGame || games[gameId].date > games[stats[playerId].lastGame].date) {
												if (!lastGameIsBefore || games[gameId].date < lastGameIsBefore) {
													stats[playerId].lastGame = gameId
													stats[playerId].lastGamePositions = stats[playerId].games[gameId]
												}
											}
										}
									}
								}
							}
						}

						//
						// Orangeman!
						//
						const orangeman = rosters[gameId].orangeman
						if (orangeman && stats[orangeman]) {
							stats[orangeman].orangemanGames.push(gameId)
						}

						//
						// Absences
						//
						// Start by assuming everyone is absent, then remove those who played.
						//
						const absentPlayers: {
							[key: PlayerId]: boolean
						} = { }
						Object.keys(data.players!).forEach(playerId => absentPlayers[playerId] = true)

						/*
						 * This causes players to be counted as "absent" if they're
						 * rostered off for all 4 qtrs. I originally did this because
						 * I noticed that some users didn't seem to have figured out
						 * how to mark players as unavailable. But now I'm providing
						 * a highlight to warn about that, and I think it's better
						 * not to work around the user's mistake but rather help them
						 * learn the proper way to do things.
						 *
						rosters[gameId].players.forEach(player => {
							//
							// If a player is rostered off for 4 qtrs, count them
							// as absent.
							//
							if (!stats[player].games[gameId]) {
								stats[player].qtrsBenched -= numPeriods
							} else {
								delete absentPlayers[player]
							}
						})

						 * Below is the original version that the above replaced.
						 */

						rosters[gameId].players.forEach(playerId => {
							delete absentPlayers[playerId]
						})

						Object.keys(absentPlayers).forEach(playerId => {
							// Record this gameId under stats[playerId].absentGames
							stats[playerId].absentGames.push(gameId)
						})
					}
				}
			})
		}
	}

    // console.log('generated stats', stats)

	return stats
}

//
// parseRoster()
//
// Takes a bunch of saved data and returns a format like this:
//
// {
// 	 44aaZbg8f5zyEPfiT80l: [
//		{ i: 0, position: 'GS', netStat: 0.0357 },
//		{ i: 9, position: 'GS', netStat: 0.0357 },
//		{ i: 18, position: 'GS', netStat: 0.0357 },
//		{ i: 27, position: 'GS', netStat: 0.0357 },
//   ],
//   ASjFCLG5GKYNjmla6CeG: [ (4) [{…}, {…}, {…}, {…}] ],
//   ...
// }
//
// If rosters are messed up, this function produces undef values, which crashes
// GridPositions.
//
// Note that this function might be sent an impossible combination of 'rosters', 'players',
// etc, if we're in the middle of changing from one team to another, and loading the new
// one's stats. We can't assume that all data will be valid & belong to the same team.
// In particular, the number of qtrs might have changed.
//
type parseRosterProps = {
	roster: PlayerId[]
	players: PlayerId[]
	available: Available
	nulled: RosterPositionIndex[]
	subs: Subs
	numPeriods: NumPeriods
	gameFormat: GameFormat
	netStats: { [key: string]: { normalized: number[] } }
	combos: ComboStats,
	errorsFatal?: boolean
}

export function parseRoster(props: parseRosterProps): AllRosterStats | null {

	const { roster, players, available, nulled, subs, numPeriods, gameFormat, netStats, combos, errorsFatal } = props

    if (!roster || !players || !available || !nulled)
		return null

	// console.log('parseRoster()', roster, roster.length, players, players.length, netStats, combos, 'available', available, 'nulled', nulled)

	//
	// Create initial object with a key of player names and a
	// value of an empty array.
	//
	const data: AllRosterStats = { }
	players.forEach(playerId => {
		data[playerId] = [ ]
	})

	if (roster.length) {

		//
		// Build data.
		//
        const positions = calculatePositions(roster.length / numPeriods, gameFormat)

		const fieldPositions = queryFieldPositions(gameFormat)

		for (let i = 0; i < roster.length; i += 1) {
			const qtr = Math.floor(i / positions.length)
			const player = roster[i]
			if (!data[player]) {
				//
				// Player is in the roster, but we don't have any data for them. We may
				// still be loading their data, or else the roster may be corrupt.
				//
				console.error('unknown player', player, 'Creating entry. But this will not work because of mismatch between players array and data object.')
				data[player] = [ ]
				if (errorsFatal)
					throw Error("Player deleted from roster.")
			}

			let idx = i

			//
			// If this player is on the bench but they will sub on, treat them as if they
			// have the on-court position.
			//
			let subPosIndex = null

			if (idx % positions.length >= fieldPositions.length) {
				({ subPosIndex } = querySub({ i, subs }))
				if (subPosIndex !== null) {
					idx = subPosIndex
				}
			}

			const posIndex = Math.min(fieldPositions.length, idx % positions.length)
			const position = positions[posIndex]

			// This is the netStat score we display on-screen
			let netStat

			if (netStats && netStats[player]) {
				let thisPosIndex = posIndex

				if (fieldPositions[posIndex] === fieldPositions[posIndex + 1]) {
					//
					// Use the correct posIndex for gameFormats where positions are duplicated
					//
					thisPosIndex = posIndex + 1
				}

				netStat = netStats[player].normalized[thisPosIndex]
			}

			// console.log('netstat', player, player && netStats && netStats[player], position, posIndex, netStat)

            // console.log(player, qtr, i, position, netStat)

			data[player][qtr] = {
				i,
				position,
				netStat,
				playing: (available[player] && !nulled.includes(i)) ? true : false,
				subPosIndex,
			}
		}

		//
		// Add combos
		//

		//
		// Don't do combo stats for 5v5 or 6v6, because those have duplicate positions
		// ('A', 'C', 'D'), and this code isn't smart enough to account for how the first
		// and second 'A' positions are actually the same.
		//
		const noComboStats = gameFormat === 5 || gameFormat === 6

		if ((combos?.pairs || combos?.trios) && !noComboStats) {
			const numPlayersPerQtr = roster.length / numPeriods
			// console.log('set trio numPlayersPerQtr', numPlayersPerQtr)
			for (let qtr = 0; qtr < numPeriods; qtr += 1) {
				let lastTrioIndex = -1 * fieldPositions.length + 2
				let lastTrioOffset = 0
				for (let i = 0; i < fieldPositions.length - 1; i += 1) {
					const posIndex = i + qtr * numPlayersPerQtr
					const p1 = roster[posIndex]
					const p2 = roster[posIndex + 1]
					if (combos.pairs && p1 && p2) {
						const obj = data[p1][qtr]
   						if (obj && obj.playing && data[p2][qtr]?.playing) {
							const pairId = `${p1}__${i}__${p2}__${i+1}`
							const pair = combos.pairs[pairId]
							if (pair) {
								obj.pair = pair
							}
							const p3 = combos.trios && roster[posIndex + 2]
							if (p3 && posIndex % numPlayersPerQtr < 5 && data[p3][qtr]?.playing) {
								const trioId = p3 && `${pairId}__${p3}__${i+2}`
								if (combos.trios[trioId]) {
									obj.trio = combos.trios[trioId]
									const offset = i - lastTrioIndex === 1 && lastTrioOffset === 0 ? 1 : 0
									obj.trioIndex = offset
									lastTrioIndex = i
									lastTrioOffset = offset
									// console.log('in qtr', qtr, 'set trio', trioId, combos.trios[trioId], offset)
								}
							}
						}
					}
				}
			}
		}
	} else {

		//
		// No roster: put everyone on the bench.
		//

		// console.log("No roster: bench everyone")

		const positions = calculatePositions(players.length, gameFormat)

		for (let qtr = 0; qtr < numPeriods; qtr += 1) {
			for (let pos = 0; pos < players.length; pos += 1) {
				const i = qtr * players.length + pos
				const player = players[pos]
				if (player) {
					const position = positions[pos]
					data[player][qtr] = {
						i,
						position,
					}
					// console.log('slot', player, qtr, data[player][qtr])
				}
			}
		}
	}

	// console.log('parseRoster results', roster, data)

	return data
}

type QuerySubProps = {
	i: RosterPositionIndex
	subs?: Subs
}

type QuerySubResult = {
	subIndex: number | null
	subPosIndex: RosterPositionIndex | null
	subStatus: SubStatus
}

export function querySub(props: QuerySubProps): QuerySubResult {

	const { subs, i } = props

	let subIndex = null
	let subPosIndex = null
	let subStatus: SubStatus = 0

	if (subs) {
		const idx = subs.findIndex(posIndex => posIndex === i)
		if (idx !== -1) {
			subIndex = idx
			subStatus = subIndex === -1 ? 0 : subIndex % 2 ? 2 : 1
			const offset = subIndex % 2 ? -1 : 1
			subPosIndex = subs[subIndex + offset]
		}
	}

	return {
		subIndex, 			// The index of the sub in the subs array
		subPosIndex,		// The position of the sub in the roster
		subStatus,			// 0 = not subbed, 1 = subbing on, 2 = subbing off
	}
}

//
// Try to fix any borked rosters. This gets called before we save and also
// afterwards.
//
export function sanitizeBest(obj: Best, numPeriods: NumPeriods, gameFormat: GameFormat): Best {

    // console.log('*** SANITIZE BEST ***', obj)

	const fieldPositions = queryFieldPositions(gameFormat)

    //
    // Figure out whether any players have been added or deleted from this
    // team since the roster was made.
    //

	const playersInRoster: {
		[key: PlayerId]: number[]
	} = { }

	const deletedPlayers: {
		[key: PlayerId]: boolean
	} = { }

	const addedPlayers: {
		[key: PlayerId]: boolean
	} = { }

	const emptySlots: number[] = [ ]

    //
    // We will modify these if necessary
    //
    const players = [ ...obj.players ]
    const roster = [ ...obj.roster ]
	const subs = obj.subs ? [ ...obj.subs ] : [ ]
	const nulled = obj.nulled ? [ ...obj.nulled ] : [ ]
    let orangeman = obj.orangeman

    if (roster.length) {

        //
        // Identify any players who are in the roster but have since been deleted, and
        // so we don't have a database player object for them.
        //
		// Also make a note of which qtr they appear in, so we can check later whether
		// this is valid.
		//

		roster.forEach((player, slot) => {
            if (player) {
				if (!playersInRoster[player]) {
					playersInRoster[player] = new Array(numPeriods).fill(0)
				}

				// A local value because roster length may change below
				const playersPerQtr = roster.length / numPeriods

				const qtr = Math.floor(slot / playersPerQtr)

                playersInRoster[player][qtr] += 1

                if (!players.includes(player)) {
                    console.log(player, "is in the roster but not the list of players!")
                    deletedPlayers[player] = true
                }
            }
        })

        //
        // Check each player appears once per qtr.
        //
        Object.keys(playersInRoster).forEach(player => {
			for (let qtr = 0; qtr < numPeriods; qtr += 1) {
				if (playersInRoster[player][qtr] !== 1) {
					console.error("Player", player, "in roster qtr", qtr, playersInRoster[player][qtr], "times! Will try to remove & re-add them.", playersInRoster)
					deletedPlayers[player] = true
					addedPlayers[player] = true
				}
			}
		})

        //
        // Remove any deleted or messed up players from the roster.
        //
        Object.keys(deletedPlayers).forEach(player=> {
            for (let i = 0; i < roster.length; i += 1) {
                if (roster[i] === player) {
                    roster[i] = ''
                }
            }
        })

        //
        // Identify any empty slots.
        //
        roster.forEach((player, slot) => {
            if (!player) {
                console.log("Slot", slot, "is empty!")
                emptySlots.push(slot)
            }
        })

        //
        // Identify any added players (listed in `players` but not `roster`).
        //
        // If so, the fix seems to be to add a new '-' position and expand roster
        // to find a place for that player.
        //
        players.forEach(player => {
            if (!playersInRoster[player]) {
                console.log(player, "is a listed player but not in the roster!", 'players', players, 'roster', roster)
                addedPlayers[player] = true
            }
        })

        if (players.length < fieldPositions.length) {
            // Time for dummy players!

            const dummyPlayers = generateNewPlayerNames(players, fieldPositions.length - players.length)

            console.log("Adding", fieldPositions.length - players.length, "dummy players", dummyPlayers)

            players.push(...dummyPlayers)

			dummyPlayers.forEach(player => {
				addedPlayers[player] = true
			})
        }

        if (emptySlots.length || Object.keys(addedPlayers).length) {
            console.log(emptySlots.length, 'empty slots and', Object.keys(addedPlayers).length, 'added players', addedPlayers)
        }

        //
        // Attempt to assign any new players to any empty slots.
        //
        if (Object.keys(addedPlayers).length) {

            Object.keys(addedPlayers).forEach(player => {

				const playersPerQtr = roster.length / numPeriods

                for (let qtr = 0; qtr < numPeriods; qtr += 1) {

                    // First we'll try to put them in an empty slot, if there is one.

                    let foundEmptySlot = false

                    for (let i = 0; i < emptySlots.length; i += 1) {
                        const slot = emptySlots[i]
                        const slotQtr = Math.floor(slot / playersPerQtr)
                        if (slotQtr === qtr) {
                            roster[slot] = player
                            console.log("Assigned", player, "to empty slot", slot, 'in qtr', qtr)
                            emptySlots.splice(i, 1)
                            foundEmptySlot = true
                            console.log("Empty slots is now", emptySlots)
                            break
                        }
                    }

                    if (!foundEmptySlot) {
                        const offset = qtr * (playersPerQtr + 1)
						const insertAt = offset + playersPerQtr
                        roster.splice(insertAt, 0, player)

						//
						// Adjust 'subs' and 'nulled' arrays to account for the new player
						//
						subs.forEach((posIndex, index) => {
							if (posIndex > insertAt) {
								subs[index] = posIndex + 1
							}
						})
						nulled.forEach((posIndex, index) => {
							if (posIndex > insertAt) {
								nulled[index] = posIndex + 1
							}
						})
                        console.log("No empty slot for", player, "qtr", qtr, "so expanded roster to fit.", offset, insertAt, '(', playersPerQtr, ')', roster)
                    }
                }
            })
        }

        if (emptySlots.length) {
            console.log("Still some empty slots - probably from leftover deleted player. Removing:", emptySlots)
            for (let i = emptySlots.length - 1; i >= 0; i -= 1) {
                const slotNum = emptySlots[i]
                roster.splice(slotNum, 1)

				//
				// Adjust 'subs' and 'nulled' arrays to account for the deleted player
				//
				subs.forEach((posIndex, index) => {
					if (posIndex > slotNum) {
						subs[index] = posIndex - 1
					}
				})
				nulled.forEach((posIndex, index) => {
					if (posIndex > slotNum) {
						nulled[index] = posIndex - 1
					}
				})
			}
            // console.log("Now roster is:", roster)
        }
    }

	//
	// Make sure subs are valid
	//
	if (subs.length) {
		const numPlayersPerQtr = roster.length / numPeriods
		for (let i = 0; i < subs.length; i += 2) {
			const subPosIndex1 = subs[i]
			const subPosIndex2 = subs[i + 1]
			const qtr1 = Math.floor(subPosIndex1 / numPlayersPerQtr)
			const qtr2 = Math.floor(subPosIndex2 / numPlayersPerQtr)
			if (qtr1 !== qtr2) {
				console.error("Subs are in different qtrs!", subs, subPosIndex1, subPosIndex2)
				// Remove bogus subs
				subs.splice(i, 2)
			}
		}
	}

    //
    // Orangeman: Cannot be undefined
    //
    if (orangeman === undefined) {
        orangeman = null
    }

	// console.log("FINISHED SANITIZATION", 'roster', roster, 'players', players, 'subs', subs, 'nulled', nulled, 'orangeman', orangeman)

    return {
        ...obj,
        roster,
        players,
		subs,
		nulled,
        orangeman,
    }
}

//
// Create starting function with players assigned randomly
//
export function createEmptyPhenotype(players: PlayerId[], numPeriods: NumPeriods, gameFormat: GameFormat): Phenotype {

	const positions = calculatePositions(players.length, gameFormat)

	const data = [ ]

	for (let i = 0; i < numPeriods; i += 1) {
		const p = players.slice()
		shuffle(p)
		for (let j = 0; j < positions.length; j++) {
			const offset = i * positions.length
			data[offset + j] = p[j]
		}
	}

	return {
		roster: data,
		score: null,
	}
}

function shuffle(array: any[]) {
	if (!Array.isArray(array)) {
		console.error('Not an array!', array)
	}
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1))
		;[array[i], array[j]] = [array[j], array[i]]
	}
}

export function calculatePositions(numPlayers: number, gameFormat: number) {
	const fieldPositions = queryFieldPositions(gameFormat)
	const positions: FieldPosition[] = fieldPositions.slice()
	for (let i = fieldPositions.length; i < numPlayers; i += 1) {
		positions.push(BENCHED)
	}
	return positions
}

export function labelRound(str: string) {
	if (str && String(str).match(/^\d+$/)) {
		return `Round ${str}`
	}
	return str
}

const TEAM_CHARS = 4
const SEASON_CHARS = 3
const GAME_CHARS = 3

//
// Take a shortcut like 'SjBVXc2' and find the matching team/season/game
//
export async function shortcutToSection(shortcut: string): Promise<Section> {

	if (!shortcut || (shortcut.length !== TEAM_CHARS && shortcut.length !== TEAM_CHARS + SEASON_CHARS && shortcut.length !== TEAM_CHARS + SEASON_CHARS + GAME_CHARS)) {
		console.error("Invalid shortcut", shortcut)
		return { }
	}

	const teamId = shortcut.substr(0, TEAM_CHARS)
	const seasonId = shortcut.substr(TEAM_CHARS, SEASON_CHARS)
	const gameId = shortcut.substr(TEAM_CHARS + SEASON_CHARS, GAME_CHARS)

    const queryTeams = query(collection(db, "teams"),
        orderBy(documentId()),
        startAt(teamId),
        endAt(teamId + "\uf8ff")
    )

    console.log('queryTeams', queryTeams)

    const teamsSnapshot = await getDocs(queryTeams)

    console.log("Number of teams:", teamsSnapshot.size)

    for (const teamDoc of teamsSnapshot.docs) {

        console.log(teamDoc.id, '=================>', teamDoc.data())

        if (!seasonId) {
            return {
                team: teamDoc.id
            }
        }

        const querySeasons = query(collection(db, `/teams/${teamDoc.id}/seasons`),
            orderBy(documentId()),
            startAt(seasonId),
            endAt(seasonId + "\uf8ff")
        )

        const seasonsSnapshot = await getDocs(querySeasons)

        console.log("Number of seasons:", seasonsSnapshot.size)

        for (const seasonDoc of seasonsSnapshot.docs) {

            console.log(seasonDoc.id, '=================>', seasonDoc.data())

            if (!gameId) {
                return {
                    team: teamDoc.id,
                    season: seasonDoc.id,
                }
            }

            const queryGames = query(collection(db, `/teams/${teamDoc.id}/seasons/${seasonDoc.id}/games`),
                orderBy(documentId()),
                startAt(gameId),
                endAt(gameId + "\uf8ff")
            )

            const gamesSnapshot = await getDocs(queryGames)

            console.log("Number of games:", gamesSnapshot.size)

            if (gamesSnapshot.size > 1) {
                console.error("Multiple matches on shortcut!", shortcut)
            }

            return {
                team: teamDoc.id,
                season: seasonDoc.id,
                game: gamesSnapshot.docs[0].id,
            }
        }
    }

    console.log("No matches for shortcut", shortcut)
	return { }
}

export function saveToLocalStorage(key: string, data: any) {
	try {
		localStorage.setItem(key, JSON.stringify(data))
	} catch(error) {
		console.error("Failed to save to localStorage.", error)
	}
}

export function loadFromLocalStorage(key: string): unknown|null {
	try {
		const data = localStorage.getItem(key)
		if (data) {
			return JSON.parse(data)
		}
	} catch(error) {
		console.error("Unable to read localStorage", error)
	}
	return null
}

export function considerRememberingSection(section: Section, recent: RecentTeams | null) {

	let changed = false
	let newRecent: RecentTeams

	// console.log('recent - considerRememberingSection', section, recent)

	if (recent && Array.isArray(recent)) {
		newRecent = [ ...recent ]
	} else {
		const savedRecentArray = loadFromLocalStorage('recent')
		// console.log("Saved recent value is", typeof savedRecentArray, savedRecentArray)
		if (savedRecentArray && Array.isArray(savedRecentArray)) {
			// console.log("Loaded recent from localStorage", savedRecentArray)
			newRecent = [ ...savedRecentArray ]
			changed = true
		} else {
			// console.log("No saved recent - setting empty array as default value")
			newRecent = [ ]
			changed = true
		}
	}

	if (section?.team && section?.season) {
		const index = newRecent.findIndex(s => s.team === section.team && s.season === section.season)
		if (index === 0) {
			//
			// no change
			//
		} else if (index !== -1) {
			const removedItems = newRecent.splice(index, 1)
			// console.log("move to front of recent", removedItems)
			newRecent.unshift(...removedItems)
			changed = true
		} else {
			newRecent.unshift({
				team: section.team,
				season: section.season,
			})
			changed = true
		}
	}

	const MAX_RECENT_LENGTH = 5

	if (newRecent?.length > MAX_RECENT_LENGTH) {
		newRecent = newRecent.slice(0, MAX_RECENT_LENGTH)
		changed = true
	}

	if (changed) {
		saveToLocalStorage('recent', newRecent)
	}

	return {
		changed,
		newRecent,
	}
}


const DEFAULT_PLAYER_NAME = 'Fill-in'

//
// Calculate new name, e.g. "Player 11"
//
// Can generate multiple names at once if requested; in this case, returns
// an array of names rather than a single string.
//
// Examples:
//
// generateNewPlayerName(players, 2) => returns [ "Player 13", "Player 14" ]
//

export const generateNewPlayerNames = (existingPlayers: PlayerId[], num: number) => {
	let newPlayerNumber = 1

	const numNamesToGenerate = Math.max(1, num)

	const players = existingPlayers.slice()

	for (let i = 0; i < numNamesToGenerate; i += 1) {
		while (isDuplicate(players, newPlayerNumber)) {
			newPlayerNumber = newPlayerNumber + 1
		}

		const newPlayerName = DEFAULT_PLAYER_NAME + ' ' + newPlayerNumber.toString()
		players.push(newPlayerName)
	}

	return players.slice(existingPlayers.length)
}

//
// Extract just the fields we want from a firebase user object
//
export function userObject(obj: FirebaseUser | CapacitorUser | UserCredential): User {

    if (!obj || typeof obj !== 'object')
        return obj

    const fields = [
        'displayName',
        'email',
        'emailVerified',
        'isAnonymous',
        'phoneNumber',
        'photoUrl',
        'providerId',
        'providerData',
        'uid'
    ] as const

	const newUser: User = {
		uid: '',
		displayName: null,
		email: null,
		phoneNumber: null,
		photoUrl: null,
		emailVerified: false,
		isAnonymous: false,
		providerData: [ ],
		providerId: '',
	}

	fields.forEach(field => {
		const value = obj[field as keyof typeof obj]
        if (value !== undefined) {
			// @ts-ignore
            newUser[field] = deepCopy(value)
        }
    })

    // Work around stupid photoURL/photoUrl misnaming.
    // Also look for photos in providerData.
    if (!newUser.photoUrl) {
		// @ts-ignore
        newUser.photoUrl = obj.providerData?.reduce((url, arr) => url || arr.photoURL, obj.photoURL)
        /*
        if (ob.photoURL) {
            newUser.photoUrl = obj.photoURL
        }
        */
    }

    return newUser
}

//
// Take a user object (of the kind we store in recoil) and
// parse it for storing in firebase's club userInfo.
//
export function userLoginObject(user: User): UserCore {
	return {
		uid: user.uid,
		name: user.displayName,
		email: user.email,
		phoneNumber: user.phoneNumber,
		photoUrl: user.photoUrl,
	}
}

const isDuplicate = (existingPlayers: PlayerId[], n: number) => existingPlayers.filter(playerName => playerName === DEFAULT_PLAYER_NAME + ' ' + n.toString()).length

//
// Return an URL to our current page (or, optionally, a supplied page),
// converting any 'capacitor://localhost' links to 'https://autoroster.io'.
//
// But we'll allow 'http://localhost/' and other URLs to work, for dev.
//
// 'page' : (Optional) String like "/game/efsdf?team=1234&season=456"
//
type getUrlProps = {
	page?: string
	section?: Section
	relative?: boolean
}

export function getUrl(props: getUrlProps) {

	let url

	if (!props.relative) {
		url = Capacitor.isNativePlatform() ? HOME_URL : window.location.origin
	} else {
		url = '/'
	}

	if (props.page) {
		url += props.page
	} else if (!props.relative) {
		url += window.location.pathname
	}

	const { section } = props

	if (section) {
		const arr = [ 'team', 'season' ] as const
		const segments = arr.map(segment => section[segment] && `${segment}=${section[segment]}`).filter(v => v)

		if (segments.length) {
			url += '?' + segments.join('&')
		}
	}

	return url
}

export async function fetchJson<T>(url: string, options?: {[key: string]: string | any | undefined}): Promise<T> {
    const fetchResult = await fetch(url, options)
    if (!fetchResult.ok) {
		console.error('Network response was not ok in fetchJson()', fetchResult)
		return Promise.reject('Network response was not ok in fetchJson()')
    }
	try {
		const data: T = await fetchResult.json()
		return data
	} catch(error) {
		console.error('Error in fetchJson()', error)
		return Promise.reject('Error in fetchJson()')
	}
}

export function deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj))
}
//
// This is sent an entire 'results' object (by EditItem.js -> formIsInvalid()),
// which we should check properly.
//

export function isValidResult(value: Result | undefined, numPeriods: number) {
	// console.log('isValidResult() value:', value, numPeriods)

	if (!value?.me || !value?.them) {
		return false
	}

	const permitAllNull = true

	//
	// Return true if we should save this 'result' value,
	// or false if we should consider it corrupt and delete it.
	//
	let ok = true
	let allNull = true

	SIDES.forEach(side => {
		if (value[side].length > numPeriods) {
			ok = false
		} else {
			const qtrs = new Array(numPeriods).fill(null)
			qtrs.forEach((_, qtr) => {
				if (ok && !validateResult({
					result: value,
					side,
					qtr,
				})) {
					ok = false
				}
				if (allNull && value[side][qtr] !== null) {
					allNull = false
				}
			})
		}
	})

	// console.log('Games.js isInvalid() ok?', ok)
	if (ok && allNull && !permitAllNull) {
		// console.log("Would have been ok but allNull is true and permitAllNull is false")
		ok = false
	}

	// console.log('isValidResult() returning', ok)
	return ok
}

export type FindMyPositionsProps = {
	playerId: PlayerId
	roster: RosterData
	players: Array<PlayerId>
	nulled: Array<RosterPositionIndex>
	subs: Subs
	numPeriods: NumPeriods
	gameFormat: GameFormat
}

//
// Return an array of positions for this player per qtr,
// e.g. [ {
//  positionName: 'GS',
//  subStatus: 2,
//  isNullPosition: false,
// },
// {
//  positionName: 'GA',
//  subStatus: 0,
//  isNullPosition: false,
// },
// ...
//
export function findMyPositions(props: FindMyPositionsProps) {
	const { playerId, roster, players, nulled, subs, numPeriods, gameFormat } = props

	if (!roster || !roster.length) {
		return new Array(numPeriods).fill(ROSTER_NOT_AVAILABLE)
	}

	const fieldPositions = queryFieldPositions(gameFormat)

	const numPositions = roster.length / numPeriods

	const arr: Array<{
		positionName: string
		subStatus: number
		isNullPosition: boolean
		isAvailable: boolean
	}> = new Array(numPeriods).fill({
		positionName: BENCHED,
		subStatus: 0,
		isNullPosition: false,
		isAvailable: false,
	})

	for (let qtr = 0; qtr < numPeriods; qtr += 1) {
		const offset = qtr * numPositions
		for (let i = 0; i < numPositions; i += 1) {
			if (roster[offset + i] === playerId) {
				const { subPosIndex, subStatus } = querySub({i: offset + i, subs})
				const isNullPosition = nulled.indexOf(offset + i) !== -1

				const positionName = (subPosIndex !== null && subStatus === 2) ? fieldPositions[subPosIndex % numPositions] : fieldPositions[i] || BENCHED

				// console.log('findMyPositions', qtr, i, offset + i, subPosIndex, subStatus, isNullPosition)
				arr[qtr] = {
					positionName,
					subStatus,
					isNullPosition,
					isAvailable: players?.includes(playerId),
				}
				continue
			}
		}
	}

	return arr
}
