import React from 'react'
import {
    IonButton,
    IonIcon,
    IonSelect,
    IonSelectOption,
} from '@ionic/react'
import {
    useEditMode,
    useMyBest,
} from './data'
import PlayerName from './PlayerName'
import { closeCircleOutline } from 'ionicons/icons'
import orange from './orange.svg'
import "./orangeman.css"

type OrangemanProps = {
	setOrangeman: (playerId: PlayerId | null) => void
}

const Orangeman = (props: OrangemanProps) => {

	const { setOrangeman } = props

    const best = useMyBest()
    const editMode = useEditMode()

    const { orangeman, players } = best

	if (!players.length || (!orangeman && !editMode)) {
		return <div />
    }

    let clearButton
    let content

    if (editMode) {
        content = (
            <IonSelect
                interface="action-sheet"
                value={orangeman || ''}
                disabled={!editMode}
				onIonChange={ (e: CustomEvent) => setOrangeman(e.detail.value)}
            >
                {
                    players.map(playerId => (
                        <IonSelectOption key={playerId} value={playerId}>
                            <PlayerName playerId={playerId} />
                        </IonSelectOption>
                    ))
                }
            </IonSelect>
        )

        if (orangeman) {
            clearButton = (
                <IonButton
                    fill="clear"
                    color="medium"
                    onClick={() => setOrangeman(null) }
                >
                    <IonIcon
                        icon={closeCircleOutline}
                        className="clear-orangeman"
                    />
                </IonButton>
            )
        }
    } else {
        content = (
            <PlayerName playerId={orangeman} allowBlank={true} />
        )
    }

    return (
        <div className="orangeman">
            <img className="orange-image" src={orange} alt="Oranges" />
            {content}
            {clearButton}
		</div>
	)
}

export default Orangeman
