import React, { useEffect } from 'react'
import {
    IonButton,
    IonLabel,
} from '@ionic/react'

//
// These throw errors because of the symlink
//
// @ts-ignore
import LoadingSpinner from '../LoadingSpinner'
// @ts-ignore
import { TermsAndConditionsLink } from '../NativePurchasing'
// @ts-ignore
import { useClub, useUser } from '../data'

type WebPurchasingProps = {
	isClub?: boolean
}

export const WebPurchasing = (props: WebPurchasingProps) => {

	const { isClub } = props

    const user = useUser()
	const club = useClub()

	const STRIPE_PRICING_TABLE_ID 		= "prctbl_1MLKEgIeegAmPX6YImgahnCT"
	const STRIPE_CLUB_PRICING_TABLE_ID 	= "prctbl_1M2lIsIeegAmPX6YIrRBXDK4"

	const pricingTableId = isClub ? STRIPE_CLUB_PRICING_TABLE_ID : STRIPE_PRICING_TABLE_ID
	const id = isClub ? club?.id : user?.uid

	if (!id) {
		throw new Error("No user or club ID")
	}

	//
	// On first mount (only), inject script into document body
	//
    useEffect(() => {
        const script = document.createElement('script')
        script.src = "https://js.stripe.com/v3/pricing-table.js"
        script.async = true

        document.body.appendChild(script)

        return () => {
			document.body.removeChild(script)
		}
	}, [ ])

    console.log('WebPurchasing', id, user?.email, club)

    return (
        <div className="webpurchasing">
            <LoadingSpinner />
            <stripe-pricing-table
				pricing-table-id={pricingTableId}
				publishable-key="pk_live_51LvYD7IeegAmPX6YO4NLAVhzrltaN8GnhpwkGscHKbYLw4ggux1ocRgdcdjLqYK7Ib7CoHs5oT8Tn7gs5B4n6Luy00OB7kuT5i"

				// For testing only:
				// pricing-table-id="prctbl_1M3GmWIeegAmPX6YnQZXYOE6"
				// publishable-key="pk_test_51LvYD7IeegAmPX6YlzN5hHuZigKMX30qGpA5EOfRZ6UbvQMumKVaTet6rX5PvDFdZxcFxdQw0Nx7SXctWaJEvnRK00lDn7owXC"

				client-reference-id={id}
				customer-email={user?.email}
            />
			<TermsAndConditionsLink />
		</div>
    )
}

const webSubscriptionManagementUrl = 'https://billing.stripe.com/p/login/3cs2c4ege4exdHieUU'

export const WebSubscriptionManagement = () => {

	//
	// Same link regardless of whether it's personal or club, I think...
	//

	return (
		<IonLabel>
			<IonButton
				id="trigger-signons"
				fill="clear"
				href={webSubscriptionManagementUrl}
				target="_blank"
			>
				Manage...
			</IonButton>
		</IonLabel>
	)
}
