import React, { useRef } from 'react'
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonModal,
	IonTitle,
    IonToolbar,
} from '@ionic/react'
import { ModalContext } from './ModalContext'

//
// Can use in two forms:
//
// (1) Supply 'show', 'setShow'
//
// (2) Supply 'trigger'
//
// Either way, can also optionally supply 'title' and 'cancelText'
//
type ModalWithCancelProps = {
	show?: boolean
	setShow?: (show: boolean) => void
	trigger?: string
	title?: string
	cancelText?: string
	className?: string
}

const ModalWithCancel = (props: React.PropsWithChildren<ModalWithCancelProps>) => {

    const { show, setShow, trigger, title, cancelText } = props

	const modalRef = useRef<HTMLIonModalElement>(null)

	const onDismiss = () => {
		if (setShow) {
			setShow(false)
		} else {
			modalRef?.current?.dismiss()
		}
	}

	const titleElement = title && <IonTitle>{title}</IonTitle>

    return (
        <IonModal
            ref={modalRef}
            isOpen={show}
			trigger={trigger}
            onWillDismiss={onDismiss}
			className={props.className}
        >
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton
                            onClick={onDismiss}
                        >
							{cancelText || 'Cancel'}
                        </IonButton>
                    </IonButtons>
					{titleElement}
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <ModalContext.Provider value={onDismiss}>
                    {props.children}
                </ModalContext.Provider>
            </IonContent>
        </IonModal>
    )
}

export default ModalWithCancel
