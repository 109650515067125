import { Haptics, ImpactStyle } from '@capacitor/haptics'
import { Link } from 'react-router-dom'
import React, { useReducer } from 'react'

import { Warning } from './Roster'
import { calculatePositions } from './util'
import {
  useEditMode,
  useHaveValidSeason,
  useMyBest,
  useMyFieldPositions,
  useMyGameFormat,
  useMyGameId,
  useMyNumPeriods,
  useSetAmDragging,
  useSwapPlayers,
  useSwapPositions,
  useSwapQtrs,
  useToggleLock,
} from './data'
import GridCell from './GridCell'
import GridPlayers from './GridPlayers'
import GridPositions from './GridPositions'
import GridPreferences from './GridPreferences'
import LoadingSpinner from './LoadingSpinner'
import {queryFieldPositions} from './definitions'

type DisplayRosterProps = {
	data: AllRosterStats | undefined | null
	loading: boolean
	showAltBoard: boolean
	showPositionPreferences: boolean
	toggleAvailable: (player: PlayerId) => void
	setOrangeman: (player: PlayerId) => void
	onSwapPlayers: () => void
}

const DisplayRoster = (props: DisplayRosterProps) => {

	const { data, loading, showAltBoard, showPositionPreferences, toggleAvailable, setOrangeman, onSwapPlayers } = props

    const [ _, forceUpdate ] = useReducer(x => x + 1, 0)

    const editMode = useEditMode()

    const best = useMyBest()
    const gameId = useMyGameId() || ''
	const numPeriods = useMyNumPeriods()
	const gameFormat = useMyGameFormat()

	const setAmDragging = useSetAmDragging()

    const swapPositions = useSwapPositions(gameId)
    const swapPlayers = useSwapPlayers(gameId)
	const swapQtrs = useSwapQtrs(gameId)
	const toggleLock = useToggleLock(gameId)

	const haveValidSeason = useHaveValidSeason()

	const fieldPositions = useMyFieldPositions()

	// console.log("DisplayRoster.js render", fieldPositions)

    if (loading) {
        return (
            <LoadingSpinner />
        )
    }

	if (!haveValidSeason) {
		return (
			<Warning>
				This game belongs to a deleted season and cannot be modified.
			</Warning>

		)
	}
	if (!best.players.length) {
		return (
			<Warning>
				No players have been added yet.
				{' '}
				<Link
					to={{
						pathname: "/players",
					}}

				>
					Add some players.
				</Link>
			</Warning>
		)
	}


    //
    // *** IMPORTANT ***
    // So this motherfucker is bound at mount-time and can't
	// rely on reading any up-to-date values! That's super-annoying.
	// I need to be very careful that I don't rely on any values
	// that might change after mount.
	//
	// You *can* use dynamic variables in a callback function that has been
	// supplied to onDrop via props.
	//
	function onDrop(_event: React.DragEvent, obj: OnDropObject) {

		setAmDragging(false)

		const selector = ('qtr' in obj) ? `[data-qtr="${obj.qtr}"]` : `[data-i="${'i' in obj ? obj.i : obj.player}"]`

		const { xCenter, yCenter } = getBoundingBox(selector)

		console.log('onDrop!', obj, selector, getBoundingBox(selector), xCenter, yCenter)

		let droppedOnPosition = false

		const isQtrHeader = 'qtr' in obj

		const madeChanges =
			xCenter !== undefined &&
			yCenter !== undefined &&
			document.elementsFromPoint.apply(document, [ xCenter, yCenter ]).some(element => {

			// console.log('onDrop checking element', element)

			//
			// Figure out whether this element is being dropped in a position space,
			// because if so, later we might use this info to decide to toggle its lock.
			//
			// Unfortunately it also counts when a position is dropped in the cell-header,
			// so we have to manually reverse that further below.
			//
			if (element.classList.contains('column-positions-without-header')) {
				droppedOnPosition = true
			}

			if (isQtrHeader) {
				// console.log('isQtrHeader checking ', element)
				if (element.classList.contains('cell-header')) {
					// console.log('One header dropped on another header')
					const qtr = Number(element.getAttribute('data-qtr'))
					if (qtr === obj.qtr) {
						// The header was dropped back where it started
						return false
					}
					// console.log('Qtr header dropped successfully?', qtr, '<=>', obj.qtr)
					Haptics.impact({ style: ImpactStyle.Light })
					swapQtrs(qtr, obj.qtr)
					return true
				}
			} else if (element.classList.contains('posed-element')) {

				//
				// We dropped something on the grid.
				//
				const tile = element.firstChild
				if (tile && tile instanceof HTMLElement && !tile.classList.contains('cell-header')) {
					const i = tile.getAttribute('data-i')
					console.log(obj, 'was dropped on tile i', i, element)

					if (i === null) {
						console.log('Dropped on a non-tile')
						return false

					} else if ('player' in obj) {
						//
						// We're moving a player tile.
						//
						if (tile.classList.contains('cell-player')) {
							if (obj.player !== i) {
								console.log('Noticed player name swapping', obj.player, i)
								Haptics.impact({ style: ImpactStyle.Light })
								swapPlayers(obj.player, i)
								onSwapPlayers()
								return true
							}
						}
					} else {
						//
						// We're moving a position tile.
						//
						if (tile.classList.contains('cell-position')) {
							if (Number(i) === Number(obj.i)) {
								console.log('Dropped on self -ignoring')
								return false
							}
							if (Number(i) !== Number(obj.i)) {
								console.log('Invoking swapPositions!', i, obj)
								Haptics.impact({ style: ImpactStyle.Light })
								swapPositions(Number(obj.i), Number(i))
								return true
							}
						}
					}
				}
			} else if ('player' in obj && element.classList.contains('orangeman')) {

				//
				// We dropped something on the Orangeman space.
				//
				// console.log("Orangeman swapping")
				setOrangeman(obj.player)
				Haptics.impact({ style: ImpactStyle.Light })
				return true
			}

			// No Haptics if we don't change anything
			// console.log("Nothing to swap")
			return false
		})

		//
		// If we dropped a position back where it started, toggle its lock
		//
		if (!madeChanges && droppedOnPosition && !isQtrHeader && 'i' in obj) {
			toggleLock(Number(obj.i))
		}

		if (!madeChanges) {
			//
			// Force a render so that positions/players animate back to where we got them
			// from.
			//
			// If we're dragging a player, we don't want to execute this immediately because
			// that interrupts (and cancels) a click on their availability toggle.
			//
			if ('player' in obj) {
				setTimeout(forceUpdate, 100)
			} else {
				forceUpdate()
			}
		}
	}

	// console.log('display render', players, roster, data, players.length, roster.length, data.length)

	return (
		<div id="board" className={`grid grid-${numPeriods} grid-format-${gameFormat}`}>
			{
				(showAltBoard && !showPositionPreferences) ? (
					<AltGridPositions />
				) : (
					<GridPlayers
						players={best.players}
						editMode={editMode}
						onDrop={onDrop}
						available={best.available}
						toggleAvailable={toggleAvailable}
						data={data}
					/>
				)
			}
			{
				(showPositionPreferences && editMode) ?
					fieldPositions.map((position, index) => (
						<GridPreferences
							key={index}
							position={position}
							positionIndex={index}
						/>
					))
					:
					[ ...Array(numPeriods).keys() ].map(qtr => (
						<GridPositions
							key={qtr}
							best={best}
							data={data}
							onDrop={onDrop}
							qtr={qtr}
							showAltBoard={showAltBoard}
						/>
					))
			}
		</div>
	)
}

const AltGridPositions = () => {

    const best = useMyBest()
	const gameFormat = useMyGameFormat()
	const fieldPositions = queryFieldPositions(gameFormat)

	const { available } = best

	if (!available)
		return null

	let numAvailablePlayers = Object.values(available).filter(isAvailable => isAvailable ? 1 : 0).length
	if (numAvailablePlayers < fieldPositions.length) {
		numAvailablePlayers = fieldPositions.length
	}

    const availablePositions = calculatePositions(numAvailablePlayers, gameFormat)

	return (
		<div className="column column-players">
			<GridCell
				type="void"
				content=""
			/>
			{
				availablePositions.map((position, index) => {
					const myClasses = [ "cell", "cell-position", `cell-position-${position}` ]
					return (
						<div key={index} className="fixed-element">
							<div className={myClasses.join(' ')}>
								{position}
							</div>
						</div>
					)
				})
			}
		</div>
	)
}

//
// e.g. getBoundingBox('[data-i="1"]')
//      => { xCenter: 123.45, yCenter: 123.45, x: 120, y: 100, width: ... }
//
function getBoundingBox(selector: string) {

	const ret: {
		xCenter: number | undefined,
		yCenter: number | undefined,
	} = {
		xCenter: undefined,
		yCenter: undefined,
	}

	//
	// Ionic can have hidden pages that are still in the DOM, so make sure we're
	// referencing the visible one.
	//
	// Note this is a bit fragile and might break if Ionic restructures how it
	// arranges its pages! I'm trying to make sure we don't select any
	// .ion-page-hidden content, but it's complicated by how there are multiple
	// .ion-page elements.
	//
	const fullSelector = `.ion-page:not(.ion-page-hidden) > ion-content #board ${selector}`
	const element = document.querySelector(fullSelector)

	if (element) {
		const box = element.getBoundingClientRect()

		ret.xCenter = (box.left + box.right) / 2
		ret.yCenter = (box.top + box.bottom) / 2
	}

	return ret
}

export default DisplayRoster
