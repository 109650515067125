import './recentlyviewed.css'

import { IonButton } from '@ionic/react'
import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  useRecent,
  useSetShowTeamSelection,
  useTeam,
  useTeamSeasons
} from './data'
import { getUrl } from './util'

const RecentlyViewed = () => {

	const recent = useRecent()

	if (!recent?.length)
		return null

	return (
		<div className="recently-viewed">
			<div className="recently-viewed-title">
			</div>
			<div className="recently-viewed-list">
			{
				recent.map((recentObj, index) =>
					<RecentlyViewedSeason
						key={index}
						recentObj={recentObj}
					/>
				)
			}
			</div>
		</div>
	)
}

type RecentlyViewedSeasonProps = {
	recentObj: RecentTeam
}

const RecentlyViewedSeason = (props: RecentlyViewedSeasonProps) => {

	const { recentObj } = props

	const history = useHistory()
	const setShowTeamSelection = useSetShowTeamSelection()

	const { team, season } = recentObj

	const { name: teamName } = useTeam(team) || { }
	const seasons = useTeamSeasons(team)
	const { name: seasonName } = seasons?.[season] || { }

	if (!recentObj)
		return null

	//
	// Don't include deleted seasons
	//
	if (!teamName || !seasonName)
		return null

	const onClick = () => {
		setShowTeamSelection(false)
		const url = getUrl({
			page: 'games',
			section: recentObj,
			relative: true,
		})
		history.push(url)
	}

	return (
		<IonButton
			fill="outline"
			onClick={onClick}
		>
			<div className="nc-team-text">
				<div className="nc-team-name">
					{teamName}
				</div>
				<div className="nc-team-org">
					{seasonName}
				</div>
			</div>

		</IonButton>
	)
}

export default RecentlyViewed
