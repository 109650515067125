import { useEffect, useCallback } from 'react'
import {
	useAmDragging,
	useMyBest,
	useMyGameId,
	useMyMadeChangesState,
	useSaveRoster,
} from './data'

//
// When we change things, save to Firebase.
//
const SaveChanges = () => {

	const [ madeChanges, setMadeChanges ] = useMyMadeChangesState()
	const best = useMyBest()
	const saveRoster = useSaveRoster()
	const gameId = useMyGameId()
	const amDragging = useAmDragging()

	// This gets called by the below effect after a short delay.
	const saveMe = useCallback(() => {
		console.log('saveMe!')
		if (amDragging) {
			// console.log("Not saving while dragging")
		} else if (gameId) {
			console.log('Saving!')
			setMadeChanges(false)
			saveRoster(gameId, best)
		}
	}, [ amDragging, saveRoster, setMadeChanges, best, gameId ])


	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>

		if (madeChanges) {
			console.log('Setting save timer')
			timer = setTimeout(saveMe, 2000)
		}

		return () => clearTimeout(timer)
	}, [ best, madeChanges, saveMe ])

	return null
}

export default SaveChanges
