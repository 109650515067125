import React, { useCallback, useEffect, useState} from 'react'

import {
	useCanAddItem,
    useMyUserTeams,
	useUser,
	useUserMustVerifyEmail,
	useUserState,
} from './data'
import List from './List'
import RecentlyViewed from './RecentlyViewed'
import { AddNewSeasonForCurrentTeam } from './MySeasons'
import { Warning } from './Roster'
import { IonButton } from '@ionic/react'
import { sendVerificationEmail } from './providers'
import {FirebaseAuthentication} from '@capacitor-firebase/authentication'
import {userObject} from './util'

const MyTeams = () => {

	const user = useUser()
    const userTeams = useMyUserTeams()

	const canAddSeason = useCanAddItem('season')

	const type: ItemTypeDefinition<Team> = {
		name: 'teams',
		niceName: 'My Teams',
		unit: 'team',
		fields: [ 'name' ],
		maxWithoutPro: 1,
		// where: [ 'managers', 'array-contains', user ? user.uid : 'public' ],
		pathname: '/seasons/',
		sortFunction: (a, b) => a.name.localeCompare(b.name),

		//
		// When creating a new team, append this data.
		//
		onAdd: () => {
			const obj: Partial<Team> = { }
			if (user?.uid) {
				obj.managers = [ user.uid ]
			}
			return obj
		},

		//
		// Require a user to have been loaded (or failed to load) before attempting to display content.
		//
		requireUser: true,
	}

	return (
		<>
			<List<Team>
				data={userTeams}
				prependContent={<RecentlyViewed />}
				type={type}
			/>
			{
				canAddSeason ? <AddNewSeasonForCurrentTeam /> : null
			}
			<AskToVerifyEmail />
		</>
	)
}

export const AskToVerifyEmail = () => {

	const shouldVerify = useUserMustVerifyEmail()
	const [ user, setUser ] = useUserState()

	const [ done, setDone ] = useState(false)
	const [ err, setErr ] = useState<string | null>(null)

	const handleVisibilityChange = useCallback(async () => {
		console.log('visibility change', document.visibilityState)
		if (document.visibilityState === 'visible') {
			if (!user?.emailVerified) {
				console.log("Reloading user object.")
				await FirebaseAuthentication.reload()
				const result = await FirebaseAuthentication.getCurrentUser()
				console.log("FirebaseAuthentication.getCurrentUser() says", result)
				if (result?.user?.emailVerified) {
					console.log("Yay, new user object with email verified.")
					setUser(userObject(result.user))
				}
			}
		}
	}, [ user?.emailVerified, setUser ])

	useEffect(() => {
		document.addEventListener('visibilitychange', handleVisibilityChange)
		return () => document.removeEventListener('visibilitychange', handleVisibilityChange)
	}, [ handleVisibilityChange ])

	if (!shouldVerify) {
		return null
	}

	const onClick = async () => {
		console.log('verify email')
		await sendVerificationEmail({
			setErr,
		})
		setDone(true)
	}

	if (done) {
		return (
			<Warning>
				{
					err ? (
						<>
							Error: {err}
						</>
					) : (
						<>
							Verification email sent. Please check your inbox.
						</>
					)
				}
				<VerifyEmailButton text="Resend email" onClick={onClick} />
				<VerifyEmailButton text="Reload" onClick={handleVisibilityChange} />
			</Warning>
		)
	}

	return (
		<Warning>
			Please
			<VerifyEmailButton text="verify your email" onClick={onClick} />
			to create a new team.
		</Warning>
	)
}

type VerifyEmailButtonProps = {
	text: string
	onClick: () => void
}

const VerifyEmailButton = (props:VerifyEmailButtonProps) => {

	return (
		<IonButton
			fill="clear"
			onClick={props.onClick}
			className="please-verify-email"
		>
			{props.text}
		</IonButton>
	)
}

export default MyTeams
