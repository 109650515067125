import React, { useState } from 'react'
import {
	useIonAlert,
} from '@ionic/react'
import LoadingSpinnerButton from './LoadingSpinnerButton'
import { deleteUser } from './providers'
import {
	useClub,
	useMyUserTeams,
	useUser,
	useUserHasProPersonally,
} from './data'
import './deleteaccountbutton.css'

const DeleteAccountButton = () => {

    const user = useUser()
    const club = useClub()

	const userHasProPersonally = useUserHasProPersonally()
	const teams = useMyUserTeams()

    const [ presentAlert ] = useIonAlert()

    const [ busy, setBusy ] = useState(false)

	const [ errMsg, setErrMsg ] = useState<string | undefined>()

	type DeleteAccountButtonOnClickProps = {
		okForPro?: boolean,
		okForTeams?: boolean,
	}

	const onClick = (opt?: DeleteAccountButtonOnClickProps) => {
		if (!user)
			return

		const { displayName, email, phoneNumber, uid } = user
		const str = displayName || email || phoneNumber || uid

		const header = `Delete Account: ${str}`

		setErrMsg(undefined)

		if (club) {
			presentAlert({
				header,
				message: `You are currently a member of the club "${club.name}." You must leave this club before you can delete your account.`,
				buttons: [ 'OK' ],
			})
			return
		}

		if (userHasProPersonally && !opt?.okForPro) {

			presentAlert({
				header,
				message: `WARNING: You have an auto-renewing subscription! You should cancel this before deleting your account.`,
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: `Continue anyway`,
						handler: () => {
							setTimeout(() => onClick({ ...opt, okForPro: true }), 500)
						}
					},
				],
			})
			return
		}

		if (teams && Object.keys(teams).length && !opt?.okForTeams) {
			presentAlert({
				header,
				message: `Warning: You currently manage ${Object.keys(teams).length} team(s), which won't be auto-deleted. To delete teams, tap Cancel and delete teams one-by-one using the team selection drop-down.`,
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: `I don't want to delete teams`,
						handler: () => {
							setTimeout(() => onClick({ ...opt, okForTeams: true }), 500)
						}
					},
				],
			})
			return
		}

        presentAlert({
            header,
			message: `This is PERMANENT and cannot be undone. You will lose access to all your AutoRoster teams and data. To continue, type "delete" below`,
			inputs:
				[
					{
						placeholder: 'Type "delete" here',
						attributes: {
							maxlength: 6
						}
					}
				],
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Delete Account',
					role: 'destructive',
					handler: onConfirm,
				},
			],
		})
	}

	const onConfirm = async (inputs: any) => {
		console.log('delete account!', user, club, inputs)

		const confirmationString = inputs[0]

		if (confirmationString.localeCompare('delete', 'en', { sensitivity: 'base' })) {
			setErrMsg(`You didn't type "delete" in the text box to confirm.`)
			return true
		}

		if (club) {
			console.log('Club member')
			setErrMsg("Please leave your club first.")
			return true
		}

		console.log('Deleting user!')

		setBusy(true)

		try {
			await deleteUser()
		} catch (err) {
			console.error("Err from deleteUser", err)
			setErrMsg(`Internal error: ${err}`)
		}

		setBusy(false)

		return true
	}

	return (
		<div className="delete-account-button">
			{
				errMsg && (
					<p className="warning">
						Failed to delete account: {errMsg}
						<br /><br />
						This can happen if you haven't authenticated recently. Please try logging out and back in.
					</p>
				)
			}
			<LoadingSpinnerButton
				busy={busy}
				onClick={onClick}
				color="danger"
				fill="outline"
				size="small"
			>
				Delete Account
			</LoadingSpinnerButton>
		</div>
	)
}

export default DeleteAccountButton
