import React from 'react'
import { IonProgressBar } from '@ionic/react'
import './progressbar.css'

type ProgressBarProps = {
	value: number
	variant?: 'determinate' | 'indeterminate'
}

const ProgressBar = (props: ProgressBarProps) => {

    const { value, variant } = props

    return (
        <IonProgressBar
            type={variant}
            value={variant === 'indeterminate' ? undefined : value / 100}
        />
    )
}

export default ProgressBar
