import "./help.css"

import { Capacitor } from '@capacitor/core'
import { FaRandom, FaStreetView } from 'react-icons/fa'
import { HiDotsHorizontal } from 'react-icons/hi'
import {
	IonAccordion,
	IonAccordionGroup,
	IonButton,
	IonIcon,
	IonItem,
	IonLabel,
	IonMenuButton,
	IonRouterLink,
} from '@ionic/react'
import { TiThSmallOutline } from 'react-icons/ti'
import { analyticsOutline, ellipse, mail, shareOutline, informationCircleOutline } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'

import { DisplayUpgradeOffer, PlanName } from './Plan'
import { newestNewsItemDate } from './newsItems'
import { saveToLocalStorage } from './util'
import { tabButtons } from './definitions'
import {
  useAmActiveTab,
  useClub,
  useHelpTabState,
  useNewsNotificationState,
  useSection,
  useSetHelpTab,
  useUser,
} from './data'
import Icon from './Icon'
import News from './News'
import comboImg from './media/combos.png'
import introRosterMp4 from './media/intro-roster.mp4'
import orange from './orange.svg'
import packageInfo from '../package.json'

import subImg from './media/sub.png'
import raincloudImg from './media/raincloud.png'
import pinImg from './media/pin.png'
import subDemoImg from './media/sub-demo.gif'
import fivePlayersImg from './media/five-players.png'

const Help = () => {

	const amActiveTab = useAmActiveTab('help')

	const [ newsNotification, setNewsNotification ] = useNewsNotificationState()

	const [ helpTab, setHelpTab ] = useHelpTabState()
	const [ subTab, setSubTab ] = useState<string | null>(null)

	const hash = window.location.hash

	// console.log('Help.js', helpTab, amActiveTab)

	//
	// Open / scroll correct value
	//
	// If we're sent a hash like '/help#stats-faq' then we have to open the Stats
	// Accordion AND the Stats -> FAQ Accordion, wait a sec, then scroll to it.
	//
	// Note this can overlap a bit with MoreInfoLink, but that's okay. We need
	// to handle both cases depending on whether the user is already on the Help
	// page or not.
	//
	useEffect(() => {
		console.log('triggered', hash, amActiveTab)
		if (amActiveTab) {
			if (hash) {
				setTimeout(() => {
					const str = hash.substring(1)
					const [ newValue, newSubValue ] = str.split('-')
					setHelpTab(newValue)
					if (newSubValue) {
						setSubTab(str)
						setTimeout(() => {
							const [ el ] = document.getElementsByClassName(`help-${str}`)
							el?.scrollIntoView({ behavior: 'smooth' })
						}, 750)
					} else {
						setSubTab(null)
					}
				}, 250)
			} else {
				saveToLocalStorage('viewedNewsPage', newestNewsItemDate)
				if (newsNotification) {
					setHelpTab('news')
					setNewsNotification(false)
				}
			}
		}
	}, [ amActiveTab, newsNotification, setNewsNotification, setHelpTab, hash ])

	const platform = Capacitor.getPlatform()
	const versionInfo = `${packageInfo?.version}-${platform}`

	return (

		<section className="help">

			<div className="help-title">
				<Icon />
				<h1>
					AutoRoster
					<span className="app-info">
						{versionInfo}
					</span>
				</h1>

			</div>

			<IonAccordionGroup
				value={helpTab}
				className="master-help"
				onIonChange={(e: CustomEvent) => {
					const newValue = e.detail.value
					setHelpTab(newValue)
				}}
			>

				<IonAccordion value="news">
					<IonItem slot="header" color="light">
						<IonLabel>
							News
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<News
							setHelpTab={setHelpTab}
						/>
					</div>
				</IonAccordion>

				<IonAccordion value="createTeam">
					<IonItem slot="header" color="light">
						<IonLabel>
							Get started
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpCreateTeam />
					</div>
				</IonAccordion>

				<IonAccordion value="roster">
					<IonItem slot="header" color="light">
						<IonLabel>
							Generate rosters
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpRoster />
					</div>
				</IonAccordion>

				<IonAccordion value="stats">
					<IonItem slot="header" color="light">
						<IonLabel>
							Stats <IonIcon icon={analyticsOutline} />
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpStats subTab={subTab} setSubTab={setSubTab} />
					</div>
				</IonAccordion>

				<IonAccordion value="autorosterpro">
					<IonItem slot="header" color="light">
						<IonLabel>
							<PlanName />
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpAutoRosterPro />
					</div>
				</IonAccordion>

				<IonAccordion value="tips">
					<IonItem slot="header" color="light">
						<IonLabel>
							Tips & Tricks
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpTips />
					</div>
				</IonAccordion>

				<IonAccordion value="sharing">
					<IonItem slot="header" color="light">
						<IonLabel>
							Sharing
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpSharing />
					</div>
				</IonAccordion>

				<IonAccordion value="troubleshooting">
					<IonItem slot="header" color="light">
						<IonLabel>
							Troubleshooting
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpTroubleshooting />
					</div>
				</IonAccordion>

				<IonAccordion value="clubs">
					<IonItem slot="header" color="light">
						<IonLabel>
							Clubs
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpClubs />
					</div>
				</IonAccordion>

				<IonAccordion value="privacy">
					<IonItem slot="header" color="light">
						<IonLabel>
							Privacy policy
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpPrivacy />
					</div>
				</IonAccordion>

				<IonAccordion value="terms">
					<IonItem slot="header" color="light">
						<IonLabel>
							Terms of Service
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpTerms />
					</div>
				</IonAccordion>

				<IonAccordion value="about">
					<IonItem slot="header" color="light">
						<IonLabel>
							About
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<About />
					</div>
				</IonAccordion>

			</IonAccordionGroup>

			<p className="help-bottom-buttons">
				<EmailMax />
				<span>
					max_barry@maxbarry.com
				</span>
			</p>

		</section>
	)
}

type NavItemProps = {
	tab?: string
	label?: string
	icon?: string
	img?: string
	IconElement?: React.ElementType
	iconClassName?: string
}

export const NavItem = (props: NavItemProps) => {

	const { tab, img, IconElement, iconClassName } = props

	if (!tab && !props.label && !img)
		return null

	const info = (tab && tabButtons.find(obj => obj.id === tab)) || undefined

	const myIcon = props.icon || info?.icon
	const myLabel = props.label || info?.label

	return (
		<span className="help-navitem">
			{
				img ? (
					<img src={img} alt={myLabel} className={iconClassName} />
				) : IconElement ? (
					<IconElement />
				) : myIcon && (
					<IonIcon icon={myIcon} className={iconClassName} />
				)
			}
			{myLabel}
		</span>
	)
}

const NavItemGo = () => (
	<NavItem IconElement={FaRandom} label="Go" />
)

const NavItemToggleView = () => (
	<NavItem IconElement={TiThSmallOutline} label="View" />
)

const HelpAutoRosterPro = () => (
	<div>
		<h3>
			<PlanName />
		</h3>
		<p>
			AutoRoster is free for managing one team and one season.
		</p>
		<p>
			If you need another team or season, you can either delete
			your current one, or subscribe to <PlanName />.
		</p>
		<ul>
			<li>
				<p>
					Keep all your data - as many teams and seasons as you like,
					so you can always review past performance.
				</p>
			</li>
			<li>
				<p>
					Access to <em>Combinations</em>, which analyse the performance
					of all Pairs and Trios.
				</p>
			</li>
		</ul>
		<DisplayUpgradeOffer />
		<p>
			Clubs can upgrade from the free Basic plan to "<PlanName isClub={true} />" to
			unlock these features for all their coaches.
		</p>
	</div>
)

// Also used in Club.js
export const HelpClubsText = () => (
	<div>
		<h3>
			<span className="offer-appname">
				AutoRoster
			</span>
			{' '}
			<span className="offer-planname">
				for Netball Clubs
			</span>
		</h3>
		<ul>
			<li>
				<p>
					Free
				</p>
			</li>
			<li>
				<p>
					No ads, even
				</p>
			</li>
			<li>
				<p>
					Manage teams & coaches: share teams between coaches, and reassign teams from one coach to another
				</p>
			</li>
			<li>
				<p>
					Club administrators can view rosters & stats for all teams
				</p>
			</li>
			<li>
				<p>
					Optional: Purchase <PlanName /> to provide additional benefits to your coaches, supporting multiple teams, multiple
					seasons, and extra stats. (This part requires a paid subscription.)
				</p>
			</li>
		</ul>
	</div>
)

const HelpClubs = () => (
	<div>
		<HelpClubsText />
		<div className="open-profile-button-container">
			<IonMenuButton menu="profileMenu" className="open-profile-button">
				View Club in Profile
			</IonMenuButton>
		</div>
	</div>
)

const HelpCreateTeam = () => (
	<>
		<h3>
			1. Sign in
		</h3>
		<p>
			You can share rosters with anyone, but only you can edit them.
			Some stats are also coach-only.
		</p>
		<h3>
			2. Add a team
		</h3>
		<p>
			Click "+ Add" to create your first team.
		</p>
		<h3>
			3. Add a season
		</h3>
		<p>
			Now you have a team, "+ Add" a season for it.
		</p>
		<h3>
			4. Add players
		</h3>
		<p>
			Tap the <NavItem tab="players" /> tab. Then:
		</p>
		<ol>
			<li>
				<p>
					Click + Add.
				</p>
			</li>
			<li>
				<p>
					Enter a player's name. You can change this later.
				</p>
			</li>
			<li>
				<p>
					Click Add New Player.
				</p>
			</li>
			<li>
				<p>
					Continue adding players as needed.
				</p>
			</li>
		</ol>
		<h3>
			5. Add games
		</h3>
		<p>
			Tap the <NavItem tab="games" /> tab.
		</p>
		<ol>
			<li>
				<p>
					Click + Add.
				</p>
			</li>
			<li>
				<p>
					Enter game details. You can change these later.
				</p>
			</li>
			<li>
				<p>
					Click Add New Game.
				</p>
			</li>
			<li>
				<p>
					Continue adding games as needed.
				</p>
			</li>
		</ol>
	</>
)

const HelpRoster = () => (
	<>
		<p>
			Tap any of your <NavItem tab="games" /> to visit a roster page.
		</p>
		<p>
			Tap <NavItem label="Edit" /> to enter Edit mode.
		</p>

		<IonAccordionGroup onIonChange={(e: CustomEvent) => e.stopPropagation()}>

			<IonAccordion value="roster-noedit">
				<IonItem slot="header" color="light">
					<IonLabel>
						Where's the Edit button?
					</IonLabel>
				</IonItem>
				<div className="ion-padding" slot="content">
					Normally, <b>Edit</b> is a big green button in the top left
					of your roster, opposite <b>View</b>. If it's not there, you're
					looking at a team you don't have permission to manage. You may
					need to log in!
				</div>
			</IonAccordion>

		</IonAccordionGroup>

		<p>
			In Edit Mode, you can:
		</p>
		<ul>
			<li>
				<p>
					Toggle players' availability by tapping the <NavItem icon={ellipse} iconClassName="tick" label="green dot" /> beside their names.
				</p>
			</li>
			<li>
				<p>
					Nominate a captain / orange provider by
					tapping the <NavItem img={orange} iconClassName="orange-image" label="Oranges" /> box.
				</p>
			</li>
			<li>
				<p>
					Click <NavItemGo /> to automatically generate a roster.
				</p>
			</li>
			<li>
				<p>
					Tap <NavItemToggleView /> for a role-based view.
				</p>
			</li>
			<li>
				<p>
					Tap <NavItem IconElement={FaStreetView} label="Positions" /> to
					enter preferences for particular players, if necessary. (Don't
					overdo it — the more you set here, the less
					ability AutoRoster has to move things around and find good rosters.)
				</p>
			</li>
		</ul>

		<p>
			<em>
				Example: Setting a few positional preferences, auto-generating a roster, and
				tweaking it.
			</em>
		</p>

		<div className="video-container">
			<video playsInline autoPlay muted controls>
				<source src={introRosterMp4} type="video/mp4" />
			</video>
		</div>

		<p>
			Rosters can be modified in many ways:
		</p>
		<ul>
			<li>
				<p>
					Drag positions around.
				</p>
			</li>

			<li>
				<p>
					Drag one player's name onto another to swap all their
					positions with each other.
				</p>
			</li>

			<li>
				<p>
					Swap entire quarters at once by dragging the Quarter heading.
				</p>
			</li>

			<li>
				<p>
					Tap <NavItemGo /> again
					to try to algorithmically improve the current roster.
				</p>
			</li>
		</ul>
		<p>
			AutoRoster tries to find the best solution for the preferences you give it!
			Try tweaking a few preferences in your <NavItem tab="settings" /> — e.g.
			decrease the importance of giving breaks to the Centre — then come back and
			re-run <NavItemGo />.
		</p>
		<p>
			<b>Tip:</b> Tap any player/position to lock it in place, so it won't
			change when you run <NavItemGo />.
		</p>
	</>
)

type HelpStatsProps = {
	subTab: string | null
	setSubTab: (value: string | null) => void
}

const HelpStats = (props: HelpStatsProps) => {

	const { subTab, setSubTab } = props

	// console.log('HelpStats', props)

	return (
		<>

			<p>
				AutoRoster can provide advanced stats to improve your team rosters.
				Most of these rely on quarter-by-quarter scores: After each match,
				enter what the score was at ¼ time, ½ time, ¾ time, and final.
			</p>
			<p>
				To enter match scores:
			</p>
			<ul>
				<li>
					<p>
						In the <NavItem tab="games" /> tab, past games will invite you to <b>Enter Scores</b>.
					</p>
					<p>
						You can also change scores later in the same place by
						tapping the three dots (<HiDotsHorizontal />) beside
						a game and then <b>Edit Game</b>.
					</p>
				</li>
			</ul>
			<p>
				To view stats directly on rosters (not just in the Stats tab):
			</p>
			<ul>
				<li>
					<p>
						In the <NavItem tab="settings" /> tab,
						toggle on <b>Show NetStats</b>. If you have <PlanName />,
						you can also toggle on <b>Pair/Trio Stats</b>.
					</p>
				</li>
			</ul>

			<p>
				<img
					className="comboImg"
					src={comboImg}
					alt="Combinations"
				/>
			</p>

			<p>
				<em>
					Example: This is a very good roster, with lots of positive scores
					for <b>NetStats</b> (how the team performs when this
					player is in this position) as well as <b>Pairs</b> and <b>Trios</b> (how
					the team performs when this combination of players have these positions together).
				</em>
			</p>

			<IonAccordionGroup
				value={subTab}
				onIonChange={(e: CustomEvent) => {
					console.log('HelpStats accordion change of some kind', e)
					e.stopPropagation()
					const newSubValue = e?.detail?.value
					setSubTab(newSubValue)
				}}
			>

				<IonAccordion value="stats-positions" className="help-stats-positions">
					<IonItem slot="header" color="light">
						<IonLabel>
							Positions Played
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpPositionsPlayed />
					</div>
				</IonAccordion>

				<IonAccordion value="stats-netStats" className="help-stats-netStats">
					<IonItem slot="header" color="light">
						<IonLabel>
							NetStats
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpNetStats />
					</div>
				</IonAccordion>

				<IonAccordion value="stats-pairings" className="help-stats-pairings">
					<IonItem slot="header" color="light">
						<IonLabel>
							Combinations (Pairs/Trios)
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpPairings />
					</div>
				</IonAccordion>

				<IonAccordion value="stats-best" className="help-stats-best">
					<IonItem slot="header" color="light">
						<IonLabel>
							Best Quarters
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpBestQtrs />
					</div>
				</IonAccordion>

				<IonAccordion value="stats-absentees" className="help-stats-absentees">
					<IonItem slot="header" color="light">
						<IonLabel>
							Absentees & Leadership
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpAbsentees />
					</div>
				</IonAccordion>

				<IonAccordion value="stats-howitworks" className="help-stats-howitworks">
					<IonItem slot="header" color="light">
						<IonLabel>
							How It Works
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpStatsIntro />
					</div>
				</IonAccordion>


				<IonAccordion value="stats-faq" className="help-stats-faq">
					<IonItem slot="header" color="light">
						<IonLabel>
							Stats FAQ
						</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<HelpStatsFAQ />
					</div>
				</IonAccordion>

			</IonAccordionGroup>
		</>
	)
}

const HelpStatsIntro = () => (
	<>

		<p>
			AutoRoster can't watch the game, but it can detect correlations between
			team performance and who's playing where.
		</p>

		<p>
			Let's say the Lalor Lightning lose a game like this:
		</p>

		<table className="help-scores-table">
			<thead>
				<tr>
					<th />
					<th>Lightning</th>
					<th>Opponent</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Q1</td>
					<td>4</td>
					<td>6</td>
				</tr>
				<tr>
					<td>Q2</td>
					<td>8</td>
					<td>10</td>
				</tr>
				<tr>
					<td>Q3</td>
					<td>12</td>
					<td>19</td>
				</tr>
				<tr>
					<td>Final</td>
					<td>16</td>
					<td>24</td>
				</tr>
			</tbody>
		</table>

		<p>
			If we calculate the goal difference for each quarter, we can see that this game
			was mostly lost in the third quarter:
		</p>

		<table className="help-scores-table">
			<thead>
				<tr>
					<th />
					<th>Lightning</th>
					<th>Opponent</th>
					<th>Score in this qtr</th>
					<th>Goal difference</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Q1</td>
					<td>4</td>
					<td>6</td>
					<td>4 - 6</td>
					<td>-2</td>
				</tr>
				<tr>
					<td>Q2</td>
					<td>8</td>
					<td>10</td>
					<td>4 - 4</td>
					<td>0</td>
				</tr>
				<tr>
					<td>Q3</td>
					<td>12</td>
					<td>19</td>
					<td>4 - 9</td>
					<td>-4</td>
				</tr>
				<tr>
					<td>Final</td>
					<td>16</td>
					<td>24</td>
					<td>4 - 5</td>
					<td>-1</td>
				</tr>
			</tbody>
		</table>

		<p>
			AutoRoster now looks for positional correlatations.
			If, for example, Annabelle, played WA / C / off / C, AutoRoster will
			note that her two quarters of C were the team's best, while she was off-court
			for their worst.
		</p>

		<p>
			This might be a coincidence — maybe the opposition played their best roster
			in that quarter, and the scoreline didn't have much to do with Annabelle.
			We shouldn't jump to conclusions from a single quarter. But after a few games,
			external factors average out, and reliable trends emerge.
		</p>

		<p>
			Stats often work best as a prompt, helping coaches identify areas of unusually high
			or low performance, and encouraging them to think about why this might be the case.
		</p>

	</>
)

const HelpPositionsPlayed = () => (
	<>
		<h2>
			Positions Played
		</h2>

		<p>
			A simple tally of the number of quarters each player has played in each position.
		</p>

		<p>
			Tap the right-most column to toggle between total quarters played and total quarters
			benched.
		</p>

	</>
)

const HelpNetStats = () => (
	<>
		<h2>
			NetStats
		</h2>

		<p>
			Once you've entered quarter-by-quarter scores, players can be rated for each position they've
			played. A score of zero means your team performs at its average level with the player in that position.
			A positive score means the team performs better than average.
		</p>

		<p>
			Higher scores are better. A GD with +0.50 means your team overperforms
			by half a goal per quarter with this player in that position.
		</p>

		<p>
			Scores are adjusted for fixture difficulty, so they're not skewed by whether your team
			wins or loses more often.
		</p>

		<p>
			To hide NetStats on rosters, tap the <NavItem tab="settings" /> tab at the bottom of
			the screen and toggle <b>Show NetStats</b>.
		</p>

		<p>
			NetStats are only visible to team coaches, even if you share a link to your roster.
		</p>

	</>
)

const HelpStatsFAQ = () => (
	<>
		<h2>
			Stats FAQ
		</h2>

		<p>
			Whether your team is full of superstars or newbies, it will function more
			effectively with players in some roles rather than others. That's what
			NetStats are for &mdash; they don't really rate individual players, but they do
			tell you whether your team overperforms or underperforms with them in
			particular positions.
		</p>
		<h4>
			Q. Why doesn't my star player have good scores in every position?
		</h4>

		<p>
			Because she's still better for your team in some positions than others. Her NetStat score shows
			whether her team performs better or worse than usual when she's in that position — so she
			can be a great GA but still have a negative NetStat score because the team is more effective
			when she's in GD.
		</p>

		<p>
			This often has a lot to do with her teammates. A versatile player capable of filling
			any role will tend to score best wherever the team needs her most. For example, if
			a team is in desperate need of a GK and no-one else can play there, an all-rounder
			can have a high NetStat score in GK even though that's not her natural position.
		</p>

		<h4>
			Q. Why does my star GS have a NetStat score of zero?
		</h4>

		<p>
			Probably because she never plays anywhere else. A score of zero means the team plays at
			its average level with her in this position — which, if she's always there, must be true
			by definition.
		</p>

		<h4>
			Q. What does a negative NetStat score mean?
		</h4>

		<p>
			That the team tends to underperform when she's in that position. This is usually because
			the team tends to work better when she's somewhere else instead. See below for more.
		</p>

		<h4>
			Q. This negative NetStat score must be wrong, surely?
		</h4>
		<p>
			Reasons a good player might display a negative number:
		</p>

		<ul>
			<li>
				<p>
					As good as she is, the team works better when she's somewhere else.
				</p>
				<p>
					Say you have two players who are both excellent GDs, but only one of them can also
					play GK. The more versatile player is likely to show negative GD scores (and positive
					GK scores) because the team works better when the
					more limited player is able to stay in her preferred role.
				</p>
			</li>
			<li>
				<p>
					The season is young, with only a couple of games of data, so the numbers
					haven't settled yet. They will average out and become more reliable over time.
				</p>
			</li>
			<li>
				<p>
					A player may always get put in a role in conjunction with another move.
					For example, if Alice only plays C to give your superstar a rest,
					and those tend to be your team's worst quarters, then Alice will have a negative
					NetStat score in C. She may still be your team's second-best C,
					and anyone else would score even more negatively.
				</p>
			</li>
			<li>
				<p>
					Sometimes skilled players are agents of chaos, and disrupt their teammates.
					They may get a lot of ball but not in a way that best helps the team.
				</p>
			</li>
			<li>
				<p>
					Similarly, a strong attacking player may neglect important but more subtle
					defensive work.
				</p>
			</li>
		</ul>

		<p>
			For most teams, every player will have a positive score in one or two positions,
			which means the team plays better with her there rather than somewhere else. (For weaker
			players, one of those positions may be on the bench.)
		</p>

		<h4>
			Q. What is the gray score on the right-hand side underneath "-"?
		</h4>

		<p>
			That's the effect when she spends a quarter on the bench. Valuable
			players usually have negative bench scores, since when they're off-court, the team
			underperforms.
		</p>
		<p>
			A high positive bench score usually means you haven't found a good role for this
			player yet.
		</p>

		<h4>
			Q. What's the difference between Average and Season NetStats?
		</h4>

		<p>
			"Season" is just "Average" multiplied by the number of quarters the player
			has spent in that position. (With some adjustment for statistical reasons.)
		</p>

		<p>
			Watch out for "Season" numbers that become strongly negative &mdash; this
			happens when you repeatedly roster players in sub-optimal positions.
		</p>
	</>
)


const HelpBestQtrs = () => (
	<>
		<h2>
			Best Quarters
		</h2>

		<p>
			A simple list of every quarter your team has played, ordered from best to worst in terms of:
		</p>

		<ul>
			<li>
				<p>
					<b>Overall</b>: Simple goal difference (goals scored minus goals conceded)
				</p>
			</li>
			<li>
				<p>
					<b>Attack</b>: Most goals scored, ignoring goals conceded
				</p>
			</li>
			<li>
				<p>
					<b>Defence</b>: Least goals conceded, ignoring goals scored
				</p>
			</li>
		</ul>

		<p>
			You should expect to see games against weak opponents on the left, and tougher games
			on the right.
		</p>
	</>
)

const HelpPairings = () => (
	<>
		<h2>
			Combinations (<PlanName />)
		</h2>

		<p>
			AutoRoster can determine which combinations of players work best together.
		</p>

		<p>
			For example, you may have a GA who is more effective when paired with
			a particular GS. Or a set of players who work especially well together
			as WD/GD/GK.
		</p>

		<p>
			With the free AutoRoster Basic plan, <NavItem tab="stats" /> → <b>Combinations</b> only
			your team's top Pair and Trio are available.
		</p>

		<p>
			With <PlanName />, you can view all Pairs and Trios, and these numbers are
			also displayed directly on rosters in role-based view by tapping <NavItemToggleView />.
			(Not available in 5- and 6-a-side formats.)
		</p>

		<p>
			<img
				className=""
				src={comboImg}
				alt="Combinations"
			/>
		</p>

		<p>
			<em>
				In the above example roster, Neve is a mobile, athletic player who can perform
				both in the circle and in midcourt — her GA NetScore is a relatively modest
				+0.2 because while she's good for the team here, she'd also be good elsewhere.
				But while she's in GA, she's a great pairing with GS Holly, hence the +0.5.
				Neve also pairs effectively with WA Clover (+0.4), and forms
				a strong trio with WA Clover and C Emily (+1.4).
			</em>
		</p>

		<p>
			A Combination is a pair or trio of players who:
		</p>

		<ul>
			<li>
				<p>
					have played the same roles together for at least 2 quarters (not necessarily in the same game)
				</p>
			</li>
			<li>
				<p>
					have adjacent roles, e.g. GS + GA or WD + GD
				</p>
			</li>
		</ul>

		<p>
			In <NavItem tab="stats" />, Combinations are ranked left to right according to
			their cumulative Season score.
			Your best combinations will be on the left, and tend to be both effective and frequently
			used.
		</p>

		<p>
			Below each combination is a list of quarters played together, ordered from best to worst.
			(This is determined by their NetStat score, which takes into account the strength of the opposition, so some
			quarters may appear in surprising positions. For example, a 3-3 quarter will be rated well if
			the team was heavily outscored in the other three quarters, but rated poorly if it occurred in
			a game the team won easily.)
		</p>

		<p>
			Combinations on the far right may benefit from reconsideration, as they tend to be both
			negatively correlated with performance and frequently used.
		</p>

		<p>
			AutoRoster usually uses Combinations stats internally when generating and scoring rosters,
			both for Basic and <PlanName /> users.
		</p>
	</>
)

const HelpAbsentees = () => (
	<>
		<h2>
			Absentees & Leadership
		</h2>

		<p>
			This shows who's been absent / captain each game.
		</p>

		<h5>
			Absentees
		</h5>
		<p>
			Toggle a player's availability in any roster by tapping the green
			dot / gray cross beside their name.
		</p>

		<h5>
			Leadership
		</h5>
		<p>
			Appoint a captain (or oranges-bringer) by using
			the
			<NavItem img={orange} iconClassName="orange-image" /> drop-down
			drop-down box at the bottom of each roster.
		</p>
	</>
)


const HelpTips = () => (
	<>
		<h4>
			<img src={pinImg} alt="" className="subimg" />
			Pin
		</h4>
		<p>
			When you click (rather than drag) a player's position while editing a roster,
			you toggle whether it is <em>pinned</em>. Pinned positions don't move
			when you use <NavItemGo /> to automatically generate a roster.
		</p>
		<h4>
			<img src={raincloudImg} alt="" className="subimg" />
			Raincloud
		</h4>
		<p>
			Normally, clicking a player's position while editing a roster will toggle whether
			it's pinned (see above). For past games, though, you cycle through an extra option: the
			Raincloud.
		</p>
		<p>
			The Raincloud is for when people didn't actually play in their rostered positions for some
			reason, e.g. the game was rained out. It lets you see where they were going to play,
			without counting it toward stats.
		</p>
		<p>
			For example, if your game was rained out at half time, you can put rainclouds on the 3rd and
			4th quarter roles so only the first two quarters are counted toward stats.
		</p>
		<h4 className="help-tips-subs">
			<img src={subImg} alt="" className="subimg" />
			Substitutions
		</h4>
		<p>
			Subs are players who come on court mid-quarter to replace someone else.
		</p>
		<p>
			<img src={subDemoImg} alt="" className="subDemoImg" />
		</p>
		<p>
			To create a sub:
		</p>
		<ol>
			<li>
				<p>
					In <NavItem label="Edit" /> mode, tap a position twice. It will play a moving arrow animation.
				</p>
			</li>
			<li>
				<p>
					Wait a second or two. The position will split, shared with a
					bench player. You can now drag them both around like normal.
				</p>
			</li>
		</ol>
		<p>
			You must have a free bench player to create a sub. If you keep tapping a position but only
			see the Pin and possibly the Raincloud, but not the arrows, it's because you don't have a free bench
			player. (Or, perhaps, because you're tapping a bench position. Tap an on-court position instead.)
		</p>
		<p>
			To remove a sub, repeat the process: Tap a position twice to show the arrow animation,
			then wait for one or two seconds.
		</p>
		<p>
			Note:
		</p>
		<ul>
			<li>
				<p>
					Subs always come from the bench, and the player they replace goes to the bench.
				</p>
			</li>
			<li>
				<p>
					Each player and position can only be subbed once per quarter.
				</p>
			</li>
			<li>
				<p>
					Auto-generated rosters don't use subs. If you tap <NavItemGo />, any
					subs will be removed. (You can tap <NavItem label="Undo" /> to get them back.)
				</p>
			</li>
		</ul>
		<p>
			For stats purposes, the player who subs in and the player who subs out are each deemed
			to have played half the quarter, sharing equal credit for the team's performance.
		</p>
		<h4>
			Fill-Ins
		</h4>
		<p>
			The easiest way to handle fill-ins &mdash; players who aren't members of your team but
			play if a regular player is absent &mdash; is to treat them as if
			they were the player they're replacing, and mark that player absent (by tapping the
			green dot beside their name in Edit mode).
		</p>
		<p>
			This will cause the fill-in's positions to be shown in gray. That means you can see
			where they play, but it doesn't count towards stats.
		</p>
		<p>
			Alternately, you can create a new player, but this can clutter up your
			rosters and stats a bit, and force you to mark them absent each game.
		</p>
		<h4>
			Game Duration (Matches That Aren't 4 Quarters)
		</h4>
		<p>
			You can create a tournament of two-halves games, or some other number of periods,
			in your Season settings. These are most easily found by navigating to the bottom of
			your <NavItem tab="games" /> tab.
		</p>
		<p>
			Note:
		</p>
		<ul>
			<li>
				<p>
					All games within the same season have the same number of periods.
				</p>
			</li>
			<li>
				<p>
				   	You can't change the number of periods after creating a season unless you
					first erase all its existing rosters.
				</p>
			</li>
			<li>
				<p>
					For flash games with a single period, NetStats and Combinations can't be generated,
					because AutoRoster relies on comparing quarters within the same game in order to
					account for fixture bias (some opponents being stronger than others).
				</p>
			</li>
		</ul>
		<h4>
			Format (5- and 6-a-side)
		</h4>
		<p>
			You can use 5- and 6-a-side game formats, including
			FAST5 (no Wings) and the A/C/D variants used by Indoor Netball and New Zealand's <em>futureFERNS</em> program.
		</p>
		<p>
			<img src={fivePlayersImg} alt="" />
		</p>
		<p>
			Select your Format when creating a new season.
		</p>
		<p>
			For A/C/D formats, Combination stats don't contribute to roster strength ratings,
			since the positions are more general. But these stats can still be viewed
			in the <NavItem tab="stats" /> tab.
		</p>
	</>
)

const HelpSharing = () => {

	return (
		<>
			<p>
				Sharing allows people to view your rosters. They can't make changes, and can't see
				stats, except for the very basic ones like <em>Positions Played</em> and <em>Absentees &amp; Leadership</em>.
			</p>

			<h4>
				Sharing One Roster
			</h4>
			<p>
				Tap the
				{' '}
				<IonIcon
					icon={shareOutline}
				/>
				{' '}
				Share icon in the top-right while viewing any particular roster.
				This will create a link to it that anyone can use.
			</p>
			<p>
				If you're using a web browser, this may simply
				generate a link that you need to paste into an email or message and send.
				On a phone or tablet, it will use your device's native sharing system.
			</p>

			<h4>
				Sharing a Whole Season
			</h4>
			<p>
				You can send a direct link to all your team's games by going to
				the <NavItem tab="games" /> and using the
				{' '}
				<IonIcon
					icon={shareOutline}
				/>
				{' '}
				Share link at the very bottom.
			</p>
			<p>
				People can also view your season's games list if you've shared any individual
				roster with them &mdash; they just need to tap <NavItem tab="games" />.
			</p>

			<h4>
				Sharing Stats & Management
			</h4>
			<p>
				If you want someone to be able to make changes or see stats,
				you need to create a Club, even if you only have one team.
				Then you can invite people to your Club and assign them as coach or
				co-coach of a team. It's a bit more complicated but you can do it
				for free.
			</p>
			<p>
				<MoreInfoLink helpSection={"clubs"} text="More about Clubs..." />
			</p>
		</>
	)
}

const HelpTroubleshooting = () => (
	<>
		<h4>
			My team has disappeared!
		</h4>
		<p>
			I think this (very alarming) problem can occur if you have network problems.
			Often you just need to restart the app (how to on
			{' '}
			<a href="https://support.apple.com/en-gb/guide/iphone/iph83bfec492/ios">Apple</a>
			{' '}
			or
			{' '}
			<a href="https://www.howtogeek.com/903829/how-to-restart-an-app-on-android/">Android</a>
			), which forces it to reload everything.
		</p>
		<h4>
			I can see my rosters but not edit them
		</h4>
		<p>
			If you can view a roster but there's no big green "Edit" button, you're not
			logged in as the team's registered coach/manager. You might be logged out,
			or you might have made more than one account, and be using the wrong one.
			For example, you might have created the team after logging in with your Google
			Account, and now you've come back and logged in with your Apple ID.
			Try signing out, then login via the same method as when you created the team.
		</p>
	</>
)

type EmailMaxProps = {
	subject?: string
	text?: string
}

export const EmailMax = (props: EmailMaxProps) => {

	const user = useUser()
	const section = useSection()
	const club = useClub()

	const platform = Capacitor.getPlatform()
	const versionInfo = `${packageInfo?.version}-${platform}`

	const debugInfo = `

AutoRoster debug info
~~~~~~~~~~~~~~~~~~~~~
Version   : ${versionInfo}
Platform  : ${platform}
User ID   : ${user?.uid || '-'}
Team ID   : ${section?.team || '-'}
Season ID : ${section?.season || '-'}
Game ID   : ${section?.game || '-'}
Club ID   : ${club?.id || '-'}
`

	const body = (props.text || '') + debugInfo

	const href = "mailto:max_barry@maxbarry.com?subject=" + encodeURI(props.subject || "AutoRoster") + "&body=" + encodeURI(body)

	return (
		<IonButton
			href={href}
			fill="outline"
		>
			<IonIcon icon={mail} slot="start" />
			<IonLabel>
				Email Max
			</IonLabel>
		</IonButton>
	)
}

type MoreInfoLinkProps = {
	helpSection: string
	helpSubSection?: string
	text: string
}

//
// Note: Does not open helpSubSection if supplied, just gets the URL right. That means
//       that the subSection will only open if you're coming from another page, not if
//       you're being linked from one part of Help to another.
//
export const MoreInfoLink = (props: MoreInfoLinkProps) => {

	const setHelpTab = useSetHelpTab()

	const url = `/help#${props.helpSection}${props.helpSubSection ? '-' + props.helpSubSection : ''}`

	return (
		<IonRouterLink
			className="more-info-link"
			routerLink={url}
			onClick={() => {
				console.log('setHelpTab', props.helpSection)
				setHelpTab(props.helpSection)
			}}
		>
			<IonIcon
				className="more-info-link-icon"
				icon={informationCircleOutline}
			/>
			{props.text}
		</IonRouterLink>
	)
}

const About = () => (
	<>
		<h2>
			About
		</h2>
		<p>
			Hi, I'm Max. I have two daughters playing netball and
			wound up coaching a kids' team. The hardest part of every week
			was figuring out the freaking roster. Then at the last minute
			one kid would be away and I could put Amy in GA but then she'd
			have no rest while other kids — arrrrgh. This is why we
			have computers, right? Right. So I built this app.
		</p>
		<p>
			Please enjoy and let me know how you go.
		</p>
		<p>
			— <a href="https://maxbarry.com" className="linkish">Max Barry</a>
		</p>

	</>
)

const HelpPrivacy = () => (
	<>
		<p>
			<em>
				Short version: This is an app I wrote myself and I didn't make it do anything bad. No ads, no
				reselling of user data.
				In order to make it work, it bundles services from Google (hosting,
				database, analytics), Facebook (login), Apple (login), and LogRocket (debugging and session
				capture), who collect the usual amount of stuff from all users.
			</em>
		</p>
		<p>
			This following document sets forth the Privacy Policy for the App. The App is bound by the Privacy Act 1988 (Cth), which sets out a number of principles concerning the privacy of individuals.
		</p>

		<h3>
			Definitions
		</h3>

		<p>
			<b>Services</b> means roster generation and similar functionality designed to aid in the management of netball games.
			<br />
			<b>The App</b> means AutoRoster, which is both an app and a website.
			<br />
			<b>We / Us</b> etc means NationStates Pty Ltd and any subsidiaries, affiliates, employees, officers, agents or assigns.
		</p>

		<h3>
			Collection of your personal information
		</h3>
		<p>
			There are many aspects of the App which can be viewed without providing personal information, however, for access to future AutoRoster customer support features you are required to submit personally identifiable information. This may include but not limited to a unique username and password, or provide sensitive information in the recovery of your lost password.
		</p>

		<h3>
			Use of your personal information
		</h3>
		<p>
			For each visitor to reach the site, we expressively collect the following non-personally identifiable information, including but not limited to browser type, version and language, operating system, pages viewed while browsing the Site, page access times and referring website address. This collected information is used solely internally for the purpose of gauging visitor traffic, trends and delivering personalized content to you while you are at this Site.
		</p>
		<p>
			From time to time, we may use customer information for new, unanticipated uses not previously disclosed in our privacy notice. If our information practices change at some time in the future we will use for these new purposes only, data collected from the time of the policy change forward will adhere to our updated practices.
		</p>

		<h3>
			Sharing of your personal information
		</h3>
		<p>
			In order to provide basic site functionality, your information may be shared with third parties who provide site services such as analytics, hosting, and debugging services, notably including Google, Facebook and LogRocket. We take reasonable steps to ensure that these organisations are bound by confidentiality and privacy obligations in relation to the protection of your personal information.
		</p>

		<h3>
			Changes to this Privacy Policy
		</h3>
		<p>
			We reserve the right to make amendments to this Privacy Policy at any time. If you have objections to the Privacy Policy, you should not access or use the Site.
		</p>

		<h3>
			Accessing Your Personal Information
		</h3>
		<p>
			You have a right to access your personal information, subject to exceptions allowed by law. If you would like to do so, please let us know. You may be required to put your request in writing for security reasons. We reserve the right to charge a fee for searching for, and providing access to, your information on a per request basis.
		</p>

		<h3>
			CCPA Privacy Rights (Do Not Sell My Personal Information)
		</h3>
		<p>
			Under the CCPA, among other rights, California consumers have the right to:
		</p>
		<ul>
			<li>
				<p>
					Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
				</p>
			</li>
			<li>
				<p>
					Request that a business delete any personal data about the consumer that a business has collected.
				</p>
			</li>
			<li>
				<p>

					Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
				</p>
			</li>
		</ul>
		<p>
			If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
		</p>

		<h3>
			GDPR Data Protection Rights
		</h3>
		<p>
			We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
		</p>
		<ul>
			<li>
				<p>
					The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
				</p>
			</li>
			<li>
				<p>
					The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
				</p>
			</li>
			<li>
				<p>
					The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
				</p>
			</li>
			<li>
				<p>
					The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
				</p>
			</li>
			<li>
				<p>
					The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
				</p>
			</li>
			<li>
				<p>
					The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
				</p>
			</li>
		</ul>
		<p>
			If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
		</p>

		<h3>
			Children's Information
		</h3>

		<p>
			Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
		</p>
		<p>
			AutoRoster does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our app, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
		</p>

		<h3>
			Contacting us
		</h3>
		<p>
			If you have any questions about this Privacy Policy and would like further information, please use the following email address: <a href="mailto:max_barry@maxbarry.com">max_barry@maxbarry.com</a>.
		</p>
	</>
)

const HelpTerms = () => {

	return (
		<div className="help-terms">
			<h3>
				LICENSED APPLICATION END USER LICENSE AGREEMENT
			</h3>
			<p>
				Apps made available through the App Store are licensed, not sold, to you. Your license to each App is subject to your prior acceptance of either this Licensed Application End User License Agreement (“Standard EULA”), or a custom end user license agreement between you and the Application Provider (“Custom EULA”), if one is provided. Your license to any Apple App under this Standard EULA or Custom EULA is granted by Apple, and your license to any Third Party App under this Standard EULA or Custom EULA is granted by the Application Provider of that Third Party App. Any App that is subject to this Standard EULA is referred to herein as the “Licensed Application.” The Application Provider or Apple as applicable (“Licensor”) reserves all rights in and to the Licensed Application not expressly granted to you under this Standard EULA.
			</p>
			<p>
				a. Scope of License: Licensor grants to you a nontransferable license to use the Licensed Application on any Apple-branded products that you own or control and as permitted by the Usage Rules. The terms of this Standard EULA will govern any content, materials, or services accessible from or purchased within the Licensed Application as well as upgrades provided by Licensor that replace or supplement the original Licensed Application, unless such upgrade is accompanied by a Custom EULA. Except as provided in the Usage Rules, you may not distribute or make the Licensed Application available over a network where it could be used by multiple devices at the same time. You may not transfer, redistribute or sublicense the Licensed Application and, if you sell your Apple Device to a third party, you must remove the Licensed Application from the Apple Device before doing so. You may not copy (except as permitted by this license and the Usage Rules), reverse-engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Licensed Application, any updates, or any part thereof (except as and only to the extent that any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open-sourced components included with the Licensed Application).
			</p>
			<p>
				b. Consent to Use of Data: You agree that Licensor may collect and use technical data and related information—including but not limited to technical information about your device, system and application software, and peripherals—that is gathered periodically to facilitate the provision of software updates, product support, and other services to you (if any) related to the Licensed Application. Licensor may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you.
			</p>
			<p>
				c. Termination. This Standard EULA is effective until terminated by you or Licensor. Your rights under this Standard EULA will terminate automatically if you fail to comply with any of its terms.
			</p>
			<p>
				d. External Services. The Licensed Application may enable access to Licensor’s and/or third-party services and websites (collectively and individually, "External Services"). You agree to use the External Services at your sole risk. Licensor is not responsible for examining or evaluating the content or accuracy of any third-party External Services, and shall not be liable for any such third-party External Services. Data displayed by any Licensed Application or External Service, including but not limited to financial, medical and location information, is for general informational purposes only and is not guaranteed by Licensor or its agents. You will not use the External Services in any manner that is inconsistent with the terms of this Standard EULA or that infringes the intellectual property rights of Licensor or any third party. You agree not to use the External Services to harass, abuse, stalk, threaten or defame any person or entity, and that Licensor is not responsible for any such use. External Services may not be available in all languages or in your Home Country, and may not be appropriate or available for use in any particular location. To the extent you choose to use such External Services, you are solely responsible for compliance with any applicable laws. Licensor reserves the right to change, suspend, remove, disable or impose access restrictions or limits on any External Services at any time without notice or liability to you.
			</p>
			<p>
				e. NO WARRANTY: YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE LICENSED APPLICATION IS AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSED APPLICATION AND ANY SERVICES PERFORMED OR PROVIDED BY THE LICENSED APPLICATION ARE PROVIDED "AS IS" AND “AS AVAILABLE,” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND LICENSOR HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE LICENSED APPLICATION AND ANY SERVICES, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND OF NONINFRINGEMENT OF THIRD-PARTY RIGHTS. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LICENSOR OR ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY. SHOULD THE LICENSED APPLICATION OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.
			</p>
			<p>
				f. Limitation of Liability. TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL LICENSOR BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OF OR INABILITY TO USE THE LICENSED APPLICATION, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT, OR OTHERWISE) AND EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. In no event shall Licensor’s total liability to you for all damages (other than as may be required by applicable law in cases involving personal injury) exceed the amount of fifty dollars ($50.00). The foregoing limitations will apply even if the above stated remedy fails of its essential purpose.
			</p>
			<p>
				g. You may not use or otherwise export or re-export the Licensed Application except as authorized by United States law and the laws of the jurisdiction in which the Licensed Application was obtained. In particular, but without limitation, the Licensed Application may not be exported or re-exported (a) into any U.S.-embargoed countries or (b) to anyone on the U.S. Treasury Department's Specially Designated Nationals List or the U.S. Department of Commerce Denied Persons List or Entity List. By using the Licensed Application, you represent and warrant that you are not located in any such country or on any such list. You also agree that you will not use these products for any purposes prohibited by United States law, including, without limitation, the development, design, manufacture, or production of nuclear, missile, or chemical or biological weapons.
			</p>
			<p>
				h. The Licensed Application and related documentation are "Commercial Items", as that term is defined at 48 C.F.R. §2.101, consisting of "Commercial Computer Software" and "Commercial Computer Software Documentation", as such terms are used in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. Consistent with 48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4, as applicable, the Commercial Computer Software and Commercial Computer Software Documentation are being licensed to U.S. Government end users (a) only as Commercial Items and (b) with only those rights as are granted to all other end users pursuant to the terms and conditions herein. Unpublished-rights reserved under the copyright laws of the United States.
			</p>
			<p>

				i. Except to the extent expressly provided in the following paragraph, this Agreement and the relationship between you and Apple shall be governed by the laws of the State of California, excluding its conflicts of law provisions. You and Apple agree to submit to the personal and exclusive jurisdiction of the courts located within the county of Santa Clara, California, to resolve any dispute or claim arising from this Agreement. If (a) you are not a U.S. citizen; (b) you do not reside in the U.S.; (c) you are not accessing the Service from the U.S.; and (d) you are a citizen of one of the countries identified below, you hereby agree that any dispute or claim arising from this Agreement shall be governed by the applicable law set forth below, without regard to any conflict of law provisions, and you hereby irrevocably submit to the non-exclusive jurisdiction of the courts located in the state, province or country identified below whose law governs:
			</p>
			<p>
				If you are a citizen of any European Union country or Switzerland, Norway or Iceland, the governing law and forum shall be the laws and courts of your usual place of residence.
			</p>
			<p>
				Specifically excluded from application to this Agreement is that law known as the United Nations Convention on the International Sale of Goods.
			</p>
		</div>
	)
}

export default Help
