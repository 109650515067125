import React, { useContext, useState } from 'react'
import {
	useIonActionSheet,
    useIonAlert,
	IonButton,
    IonIcon,
	IonRouterLink,
} from '@ionic/react'
import { doc, setDoc, updateDoc } from "firebase/firestore"
import { ejectUserFromClub, leaveClub, modifyCoachAccess, setClubUserStatus } from './firebase'
import { db } from './firebase'
import InvitationLink from './InvitationLink'
import LoadingSpinner from './LoadingSpinner'
import LoadingSpinnerButton from './LoadingSpinnerButton'
import ModalWithCancel from './ModalWithCancel'
import {ModalContext} from './ModalContext'
import EditItem from './EditItem'
import { HelpClubsText } from './Help'
import {
	useClub,
    useClubHasPro,
	useMyClubUser,
	useMyClubUsersSorted,
	useStoreCustomerInfo,
	useTeam,
	useTeamSeasons,
    useUser,
    useUserHasProPersonally,
    useUserTeams,
} from './data'
import {ProfileContext} from './ProfileContext'
import { DuplicatePlanWarning, PlanName, SubscriptionManagement, DisplayUpgradeOffer } from './Plan'
import AddItem from './AddItem'
import { settingsSharp, add, personCircleOutline } from 'ionicons/icons'
import { CiEdit } from 'react-icons/ci'
import './club.css'

const Club = () => {

    const user = useUser()
    const club = useClub()

    // console.log('Club', club)

    if (!club) {
        return (
            <section className="club">
				<HelpClubsText />

				<p>
					<IonButton
						id="trigger-create-club"
						expand="block"
					>
						Create New Club
					</IonButton>
				</p>
				<p>
					<IonButton
						id="trigger-join-club"
						expand="block"
					>
						Join Existing Club
					</IonButton>
				</p>
				<ModalWithCancel
					trigger="trigger-create-club"
					title="Create New Club"
				>
					<CreateClub />
				</ModalWithCancel>

                <ModalWithCancel
                    trigger="trigger-join-club"
                    title="Join Existing Club"
                >
					<>
						<p>
							To join an existing club, you need an invitation link.
						</p>
						<p>
							Ask a club administrator to generate an invitation link for you
							in their club management area.
						</p>
					</>
				</ModalWithCancel>

			</section>
		)
	}

    const amAdmin = user && club.admin.includes(user.uid)

    return (
        <section>
			<div className="club-id">
				Club ID: {club.id.substr(0, 6)}
			</div>
            <h2>
                {club.name}
            </h2>
            <p>
				{club.admin.length} Admin &bull; {Object.keys(club.users).length - club.admin.length} Coaches
            </p>
            {
                amAdmin ? <DisplayClubForAdmin /> : <DisplayClubForUser />
            }
        </section>
    )
}

//
// Generate link - valid for 7 days. Can cancel it.
//
// Need to store emails etc in a sub-document of '/clubs' - e.g. '/clubs/admin' -
// with restricted read access, so random internet people can't view it.
//
// Generate privateCode and store in /clubs/admin, which is what gets (privately)
// sent to people who want to join, while also storing a hashed version publicCode
// in /clubs, which anyone can read. When someone tries to join, we hash their
// supplied code and check if it matches publicCode.
//
// Also publicly store codeExpiryTime.
//
// HOWEVER -- we can't allow this app to determine whether the user is permitted
// to join or not, can we? Because someone could just discover the endpoints and
// send "confirmed" requests to Firebase.
//
// So I probably need to write a Firebase Cloud Function to handle this.
//
// In App:
// - When club is created, also create /admin subdocument with user names (writable by this admin)
// - Allow admin to promote another user to admin
//
// Cloud Function:
// - Direct: Generate random invite code, store in /clubs/admin
// - Check whether join code is correct; if so, join new user to club
//
const DisplayClubForAdmin = () => {
    return (
        <>
			<DisplayClubPlan />
			<p>
				You are an admin.
            </p>
            <InvitationLink />
            <DisplayClubUsers />
            <LeaveClubButton />
        </>
    )
}

const DisplayClubPlan = () => {
	const club = useClub()
	const clubHasPro = useClubHasPro()
	const storeInfo = useStoreCustomerInfo(club?.id || '')

	let content

	if (clubHasPro) {
		content = (
			<p className="club-has-plan">
				<PlanName isClub={true} />
				<SubscriptionManagement isClub={true} />
			</p>
		)
	} else {
		let expiryStr

		if (storeInfo?.entitlements?.pro) {
			const { expires_date } = storeInfo.entitlements.pro
			const isExpired = !expires_date || new Date(expires_date) < new Date()
			if (isExpired) {
				expiryStr = (
					<p className="warning">
						Plan expired {expires_date ? (new Date(expires_date)).toLocaleDateString() : ''}
					</p>
				)
			}
		}

		content = (
			<>
				{expiryStr}
				<DisplayUpgradeOffer isClub={true} />
			</>
		)
	}

	return (
		<>
			{content}
		</>
	)
}

const DisplayClubUsers = () => {

    const club = useClub()
	const user = useUser()
	const users = useMyClubUsersSorted()

	const [ modifyCoachPermissions, setModifyCoachPermissions ] = useState<CoachPermissions>(null)

	const [ busy, setBusy ] = useState(false)

	const [ presentAlert ] = useIonAlert()
	const [ presentActionSheet ] = useIonActionSheet()

    // console.log('club', club)

	const confirmEjectCoach = (uid: UserId) => {
		console.log('confirmEjectUserFromClub')

		if (!user) {
			console.error('No user!')
			return
		}

		if (!club) {
			console.error('No club!')
			return
		}

		setBusy(true)

		const go = async () => {

			try {
				await ejectUserFromClub({
					userId: user.uid,
					clubId: club.id,
					coachId: uid,
				})
			} catch (err) {
				console.error("Caught error", err)
			}

			setBusy(false)
		}

		// Allow us to return & close the alert popup without waiting for a response
		go()
	}

	const ejectCoach = (uid: UserId) => {

		presentAlert({
			header: 'Eject Coach',
			message: `Are you sure you want to remove this coach from the club? They won't be able to rejoin without an invitation code. Also, your will lose access to their teams unless they are co-managed by one of your club's other users.`,
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Eject',
					role: 'destructive',
					handler: () => confirmEjectCoach(uid),
				},
			],
		})
	}

	const confirmAlterCoachStatus = (uid: UserId, status: string) => {

		if (!status)
			return

		if (status !== 'admin' && status !== 'coach')
			return

		console.log('confirmAlterCoachStatus')

		if (!user) {
			console.error('No user!')
			return
		}

		if (!club) {
			console.error('No club!')
			return
		}

		setBusy(true)

		const go = async () => {
			try {
				await setClubUserStatus({
					userId: user.uid,
					clubId: club.id,
					coachId: uid,
					status,
				})
			} catch (err) {
				console.error("Caught error", err)
			}

			setBusy(false)
		}

		// Allow us to return & close the alert popup without waiting for a response
		go()
	}

	const alterCoachStatus = (uid: UserId) => {
		presentActionSheet({
			header: 'Change User Status',
			subHeader: 'Coaches can only modify their own teams. Admins can add, remove & reassign coaches.',
			buttons: [
				{
					text: 'Admin',
					data: 'admin',
				},
				{
					text: 'Coach',
					data: 'coach',
				},
				{
					text: 'Cancel',
					role: 'cancel',
				},
			],
			onDidDismiss: ({ detail }) => confirmAlterCoachStatus(uid, detail.data),
		})
	}

    return (
        <section>
            <h4>
                Users ({users.length})
            </h4>
			{busy && <LoadingSpinner />}
            <div className="club-users-list">
                {
                    users.map(uid => (
                        <DisplayClubUserRow
                            key={uid}
                            uid={uid}
							amUser={uid === user?.uid}
							allowEdit={true}
                            setModifyCoachPermissions={setModifyCoachPermissions}
							ejectCoach={ejectCoach}
							alterCoachStatus={alterCoachStatus}
                        />
                    ))
                }
            </div>
            <ModifyCoachPermissions
                modifyCoachPermissions={modifyCoachPermissions}
                setModifyCoachPermissions={setModifyCoachPermissions}
            />
        </section>
    )
}

type DisplayClubUserRowProps = {
	uid: UserId
	amUser: boolean
	allowEdit: boolean
	alterCoachStatus: (uid: UserId) => void
	ejectCoach: (uid: UserId) => void
	setModifyCoachPermissions: React.Dispatch<React.SetStateAction<CoachPermissions>>
}

const DisplayClubUserRow = (props: DisplayClubUserRowProps) => {

    const { uid, amUser, allowEdit, alterCoachStatus, setModifyCoachPermissions, ejectCoach } = props

    const club = useClub()

	const clubUser = useMyClubUser(uid)

	const teams = useUserTeams(uid)

	if (!club)
		return null

    const role = club.admin.includes(uid) ? 'Admin' : 'Coach'

	return (
		<div className="display-club-user-row">
			<div className="display-club-user-name">
				<DisplayClubUser
					uid={uid}
					amUser={amUser}
					allowEdit={allowEdit}
				/>
				{!amUser && (
					<IonButton
						className="club-eject-coach-button"
						size="small"
						fill="clear"
						color="danger"
						onClick={() => ejectCoach(uid)}
					>
						Eject coach
					</IonButton>
				)}
			</div>
			<div className="display-club-user-subtitle">
				<div className="display-club-user-role">
					<IonButton
						className="club-modify-role-button"
						size="small"
						fill="clear"
						onClick={() => alterCoachStatus(uid)}
					>
						{role}
					</IonButton>
				</div>
				<div className="display-club-user-email">
					{clubUser?.email || clubUser?.phoneNumber || ''}
				</div>
				<div className="display-club-user-lastlogin">
					Last seen {displayTimeAgo(clubUser?.lastLogin)}
				</div>
			</div>
			<div className="display-club-user-teams">
				{
					Object.keys(teams).length === 0 ? (
						<div className="display-club-user-no-teams">
							(No teams)
						</div>
					) :
						Object.entries(teams).map(([ teamId, team]) => {

							return (
								<div
									key={teamId}
									className="display-club-user-team"
								>
									<div className="club-add-remove-permission-buttons">
										<IonButton
											size="small"
											fill="outline"
											expand="block"
											onClick={() => setModifyCoachPermissions({ teamId }) }
										>
											<IonIcon icon={settingsSharp} />
											{team.name}
										</IonButton>
									</div>
								</div>
							)
						})
				}
			</div>
		</div>
	)
}

type DisplayClubUserProps = {
	uid: UserId
	amUser?: boolean
	allowEdit?: boolean
}

const DisplayClubUser = (props: DisplayClubUserProps) => {

	const { uid, amUser, allowEdit } = props

	const club = useClub()
	const clubUser = useMyClubUser(uid)

	const [ editMode, setEditMode ] = useState(false)

	if (!club)
		return null

	if (!clubUser) {
		if (amUser) {
			// Temporary glitch registering with clubUserLogin,
			// so cover for now
			return (
				<>
					Me
				</>
			)
		}
		return (
			<>
				User outside club - {uid.substring(0, 6)}
			</>
		)
	}

	const onClickEdit = () => {
		setEditMode(true)
	}

	const onSubmit = async (data: Coach) => {

		const cid = `/clubs/${club.id}/userInfo`

		try {
			await updateDoc(doc(db, cid, uid), {
				nickname: data.nickname,
			})
			console.log("Doc updated", data)
		} catch (err) {
			console.error("Failed to update doc", err)
		}

		setEditMode(false)
	}

	return (
		<>
			<DisplayClubUserPhoto
				photoUrl={clubUser.photoUrl}
			/>
			{
				editMode ? (
					<EditItem<Coach>
						mode="edit"
						type={{
							name: 'Coaches',
							unit: 'coach',
							fields: [ 'nickname' ],
						}}
						item={{
							id: 'n/a',
							created: 0,
							nickname: displayName(clubUser),
						}}
						onSubmit={onSubmit}
						onCancel={() => setEditMode(false)}
					/>
				) : (
					<>
						<span className="display-club-user-displayname">
							{displayName(clubUser)}
							{amUser && ' (me)' }
						</span>
						{
							allowEdit && (
								<CiEdit
									className="edit-nickname-button"
									onClick={onClickEdit}
								/>
							)
						}
					</>
				)
			}
		</>
	)
}

type DisplayClubUserPhotoProps = {
	photoUrl?: string
}

const DisplayClubUserPhoto = (props: DisplayClubUserPhotoProps) => {
	const { photoUrl } = props

	if (photoUrl) {
		return (
			<img className="user-photo" src={photoUrl} alt="" />
		)
	}

	return (
		<IonIcon className="user-photo" icon={personCircleOutline} />
	)
}

const displayName = (user: User | ClubUser, withPrivacy?: boolean) => {

	const { email, phoneNumber } = user

	let display = ('nickname' in user && user.nickname) || ('name' in user && user.name)

	if (!display) {
		if (email) {
			const match = email.match('^(.+)@privaterelay.appleid.com$')
			if (match) {
				display = match[1].substr(0, 6) + ' (Private AppleID)'
			} else {
				display = withPrivacy ? email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2") : email
			}
		} else if (phoneNumber) {
			display = withPrivacy ? phoneNumber.substring(0, 7) + '***' : phoneNumber
		} else {
			display = 'Anonymous'
		}
	}

	return display
}

function displayTimeAgo(t: number | undefined) {
    if (!t) return 'never'

    const nDate = new Date()
    const tDate = new Date(t)

    // Calculate the difference in milliseconds
    const timeDifference = nDate.getTime() - tDate.getTime() // nDate - tDate now returns a number

    // Convert 6 days to milliseconds
    const sixDaysInMilliseconds = 86400 * 6 * 1000

    if (timeDifference > sixDaysInMilliseconds) {
        return tDate.toLocaleDateString()
    }

	if (nDate.getDate() === tDate.getDate()) {
		return 'today'
	}

	const dayDiff = nDate.getDay() - tDate.getDay()
	if (dayDiff === 1) {	// This fails to deal with month changeover
		return 'yesterday'
	}

	const weekday = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ]
	return weekday[tDate.getDay()]
}

type CoachPermissions = null | {
	teamId: TeamId
}

type ModifyCoachPermissionsProps = {
	modifyCoachPermissions: CoachPermissions
	setModifyCoachPermissions: React.Dispatch<React.SetStateAction<CoachPermissions>>
}

const ModifyCoachPermissions = (props: ModifyCoachPermissionsProps) => {

    const { modifyCoachPermissions, setModifyCoachPermissions } = props

    const teamId = modifyCoachPermissions?.teamId
    const user = useUser()
    const team = useTeam(teamId || '')
	const club = useClub()
    const seasons = useTeamSeasons(teamId || '')

	const closeSideMenu = useContext(ProfileContext)
	const onDismiss = useContext(ModalContext)

    const [ showAddCoach, setShowAddCoach ] = useState(false)

    const [ busy, setBusy ] = useState(false)
    const [ presentAlert ] = useIonAlert()

	// console.log('team', teamId, team, 'seasons', seasons)

    let content = null

    if (club && teamId) {

		type ModifyCoachProps = {
			uid: UserId
			type: 'add' | 'remove'
		}

		const modifyCoach = (props: ModifyCoachProps) => {
			const { type, uid } = props
            let message
            let confirmButton
            if (type === 'remove') {
                message = `Are you sure you want to remove this coach's ability to edit this team?`
                confirmButton = {
                    text: 'Remove',
                    role: 'destructive',
                    handler: () => onConfirm({ uid, type }),
                }
            } else {
                message = `Grant this coach the ability to edit this team?`
                confirmButton = {
                    text: 'Add',
                    handler: () => onConfirm({ uid, type }),
                }
            }

            presentAlert({
                message,
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                    confirmButton,
                ],
            })
        }

		const onConfirm = async (props: ModifyCoachProps) => {
			const { uid, type } = props
            console.log('modify coach access', club)

			if (!user) {
				console.error('No user!')
				return
			}

			if (!club) {
				console.error('No club!')
				return
			}

			setBusy(true)
			setShowAddCoach(false)

			const go = async () => {
				try {
					await modifyCoachAccess({
						userId: user.uid,
						clubId: club.id,
						teamId,
						coachId: uid,
						type,
					})
				} catch (err) {
					console.error("Caught error", err)
				}

				setBusy(false)
			}

			// Allow us to return & close the alert popup without waiting for a response
			go()
		}

		let seasonContent = null
		if (seasons) {
			seasonContent = (
				<div className="display-club-user-teams">
				{
					Object.keys(seasons).sort((a, b) => seasons[b].created - seasons[a].created).map(seasonId => (
						<div key={seasonId} className="display-club-user-team club-add-remove-permission-buttons">
							<IonRouterLink routerLink={`/games?team=${teamId}&season=${seasonId}`}>
							<IonButton
								size="small"
								fill="outline"
								expand="block"
								onClick={() => {
									setModifyCoachPermissions(null)
									typeof onDismiss === 'function' && onDismiss()
									typeof closeSideMenu === 'function' && closeSideMenu()
								}}
							>
								<IonIcon icon={settingsSharp} />
								{seasons[seasonId].name}
							</IonButton>
							</IonRouterLink>
						</div>
					))
				}
				</div>
			)
		}

        content = (
            <div>
                <h1>
                    {team?.name}
                </h1>
				<p>
					Seasons:
				</p>
				{seasonContent}
                <p>
                    Coaches:
                </p>
                <div className="club-team-coaches-list">
                    {
                        team?.managers && team.managers.map(uid => {

                            const userBelongsToClub = club.users.includes(uid)

							return (
								<p key={uid}>
									<DisplayClubUser uid={uid} />

									<IonButton
										size="small"
										fill="outline"
										color="danger"
										disabled={!userBelongsToClub || team.managers.length <= 1}
										onClick={() => modifyCoach({ uid, type: 'remove' })}
									>
										Remove
									</IonButton>
								</p>
							)
						})
					}
				</div>
				{
					showAddCoach ? (
						<div>
							<p>
								Grant a coach the ability to edit this team:
							</p>
							<div>
								{
									club.users.map(uid => (
										<IonButton
											key={uid}
											fill="outline"
											disabled={team?.managers?.includes(uid)}
											onClick={() => modifyCoach({ uid, type: 'add' })}
										>
											<DisplayClubUser uid={uid} />
										</IonButton>
									))
								}
							</div>
						</div>
					) : (
						<p>
							<IonButton
								fill="outline"
								onClick={() => setShowAddCoach(true)}
							>
								<IonIcon icon={add} />
								Add Coach
							</IonButton>
						</p>
					)
				}
			</div>
		)
	} else {
		content = <p>Loading club...</p>
	}

	const show = modifyCoachPermissions !== null
	const setShow = () => setModifyCoachPermissions(null)

	return (
		<ModalWithCancel
			show={show}
			setShow={setShow}
			cancelText="Done"
		>
			{content}
			{busy && <LoadingSpinner />}
		</ModalWithCancel>
	)
}

const DisplayClubForUser = () => {

    const userHasProPersonally = useUserHasProPersonally()
    const clubHasPro = useClubHasPro()

    let proStatus
    if (clubHasPro && userHasProPersonally) {
        proStatus = <DuplicatePlanWarning />
    } else if (clubHasPro) {
        proStatus = (
            <p>
                You are receiving <PlanName /> from this club.
            </p>
        )
    } else if (userHasProPersonally) {
        proStatus = (
            <p>
                You are personally subscribed to <PlanName />, but your
                club is not.
            </p>
        )
    }

    return (
        <div>
            <p>
                You are a <b>coach</b> of this club.
            </p>
            {proStatus}
            <p>
                Club administrators can view your rosters and stats. They can
                grant other coaches the ability to edit your rosters, and remove
                that ability from coaches, including you.
            </p>

            <LeaveClubButton />
        </div>
    )
}

const LeaveClubButton = () => {

    const user = useUser()
    const club = useClub()

    const [ presentAlert ] = useIonAlert()

    const [ busy, setBusy ] = useState(false)

	if (!club)
		return null

    const onClick = () => {
        presentAlert({
            header: club.name,
            message: `Are you sure you want to leave? You won't be able to re-join without an invitation code.`,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'Leave',
                    role: 'destructive',
                    handler: onConfirm,
                },
            ],
        })
    }

	const onConfirm = async () => {
		console.log('leave club!', user, club)

		if (user) {
			setBusy(true)

			try {
				const response = await leaveClub({
					userId: user.uid,
					clubId: club.id,
				})
				console.log('leaveClub response:', response)
			} catch (err) {
				console.error("Caught error", err)
			}

			setBusy(false)
		}
	}

	return (
		<div className="leave-club-button">
			<LoadingSpinnerButton
				busy={busy}
				onClick={onClick}
				color="danger"
				fill="outline"
				size="small"
			>
				Leave Club
			</LoadingSpinnerButton>
		</div>
	)
}

type CreateClubProps = {
	modalRef?: React.RefObject<HTMLIonModalElement>
}

const CreateClub = (props: CreateClubProps) => {

    const { modalRef } = props

	console.log('CreateClub', 'with modalRef? Passed by ModalWithCancel?? if not please delete', modalRef)

    const user = useUser()

	const type: ItemTypeDefinition<Club> = {
        name: 'clubs',
        unit: 'club',
        fields: [
            'name',
            'abbreviation',
        ],
		onAdd: () => {
			if (user) {
				return {
					users: [ user.uid ],
					admin: [ user.uid ],
				}
			}
			return { }
		},
    }

	const onSubmit = async (result: { data: Club, id: string | undefined }) => {
		if (result.id && user) {

			const cid = `/clubs/${result.id}/userInfo`

			const newDoc = {
				name: user.displayName,
				email: user.email,
				phoneNumber: user.phoneNumber,
				photoUrl: user.photoUrl,
			}

			try {
				await setDoc(doc(db, cid, user.uid), newDoc)
				console.log('club userInfo doc written', cid)
			} catch (err) {
				console.error('Failed to write club userInfo doc!', err)
			}
		}

		modalRef?.current?.dismiss()
	}

	const onCancel = () => {
		console.log('cancel')
		modalRef?.current?.dismiss()
	}

	return (
		<div>
			<AddItem<Club>
				type={type}
				items={[ ]}
				onSubmit={onSubmit}
				onCancel={onCancel}
			/>
		</div>
	)
}
export default Club
