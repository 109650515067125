import { Haptics, ImpactStyle } from '@capacitor/haptics'
import { HiDotsHorizontal } from 'react-icons/hi'
import {
    IonButton,
    IonCard,
    IonContent,
    IonIcon,
    IonLabel,
    IonList,
    IonPopover,
    IonRippleEffect,
    useIonAlert,
} from '@ionic/react'
import { checkmarkSharp, createOutline, trashOutline } from 'ionicons/icons'
import React, { useState, useEffect, useRef } from 'react'

import { deepCopy } from './util'
import {
    useCanEditItem,
	useDeleteItem,
    useNewTeamIdState,
	useSetShowTeamSelection,
} from './data'
import EditItem from './EditItem'
import { useSaveItem } from './functions'
import { dbids } from './definitions'

type ItemProps<T> = {
	item: T
	items: Array<T>
	type: ItemTypeDefinition<T>
	editMode: boolean|string
	setEditMode: (editMode: boolean|string) => void
}

const Item = <T extends Item>(props: ItemProps<T>) => {

	const { item, items, type, editMode } = props

    const canEdit = useCanEditItem(type?.unit)
	const deleteItem = useDeleteItem<T>()
    const [ presentAlert ] = useIonAlert()
    const [ newTeamId, setNewTeamId ] = useNewTeamIdState()

	// const [ editMode, setEditMode ] = useState<boolean | string>(false)

	const toggleEditMode = () => props.setEditMode(!editMode)
	const setShowTeamSelection = useSetShowTeamSelection()
	const saveItem = useSaveItem<T>()

	// console.log('item', item, 'type', type, 'canEdit', canEdit)

    useEffect(() => {
		let timer: ReturnType<typeof setTimeout>

        if (editMode) {
            timer = setTimeout(() => {

                const id = `editable-item-id-${item.id}`

                if (editMode === 'result') {
					const element = document.querySelector(`#${id} .enter-results-table tr td:nth-child(2) input`) as HTMLInputElement | null
					if (element) {
						console.log('Focus!')
						element.focus()
					}
				} else {
					const formElement = document.getElementById(id)
					if (formElement) {
						formElement.scrollIntoView({
							behavior: 'smooth',
							block: 'end', // Use 'block' instead of 'alignToTop'
						})
					}
                }
            }, 120)
        }

        return () => clearTimeout(timer)
    }, [ editMode, item.id ])

	const handleChange = async (data: T) => {

		const newData = deepCopy(data)

        if ('result' in newData && newData.result && !newData.result.me.filter(score => score !== null).length && !newData.result.them.filter(score => score !== null).length) {
            newData.result = undefined
        }

		//
		// Write to Firebase
		//
		try {
			// Respond immediately
			toggleEditMode()
			const { unit } = type
			if (!(unit in dbids)) {
				throw new Error("Illegal attempt to save item type " + type.name)
			}
			await saveItem(unit as keyof DbidMapping, newData)
		} catch (err) {
			console.error("Failed to update doc", err)
			// Switch back to edit mode to show it didn't work
			toggleEditMode()
		}
	}

    const onClickDelete = async () => {
		const alertData = await deleteItem({ type, item })
		presentAlert(alertData)
    }

    const allFields = type.fields.concat(type.editOnlyFields || [ ])

    const myClasses = [ 'autoroster-item', type.unit ]

    let content
    let routerLink
	let onClick: null | (() => void) = null

    if (editMode) {
        const submitButtonContent = (
            <>
                <IonIcon icon={checkmarkSharp} /> Update
            </>
        )
        return (
            <EditItem
                mode="edit"
                editMode={editMode}
                type={type}
                item={item}
                items={items}
                className={`editable-item autoroster-item ${type.unit}`}
                submitButtonContent={submitButtonContent}
                onSubmit={handleChange}
                onCancel={toggleEditMode}
            />
        )
    } else {

        if (type.linkToItem) {

            routerLink = `/${type.name}/${item.id}`

        } else if (type.unit === 'season') {

            routerLink = `/games?team=${newTeamId}&season=${item.id}`

        }

        if (type.unit === 'team') {

            onClick = () => setNewTeamId(item.id || null)

        } else if (type.unit === 'season' && newTeamId) {
           onClick = () => {
			   setNewTeamId(null)

			   /*
				* Commented out because this causes a weird bug whereby
				* if you load on the Games page, then click to Help, then
				* select a new team/season from the normal popup (not Recent),
				* then the URL changes but the page doesn't reload.
				*
				* HOWEVER I probably added this to fix another weird caching
				* problem, so let's see...
				*
			   const url = getUrl({
				   relative: true,
				   page: 'games',
				   section: {
					   team: newTeamId,
					   season: item.id,
				   },
			   })

			   history.push(url)
				*/

			   //
			   // We need to set this explicitly just in case the user selects
			   // the team & season they were already viewing, in which case no
			   // navigation occurs.
			   //
			   setShowTeamSelection(false)
           }
		}

		if (!routerLink && !onClick) {
            console.error("Bad attempt to link", type, item)
        }

        content = (
            <div
                className={myClasses.join(' ')}
            >

                <IonRippleEffect></IonRippleEffect>

                <ItemMenu
                    unit={type.unit}
                    canEdit={canEdit}
                    onClickDelete={onClickDelete}
                    onClickEdit={toggleEditMode}
                />
				<div className="item-content">
                {
                    allFields.map((field, index) =>
						<ItemField<T>
                            key={String(field) || `item-field-${index}`}
                            field={field}
                            value={item[field]}
                            type={type}
							item={item}
							setEditMode={props.setEditMode}
                        />
                    )
                }
				</div>

                {
                    type.ExtraContent && <type.ExtraContent item={item} />
                }
            </div>
        )
    }

    return (
        <IonCard
            button={true}
            routerLink={routerLink}
			onClick={() => {
				Haptics.impact({ style: ImpactStyle.Light })
				if (onClick) {
					onClick()
				}
			}}
        >
			{content}
		</IonCard>
	)
}

type ItemFieldProps<T> = FormatItemFieldProps<T> & {
	type: ItemTypeDefinition<T>
}

const ItemField = <T extends Item>(props: ItemFieldProps<T>) => {
	const { type } = props

	const content = type.formatField ? type.formatField(props) : String(props.value)

	return (
		<div className={`field-${String(props.field)}`}>
			{content}
		</div>
	)
}

type ItemMenuProps = {
	unit: string
	canEdit: boolean
	onClickEdit: () => void
	onClickDelete: () => void
}

const ItemMenu = (props: ItemMenuProps) => {

	const { canEdit, unit, onClickEdit, onClickDelete } = props

	const [ showPopover, setShowPopover ] = useState(false)

	const popover = useRef<HTMLIonPopoverElement>(null)

	if (!canEdit) {
		return null
	}

	const handleClick = (event: React.MouseEvent) => {
		event.stopPropagation()
		event.preventDefault()
		if (popover.current) {
			popover.current.event = event
			setShowPopover(true)
		}
	}

	const handleClose = (event: React.MouseEvent) => {
		event.stopPropagation()
		event.preventDefault()
        setShowPopover(false)
	}

	return (
		<div className="item-menu">
            <IonButton
                className="button-item-menu button-react-icon"
                fill="clear"
                color="medium"
                onClick={handleClick}
            >
                <HiDotsHorizontal />
            </IonButton>
            <IonPopover
                ref={popover}
                isOpen={showPopover}
                reference="event"
                dismissOnSelect={true}

				// @ts-ignore
                onDidDismiss={handleClose}
            >
                <IonContent>
                    <IonList>
						<IonButton
							className="item-menu-item item-menu-view"
							// no need for onClick - just let the click propagate to the parent
							expand="full"
							fill="clear"
						>
							<IonLabel>
								View {unit}
							</IonLabel>
						</IonButton>
						<IonButton
							className="item-menu-item item-menu-edit"
							onClick={e => {
								onClickEdit()
								handleClose(e)
							}}
							expand="full"
							fill="clear"
						>
							<IonIcon icon={createOutline} slot="start" />
							<IonLabel>
								Edit {unit}
							</IonLabel>
						</IonButton>
						<IonButton
							className="item-menu-item item-menu-delete"
							onClick={e => {
								onClickDelete()
								handleClose(e)
							}}
							expand="full"
							fill="clear"
							color="danger"
						>
							<IonIcon icon={trashOutline} slot="start" />
							<IonLabel>
								Delete {unit}
							</IonLabel>
						</IonButton>
					</IonList>
				</IonContent>
			</IonPopover>
		</div>
	)
}

export default Item
