import "./list.css"

import {
	IonButton,
} from '@ionic/react'
import { add } from 'ionicons/icons'
import React, { useState, useEffect } from 'react'
import posed, { PoseGroup } from 'react-pose'

import { EditSeason } from './MySeasons'
import { SignInButton } from './Intro'
import { Warning } from './Roster'
import { ucFirst } from './util'
import {
  useActiveTab,
  useAmAddingNewSeason,
  useCanAddItem,
  useHaveValidSeason,
  useMySeason,
  useMyTeam,
  useNewTeamId,
  useSection,
  useShowTeamSelectionState,
  useUser,
} from './data'
import AddItem from './AddItem'
import EditButton from './EditButton'
import FadeText from './FadeText'
import Item from './Item'
import LoadingBar from './LoadingBar'
import LoadingSpinner from './LoadingSpinner'

const staggerDuration = 40

const AnimatedBox = posed.div({
    enter: {
        y: 0,
        opacity: 1,
		delay: ({ i }: { i?: number }) => (i || 0) * staggerDuration, // Explicitly type 'i' as an optional number
    },
    exit: {
        y: 50,
        opacity: 0,
    }
})

type ListProps<T> = {
	type: ItemTypeDefinition<T>
	data: null | {
		[id: string]: T
	}
	prependContent?: JSX.Element
}

const List = <T extends Item>(props: ListProps<T>) => {

    const { type, data, prependContent } = props

	const user = useUser()
	const section = useSection()
	const team = useMyTeam()
	const season = useMySeason()
	const haveValidSeason = useHaveValidSeason()
    const canAddItem = useCanAddItem(type?.unit)
	const newTeamId = useNewTeamId()
	const activeTab = useActiveTab()

	const amAddingNewSeason = useAmAddingNewSeason()

	const [ showTeamSelection, setShowTeamSelection ] = useShowTeamSelectionState()

	const [ items, setItems ] = useState<Array<T>>([ ])
	const [ itemEditModes, setItemEditModes ] = useState<Array<boolean|string>>([ ])
	const [ mode, setMode ] = useState<'add'|null>(null)
	const [ seasonEditMode, setSeasonEditMode ] = useState(false)

    const loading = data === null

	// 'missingTeam': We're not loading but we have no items because the user hasn't
	// selected a team; that is, either there's no section.team or there is but we
	// failed to load it.
	const missingTeam = type?.name !== 'teams' && (type?.name !== 'seasons' || !newTeamId) && (!section.team || (team !== null && !team?.name))

	// 'noUser': We don't have a user and it's not because their data just hasn't
	// loaded yet. NOTE: This may be perfectly fine, as most lists don't require a user.
	const noUser = user === null

	// 'missingUser': We're required to have a user before we can load this data, and
	// we don't, either because the user hasn't loaded yet or they're a guest.
	const missingUser = type.requireUser && !user

	const amEditingItem = itemEditModes.some(mode => mode)

	// console.log('data', data)
	// console.log('>>>>>>>>>>>> user', user) // , missingTeam, team, 'data', data, 'mode', mode)

	//
	// Load data on component mount.
	//
	useEffect(() => {

		//
		// Some types of items (currently only teams) need to check something before
		// loading any data (like whether you are a logged-in user). So
		// if this precheck exists and fails, abort any attempt to load data.
		//
		if (missingUser) {
            console.log('Abort, need a user first')
			return
		}

        if (data) {

            // console.log('List initializing with type', type.unit, 'and data', data)

            //
            // Add 'id' field to each object, since we're about to turn
            // them into an array.
            //
			const myData: {
				[id: string]: T
			} = { }

			Object.entries(data).forEach(([ id, obj ]) => {
                myData[id] = {
                    ...obj,
                    id,
                }
            })

			//
            // Convert to array, sorting if necessary.
            //
            const sortedItems = Object.values(myData).sort(type.sortFunction || defaultSortFunction)

            setItems(sortedItems)
            //console.log('set items', data, sortedItems)
			setItemEditModes(sortedItems.map(() => false))
        } else {
            console.log('No data -', data)
        }

    }, [ data, section, type, user, missingUser ])

    //
    // When we change screens, reset 'mode', because we don't want to remain in Add mode if
    // we navigate away.
    //
	// Likewise when we change season settings, we need to close Season Edit Mode to ensure it will show fresh data.
	//
    useEffect(() => {
		// console.log('reset mode')
        setMode(null)
		setSeasonEditMode(false)
    }, [ activeTab, season ])

	useEffect(() => {
		// console.log('amAddingNewSeason', amAddingNewSeason)
		if (amAddingNewSeason && type.unit === 'season') {
			setMode('add')
		}
	}, [ amAddingNewSeason, type.unit ])

	const addButton = {
		show: !loading && canAddItem,
		className: "button-react-icon button-animated button-mixed",
		onClick: () => setMode('add'),
	}

	//
	// What to display if we're not showing any items.
	//
	let noItemsMessage
    if (!items.length) {

		if (missingUser) {
			//
			// These items require a user.
			//
			noItemsMessage = (
				<div className="no-items">
					<p>
						You are not logged in.
					</p>
					<SignInButton />
				</div>
			)
		} else if (missingTeam) {
			noItemsMessage = (
				<div className="no-items">
					<p>
						No team selected.
					</p>
					<p>
						<IonButton
							onClick={() => setShowTeamSelection(!showTeamSelection)}
						>
							Select team
						</IonButton>
					</p>
				</div>
			)
		} else if (loading) {
            noItemsMessage = <LoadingSpinner />
		} else if (type.requireValidSeason && !haveValidSeason) {
			noItemsMessage = <Warning>This is a deleted season.</Warning>
		} else if (noUser) {
			//
			// No items and we can't add some because we don't have a user.
			//
			noItemsMessage = (
				<div className="no-items">
					<p>
						You are not logged in.
					</p>
					<SignInButton />
				</div>
			)
		} else {
            //
            // We've loaded but there are no items at all.
            //
			noItemsMessage = (
				<div className="no-items">
					<p>
						<FadeText>
							No {type.name} found.
						</FadeText>
					</p>
					<p>
						<FadeText>
							Add one below!
						</FadeText>
					</p>
				</div>
			)
		}
	}

	return (
		<section className={`list ${type.name}`}>
			<LoadingBar
				variant="determinate"
				loading={loading && !missingTeam && !missingUser}
			/>
			{prependContent}
			<div className={`list-items list-${type.name} list-edit-${amEditingItem ? 'yes' : 'no'}`}>
				{
					noItemsMessage || (
						<PoseGroup>
							{
								items.map((item, index) => (
									<AnimatedBox
										key={item.id ?? `item-${index}`}
										i={index}
										className="list-item"
									>
										<Item
											item={item}
											items={items}
											type={type}
											editMode={itemEditModes[index]}
											setEditMode={(editMode: boolean|string) => {
												const newModes = [ ...itemEditModes ]
												newModes[index] = editMode
												setItemEditModes(newModes)
											}}
										/>
									</AnimatedBox>
								))
							}
						</PoseGroup>
					)
				}
			</div>
			{
				mode === 'add' ? (
					<AddItem
						type={type}
						onCancel={() => setMode(null)}
						quickAdding={type.quickAdding}
						items={items}
					/>
				) :
					(type.requireValidSeason && !haveValidSeason) ? null : (
					<div className="underneath-list-add-edit">
						{
							seasonEditMode ? null : (
								<EditButton
									text={`Add ${ucFirst(type.unit)}`}
									icon={add}
									show={addButton.show}
									ready={true}
									className={addButton.className}
									onClick={addButton.onClick}
								/>
							)
						}
						{
							type.unit === 'game' ? (
								<EditSeason
									seasonEditMode={seasonEditMode}
									setSeasonEditMode={setSeasonEditMode}
									loading={loading}
								/>
							) : null
						}
					</div>
				)
			}
		</section>
	)
}

//
// Sorts from oldest to newest, then alphabetically.
//
function defaultSortFunction(a: Item, b: Item): number {
	let diff = a.created - b.created
	if (!diff) {
		if ('name' in a && 'name' in b) {
			diff = String(a.name).localeCompare(b.name)
		}
	}
	return diff
}

export default List
