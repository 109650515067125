import React from 'react'

//
// Using React.memo() here means that we won't re-render the component unless the props
// change -- largely useless because the onClick functions get rebuilt a lot for most
// cells, but it avoids re-renders of a few, like Header cells.
//
type GridCellProps = {
	type: string
	content: string | JSX.Element | null

	className?: string
	isAvailable?: boolean
	isSubAvailable?: boolean
	isNullPosition?: boolean
	subStatus?: SubStatus
	subAnimation?: SubAnimation
	richContent?: string | JSX.Element
	editContent?: React.ReactNode
	positionName?: string
	icons?: Array<React.ReactNode>
	iconsRight?: React.ReactNode | React.ReactNode[]
	i?: I
	qtr?: number
	onClick?: (i: I, event: React.MouseEvent) => void
	onClickIcon?: (i: I, event: React.MouseEvent) => void
	onClickIconRight?: (i: I, event: React.MouseEvent) => void
}

const GridCell = React.memo((props: React.PropsWithChildren<GridCellProps>) => {

	const { type, content, positionName, i } = props

	const safeContent = typeof content === 'string' && content.replace(/ /, '-')
	const available = (props.isAvailable ? '' : 'un') + 'available'
	const nullPosition = (props.isNullPosition ? 'nulled' : 'normal')

	const iconbox = i === undefined ? null : <MakeIconbox i={i} icons={props.icons} onClick={props.onClickIcon} />

	const iconboxRight = i === undefined ? null : <MakeIconbox i={i} icons={props.iconsRight} onClick={props.onClickIconRight} extraClass={"iconbox-right"} />

	const myContent = props.editContent || props.richContent || (
		<>
			{content}
			{iconbox}
			{iconboxRight}
		</>
	)

	const myClasses = [
		'cell',
		`cell-${type}`,
		`cell-${type}-${available}`,
		`cell-${type}-${nullPosition}`,
	]

	if (positionName) {
		myClasses.push(`cell-${type}-${positionName}`)
	} else {
		myClasses.push(`cell-${type}-${safeContent}`)
	}

	if (props.subStatus) {
		myClasses.push(`cell-substatus-nonzero cell-substatus-${props.subStatus}`)
		myClasses.push(`cell-sub-${props.isSubAvailable ? '' : 'un'}available`)
	}

    if (props.className) {
        myClasses.push(props.className)
    }

	if (props.subAnimation) {
		myClasses.push(`cell-subsplit-${props.subAnimation[0]} cell-subsplit-${props.subAnimation[0]}-${props.subAnimation[1]}`)
	}

	return (
		<div
			className={myClasses.join(' ')}
			data-i={i}
			data-qtr={type === 'header' ? props.qtr : undefined}
			onClick={event => props.onClick && i !== undefined && props.onClick(i, event)}
		>
			{
				props.subStatus === undefined ? null : (
					<span className={`cell-substatus${props.subStatus ? '' : '-none'}`} />
				)
			}
			<span className="cell-content">
				{myContent}
			</span>
			{props.children}
		</div>
	)
})

GridCell.displayName = 'GridCell'

type MakeIconboxProps = {
	i: I
	icons: React.ReactNode | React.ReactNode[]
	onClick?: (i: I, event: React.MouseEvent) => void
	extraClass?: string
}

const MakeIconbox = (props: MakeIconboxProps) => {

	const { i, icons, onClick, extraClass } = props

	if (icons) {
		const iconList = Array.isArray(icons) ? icons : [ icons ]

		if (iconList.length) {
			return (
				<div
					className={`iconbox ${extraClass || ''}`}
				>
					{
						iconList.map((icon, index) => (
							<span
								key={index}
								className="iconbox-hitbox"
								onClick={event => onClick && onClick(i, event) }
							>
								{icon}
							</span>
						))
					}
				</div>
			)
		}
	}

	return null
}

export default GridCell
