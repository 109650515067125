import React from 'react'
import {
    IonButton,
} from '@ionic/react'
import {
    useEditMode,
    useMyRoster,
    useMyRosterIsStale,
    useReady,
} from './data'
import { FaRandom } from 'react-icons/fa'

type GoButtonProps = {
	onAutoRoster: () => void
	noHighlight?: boolean
}

const GoButton = (props: GoButtonProps) => {

    const editMode = useEditMode()
    const ready = useReady()
    const roster = useMyRoster()
    const rosterIsStale = useMyRosterIsStale()

    const highlight = editMode && ready && !props.noHighlight && (rosterIsStale || !roster?.roster?.length)

	return (
			<IonButton
                className={'button-go button-react-icon' + (highlight ? ' button-go-highlight' : '')}
                onClick={props.onAutoRoster}
                disabled={!ready || !editMode}
                title="Automatically generate a roster"
            >
				<FaRandom />
				<span className="roster-button-text">
					Go
				</span>
			</IonButton>
	)
}

export default GoButton
