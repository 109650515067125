import React from 'react'
import {
	IonAccordion,
	IonAccordionGroup,
	IonItem,
	IonLabel,
} from '@ionic/react'
import {
	newsItems,
} from './newsItems'

type NewsProps = {
	setHelpTab: (tab: string) => void
}

const News = (props: NewsProps) => {

	// console.log('News.js', newsItems)

	return (
		<div className="news">

			<NewsItem
				{...props}
				item={newsItems[0]}
			/>

			<div className="news-date">
				Older News
			</div>

			<IonAccordionGroup
				onIonChange={(e: CustomEvent) => e.stopPropagation()}
			>

				{
					newsItems.slice(1).map((item, index) => (

						<IonAccordion key={index} value={`news-${index}`}>
							<IonItem slot="header" color="light">
								<IonLabel>
									{item.date}
								</IonLabel>
							</IonItem>
							<div className="ion-padding" slot="content">
								<NewsItem
									{...props}
									item={item}
								/>
							</div>
						</IonAccordion>
					))
				}
			</IonAccordionGroup>

		</div>
	)
}

type NewsItemProps = {
	item: NewsItem
	setHelpTab: (tab: string) => void
}

const NewsItem = (props: NewsItemProps) => {

	const { item, setHelpTab } = props

	if (!item)
		return null

	return (
		<div className="ion-padding" slot="content">
			<div className="news-date">
				{item.date}
			</div>
			<div className="news-content">
				{
					item.content({
						setHelpTab,
					})
				}
			</div>
		</div>
	)
}

export default News
