import { useEffect } from 'react'
import LogRocket from 'logrocket'
import {
	useUser,
} from './data'
import {
	setAnalyticsUserId,
} from './firebase'
import { LOGROCKET_APP_ID } from './logrocketCredentials'
import packageInfo from '../package.json'

//
// LogRocket
//
//
// Don't run LogRocket in dev mode because it shims the code
// and makes it harder to debug.
//
// We only get 1,000 free sessions per month of LogRocket, so
// let's sample traffic so we don't run out within a
// few days.
//
if (!import.meta.env.DEV) {
    const SAMPLE	= 0.02

	if (Math.random() < SAMPLE) {
		LogRocket.init(LOGROCKET_APP_ID, {
			release: packageInfo?.version,
		})
	}
}

const AnalyticsLogger = () => {

    const user = useUser()

    // const info = useStoreUserInfo()

    // console.log('AnalyticsLogger.js', info)

    //
    // Register user with LogRocket, etc
    //
    useEffect(() => {
        if (user) {

            console.log('Register user with LogRocket and FirebaseAnalytics')

			const obj: {
				name: string,
				email?: string,
			} = {
				name: user?.displayName || 'Anonymous', // E: Type 'string | null' is not assignable to type 'string | number | boolean'.   Type 'null' is not assignable to type 'stri…
			}

			if (user.email) {
				obj.email = user.email
			}

            LogRocket.identify(LOGROCKET_APP_ID, obj)

            setAnalyticsUserId(user?.uid)

        }
    }, [ user ])

	/*
    const activeSubscriptions = info?.activeSubscriptions

    useEffect(() => {
        if (activeSubscriptions) {
            console.log('Set user property', activeSubscriptions)
            setAnalyticsUserProperty('subscriptions', activeSubscriptions)
        }
    }, [ activeSubscriptions ])
	*/

    return null
}

export default AnalyticsLogger
