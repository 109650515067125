import './settings.css'

import { FaRandom } from 'react-icons/fa'
import {
    IonAlert,
    IonButton,
    IonContent,
    IonIcon,
    IonItem,
    IonPage,
    IonRange,
    IonSelect,
    IonSelectOption,
    IonToggle,
} from '@ionic/react'
import { informationCircleOutline, warning } from 'ionicons/icons'
import React, { useState } from 'react'

import {
  PRESET_CUSTOM,
  defaultPreset,
  presets,
  sliderSettings,
} from './definitions'
import { useSettingsState, useUserHasPro } from './data'
import FadeText from './FadeText'
import Header from './Header'

//
// Create an object for all tooltips
//
const tooltips: {
	[key in SettingsSliderType]?: { desc: string, tip: string }
} = { }

sliderSettings.forEach(setting => {
	const { id, desc, tip } = setting
	tooltips[id] = { desc, tip }
})

//completeTooltips = tooltips as { [key in SettingsSliderType]: { desc: string, tip: string } };

function queryValueForPreset(preset: keyof typeof presets, setting: SettingsSliderType) {
	return presets[preset].defaults[setting]
	//?? sliderSettings.find(s => s.id === setting)?.default
}

const Settings = () => {

	const [ settings, setSettings ] = useSettingsState()
	const userHasPro = useUserHasPro()

	const [ tooltip, setTooltip ] = useState<SettingsSliderType>()

	const currentPresetId = settings.preset || defaultPreset
    const currentPreset = presets[currentPresetId]

	/*
	const valuesForPreset = useMemo(() => {
		return Object.keys(presets).reduce((obj, id) => {
			obj[id] = sliderSettings.reduce((map, setting) => {
				map[setting.id] = presets[id].defaults[setting.id] ?? setting.default
				return map
			}, { })
			return obj
		}, { })
	}, [ ])
	*/

	const changeValue = (obj: { id: keyof typeof settings, value: Settings[keyof typeof settings] }) => {

		const { id, value } = obj

		let newSettings = {
			...settings,
			[id]: value,
		}

		if (id in currentPreset.defaults && value !== currentPreset.defaults[id as SettingsSliderType]) {

			// When manually changing a slider, set the preset to CUSTOM
			//

			console.log("Forcing change to CUSTOM")
			newSettings.preset = PRESET_CUSTOM

		} else if (id === 'preset' && value !== 'CUSTOM') {
			//
			// When selecting a preset, return all the values to default
			//

			sliderSettings.forEach(setting => {
				newSettings[setting.id] = queryValueForPreset(value as SettingsPresetType, setting.id)
			})
		}

		setSettings(newSettings)
	}

	const sortedPresetIds = Object.entries(presets).sort((a, b) => a[1].order - b[1].order).map(([ id, _ ]) => id as SettingsPresetType)

	/*
	const presetButtons = sortedPresetIds.map(id => ({
		text: presets[id].name,
		icon: presets[id].iconOutline,
		id,
		handler: () => changeValue({ id: 'preset', value: id }),
	}))

	presetButtons.push({
		text: 'Cancel',
		role: 'cancel',
	})
	*/

	return (
		<IonPage>
			<IonContent className="ion-padding">
				<section className="settings">

					<IonItem>
						<IonIcon
							className="preset-icon"
							icon={currentPreset.icon}
							slot="end"
						/>

						<IonSelect
							label="Preset"
							interface="action-sheet"
							value={currentPresetId}
							onIonChange={ (e: CustomEvent) => changeValue({
								id: 'preset',
								value: e.detail.value,
							})}
						>
							{
								sortedPresetIds.map(id =>
									<IonSelectOption
										value={id}
										key={id}
									>
										<IonIcon icon={presets[id].icon} />
										{presets[id].name}
									</IonSelectOption>
								)
							}
						</IonSelect>
					</IonItem>

					<IonItem lines="none">
						<IonToggle
							justify="space-between"
							checked={!!settings.showNetStats}
							onIonChange={ () => changeValue({ id: 'showNetStats', value: !settings.showNetStats })}
						>
							Show NetStats
						</IonToggle>
					</IonItem>

					<IonItem disabled={!settings.showNetStats || !userHasPro}>
						<IonToggle
							justify="space-between"
							checked={!!settings.showNetStats && !!settings.showNetStatsCombos && userHasPro}
							onIonChange={ () => changeValue({ id: 'showNetStatsCombos', value: !settings.showNetStatsCombos })}
						>
							... and Pair/Trio Stats
							{
								!userHasPro && (
									<span className="pro">PRO</span>
								)
							}
						</IonToggle>
					</IonItem>

					<IonItem lines="none">
						<p className="settings-sliders-explanation">
							Sliders set importance, and are
							used when you auto-generate rosters with the
							{' '}
							<span className="inline-flex-block sample-button">
								<IonButton
									className="button-go button-react-icon"
								>
									<FaRandom />
								</IonButton>
								Go button.
							</span>

						</p>
					</IonItem>

					<SliderWarning
						values={settings}
					/>

					{
						sliderSettings.map(setting => {

							return (
								<IonItem
									key={setting.id}
									className="setting"
									lines="none"
								>
									<IonRange
										pin={true}
										pinFormatter={value => `${value}`}
										// defaultValue={initialSliderValues[setting.id]}
										value={settings[setting.id]}
										min={0}
										max={10}
										onIonChange={(e: CustomEvent) => {
											const { value } = e.detail
											changeValue({
												id: setting.id,
												value: typeof value === 'number' ? value : value.lower
											})
										}}
									>
										<div
											slot="label"
											className="setting-desc ion-text-wrap"
											onClick={() => setTooltip(setting.id)}
										>
											{setting.desc}
											<IonIcon
												icon={informationCircleOutline}
												className="tooltip-icon"
											/>
										</div>
									</IonRange>
								</IonItem>
							)
						})
					}

					<Tooltip
						tooltip={tooltip}
						onDismiss={() => setTooltip(undefined)}
					/>
				</section>
			</IonContent>

			<Header
				allowTeamChange={true}
			/>

		</IonPage>
	)
}

type TooltipProps = {
	tooltip: keyof typeof tooltips | undefined
	onDismiss: () => void
}

const Tooltip = (props: TooltipProps) => {

    const { tooltip, onDismiss } = props

	const t = tooltip ? tooltips[tooltip] : undefined
	const desc = t ? t.desc : undefined
	const tip = t ? t.tip : undefined

    return (
        <IonAlert
            isOpen={!!tooltip}
            onDidDismiss={onDismiss}
            header={desc}
            message={tip}
            buttons={[ 'OK' ]}
        />
    )
}

type SliderWarningProps = {
	values: Settings
}

const SliderWarning = (props: SliderWarningProps) => {

	const { values } = props

	const numHighValues = Object.values(values).filter(n => typeof n === 'number' && n >= 9).length

	if (numHighValues < 2)
		return null

	return (
		<FadeText
			isBlock={true}
			contentKey="slider-warning"
		>
			<IonItem lines="none">
				<p className="settings-sliders-warning">
					<IonIcon icon={warning} slot="start" />
					Multiple sliders are set to very high values. It's best to move a slider to
					the far right only if you want AutoRoster to prioritize that far above everything else.
				</p>
			</IonItem>
		</FadeText>
	)
}

export default Settings
