import React from 'react'
import {
    IonPage,
    IonContent,
} from '@ionic/react'
import List from './List'
import Header from './Header'
import {
    useMyPlayers,
} from './data'


const Players = () => {

    const data = useMyPlayers()

	const type: ItemTypeDefinition<Player> = {
		name: 'players',
		unit: 'player',
		fields: [
			'name',
		],
		linkToItem: true,
		requireValidSeason: true,
		quickAdding: true,
		sortFunction: (a, b) => a.name ? a.name.localeCompare(b.name) : -1,
        isInvalid: {
            name: ({ value, items }) => {
                if (items?.filter(obj => obj.name === value).length) {
                    return 'Name must be unique'
                }
                return false
            },
        },
	}

	return (
        <IonPage>
            <IonContent>
				<List<Player>
                    data={data}
                    type={type}
                />
            </IonContent>
            <Header allowTeamChange={true} />
        </IonPage>
	)
}

export default Players
