import React, { useState, useContext } from 'react'
import {
    useIonAlert,
    IonItem,
	IonLabel,
    IonToggle,
} from '@ionic/react'
import { Purchases } from '@revenuecat/purchases-capacitor'
import { ProfileContext } from './ProfileContext'
import { ModalContext } from './ModalContext'
import { PlanName } from './Plan'
import LoadingSpinner from './LoadingSpinner'
import LoadingSpinnerButton from './LoadingSpinnerButton'
import Icon from './Icon'
import {
	useClub,
	useSetHelpTab,
	useSetStoreCustomerInfo,
    useStoreOfferings,
	useUser,
} from './data'
import './plan.css'

type NativePurchasingProps = {
	isClub?: boolean
}

const NativePurchasing = (props: NativePurchasingProps) => {

	const { isClub } = props

    const [ busy, setBusy ] = useState(false)

    const [ selected, setSelected ] = useState('monthly')

    const onDismiss = useContext(ModalContext)

    const [ presentAlert ] = useIonAlert()

	const user = useUser()
	const club = useClub()

    const offerings = useStoreOfferings()

	const id = isClub ? club?.id : user?.uid
	const setStoreCustomerInfo = useSetStoreCustomerInfo(id || '')

    console.log('offerings', offerings)

	//
	// These might take a while to load.
	// TODO: They might not load at all, either, so show a loading screen.
	//
	if (!offerings.current) {
		console.log("No offerings! Wait for them to load...")
		return <LoadingSpinner />
	}

	const { availablePackages } = offerings.current

    const onToggle = () => setSelected(selected === 'monthly' ? 'annual' : 'monthly')

	let packs
	if (isClub) {
		packs = {
			monthly: availablePackages.filter(obj => obj.identifier === 'ClubMonthly')[0],
			annual: availablePackages.filter(obj => obj.identifier === 'ClubAnnual')[0],
		}
	} else {
		packs = {
			monthly: availablePackages.filter(obj => obj.identifier === '$rc_monthly')[0],
			annual: availablePackages.filter(obj => obj.identifier === '$rc_annual')[0],
		}
	}

	const aPackage = packs[selected]

	if (!aPackage) {
		console.error("No aPackage?!", aPackage, 'packs', packs, 'selected', selected, 'offerings', offerings)
		return (
			<div>
				<h1>
					Whoops!
				</h1>
				<p>
					Couldn't find store package.
				</p>
			</div>
		)
	}

	if (!user || !id) {
		return (
			<div>
				<p>
					You need to be logged in to subscribe.
				</p>
			</div>
		)
	}

	if (isClub && !club) {
		return (
			<div>
				<p>
					Waiting for club...
				</p>
			</div>
		)
	}

    const onClick = async () => {

		console.log('NativePurchasing - onClick')

        setBusy(true)

        try {

			if (isClub) {
				console.log('attempting to log out user & log in club via CapacitorPurchases...')

				try {
					await Purchases.logOut()
				} catch(err) {
					console.log("Already logged out.", err)
				}

				await Purchases.logIn({
					appUserID: club!.id,
				})

				console.log('Logged in club.')
			}

			console.log('Now try purchasing this pack:', aPackage)

			const result = await Purchases.purchasePackage({
				aPackage,
			})

			console.log('******** My Purchase result!', result)

			if (result) {
				// Name changed but I'm not sure it's consistent...
				const purchaserInfo = result.customerInfo
				console.log('New purchaserInfo', purchaserInfo)

				//
				// TODO: The result we get below is DIFFERENT to the
				// web API, so we need to do some conversion.
				//
				// Might be better/possible just to flag that we need
				// to query the REST API instead? Although even so
				// we probably want to immediately signal that a purchase
				// succeeded.
				//
				const convertedPurchaserInfo: StoreCustomerInfo = {
					...purchaserInfo,
					first_seen: purchaserInfo.firstSeen,
					last_seen: purchaserInfo.requestDate,
					original_app_user_id: purchaserInfo.originalAppUserId,
					management_url: purchaserInfo.managementURL,
					original_application_version: purchaserInfo.originalApplicationVersion,
					original_purchase_date: purchaserInfo.originalPurchaseDate,
					active_subscriptions: purchaserInfo.activeSubscriptions,
					non_subscriptions: purchaserInfo.nonSubscriptionTransactions,
					entitlements: {
						pro: {
							expires_date: purchaserInfo.entitlements.active.pro.expirationDate,
							product_identifier: purchaserInfo.entitlements.active.pro.identifier,
							purchase_date: purchaserInfo.entitlements.active.pro.latestPurchaseDate,
						}
					},
				}

				setStoreCustomerInfo(convertedPurchaserInfo)

				if (typeof purchaserInfo.entitlements.active.pro !== "undefined") {
					// Unlock that great "pro" content
					console.log('Purchase success!')
					presentAlert({
						header: 'Subscription Successful!',
						message: `Thanks for supporting AutoRoster!`,
						buttons: [
							{
								text: 'OK',
								role: 'confirm',
								handler: onDismiss,
							},
						],
					})
				} else {
					console.log("That didn't really work, I think?")
				}
			}

			if (isClub) {
				console.log('Logging user back in, just in case they now want to make personal purchases.')
				try {
					await Purchases.logOut()
					await Purchases.logIn({
						appUserID: user.uid,
					})
				} catch(err) {
					console.log("That didn't work", err)
				}
			}

        } catch (err) {
            console.error("Hey maxy an error ", err)
        }

        console.log('Done purchasing.')

        setBusy(false)
    }

    const annualSavingPerc = packs.annual && Math.floor(100 * (1 - packs.annual.product.price / (12 * packs.monthly.product.price)))

    const period = selected === 'monthly' ? 'month' : 'year'

    return (
        <div className="native-purchasing">
            <h1>
                <Icon />
                <PlanName isClub={isClub} />
            </h1>
            <p>
                {aPackage.product.description}
            </p>
			{
				isClub && club && (
					<p>
						{club.name}
					</p>
				)
			}
            <IonItem>
                <IonLabel
                    className="price"
                >
					{aPackage.product.priceString} / {period}
                </IonLabel>
            </IonItem>
			{
				packs.annual && (
					<IonItem
						lines="none"
					>
						<IonToggle
							checked={selected === 'annual'}
							onIonChange={onToggle}
							labelPlacement="end"
							justify="start"
							text-wrap
						>
							Save {annualSavingPerc}% with annual subscription
						</IonToggle>
					</IonItem>
				)
			}
			<LoadingSpinnerButton
				busy={busy}
				onClick={onClick}
				expand="block"
			>
				Subscribe for {aPackage.product.priceString} / {period}
			</LoadingSpinnerButton>

			<TermsAndConditionsLink />
		</div>
	)
}

export const TermsAndConditionsLink = () => {

	const setHelpTab = useSetHelpTab()

	const closeSideMenu = useContext(ProfileContext)
	const onDismiss = useContext(ModalContext)

	const onClick = (tab: string) => {
		console.log('T&Cs/Privacy click to', tab)
		onDismiss && onDismiss()
		closeSideMenu && closeSideMenu()
		setHelpTab(tab)
	}

	return (
		<div className="tcs-link">
			<p>
				<a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/">Terms & Conditions</a>
				{' '}
				and
				{' '}
				<a href="/help#privacy" onClick={() => onClick("privacy")}>Privacy Policy</a>
			</p>
			<p>
				Your subscription will be charged to your account at confirmation of purchase, and will automatically renew (at the duration selected) unless auto-renew is turned off at least 24 hours before the end of the current period.
			</p>
		</div>
	)
}

export default NativePurchasing
