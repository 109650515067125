import React from 'react'
import {
    IonIcon,
} from '@ionic/react'
import { PoseGroup } from 'react-pose'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import GridCell from './GridCell'
import PlayerName from './PlayerName'
import {
    useReady,
	useSetAmDragging,
} from './data'
import { FixedItem, DraggableItemPlayer } from './definitionsPoses'
import { RxDragHandleDots2 } from 'react-icons/rx'
import { TiTimes } from 'react-icons/ti'
import { ellipse } from 'ionicons/icons'

type GridPlayersProps = {
	players: Array<PlayerId>
	data: AllRosterStats | undefined | null
	editMode?: boolean
	onDrop?: (e: React.DragEvent, payload: OnDropObject) => void
	available: { [playerId: PlayerId]: boolean }
	toggleAvailable: (playerId: PlayerId) => void
}

const GridPlayers = (props: GridPlayersProps) => {
	const { players, data, editMode, onDrop, available } = props

    const ready = useReady()

	const setAmDragging = useSetAmDragging()

    const allowDrag = ready && editMode
	const ItemPlayer = allowDrag ? DraggableItemPlayer : FixedItem

	const onDragStart = () => {
		setAmDragging(true)
		Haptics.impact({ style: ImpactStyle.Light })
	}

	//
	// Warn the user when a player is set to "available" but has no rostered positions.
	//
	const playersWithNoPositions =
		ready &&
		editMode &&
		data &&
		Object.entries(data).filter(([ _, playerRosterStats ]) =>
									playerRosterStats.filter(obj => !obj || !obj.playing || obj.position !== '-').length === 0
								   ).map(([ playerId, _ ]) => playerId)

    return (
        <div className="column column-players">
			<FixedItem className="fixed-element" key="top-left-void">
				<GridCell type="void" content="" />
			</FixedItem>
            <PoseGroup>
                {
                    players.map((player, index) =>
                        <ItemPlayer
                            key={allowDrag ? player : index}
							className={`${allowDrag ? "posed-element" : "fixed-element"} ${playersWithNoPositions && playersWithNoPositions.includes(player) ? 'animated-pulse' : ''}`}
							onDragStart={onDragStart}
                            onDragEnd={e => onDrop && onDrop(e, { player: player }) }
                            isAvailable={available[player]}
                        >
                            <CellPlayer
								allowDrag={allowDrag}
                                playerId={player}
                                isAvailable={available[player]}
                                toggleAvailable={props.toggleAvailable}
                            />
                        </ItemPlayer>
                    )
                }
            </PoseGroup>
        </div>
    )
}

type CellPlayerProps = {
	playerId: PlayerId
	isAvailable?: boolean
	allowDrag?: boolean
	toggleAvailable: (playerId: PlayerId) => void
}

//
// Don't re-render cells unless the contents change
//
const CellPlayer = React.memo((props: CellPlayerProps) => {

	const { playerId, isAvailable, allowDrag } = props

	const playerAvailable = isAvailable ? (
        <IonIcon icon={ellipse} className="tick" />
	) : (
		<TiTimes className="cross" />
	)

	const content = (
		<>
			{allowDrag && <RxDragHandleDots2 className="drag-handle" />}
			<PlayerName playerId={playerId} />
		</>
	)

	const onClickIcon = (_i: I, e: React.MouseEvent) => {
		e.preventDefault()
		props.toggleAvailable(playerId)
	}

	return (
		<GridCell
			type="player"
            content={content}
			icons={[ playerAvailable ]}
			onClickIcon={onClickIcon}
			isAvailable={isAvailable}
			i={playerId}
		/>
	)
})

CellPlayer.displayName = 'CellPlayer'

export default GridPlayers
