//
// Given 'stats' and 'games', generate NetStats for each player in each position.
//
import { addDoc, collection, deleteDoc, deleteField, doc, updateDoc } from 'firebase/firestore'
import { CallbackInterface, useRecoilCallback } from 'recoil'

import {
  ALL_PREFS,
  LOCAL_STORAGE_KEY_SETTINGS,
  MIN_NUM_QTRS_PER_COMBO,
  NORMALIZE_MIN_QTRS,
  NULL_NETSTAT_VALUE,
  PREFS_PREFER,
  PRESET_CUSTOM,
  dbids,
  defaultPreset,
  presets,
  queryFieldPositions,
} from './definitions'
import { db } from './firebase'
import { deepCopy, fetchJson, loadFromLocalStorage, parseData } from './util'
import {
  myGames,
  myTeam,
  newSeasonConnector,
  newTeamId,
  section,
  queryDbid,
} from './data'
import {isValidResult} from "./util"

type Items = {
	games: Games | undefined
	players: Players | undefined
	rosters: Rosters
	numPeriods: NumPeriods
	gameFormat: GameFormat
}

export function generateAllNetStats(items: Items, data?: AllPlayerStats): AllNetStats {

	// console.log('generateAllNetStats()', items, data)

	const { games, players, numPeriods, gameFormat } = items

	const fieldPositions = queryFieldPositions(gameFormat)

	const allNetStats: AllNetStats = { }

	if (games && players) {

		const qtrGoalDiffs = calculateQtrGoalDiffs({
			games,
			numPeriods,
		})

		// console.log('qtr goal diffs', qtrGoalDiffs)

		if (Object.keys(qtrGoalDiffs).length) {

			const stats = data || parseData(items)
			// console.log('My parseData result is', stats)

			Object.keys(players).forEach(playerId => {

				const netStats = new Array(fieldPositions.length + 1).fill(null)
				const posCount = new Array(fieldPositions.length + 1).fill(0)

				const { games: myGames } = stats[playerId]

				// console.log(playerId, 'played', myGames)

				Object.keys(qtrGoalDiffs).forEach(gameId => {
					if (myGames[gameId]) {
						const myPositions = myGames[gameId]
						myPositions.forEach((posArr, qtr) => {
							posArr.forEach(pos => {
								const posIndex = pos ?? fieldPositions.length
								const gameTime = 1 / posArr.length 	// Usually 1, but 0.5 for subs

								const diff = qtrGoalDiffs[gameId][qtr]

								if (diff !== null) {
									const currentNetStat = netStats[posIndex] || 0
									netStats[posIndex] = currentNetStat + (diff * gameTime)
								}

								posCount[posIndex] += gameTime
								// console.log(playerId, "game", gameId, "qtr", qtr, "posIndex", posIndex, "goaldiff", diff, '*', gameTime, '=', (diff || 0) * gameTime)
							})
						})
					}
				})

				//
				// For gameFormats where there are duplicate positions (e.g. 2 x "A", 2 x "D"),
				// we merge the posCount and netStats for those positions.
				//
				fieldPositions.forEach((pos, posIndex) => {
					if (pos === fieldPositions[posIndex + 1]) {
						posCount[posIndex + 1] += posCount[posIndex]
						netStats[posIndex + 1] += netStats[posIndex]
						posCount[posIndex] = 0
						netStats[posIndex] = null
					}
				})

				allNetStats[playerId] = {
					raw: netStats,
					posCount,
					normalized: [ ],
					adjusted: [ ],
				}

				allNetStats[playerId].normalized = netStats.map((v, index) => {
					let newValue = v
					if (v !== null) {
						newValue /= (posCount[index] || 1)
						if (posCount[index] < NORMALIZE_MIN_QTRS) {
							//
							// We scale netStat scores way down when there's a very small sample size.
							//
							newValue *= 0.80 / Math.pow(NORMALIZE_MIN_QTRS - posCount[index], 1.5)
						}
					}
					return newValue
				})

				//
				// 'adjusted' is what generateRoster uses -- players who have never played a position have
				// a value of NULL_NETSTAT_VALUE (e.g. -1.25), and players with very few quarters share
				// some of that penalty.
				//
				// Because the thing is you don't want to get carried away with a player who has decent stats from 2 qtrs.
				// If she's -0.3 that's not great but a lot better than NULL_NETSTAT_VALUE.
				//
				const allNetStatAbsValues = [ ...(Object.values(allNetStats).map(obj => obj.normalized.map(n => Math.abs(n))).flat()) ]
				const max = Math.max(...allNetStatAbsValues)
				const scale = 1.50 / (max || 1)
				// console.log('allNetStatAbsValues', max, scale, allNetStatAbsValues)

				allNetStats[playerId].adjusted = allNetStats[playerId].normalized.map((v, index) => {
					let newValue = v
					if (v === null) {
						newValue = NULL_NETSTAT_VALUE
					} else if (posCount[index] * 1.5 < NORMALIZE_MIN_QTRS) {
						//
						// We should try to avoid rostering players in roles they've never played before, but we also
						// don't want a player to gain +NULL_NETSTAT_VALUE just from playing a single qtr. So let's
						// remove the NULL_NETSTAT_VALUE at 1.5X, so that you no longer have any penalty after
						// playing 4 qtrs (assuming NORMALIZE_MIN_QTRS == 6).
						//
						const adj = NULL_NETSTAT_VALUE * ((NORMALIZE_MIN_QTRS - 1.5 * posCount[index]) / NORMALIZE_MIN_QTRS)
						newValue += adj
					}
					newValue *= scale
					return newValue
				})

				// console.log(playerId, 'final netstats', allNetStats[playerId], 'from raw stats', netStats, 'with posCount', posCount)
			})
		}

	}

	// console.log('allNetStats', allNetStats)

	return allNetStats
}

type calculateQtrGoalDiffsProps = {
	games: Games
	numPeriods: number
	raw?: boolean
}

//
// By default, returns normalized qtr goal diffs.
//
export function calculateQtrGoalDiffs(props: calculateQtrGoalDiffsProps) {

	const { games, raw, numPeriods } = props

	const qtrGoalDiffs: {
		[key: GameId]: Array<number|null>
	} = { }

    if (games) {

		const qtrs = [ ...Array(numPeriods).keys() ]

        Object.keys(games).forEach(gameId => {

			//
			// Ensure that we use validated 'results', so we don't get
			// confused if the user enters scores, then changes the season's
			// numPeriods.
			//
			const result = formatResult(games[gameId].result, numPeriods)

            //
            // True if at least 1 qtr (other than the last) has non-null scores.
            //
            // This may mean that only some do.
            //
            const haveQtrByQtrScores = qtrs.some(qtr => qtr < (numPeriods - 1) && result.me[qtr] !== null && result.me[qtr] !== undefined && result.them[qtr] !== null && result.them[qtr] !== undefined)

			// console.log('goal diffs?', haveQtrByQtrScores, 'with numPeriods', numPeriods, 'for result', result)

            if (haveQtrByQtrScores) {

                const qtrGoalsMe = result.me.map((goals, qtr) => {
					const g = goals ?? 0
                    if (!qtr)
                        return g
					return g - (result.me[qtr - 1] || 0)
                })

                const qtrGoalsThem = result.them.map((goals, qtr) => {
					const g = goals ?? 0
                    if (!qtr)
                        return g
					return g - (result.them[qtr - 1] || 0)
                })

                qtrGoalDiffs[gameId] = qtrs.map(qtr => {

                    let diff = qtrGoalsMe[qtr] - qtrGoalsThem[qtr]

                    // Normalize!
                    if (!raw) {
						const finalPeriod = numPeriods - 1
                        const avgDiffPerQtr = ((result.me[finalPeriod] || 0) - (result.them[finalPeriod] || 0)) / numPeriods
						diff -= avgDiffPerQtr
					}

                    return diff
                })
            }
        })
    }

    // console.log('qtrGoalDiffs', qtrGoalDiffs)

    return qtrGoalDiffs
}

export function calculateRosterNetStats(data: AllRosterStats, numPeriods: number): RosterNetStats {

	// console.log('calculateRosterNetStats', data)

	const qtrs = new Array(numPeriods).fill(0)

	Object.keys(data).forEach(player => {
		data[player].forEach((obj, qtr) => {
			if (obj) {
				const { netStat, pair, trio, playing } = obj
				if (playing) {

					if (netStat) {
						qtrs[qtr] += netStat
					}

					if (pair) {
						qtrs[qtr] += pair
					}

					if (trio) {
						qtrs[qtr] += trio
					}
				}
			}
		})
	})

	return {
		qtrs,
		total: qtrs.reduce((total, score) => total + score, 0),
	}
}

type findPairingsProps = {
	games: Games
	onCourtRosters: OnCourtRosters
	numPeriods: NumPeriods
	gameFormat: GameFormat
	findTrios?: boolean
	userHasPro?: boolean
}

export function findPairings(props: findPairingsProps): Array<Pair> {

    const { games, onCourtRosters, numPeriods, gameFormat, findTrios, userHasPro } = props

    const qtrGoalDiffs = calculateQtrGoalDiffs({
		games,
		numPeriods,
	})

	const fieldPositions = queryFieldPositions(gameFormat)

    // console.log('findPairings()', { ...props, qtrGoalDiffs })

	const allPairings: {
		[key: string]: {
			diff: number
			n: number
			games: Array<GamePair>
			numPeriods: number
		}
	} = { }

    Object.keys(qtrGoalDiffs).forEach(gameId => {
        qtrGoalDiffs[gameId].forEach((diff, qtr) => {

            if (diff === null) {
                return
            }

			if (!onCourtRosters[gameId]) {
				return
			}

            const playersPerQtr = onCourtRosters[gameId].length / numPeriods
            const qtrRoster = onCourtRosters[gameId].slice(qtr * playersPerQtr, (qtr + 1) * playersPerQtr)
            // console.log('Roster for game', gameId, 'qtr', qtr, qtrRoster)
            qtrRoster.forEach((playerArr1, posIndex1) => {
				playerArr1.forEach((playerId1, subIndex1) => {
					qtrRoster.forEach((playerArr2, posIndex2) => {
						playerArr2.forEach((playerId2, subIndex2) => {
							const isValidPair = (
								playerId1 !== null &&
								playerId2 !== null &&
								posIndex1 < posIndex2 &&
								isAdjacentPosition(posIndex1, posIndex2, gameFormat) &&
								posIndex1 < fieldPositions.length &&
								posIndex2 < fieldPositions.length &&
								(playerArr1.length === 1 || playerArr2.length === 1 || subIndex1 === subIndex2)
							)
							if (isValidPair) {
								const pairId = `${playerId1}__${posIndex1}__${playerId2}__${posIndex2}`
								const pairIds = [ ]

								// 'amt' is the percentage of time the player was on-court during this quarter.
								// Usually 1, but 0.5 for subs.
								const amt = (playerArr1.length === 1 && playerArr2.length === 1) ? 1 : 0.5

								if (!findTrios) {
									pairIds.push(pairId)
								} else {
									qtrRoster.forEach((playerId3, posIndex3) => {
										const isValidTrio = (
											playerId3 !== null &&
											posIndex3 > posIndex1 &&
											posIndex3 > posIndex2 &&
											(
												isAdjacentPosition(posIndex3, posIndex2, gameFormat) ||
												isAdjacentPosition(posIndex3, posIndex1, gameFormat)
											) &&
											posIndex3 < fieldPositions.length
										)
										if (isValidTrio) {
											pairIds.push(`${pairId}__${playerId3}__${posIndex3}`)
										}
									})
								}

								pairIds.forEach(pairId => {

									const game: GamePair = {
										...games[gameId],
										qtr,
										diff: diff * amt,
									}

									// console.log('findPairings pairId', pairId, 'diff', diff, 'amt', amt)

									if (!allPairings[pairId]) {
										allPairings[pairId] = {
											diff: diff * amt,
											n: amt,
											games: [ game ],
											numPeriods,
										}
									} else {
										allPairings[pairId].n += amt
										allPairings[pairId].diff += diff * amt
										allPairings[pairId].games.push(game)
									}
								})
							}
						})
					})
				})
			})
		})
	})

	const sortedPairings: Array<Pair> = Object.keys(allPairings).sort((a, b) => allPairings[b].diff - allPairings[a].diff).filter(id => allPairings[id].n >= MIN_NUM_QTRS_PER_COMBO).map((id, i) => {
		const { n, diff, games } = allPairings[id]
		const [ playerId1, positionIndex1, playerId2, positionIndex2, playerId3, positionIndex3 ] = id.split('__')
		return {
			id,
			i,
			n,
			diff,
			games,
			avg: diff / n,
			playerId1,
			positionIndex1: Number(positionIndex1),
			playerId2,
			positionIndex2: Number(positionIndex2),
			playerId3,
			positionIndex3: Number(positionIndex3),
			numPeriods,
		}
	})

	//
	// For non-Pro users, only show the first (best) pair/trio.
	//
	if (!userHasPro) {
		return sortedPairings.map((obj, index) => {
			if (!index) {
				return obj
			}
			return {
				...obj,
				dummy: true,
				games: [ ],
			}
		})
	}

	// console.log('findPairings() result', sortedPairings)

	return sortedPairings
}

function isAdjacentPosition(posIndex1: number, posIndex2: number, gameFormat: GameFormat) {
	switch (gameFormat) {
		case 5:
			// 5-a-side: C is adjacent to everyone
			return posIndex1 === 2 || posIndex2 === 2 || Math.abs(posIndex2 - posIndex1) === 1
		case 6:
			// 6-a-side: C is adjacent to everyone
			return posIndex1 === 2 || posIndex2 === 2 || posIndex1 === 3 || posIndex2 === 3 || Math.abs(posIndex2 - posIndex1) === 1
		default:
			// For Fast5 and 7-a-side, adjacent is 1 difference on posIndex
			return Math.abs(posIndex2 - posIndex1) === 1
	}
}

//
// format 'result' field
//
export function formatResult(result: Result | undefined, numPeriods = 4): Result {

	//
	// Ensure basic format is correct
	//
	if (!result || typeof result !== 'object' || !result.me || !result.them || result.me.length !== numPeriods || result.them.length !== numPeriods) {
		return {
			me:		new Array(numPeriods).fill(null),
			them:	new Array(numPeriods).fill(null),
		}
	}

	const r = deepCopy(result)

	const sides = [ 'me', 'them' ] as const
	sides.forEach(side => {
		[ ...Array(numPeriods).keys() ].forEach(qtrKey => {
			const qtr = Number(qtrKey)

			//
			// @ts-ignore: Suppress TS2367
			// I want to check for bad values from previous versions
			//
			if (r[side][qtr] === '') {
				r[side][qtr] = null
			} else if (r[side][qtr] !== null) {
				r[side][qtr] = Number(r[side][qtr])
			}
		})
	})

	return r
}

//
// Check whether this result value makes sense.
//
type validateResultProps = {
	result: Result
	side: Side
	qtr: number
}

export function validateResult(props: validateResultProps): boolean {
	const { result, side, qtr } = props

    // console.log('validateResult', result, side, qtr)

	if (!side || !result[side]) {
		// Not sure how this happens, but it happens
		return false
	}

	const value = result[side][qtr]
	const otherSide = side === 'me' ? 'them' : 'me'

	// console.log('validateResult', value, side, qtr, result)

	if (value === undefined) {
		// Must be null, not undefined or empty -- this is
		// to catch results like [ 1, 2, empty, 4 ]
		return false
	}

	if (value === null) {

        // Both sides must have value
		if (result[otherSide][qtr] !== null) {
			return false
		}

		// Can't be null if previous qtr is not null
		if (qtr > 0 && result[side][qtr - 1] !== null) {
			return false
		}

		return true
	}

    // Can't be less than 0
    if (value < 0) {
        return false
    }

    // Can't be more than 999
    if (value > 999) {
        return false
    }

	// Must be higher than prev values
	for (let i = qtr - 1; i >= 0; i -= 1) {
		if (value < Number(result[side][i])) {
			return false
		}
	}

	return true
}

//
// Load any saved 'settings' & validate, or else set to default
// values.
//
type DefaultKeys = keyof typeof presets[typeof defaultPreset]['defaults']

export function initializeSettings() {
    //
    // First build default values. We might add new settings in the future,
    // so do this even if we're about to overwrite most/all of them with
    // saved values from localStorage.
    //
    const savedSettings: Settings = loadFromLocalStorage(LOCAL_STORAGE_KEY_SETTINGS) || { } as Settings

	// Get the keys as an array of the specific types
	const validKeys = Object.keys(presets[defaultPreset].defaults) as DefaultKeys[]

	const initialValues: Settings = {
		preset: defaultPreset,
		showNetStats: true,
		showNetStatsCombos: true,
		preferenceType: PREFS_PREFER,
	}

	validKeys.forEach(key => {
		const savedValue = savedSettings[key]
        initialValues[key] = isValidSettingNumber(savedValue) ? savedValue : 5
    })

    // Load the player's preset if valid
	const savedPreset = savedSettings.preset
	if (savedPreset && savedPreset in presets) {
		initialValues.preset = savedPreset
		if (savedPreset !== PRESET_CUSTOM) {
			Object.entries(presets[savedPreset].defaults).forEach(([ key, value ]) => {
				initialValues[key as DefaultKeys] = value
			})
		}
	}

    // 'showNetStats' is true unless the player has deliberately turned it off
    if (savedSettings.showNetStats !== undefined && !savedSettings.showNetStats) {
        initialValues.showNetStats = false
    }

	// 'showNetStatsCombos' is true unless the player has deliberately turned it off
	if (savedSettings.showNetStatsCombos !== undefined && !savedSettings.showNetStatsCombos) {
		initialValues.showNetStatsCombos = false
	}

	// 'preferenceType'
	const savedPreferenceType = savedSettings.preferenceType
	if (savedPreferenceType && ALL_PREFS.includes(savedPreferenceType)) {
		initialValues.preferenceType = savedPreferenceType
	}

    return initialValues
}

const isValidSettingNumber = (n: unknown) => typeof n === 'number' && Number.isInteger(n) && n >= 0 && n <= 10

//
// Used by Games.jsx
//
export function scrollGamesTab() {

    const [ listElement ] = document.getElementsByClassName('list-games')

    if (listElement) {
        const presentGames = listElement.getElementsByClassName('date-present')
        const futureGames = listElement.getElementsByClassName('date-future')
        if (presentGames.length || futureGames.length) {
            const arr = listElement.getElementsByClassName('date-past')
            const el = arr[arr.length - 1]
            const target = el?.closest('.game')
            if (target) {
                target.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }
}

async function enableSync(args: CallbackInterface, connectorTeam: ConnectorTeam) {

	const { snapshot, set } = args

	console.log('enableSync', connectorTeam)

	const { id, teamName, competitionId, compName, divId, divName, orgName } = connectorTeam

	const connector: Connector = {
		type: 'NetballConnect',
		id: 0,
		params: {
			id,
			competitionId,
			compName,
			divId,
			divName,
			orgName,
			teamName,
		},
	}

	console.log('connector', connector)

	const newTId = await snapshot.getPromise(newTeamId)
	if (newTId) {
		set(newSeasonConnector, connector)
	} else {
		const { team, season } = await snapshot.getPromise(section)

		if (team && season) {
			const dbid = `/teams/${team}/seasons`

			const data = {
				connector,
			}

			console.log("Saving this data", data, "for id", season)
			await updateDoc(doc(db, dbid, season), data)
			console.log("Doc updated", dbid)
		}
	}
}

export const useEnableSync = () => useRecoilCallback(args => (connectorTeam: ConnectorTeam) => enableSync(args, connectorTeam))

async function disableSync(args: CallbackInterface) {

	const { snapshot, set } = args

	const newTId = await snapshot.getPromise(newTeamId)
	if (newTId) {
		console.log('set newSeasonConnector', null)
		set(newSeasonConnector, null)
	} else {
		const { team, season } = await snapshot.getPromise(section)

		if (team && season) {
			console.log('disableSync', season)

			const dbid = `/teams/${team}/seasons`

			await updateDoc(doc(db, dbid, season), {
				connector: deleteField(),
			})

			console.log("Doc updated", dbid)
		}
	}
}

export const useDisableSync = () => useRecoilCallback(args => () => disableSync(args))

async function syncFixture(args: CallbackInterface, connector: Connector, numPeriods: number): Promise<Changes | null> {

	console.log('syncFixture()', connector)

	const { type, params } = connector

	const { snapshot } = args

	if (type === 'NetballConnect') {

		const team = await snapshot.getPromise(myTeam)
		const games = await snapshot.getPromise(myGames)

		if (!team || !games)
			return null

		const { id, competitionId } = params

		const url = `https://api-netball.squadi.com/livescores/round/matches?competitionId=${competitionId}&teamIds=[${id}]&ignoreStatuses=[1]`

		console.log('FETCH', url)

		let matches
		try {
			const data = await fetchJson<NetballConnectMatches>(url)
			// console.log('DATA', data)
			matches = data.rounds.map(obj => obj.matches).flat()
			// console.log('MATCHES', matches)
		} catch (err) {
			console.error("Failed to fetch", url, err)
			return null
		}

		//
		// Empty data: might be bogus
		//
		if (!matches?.length) {
			console.log("No matches in fetched data; can't sync.")
			return null
		}

		const newGames: Array<{
			id: number
			date: number
			opponent: string
			round: string
			venue: string
			myGameId?: GameId
			meScore?: number
			themScore?: number
			amTeam1?: boolean
			result?: Result
		}> = matches.map(match => ({
			id: match.id,
			date: (new Date(match.startTime)).getTime(),
			opponent: String(match.team1Id === id ? match.team2.name : match.team1.name).trim(),
			round: String(match.round.name).replace('Round ', ''),
			venue: `Court ${match.venueCourt.courtNumber}`,
			meScore: match.team1Id === id ? match.team1Score : match.team2Score,
			themScore: match.team1Id === id ? match.team2Score : match.team1Score,
			amTeam1: match.team1Id === id,
		})).filter(match => match.opponent !== 'Bye')

		const myGameIds: {
			[key: string]: boolean
		} = { }

		const arr = [ 1, 2 ] as const
		arr.forEach(pass => {

			newGames.forEach(match => {
				const gameId = Object.keys(games).find(gameId => {
					// Don't allow the same game to be paired with 2 different matches
					if (myGameIds[gameId])
						return false
					const game = games[gameId]
					if (isSameRound(match.round, game.round))
						return true
					if (match.date === game.date)
						return true
					// 2nd pass - any unclaimed games within 4 hours of original time are OK
					if (pass > 1 && Math.abs(match.date - game.date) < 4 * 3600 * 1000)
						return true
					return false
				})
				if (gameId && !(gameId in myGameIds)) {
					match.myGameId = gameId
					myGameIds[gameId] = true
				}
			})

		})

		// console.log('newGames', newGames)

		//
		// Fetch quarter-by-quarter scores
		//
		await Promise.all(newGames.map(async (game, index) => {

			// Skip future games
			if (game.date > Date.now())
				return

			const matchId = game.id
			const competitionId = params.competitionId

			const url = `https://api-netball.squadi.com/livescores/matches/periodScores?matchId=${matchId}&source=web&competitionId=${competitionId}&sportRefId=1&requestTimeStamp=${(new Date()).toISOString()}`

			try {
				const periodScoresData = await fetchJson<NetballConnectPeriodScores>(url)

				// console.log('periodScoresData', periodScoresData, 'for game', game, 'at url', url)

				if (!periodScoresData?.length) {
					console.error("No periodScoresData for game - might be a forfeit", game)
					return
				}

				if (periodScoresData[0].matchId !== matchId) {
					throw new Error("Mismatched matchId " + matchId)
				}

				const result: Result = {
					me: [ ],
					them: [ ],
				}
				periodScoresData.forEach(obj => {
					result.me.push(obj[game.amTeam1 ? 'team1Score' : 'team2Score'])
					result.them.push(obj[game.amTeam1 ? 'team2Score' : 'team1Score'])
				})

				// Sometimes the qtr-by-qtrs are missing the final score
				if (game.meScore) {
					result.me[numPeriods - 1] = game.meScore
				}
				if (game.themScore) {
					result.them[numPeriods - 1] = game.themScore
				}

				if (isValidResult(result, numPeriods)) {
					newGames[index].result = result
				} else {
					console.error("Not syncing scores - this isn't a valid result", result, "for game", game, "with numPeriods", numPeriods)
				}

				// console.log('MATCHES', matches)

			} catch (err) {
				console.error("Failed to fetch", url, err)
			}

		}))

		//
		// Delete temporary fields
		//
		newGames.forEach(game => {
			delete game.meScore
			delete game.themScore
			delete game.amTeam1
		})

		console.log("newGames after periodScores", newGames)

		const fields = [ 'date', 'opponent', 'round', 'venue', 'result' ] as const

		//
		// First we build up a list of 'updates'
		//
		const updates: Array<ChangeAddGame | ChangeDeleteGame | {
			myGameId: GameId
			field: keyof Game
			oldValue: Game[keyof Game]
			newValue: Game[keyof Game]
		}> = [ ]

		newGames.forEach(game => {
			const { myGameId } = game
			if (!myGameId) {
				// Added game
				updates.push({
					add: true,
					game: {
						...game,
						created: Date.now(),
					},
				})
			} else {
				// Modified game
				fields.forEach(field => {
					let isDifferent = false
					if (field === 'result') {
						// Never suggest removing a score
						if (game.result && areDifferentResults(games[myGameId].result, game.result)) {
							isDifferent = true
						}
					} else if (game[field] !== games[myGameId][field]) {
						isDifferent = true
					}
					if (isDifferent) {
						console.log("FOUND DIFFERENCE", myGameId, game[field], games[myGameId][field])
						updates.push({
							myGameId,
							field,
							oldValue: games[myGameId][field],
							newValue: game[field],
						})
					}
				})
			}
		})

		Object.keys(games).forEach(gameId => {
			if (!newGames.find(game => game.myGameId === gameId)) {
				// Deleted game
				updates.push({
					delete: gameId,
					game: games[gameId],
				})
			}
		})

		console.log('UPDATES', updates)

		//
		// Now we convert the list of updates into a list of 'changedGames',
		// which bundles all changes for a single game together.
		//
		const changes: Changes = [ ]

		updates.forEach(change => {
			if ('add' in change || 'delete' in change) {
				changes.push(change)
			} else {
				const { myGameId, field, oldValue, newValue } = change

				const obj = {
					field,
					oldValue,
					newValue,
				}

				const last = changes.length && changes[changes.length - 1]
				if (last && 'myGameId' in last && last.myGameId === myGameId) {
					last.changes.push(obj)
				} else {
					changes.push({
						myGameId,
						changes: [ obj ],
					})
				}
			}
		})

		return changes
	} else {
		console.error("Unknown connector:", connector)
	}
	return null
}

//
// Strip out 'id' field and save to firebase
//
type SaveItemData<T> = Partial<T> & { id: string }

async function saveItem<T extends Item>(args: CallbackInterface, name: keyof typeof dbids, newData: SaveItemData<T>) {
	const { snapshot } = args

	const s = await snapshot.getPromise(section)
	//if (!(name in dbids)) {
	//	throw new Error("Illegal attempt to save item type " + name)
	//}
	//const dbid = queryDbid(name as keyof typeof dbids, s)
	const dbid = queryDbid(name, s)
	const { id, ...obj } = { ...newData }
	await updateDoc(doc(db, dbid, id), obj)
	console.log("Doc updated", dbid, id)
}

// export const useSaveItem = <T extends Item>() => useRecoilCallback(args => (name: keyof typeof dbids, newData: SaveItemData<T>) => saveItem<T>(args, name, newData))

export const useSaveItem = <T extends Item>() => useRecoilCallback(args => (name: keyof { [K in keyof DbidMapping]: DbidMapping[K] extends T ? K : never }, newData: SaveItemData<T>) => saveItem<T>(args, name, newData))

function isSameRound(r1: string | number, r2: string | number) {
	if (String(r1).toLowerCase() === String(r2).toLowerCase()) {
		return true
	}
	if (!String(r1).match(/final/i) && !String(r2).match(/final/i)) {
		const re = new RegExp(/\d+/g)
		const n1 = String(r1).match(re)?.[0]
		const n2 = String(r2).match(re)?.[0]
		if (n1 && n1 === n2) {
			return true
		}
	}
	return false
}

function areDifferentResults(result1: Result | undefined, result2: Result | undefined) {
	return result1?.me.length !== result2?.me.length || result1?.me.some((v, i) => v !== result2?.me[i]) || result1?.them.some((v, i) => v !== result2?.them[i])
}

export const useSyncFixture = () => useRecoilCallback(args => (connector: Connector, numPeriods: number) => syncFixture(args, connector, numPeriods))

async function importChanges(args: CallbackInterface, changes: Changes) {

	console.log('importChanges()', changes)

	const { snapshot } = args

	const s = await snapshot.getPromise(section)

	const dbid = `/teams/${s.team}/seasons/${s.season}/games`

	await Promise.all(changes.map(async change => {

		if ('changes' in change) {
			const { myGameId } = change

			const data: Partial<Game> = { }
			change.changes.forEach(obj => {
				const { field, newValue } = obj
				if (typeof newValue === 'object' && field === 'result') {
					data[field] = newValue
				} else if (typeof newValue === 'number' && (field === 'date' || field === 'created')) {
					data[field] = newValue
				} else if (typeof newValue === 'string' && (field === 'opponent' || field === 'round' || field === 'venue')) {
					data[field] = newValue
				} else {
					console.error("Unexpected change", obj)
				}
			})

			console.log("Writing data for change", change, data, "to gameId", myGameId)

			await updateDoc(doc(db, dbid, myGameId), data)
			console.log("Doc updated", dbid, myGameId)
		} else if ('add' in change) {
			// Add new game
			const data = {
				...change.game,
				created: Date.now(),
			}
			const docRef = await addDoc(collection(db, dbid), data)
			console.log("Doc added", docRef?.id)
		} else if ('delete' in change) {
			await deleteDoc(doc(db, dbid, change.delete))
			console.log('Deleted game', change)
			console.log('Trying to delete roster', change)
			await deleteDoc(doc(db, `/teams/${s.team}/seasons/${s.season}/rosters`, change.delete))
		} else {
			console.error("Whaaaat?")
		}
	}))

	console.log("Successfully wrote all changes.")
}

export const useImportChanges = () => useRecoilCallback(args => (changes: Changes) => importChanges(args, changes))
