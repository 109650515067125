//
// Definitions
//

import {
	shirt,
	shirtOutline,
	rocket,
	rocketOutline,
	body,
	bodyOutline,
	diamond,
	diamondOutline,
	calendar,
	analyticsOutline,
	optionsOutline,
	informationCircleOutline,
} from 'ionicons/icons'
import { players } from './media/teamIcon'

const fieldPositions = [
	'GS', 'GA', 'WA', 'C', 'WD', 'GD', 'GK',
] as const
const fieldPositions_Fast5 = [
	'GS', 'GA', 'C', 'GD', 'GK',
] as const
const fieldPositions_6ASide = [
	'A', 'A', 'C', 'C', 'D', 'D',
] as const
const fieldPositions_5ASide = [
	'A', 'A', 'C', 'D', 'D',
] as const

export const queryFieldPositions = (gameFormat: GameFormat) => {
	switch (gameFormat) {
		case 4: return fieldPositions_Fast5
		case 5: return fieldPositions_5ASide
		case 6: return fieldPositions_6ASide
		default: return fieldPositions
	}
}

export const BENCHED 						= '-' as const
export const RAIN 							= 'nulled' as const

export const ROSTER_NOT_AVAILABLE 			= 'na'

export const queryFieldPositionsPlusBench = (gameFormat: GameFormat) => {
	return [
		...queryFieldPositions(gameFormat),
		BENCHED,
	] as const
}

// Unfortunately this is duplicated in CSS
const fieldPositionColors = [
	'#65b965', '#00c097', '#00bac0', '#60ade0', '#ab9dec', '#e38edf', '#ff86c0', '#EEEEEE',
] as const
const fieldPositionColors_Fast5 = [
	'#65b965', '#00c097', '#60ade0', '#e38edf', '#ff86c0', '#EEEEEE',
] as const
const fieldPositionColors_6ASide = [
	'#00c097', '#00c097', '#60ade0', '#60ade0', '#ff86c0', '#ff86c0', '#EEEEEE',
] as const
const fieldPositionColors_5ASide = [
	'#00c097', '#00c097', '#60ade0', '#ff86c0', '#ff86c0', '#EEEEEE',
] as const

export const queryFieldPositionColors = (gameFormat: GameFormat) => {
	switch (gameFormat) {
		case 4: return fieldPositionColors_Fast5
		case 5: return fieldPositionColors_5ASide
		case 6: return fieldPositionColors_6ASide
		default: return fieldPositionColors
	}
}

// export const numPeriods = 4
export const defaultNumPeriods 				= 4 as const

//
// If a player's never been tried in a position, assume they're not great
// at it.
//
export const NULL_NETSTAT_VALUE				= -1.25 as const

export const NORMALIZE_MIN_QTRS				= 6 as const

export const NORMALIZE_MIN_QTRS_FOR_PAIRS 	= {
	pairs: 6,
	trios: 5,
} as const

export const HOME_URL 						= 'https://autoroster.io' as const
export const APP_STORE_URL 					= 'https://apps.apple.com/us/app/autoroster-for-netball/id1642680492' as const

export const SIDES 							= [ 'me', 'them' ] as const

export const PAIR_MODES 					= [ 'pairs', 'trios' ] as const

export const tabButtons = [
    {
        id: 'players',
        label: 'Players',
        icon: players,
		requireUser: false,
    },
    {
        id: 'settings',
        label: 'Settings',
        icon: optionsOutline,
        requireUser: true,
    },
    {
        id: 'games',
        label: 'Games',
        icon: calendar,
		requireUser: false,
    },
    {
        id: 'stats',
        label: 'Stats',
        icon: analyticsOutline,
		requireUser: false,
    },
    {
        id: 'help',
        label: 'Help',
        icon: informationCircleOutline,
		requireUser: false,
    },
] as const

export const otherValidSections 					= [ 'join-club' ]

export const isValidTab = (id: string) => !!tabButtons.find(tab => id === tab.id)

//
// Stats
//

// Combo must have been used at least this many times to appear in stats
export const MIN_NUM_QTRS_PER_COMBO		= 2 as const

//
// Settings.jsx
//

export const LOCAL_STORAGE_KEY_SETTINGS = 'settings' as const

const PRESET_DEVELOPMENT			= 'DEVELOPMENT' as const
const PRESET_JUNIOR					= 'JUNIOR' as const
const PRESET_SENIOR					= 'SENIOR' as const
export const PRESET_CUSTOM			= 'CUSTOM' as const

export const defaultPreset			= 'JUNIOR'

export const presets = {
	[PRESET_DEVELOPMENT]: {
		name: 'Development',
		order: 1,
		tooltip: "Development players are given more positional variety, and simple bib changes are prioritized.",
        icon: body,
        iconOutline: bodyOutline,
		defaults: {
			multiplePositions: 7,
			similarPositions: 7,
			centerExhaustion: 2,
			directSwaps: 7,
			halfTimeSwaps: 6,
			benchEvenly: 8,
			similarLastGame: 4,
			evenSeason: 3,
			netStats: 0,
		},
	},
	[PRESET_JUNIOR]: {
		name: 'Junior',
		order: 2,
		tooltip: "Junior teams mix optimal team performance with some positional variety.",
        icon: rocket,
        iconOutline: rocketOutline,
		defaults: {
			multiplePositions: 5,
			similarPositions: 3,
			centerExhaustion: 5,
			directSwaps: 0,
			halfTimeSwaps: 0,
			benchEvenly: 8,
			similarLastGame: 0,
			evenSeason: 0,
			netStats: 5,
		},
	},
	[PRESET_SENIOR]: {
		name: 'Senior',
		order: 3,
		tooltip: "For Senior players, team performance is prioritized, and \"similar positions\" are determined by height and mobility.",
        icon: diamond,
        iconOutline: diamondOutline,
		defaults: {
			multiplePositions: 2,
			similarPositions: 3,
			centerExhaustion: 5,
			directSwaps: 0,
			halfTimeSwaps: 0,
			benchEvenly: 5,
			similarLastGame: 0,
			evenSeason: 0,
			netStats: 9,
		},
	},
	[PRESET_CUSTOM]: {
		name: 'Custom',
		order: 4,
		tooltip: "Your own prefrences.",
        icon: shirt,
        iconOutline: shirtOutline,
		defaults: {
			multiplePositions: 5,
			similarPositions: 5,
			centerExhaustion: 5,
			directSwaps: 5,
			halfTimeSwaps: 5,
			benchEvenly: 5,
			similarLastGame: 5,
			evenSeason: 5,
			netStats: 5,
		},
	}
} as const

export const sliderSettings = [
	{
		id: 'netStats',
		desc: 'Optimize team performance',
        tip: 'Put players in positions that correlate with team success. Available when you\'ve entered quarter-by-quarter scores for at least one completed game.',
		default: 5,
		hideOnSingleRoster: true,
	},
	{
		id: 'benchEvenly',
		desc: 'Bench players evenly',
		tip: 'Give all players the same amount of game time.',
		default: 5,
	},
	{
		id: 'centerExhaustion',
		desc: 'Avoid exhausting Centre',
		tip: 'Give breaks to players who play Centre.',
		default: 5,
	},
	{
		id: 'multiplePositions',
		desc: 'Prefer multiple positions',
		tip: 'Players have more than one position during the game.',
		default: 5,
	},
	{
		id: 'similarPositions',
		desc: 'Prefer similar positions',
		tip: 'When changing positions, players move to a similar role.',
		default: 5,
	},
	{
		id: 'similarLastGame',
		desc: 'Copy previous game',
		tip: 'Prefer players in similar positions to the last time they played.',
		default: 5,
		hideOnSingleRoster: true,
	},
	{
		id: 'directSwaps',
		desc: 'Prefer direct swaps',
		tip: 'Players swap in pairs, with each taking the other\'s position.',
		default: 5,
	},
	{
		id: 'halfTimeSwaps',
		desc: 'Prefer half-time changes',
		tip: 'Players change positions at half-time rather than after the 1st and 3rd quarters.',
		default: 5,
	},
	{
		id: 'evenSeason',
		desc: 'Rotate positions throughout season',
		tip: 'Throughout the season, give all players equal time in each position.',
		default: 5,
		hideOnSingleRoster: true,
	},
] as const

export const PREFS_PREFER 		= 'Prefer' as const
export const PREFS_REQUIRE 		= 'Require' as const
export const ALL_PREFS = [ PREFS_PREFER, PREFS_REQUIRE ] as const

export const dbids = {
	club: '/clubs',
	team: '/teams',
	season: '/teams/__TEAM__/seasons',
	player: '/teams/__TEAM__/seasons/__SEASON__/players',
	game: '/teams/__TEAM__/seasons/__SEASON__/games',
	roster: '/teams/__TEAM__/seasons/__SEASON__/rosters',
} as const
