import React, { useState, useEffect } from 'react'
import {
    IonButton,
    IonMenuButton,
    IonRouterLink,
} from '@ionic/react'
import { joinClub } from './firebase'
import Icon from './Icon'
import LoadingSpinnerButton from './LoadingSpinnerButton'
import {
    useUser,
    useClub,
    useThisClub,
	useSetShowLogin,
} from './data'
import { userLoginObject } from './util'
import './joinclub.css'

const JoinClub = () => {

    return (
        <section className="joinclub">
            <h1>
                <Icon />
                You've Been Invited to Join a Club on AutoRoster!
            </h1>

            <JoinClubContent />
        </section>
    )
}

const JoinClubContent = () => {

	const [ err, setErr ] = useState<string | null>(null)
    const [ busy, setBusy ] = useState(false)
    const [ success, setSuccess ] = useState(false)

    const user = useUser()

    const club = useClub()

    const [ , clubId, code ] = window.location.pathname.split('/').filter(v => v)

    const thisClub = useThisClub(clubId)

	const setShowLogin = useSetShowLogin()

    // console.log('JoinClub.js', clubId, thisClub)

    useEffect(() => {
        if (!code || !clubId) {
            setErr('The invitation link you used is misformed. Please make sure it was sent correctly.')
        } else if (!thisClub?.id) {
            setErr('Club not found.')
        } else if (club?.id === thisClub?.id) {
            setErr("You've already joined this club.")
        } else if (club?.id) {
            setErr("You're already part of another club: " + club.name + ". Please leave that one first, using the top-left menu.")
        } else {
            setErr(null)
        }
    }, [ code, clubId, thisClub?.id, thisClub?.name, club?.id, club?.name ])

	if (!thisClub) {
		return (
			<p>
				Loading club...
			</p>
		)
	}

    if (success) {
        return (
            <>
                <p>
                    Welcome! You are now a member of <span className="club-name">{thisClub.name}</span>.
                </p>

                <div className="open-profile-button-container">
                    <IonMenuButton menu="profileMenu" className="open-profile-button">
                     My Profile
                    </IonMenuButton>
                </div>

                <IonRouterLink routerLink="/">
                    <IonButton
                        expand="block"
                    >
                        Continue
                    </IonButton>
                </IonRouterLink>
            </>
        )
    }

    if (err) {
        return (
            <>
				<h4>
					{thisClub?.name}
				</h4>
                <p className="error-message">
                    {err}
                </p>
                <IonRouterLink routerLink="/">
                    <IonButton
                    >
                        OK
                    </IonButton>
                </IonRouterLink>
            </>
        )
    }

    const onClick = async () => {

		if (!user)
			return

		const userObject = userLoginObject(user)
		if (!userObject)
			return

        setBusy(true)

        try {
            const { data } = await joinClub({
                clubId,
                code,
				user: userObject,
            })

            console.log('result', data)

            const { ok, error } = data

            if (ok) {
                setSuccess(true)
            } else if (error) {
                setErr(error)
            }

            setBusy(false)

        } catch (err) {
            setErr("Unknown error: This request did not succeed.")
        }

        setBusy(false)
    }

    return (
        <div>
            <h4>
                {thisClub?.name}
            </h4>
            <p>
				If you join:
			</p>
			<ul>
				<li>
					This club's admin can view & modify your rosters, view your team stats, and add/remove coaches to/from your teams. This
includes the ability to remove you as coach.
				</li>
				<li>
					You'll receive any upgraded account benefits purchased by the club.
				</li>
			</ul>
			<p>
				You can leave the club at any time.
			</p>

			{
				user ? (

					<LoadingSpinnerButton
						busy={busy}
						onClick={onClick}
					>
						Join {thisClub.name}
					</LoadingSpinnerButton>
				) : (
					<>
						<p>
							Before you can join a club, you need an account!
						</p>
						<IonButton
							expand="block"
							onClick={() => setShowLogin(true)}
						>
							Sign in
						</IonButton>
					</>
				)
			}

			<p>
				If you don't want to join this club, tap Cancel.
			</p>

			<p>
				<IonRouterLink routerLink="/">
					<IonButton
						className="button-cancel"
						fill="clear"
						color="medium"
					>
						Cancel
					</IonButton>
				</IonRouterLink>
			</p>

		</div>
	)
}

export default JoinClub
