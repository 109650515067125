import "typeface-heebo"

import './index.css'

import { Capacitor } from '@capacitor/core'
import { ErrorBoundary } from 'react-error-boundary'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { RecoilLogger, createLogger } from 'recoil-devtools-logger'
import { RecoilRoot } from 'recoil'
import React, { Suspense, useEffect } from 'react'

import {
  considerRememberingSection,
  loadFromLocalStorage,
  saveToLocalStorage,
  userObject
} from './util'
import { getFirebaseAuth } from './firebase'
import { User as FirebaseUser } from "@firebase/auth"

import { haveUnreadNewsItem } from './newsItems'
import {
  useRecentState,
  useSectionState,
  useSetNewsNotification,
  useSetShowLogin,
  useShowIntroScreen,
  useUserState,
} from './data'
import Analytics from './Analytics'
import ErrorFallback from './ErrorFallback'
import Intro from './Intro'
import Login from './Login'
import Main from './Main'
import MyPurchases from './MyPurchases'
import SectionUpdater from './SectionUpdater'

//
// Pinned react-router-dom to 5.2 because Ionic doesn't support v6:
//
// https://github.com/ionic-team/ionic-framework/issues/24177
//

const isDev = import.meta.env.DEV

let recoilLogger: any = null

if (isDev) {

	recoilLogger = createLogger({
		// @ts-expect-error: The recoil-devtools-logger types are wrong: a boolean is OK
		collapsed: true,
		timestamp: false,
		duration: false,
		level: {
			action: 'debug',
			nextState: 'debug',
			error: 'error'
		},
		logger: {
			...console,
			group: () => null,
			groupEnd: () => null,
			groupCollapsed: () => null,
		},
	})
}

const App = () => {

    const [ user, setUser ] = useUserState()
    const [ section, setSection ] = useSectionState()
	const [ recent, setRecent ] = useRecentState()
    const setShowLogin = useSetShowLogin()
	const showIntroScreen = useShowIntroScreen()
	const setNewsNotification = useSetNewsNotification()

    //
    // Track user state changes.
    //
	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>

		const unsubscribe = getFirebaseAuth().onAuthStateChanged((result: FirebaseUser | null) => {
			console.log("onAuthStateChanged()", result)

			// No need to fetchCurrentUser any more
			clearTimeout(timer)

			if (!result) {
				console.log('Logging out.')
				setUser(null)
			} else {
				setUser(userObject(result))
				setShowLogin(false)
			}
		})

		// Call the async function inside the effect without making the effect itself async
		const fetchCurrentUser = async () => {
			const result = await FirebaseAuthentication.getCurrentUser()
			const { user } = result
			console.log("Firebase says result is", result, "current user is", user)
			if (user === null) {
				setUser(null) // finished loading (so not undefined) but no user
			} else if (user) {
				setUser(userObject(user))
				setShowLogin(false)
			}
		}

		// On initial load, fetch the current user. This may be interrupted by a response
		// from onAuthStateChanged(), in which case we cancel the timer.
		timer = setTimeout(fetchCurrentUser, 1000)

		// Cleanup
		return () => {
			unsubscribe()
			FirebaseAuthentication.removeAllListeners()
			clearTimeout(timer)
		}
	}, [ setUser, setShowLogin ])

    //
    // LocalStorage will hold only the ID and name of the recently selected
    // team/season. On App load, we use the ID to look up actual data
    // for each of those, which we hold in memory -- this has additional
    // data like permissions, which we don't want to save in localStorage.
    //
    useEffect(() => {

        //
        // We only want to save 'team' and 'season' in localStorage,
        // not 'game'
        //
        if (section && section.team) {
			saveToLocalStorage('section', {
                team: section.team,
                season: section.season,
            })
        }

		//
		// Keep our locally-stored list of recently viewed teams
		// up to date.
		//
		const { changed, newRecent } = considerRememberingSection(section, recent)
		if (changed) {
			setRecent(newRecent)
			// console.log('Saved new recent', newRecent)
		}
	}, [ section, recent, setRecent ])

    useEffect(() => {
        const savedSection = loadFromLocalStorage('section')
        if (savedSection && typeof savedSection === 'object') {
            console.log("Set section in App.js", savedSection)
			// Not sure how this happens but anyway... don't allow string "undefined" as a value
			const savedSectionTyped = savedSection as Section
			const secs = [ 'team', 'season', 'game' ] as const
			secs.forEach(sec => {
				if (savedSectionTyped[sec] === 'undefined') {
					delete savedSectionTyped[sec]
				}
			})
            setSection(savedSection)
        }

    }, [ setSection ])

	//
	// See if we need to highlight a recent News item.
	//
	useEffect(() => {
		// async because we don't want to delay startup.
		const considerDisplayingNewsNotification = async () => {
			setNewsNotification(haveUnreadNewsItem())
		}
		considerDisplayingNewsNotification()
	}, [ setNewsNotification ])

    console.log("App.js", {
        user: user?.uid,
        nativePlatform: Capacitor.isNativePlatform(),
        env: import.meta.env,
    })

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
			<Suspense fallback={<div style={{ marginTop: '4em' }}>Loading...</div>}>
				{
					showIntroScreen ? <Intro /> : <Main />
				}
				<Login />
				<Analytics />
				<MyPurchases />
				<SectionUpdater />
			</Suspense>
		</ErrorBoundary>
	)
}

const RecoilApp = () => (
    <RecoilRoot>
        {
            isDev && <RecoilLogger logger={recoilLogger} />
        }
        <App />
    </RecoilRoot>
)

export default RecoilApp
