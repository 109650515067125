import "./roster.css"

import { FaExclamationTriangle } from 'react-icons/fa'
import { analyticsSharp } from 'ionicons/icons'
import {
    useIonAlert,
    IonIcon,
} from '@ionic/react'
import React, { useMemo, useState, useEffect, useCallback } from 'react'

import LoadingSpinner from './LoadingSpinner'

import { FadeInBox } from './definitionsPoses'
import { SignInButton } from './Intro'
import {
	calculateRosterNetStats,
} from './functions'
import {
	deepCopy,
	parseData,
	parseRoster,
	sanitizeBest,
	createEmptyPhenotype,
	generateNewPlayerNames,
} from './util'
import { generateRoster } from './generateRoster'
import {
    useAddToHistory,
    useEditModeState,
    useMyBestState,
	useMyComboStats,
    useMyGame,
    useMyGames,
    useMyGameId,
    useMyLocksState,
    useMyNetStats,
	useMyNumPeriodsLoaded,
    useMyPlayers,
    useMyPlayerNames,
	useMyPreferences,
    useMyRoster,
    useMyRosters,
	useMyTeam,
	useSetMyMadeChanges,
    useSetReady,
    useSettings,
    useSetMyRosterIsStale,
    useShowNetStats,
    useShowPositionPreferencesState,
    useStartHistory,
	useUser,
	useMySeasonStatus,
	useMyGameFormat,
	useSubSplitState,
	usePerformSubSplit,
} from './data'
import AwayPlayers from './AwayPlayers'
import ControlBox from './ControlBox'
import DecimalNumber from './DecimalNumber'
import DeleteButton from './DeleteButton'
import DisplayRoster from './DisplayRoster'
import DisplayScores from './DisplayScores'
import Orangeman from './Orangeman'
import PositionPreferencesSetting from './PositionPreferencesSetting'
import ProgressBar from './ProgressBar'
import { queryFieldPositions } from "./definitions"

const Roster = () => {

	const team = useMyTeam()
	const user = useUser()

    const game = useMyGame()
    const gameId = useMyGameId()

    const initialRoster = useMyRoster()

    const setReady = useSetReady()

    const rosters = useMyRosters()
    const games = useMyGames()

    const playerNames = useMyPlayerNames()

	//
	// We need to be able to tell when we're loading
	//
	const mySeasonStatus = useMySeasonStatus()
    const loading = mySeasonStatus === 'loading'

    const preferences = useMyPreferences()
    const settings = useSettings()
    const netStats = useMyNetStats()
	const combos = useMyComboStats()
    const showNetStats = useShowNetStats()
	const numPeriods = useMyNumPeriodsLoaded()
	const gameFormat = useMyGameFormat()

	const fieldPositions = queryFieldPositions(gameFormat)

    const [ editMode, setEditMode ] = useEditModeState()
    const setMyRosterIsStale = useSetMyRosterIsStale()

    const [ best, setBest ] = useMyBestState()
    const setMadeChanges = useSetMyMadeChanges()

    const [ showPositionPreferences, setShowPositionPreferences ] = useShowPositionPreferencesState()

    const [ needsSort, setNeedsSort ] = useState(false)

    const [ locks, setLocks ] = useMyLocksState()
	const [ subSplit, setSubSplit ] = useSubSplitState()
	const performSubSplit = usePerformSubSplit()

    const [ showAltBoard, setShowAltBoard ] = useState(false)

    const addToHistory = useAddToHistory()
    const startHistory = useStartHistory()

    const [ progress, setProgress ] = useState(0)

    const [ presentAlert ] = useIonAlert()

	// console.log('netStats', netStats)

    useEffect(() => {
        if (playerNames) {
            setNeedsSort(true)
        }
    }, [ playerNames ])

    //
    // When changing games, reset important things
    //
    useEffect(() => {
        if (gameId) {
            setEditMode(false)
            setShowPositionPreferences(false)
			setSubSplit(null)
        }
    }, [ gameId, setEditMode, setShowPositionPreferences, setSubSplit ])

	const sortThesePlayers = useCallback((players: PlayerId[], available: Available) => {
        return players.slice().sort((a, b) => {
            let result = Number(available[b]) - Number(available[a])
            if (!result) {
                const aName = (playerNames && playerNames[a]?.toLowerCase()) || a
                const bName = (playerNames && playerNames[b]?.toLowerCase()) || b
                if (aName > bName) {
                    result = 1
                } else if (aName < bName) {
                    result = -1
                } else {
                    result = 0
                }
            }
            return result
        })
    }, [ playerNames ])

    //
    // When players/availability changes, resort them
    //
    useEffect(() => {
        if (needsSort && numPeriods) {
            // console.log('Resorting!')

            setNeedsSort(false)

            const sortedPlayers = sortThesePlayers(best.players, best.available)

            if (JSON.stringify(sortedPlayers) !== JSON.stringify(best.players)) {
                console.log('Resorted players')
                setBest(sanitizeBest({
                    ...best,
                    players: sortedPlayers,
				}, numPeriods, gameFormat))
            }
        }

    }, [ needsSort, setBest, best, sortThesePlayers, gameFormat, numPeriods ])

    //
    //
    // Now this is slightly fucked because the database stores 'players' as a list
    // of the players marked available for this roster, while Roster.js uses it as
    // a complete list of all players?
    //
	const calculateInitialValues = useCallback((erase?: boolean) => {

        // console.log('calculateInitialValues', 'playerNames', playerNames, 'initialRoster', initialRoster)

		if (numPeriods === null) {
			console.log('Aborting calculateInitialValues due to numPeriods', numPeriods)
			return
		}

		// const b: Best = { }

        //
        // Note differences:
        //
        // (A) myPlayers: Object of player objects, including every players registered for this team
        //
        // (B) best.players: Array of playerIds: a simple map from myPlayers
        //
        // (C) initialRoster.players: Array of playerIds, including only those who are 'available' in this roster
        //
        // We need to map (C) to (B) here.
        //

        // Start off by setting up 'available' based on 'myPlayers', with all availability set to false
		const available: Available = { }
        Object.keys(playerNames).forEach(playerId => {
			available[playerId] = false
        })

		const players: Array<PlayerId> = Object.keys(playerNames)

        // Make sure we have a minimum number of players
        let dummyPlayers
        if (players.length < fieldPositions.length) {
            dummyPlayers = generateNewPlayerNames(players, fieldPositions.length - players.length)
            players.push(...dummyPlayers)
        }

        if (initialRoster) {
            // Convert Firestore 'players' (an array of AVAILABLE playerIds) to 'best.players' (an array of ALL playerIds)
            initialRoster.players.forEach(playerId => available[playerId] = true)
        } else {
            // No existing roster, so set everyone to available
            Object.keys(playerNames).forEach(playerId => available[playerId] = true)
        }

        // This must happen AFTER 'players' and 'available' are calculated above.
        const sortedPlayers = sortThesePlayers(players, available)

        //
        // nulled records position indexes of rained-out / canceled qtrs.
        //
		let nulled: RosterPositionIndex[] = [ ]

		let subs: Subs = [ ]

		let roster: RosterData

        if (erase) {
            roster = [ ]
            nulled = [ ]
			subs = [ ]
        } else {
            roster = initialRoster?.roster || [ ]
            nulled = initialRoster?.nulled || [ ]
			subs = initialRoster?.subs || [ ]
        }

        const orangeman = initialRoster?.orangeman || null

		const b: Best = {
			created: 0,
			players: sortedPlayers,
			available,
			roster,
			nulled,
			subs,
			orangeman,
		}

        console.log("Calculated initial values:", b)

        setMyRosterIsStale(false)

        const newState = sanitizeBest(b, numPeriods, gameFormat)

        setBest(newState)

        startHistory(newState, erase)

    }, [ initialRoster, setBest, setMyRosterIsStale, playerNames, startHistory, sortThesePlayers, numPeriods, gameFormat, fieldPositions.length ])

	//
	// When editMode changes, reset subSplit
	//
	useEffect(() => {
		setSubSplit(null)
	}, [ editMode, setSubSplit ])

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>
		if (subSplit !== null) {
			timer = setTimeout(() => performSubSplit(), 1600)
		}
		return () => clearTimeout(timer)
	}, [ subSplit, performSubSplit ])

    useEffect(() => {
        if (playerNames) {
			console.log('Something important changed - triggering calculateInitialValues()')
			calculateInitialValues()
		}
	}, [ calculateInitialValues, gameId, playerNames ])

	const data = useMemo(() => (loading || numPeriods === null) ? undefined : parseRoster({
		roster: best.roster,
		players: best.players,
		available: best.available,
		nulled: best.nulled,
		subs: best.subs,
		netStats,
		combos,
		numPeriods,
		gameFormat,
    }), [ loading, best, netStats, combos, gameFormat, numPeriods ])

	const rosterNetStats = useMemo(() => !loading && data && numPeriods !== null && showNetStats && calculateRosterNetStats(data, numPeriods), [ loading, showNetStats, data, numPeriods ])

	// console.log('Roster.js render', 'loading', loading, 'numPeriods', numPeriods, 'mySeasonStatus', mySeasonStatus, 'best', best, 'data', data)

	// Still loading...
	if (loading) {
		return <LoadingSpinner />
	}

	if (user === null && !team) {
		return (
			<div className="ion-padding">
				<p>
					To view this game, log in or use a shared link.
				</p>
				<p>
					<SignInButton/>
				</p>
			</div>
		)
	}

	if (!game || !Object.keys(game).length) {
		console.log("No game?", gameId, game, loading)
        return (
            <div className="ion-padding">
                This game does not exist.
            </div>
        )
    }

	// Don't think this should happen, but we need to stop here if
	// numPeriods is null.
	if (numPeriods === null) {
		return (
			<div className="ion-padding">
				Loading error.
			</div>
		)
	}

    const haveSetPositions = Object.values(preferences).filter(arr => arr && arr.filter(v => v).length).length ? true : false

	// console.log('Roster.jsx render', numPeriods)
	// console.log('Roster.js render') // , best?.roster && best.roster[0]) // , game, 'players', players)

	function toggleAltBoard() {

        setShowAltBoard(!showAltBoard)
        setShowPositionPreferences(false)
    }

	// was: function
	const setOrangeman = (orangeman: PlayerId | null) => {
        console.log('setOrangeman', orangeman)
        const newState = sanitizeBest({
            ...best,
            orangeman,
		}, numPeriods, gameFormat)
        setBest(newState)
        addToHistory(newState)
        setMadeChanges(true)
    }

	// was: function
	const toggleAvailable = (playerId: PlayerId) => {
        //console.log('toggleAvailable', playerId)

        if (!playerNames[playerId]) {
            console.log('Ignoring attempt to toggle availability of dummy player', playerId)
            return
        }

        const available = Object.assign({ }, best.available)
        available[playerId] = !available[playerId]

        const newState = sanitizeBest({
            ...best,
            available,
        }, numPeriods, gameFormat)

        setBest(newState)
        addToHistory(newState)
        setMyRosterIsStale(true)
        setMadeChanges(true)
        setNeedsSort(true)
    }

    function onSwapPlayers() {
        setNeedsSort(true)
    }

	const go = () => {

        const { orangeman, available } = best

		const startingPhenotype: Phenotype = (best && best.roster && best.roster.length) ? {
			...deepCopy({
				...best,
				subs: [ ],
			}),
			score: null,
			orangeman: undefined,
		} : createEmptyPhenotype(best.players, numPeriods, gameFormat)

        startingPhenotype.orangeman = orangeman

        const stats = parseData({
            players: playerNames,
            rosters,
            games,
			numPeriods,
			gameFormat,
        }, true, game?.date)

		setBest({
			...best,
			...startingPhenotype,
		})

        setShowPositionPreferences(false)
        setMyRosterIsStale(false)

        setReady(false)
        setProgress(0)

        generateRoster({
            onProgress,
            startingPhenotype,
            players: best.players,
            locks,
            available,
            preferences,
            netStats,
            settings,
            stats,
			combos,
			numPeriods,
			gameFormat,
        })
    }

	//
	// Note: 'best' is trapped in a closure and refers to the object before
	// we started changing things, not the most recent iteration!
	//
	function onProgress(newState: Best | ProgressBest) {
        console.log('onProgress', newState)
        if ('ready' in newState) {
            setReady(newState.ready)

            if (newState.ready) {
                // Assume we generated a new roster... better than saving every time we iterate, I think.
                addToHistory() 		// Let the snapshot look up the new 'best', free from this closure
                setMyRosterIsStale(false)
                setMadeChanges(true)
            }
        }
        if ('progress' in newState) {
            setProgress(newState.progress)
        }
        if ('best' in newState) {
			const newBest: Best = {
				...best, // i.e. state of 'best' before we clicked "GO"
				...newState.best,
				created: Date.now(),
			}
			setBest(newBest)
		}
	}

    function eraseRoster() {
        presentAlert({
            header: 'Erase Roster',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'Erase',
                    role: 'destructive',
                    handler: () => {
                        calculateInitialValues(true)
                        setMadeChanges(true)
                        setLocks([ ])
						setSubSplit(null)
                    },
                },
            ],
        })
    }

    const myClasses = [
        'roster-container',
        `roster-${editMode ? 'un' : ''}locked`,
    ]

    if (showPositionPreferences) {
        myClasses.push('roster-position-preferences')
    } else {
        if (showAltBoard && editMode) {
            myClasses.push('roster-edit-altboard')
        }
        if (showAltBoard) {
            myClasses.push('roster-altboard')
        }
    }

    const content = (
        <div className={myClasses.join(' ')}>
            <ControlBox
                haveSetPositions={haveSetPositions}
                showAltBoard={showAltBoard}
                onToggleAltBoard={() => { toggleAltBoard() }}
                onAutoRoster={go}
            />
            <ProgressBar
                variant="determinate"
                value={loading ? 20 : progress}
            />
            <Warnings />
            <DisplayRoster
				data={data}
                loading={loading}
                toggleAvailable={toggleAvailable}
                showAltBoard={showAltBoard}
                showPositionPreferences={showPositionPreferences}
                setOrangeman={setOrangeman}
                onSwapPlayers={onSwapPlayers}
            />
            {
                showPositionPreferences && (
                    <PositionPreferencesSetting
                        showPositionPreferences={showPositionPreferences}
                    />
                )
            }
            <DisplayScores />
            {
                showNetStats && rosterNetStats && (
                    <RateNetStats rosterNetStats={rosterNetStats} />
                )
            }
            {
                !editMode && (
                    <AwayPlayers />
                )
            }
            <div className="underneath-grid">
                <Orangeman
                    setOrangeman={setOrangeman}
                />

                <DeleteButton
                    show={editMode}
                    onClick={eraseRoster}
                />

            </div>

        </div>
    )

    return (
        <FadeInBox pose={best?.roster ? 'visible' : 'hidden'}>
            {content}
        </FadeInBox>
    )

}

type RateNetStatsProps = {
	rosterNetStats: RosterNetStats
}

const RateNetStats = (props: RateNetStatsProps) => {

    const { rosterNetStats } = props

	const numPeriods = useMyNumPeriodsLoaded()

    if (!rosterNetStats || !numPeriods)
        return null

    // console.log('RateNetStats', rosterNetStats)

    const { qtrs, total } = rosterNetStats

    return (
        <div className="undergrid undergrid-netstats">
            <div className={`grid grid-${numPeriods}`}>
                <div className="column column-players">
                    <div className="fixed-element">
                        <div className={`cell cell-position cell-void cell-netstat cell-netstat-${total < 0 ? 'negative' : 'positive'}`}>
                            <IonIcon icon={analyticsSharp} />
                            <DecimalNumber
                                value={total}
                                isComparison={true}
                            />
                        </div>
                    </div>
                </div>
                {
                    qtrs.map((score, index) => (
                        <div className="column column-positions" key={index}>
                            <div className="fixed-element">
                                <RateNetStatsQtr
                                    key={index}
                                    score={score}
                                    className="cell cell-position cell-void"
                                />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

type RateNetStatsQtrProps = {
	score: number | null
	className?: string
}

const RateNetStatsQtr = (props: RateNetStatsQtrProps) => {

    const { score, className } = props

    return (
        <div className={(className || '') + ` cell-netstat cell-netstat-${(score && score < 0) ? 'negative' : 'positive'}`}>
            <DecimalNumber
                value={score}
                isComparison={true}
            />
        </div>
    )
}

const Warnings = () => {

    const players = useMyPlayers()
    const roster = useMyRoster()

	const warnings = [ ]

	if (players && !Object.keys(players).length) {
		warnings.push("Add players before creating a roster.")
	} else if (roster?.players && !roster.players.length) {
		warnings.push("No players are marked available.")
	}

    if (!warnings.length)
        return null

    return (
		<>
			{
				warnings.map((warning, index) => <Warning key={index}>{warning}</Warning>)
			}
		</>
	)
}

export const Warning = (props: React.PropsWithChildren) => {

    return (
		<div className="roster-warning">
			<span>
				<FaExclamationTriangle />
				{' '}
				{props.children}
			</span>
			{' '}
		</div>
	)
}

export default Roster
