import React from 'react'
import {
    useAmViewingOwnTeam,
    useEditMode,
    useMyBest,
    useMyFieldPositions,
    useMyPreferences,
    useSetMyRosterIsStale,
} from './data'
import GridCell from './GridCell'
import GridCellPosition from './GridCellPosition'
import { PositionPreference } from './definitionsPoses'
import { useSaveItem } from './functions'

type GridPreferencesProps = {
	position: string
	positionIndex: number
}

const GridPreferences = (props: GridPreferencesProps) => {

	const { position, positionIndex } = props

    const preferences = useMyPreferences()
    const best = useMyBest()

    const { players } = best

    if (!players?.length) {
        return null
    }

    return (
        <div className="column column-positions column-preferences">
			<div className="fixed-element">
				<GridCell
					type="header"
					content={position}
				/>
			</div>
			{
				players.map((playerId, index) =>
					<CellPreference
						key={`${index}-${position}`}
						playerId={playerId}
						position={position}
                        prefs={preferences[playerId]}
						value={(preferences[playerId] && preferences[playerId][positionIndex]) || 0}
					/>
				)
			}
		</div>
	)
}

type CellPreferenceProps = {
	value: number
	playerId: string
	position: string
	prefs: number[] | undefined
}

//
// Don't re-render cells unless the contents change
//
const CellPreference = React.memo((props: CellPreferenceProps) => {

	const { value, playerId, position, prefs } = props

    const editMode = useEditMode()
    const amViewingOwnTeam = useAmViewingOwnTeam()

    const setMyRosterIsStale = useSetMyRosterIsStale()

	const fieldPositions = useMyFieldPositions()

	const saveItem = useSaveItem<Player>()

    //
    // Position Preferences
    //
	type SetPreferenceProps = {
		playerId: string
		position: string
		value: number
	}

	const setPreference = async (props: SetPreferenceProps) => {

        console.log('setPreference', props)

        if (!editMode || !amViewingOwnTeam) {
            console.error("Not allowed")
            return
        }

        const newPrefs = prefs?.slice() || new Array(fieldPositions.length).fill(0)

		//
		// This works for all game formats, including those where there are multiple
		// positions with the same name (e.g. A, A, C, D, D).
		//
		fieldPositions.forEach((position, index) => {
			if (position === props.position) {
				newPrefs[index] = props.value
			}
		})

		console.log('newPrefs', newPrefs)

		const data = {
			id: playerId,
            prefs: newPrefs,
        }

		await saveItem('player', data)

        setMyRosterIsStale(true)
        console.log('Success')
    }

	type HandleClickProps = {
		playerId: string
		position: string
	}

	const handleClick = (args: HandleClickProps) => {
		if (setPreference) {
			let newValue = value + 1
			if (newValue > 1) {
                newValue = -1
			}
			const newArgs: SetPreferenceProps = {
				...args,
				value: newValue,
			}
			setPreference(newArgs)
		}
	}

	const content = (value ? position : '-')

	const richContent = (value === -1 ? (
		<div className="exclude-position">
			<BanIcon />
			{position}
		</div>
	) : position)

	return (
		<PositionPreference
			key={`${position}-${playerId}`}
			className="posed-element position-preference"
		>
			<GridCellPosition
				i={position}
				content={content}
				richContent={richContent}
				onClick={() => handleClick({ playerId, position })}
				subStatus={0}
			/>
		</PositionPreference>
	)
})

CellPreference.displayName = 'CellPreference'

const BanIcon = () => (
	<svg viewBox="0 0 100 100" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
		<line x1="20" y1="20" x2="80" y2="80" stroke="red" strokeWidth="6"/>
	</svg>
)

export default GridPreferences
