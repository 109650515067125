import React, { useState, useEffect } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share'
import {
	IonButton,
	IonIcon,
    useIonAlert,
} from '@ionic/react'
import {
    useEditModeState,
    useSection,
    useShowPositionPreferencesState,
} from './data'
import {
    getUrl,
} from './util'
import { shareOutline } from 'ionicons/icons'
import './sharebutton.css'

//
// NOTE: Calling navigator.share({ title: 'title', url: 'url' }) seems
// to crash Chrome at the moment, even though calling canShare() returns
// true. So I'm trying to limit this to touch devices only.
//

//
// This is a bit messy because it copies the URL to the Clipboard
// and THEN tries to use the Web Share API - which, if it succeeds,
// has no use for the Clipboard. So we overwrite the Clipboard
// unneccessarily in that situation. But I can't figure out how to
// do it otherwise.
//

type ShareButtonProps = {
	url?: string
	title?: string
	fill?: 'clear' | 'outline' | 'solid' | 'default'
	size?: 'small' | 'default' | 'large'
	expand?: 'full' | 'block'
}

const ShareButton = (props: React.PropsWithChildren<ShareButtonProps>) => {

    const [ presentAlert ] = useIonAlert()

    const [ haveShareAPI, setHaveShareAPI ] = useState(false)

    const [ editMode, setEditMode ] = useEditModeState()
    const [ showPositionPreferences, setShowPositionPreferences ] = useShowPositionPreferencesState()

    const section = useSection()

	const pageUrl = props.url || getUrl({ section })
	const pageTitle = props.title || document.title

    //
    // On mount, test whether we can use the Capacitor Share API
    //
    useEffect(() => {
        Share.canShare().then(result => setHaveShareAPI(result?.value))
    }, [ ])

    const onShare = () => {

        console.log('share!', haveShareAPI, window.location.pathname.substring(0, 7))

		if (Capacitor.isNativePlatform() && haveShareAPI) {

            console.log('Sharing via Share API')

            Share.share({
                title: pageTitle,
                // text: 'Really awesome thing you need to see right meow',
                url: pageUrl,
                dialogTitle: 'Share',
            })

        } else {

            // Web

            if (window.location.pathname.substring(0, 7) === '/games/') {
                presentAlert({
                    header: 'Print or Share Roster',
                    buttons: [
                        {
                            text: 'Print',
                            handler: onWebPrint,
                        },
                        {
                            text: 'Share',
                            handler: () => setTimeout(onWebShare, 800),
                        },
                    ],
                })
            } else {
                onWebShare()
            }
        }
    }

    const onWebShare = () => {

        if (navigator?.share) {

            console.log('Sharing via navigator.share')

            navigator
                .share({
                    title: pageTitle,
                    url: pageUrl,
                })
                .then(() => {
                    console.log('Thanks for sharing!')
                    // window.alert('Thanks for sharing!')
                })
                .catch(err => {
                    console.error(err)
                    // window.alert("Error: " + err)
                })

		} else {

			console.log('Sharing via fallback link copy', pageUrl)

			presentAlert({
				header: 'Link Copied',
				subHeader: pageUrl,
				message: 'Paste into a message to share with others.',
				buttons: [ 'OK' ],
			})
		}
	}

	const onWebPrint = () => {
		if (showPositionPreferences) {
			setShowPositionPreferences(false)
		}
		if (editMode) {
			setEditMode(false)
		}

		// For some reason, iOS Safari can insert a REALLY long delay
		// after hitting "Print" before it shows the print preview
		// screen... like 30-60s. But it does work!
		setTimeout(window.print, 100)
	}

	return (
		<CopyToClipboard text={pageUrl}>

			<IonButton
				className="button-share"
				size={props.size || "small"}
				disabled={false}
				onClick={onShare}
				title="Share"
				slot="primary"
				fill={props.fill || "clear"}
				expand={props.expand}
			>
				<IonIcon
					icon={shareOutline}
					slot={props.children ? 'start' : 'end'}
				/>
				{props.children}
			</IonButton>

		</CopyToClipboard>
	)
}

export default ShareButton
