import {
    IonDatetime,
    IonDatetimeButton,
	IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
	IonNote,
	IonSelect,
    IonSelectOption,
	useIonAlert,
} from '@ionic/react'
import { informationCircleOutline } from 'ionicons/icons'
import React, { useEffect, useRef, useState } from 'react'

import { ConnectorSelector } from './MySeasons'
import { formatResult, validateResult } from './functions'
import { ucFirst } from './util'
import {
	useMyNumPeriods,
	useNewTeamId,
	useSection,
	useTeamRosters,
} from './data'
import {DatetimeChangeEventDetail, IonDatetimeCustomEvent, IonInputCustomEvent, IonSelectCustomEvent, SelectChangeEventDetail} from '@ionic/core'

type EditFieldProps<T> = {
	field: keyof T
	type: ItemTypeDefinition<T>
	fieldData: T | undefined
	setFieldData: (obj: T) => void
	disabled: boolean
	isSingleItem: boolean
	isSeasonSettings: boolean
	items?: Array<T>
	mode: 'add' | 'edit'
}

const EditField = <T extends Item>(props: EditFieldProps<T>) => {

	const { field, type, fieldData, setFieldData, disabled, isSingleItem, isSeasonSettings, items, mode } = props

	const [ invalidError, setInvalidError ] = useState<string>()

	const [ presentAlert ] = useIonAlert()

	const numPeriods = useMyNumPeriods()

	const inputRef = useRef<HTMLIonInputElement>(null)

	// Needs to trigger when a 'name' / 'opponent' field clears and we're quickAdding...
	// I think when fieldData resets, that's good enough
	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>
		if ((field === 'name' || field === 'opponent') && !fieldData?.[field]) {
			// console.log('setFocus')
			timer = setTimeout(() => inputRef?.current?.setFocus(), 100)
		}
		return () => clearTimeout(timer)
	}, [ field, fieldData ])

    if (!fieldData)
        return null

    // console.log('EditField', props)

	const isDate = field === 'date'
	const isResult = field === 'result'
	const isNumPeriods = field === 'numPeriods'
	const isGameFormat = field === 'gameFormat'
	const isConnector = field === 'connector'

	//const onChange = (e: IonInputCustomEvent<InputEvent>) => {
	const onChange = (e: any) => {

		console.log('onChange', e, e.target.value)

		/*
		 * This crashed on Add New Season
		if (!(e.target instanceof HTMLInputElement)) {
			throw Error('Expected target to be an input element')
		}
		*/

		const event = e as IonInputCustomEvent<InputEvent>
		let v: string | number | null | undefined | Result | Connector = event.target?.value

		if (isDate) {
			// We store dates as a number.
			v = new Date(String(v)).getTime()
		}
		else if (isResult) {
			const el = event.target.closest('.add-result-field') as HTMLElement | null
			if (el) {
				const qtr = el.dataset.resultQtr
				const side = el.dataset.resultSide
				// console.log('qtr', qtr, 'side', side, v, el.dataset)

				if (qtr !== undefined && (side === 'me' || side === 'them')) {
					const { result } = fieldData as Game
					const newResult = formatResult(result, numPeriods)
					newResult[side][Number(qtr)] = (v === null ? null : Number(v))
					v = formatResult(newResult, numPeriods)
				}
			}
		}

		const isInvalid = type.isInvalid?.[field]
		if (isInvalid) {
			console.log('check target value', event.target?.value)
			const errMsg = isInvalid({
				value: event.target?.value,
				items,
			})
			if (errMsg !== invalidError && typeof errMsg === 'string') {
				// Allow the component to finish rendering before triggering rerender
				setTimeout(() => setInvalidError(errMsg), 20)
			}
		}

		// @ts-ignore - not sure how to check for types
		setField(v)
	}

	const setField = (value: T[keyof T]) => {
		console.log('setField field', field, 'value', value, 'fieldData', fieldData)
		const newValue: T = {
			...fieldData,
			[field]: value,
		}
		//
		// If we're setting a connector, and the season doesn't have a name, set it to what
		// the connector says.
		//
		if (field === 'connector' && 'connector' in newValue) {
			const v = value as Connector
			const f = fieldData as Season
			if (!f.name && v?.params?.compName) {
				newValue.name = v.params.compName
			}
		}
		setFieldData(newValue)
	}

	let value = fieldData[field]

	if (isDate) {
		const t = value ? new Date(value as number) : new Date()
		value = formatDate(t) as T[keyof T]
	} else if (isResult) {
		value = formatResult(value as Result|undefined, numPeriods) as T[keyof T]
	}

	// console.log('value', field, fieldData, value, typeof value)

	const Element =
		isResult ? ResultFields :
		isDate ? DateTimePicker :
		isNumPeriods ? PeriodSelector :
		isGameFormat ? GameFormatSelector :
		isConnector ? ConnectorSelector :
		IonInput

	const prefix = (mode === 'add' && field === 'name') ? 'New ' : ''

	const label = prefix + `${ucFirst(type.unit)} ${ucFirst(String(field))}`
	let tooltip

	const tooltipText = type.fieldTooltips && type.fieldTooltips(field)

	if (tooltipText) {
		const onClick = () => presentAlert({
			message: tooltipText,
			buttons: [ 'OK' ],
		})
		tooltip = (
			<IonIcon
				icon={informationCircleOutline}
				className="tooltip-icon tooltip-item-icon"
				onClick={onClick}
			/>
		)
	}

	const lines = isSingleItem ? 'none' : 'inset'

	const hasLabel = (isNumPeriods || isGameFormat || isConnector) ? false : true

	const refProps: {
		ref?: React.RefObject<HTMLIonInputElement>
	} = { }
	if (field === 'name' || field === 'opponent') {
		refProps.ref = inputRef
	}

	return (
		<IonItem
			lines={lines}
			className={invalidError ? "ion-invalid" : ""}
			disabled={disabled}
		>

			{tooltip}

			{
				hasLabel && (
					<IonLabel position="stacked">
						{label}
					</IonLabel>
				)
			}

			<Element
				autoCorrect="off"
				autocapitalize="words"
				className="add-item-field"
				data-field={field}
				aria-label={field}
				fieldId={fieldData.id}
				fieldData={fieldData}
				// @ts-ignore
				value={value}
				onIonInput={onChange}
				disabled={disabled}
				inputMode="text"
				editItemMode={mode}
				type="text"
				size="small"
				isSeasonSettings={isSeasonSettings}
				{...refProps}
			/>
			{
				invalidError && (
					<IonNote slot="error">
						{invalidError}
					</IonNote>
				)
			}
		</IonItem>
	)
}

type ResultFieldsProps = {
	value: Result
	disabled: boolean
}

const ResultFields = (props: ResultFieldsProps) => {

	const { value } = props

	const numPeriods = useMyNumPeriods()

    return (
        <div>
            <table
                className={`enter-results-table ${props.disabled ? 'results-table-disabled' : ''}`}
            >
                <thead>
                    <tr>
                        <th />
                        <th>
                            You
                        </th>
                        <th>
                            Them
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
						[ ...Array(numPeriods).keys() ].map(qtr => (
                            <tr key={qtr}>
                                <td>
									{qtr === numPeriods - 1 ? 'Final' : `${numPeriods === 4 ? 'Q' : 'P'}${qtr + 1}`}
                                </td>
                                <td>
                                    <ResultField
                                        result={value}
                                        side="me"
                                        qtr={qtr}
                                        specialProps={props}
                                    />
                                </td>
                                <td>
                                    <ResultField
                                        result={value}
                                        side="them"
                                        qtr={qtr}
                                        specialProps={props}
                                    />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

type ResultFieldProps = {
	result: Result
	side: 'me' | 'them'
	qtr: number
	specialProps: ResultFieldsProps
}

const ResultField = (props: ResultFieldProps) => {
	const { result, side, qtr, specialProps } = props

	const isError = !validateResult({ result, side, qtr })

	return (
		<IonInput
			{...specialProps}
			value={result[side][qtr] === null ? '' : result[side][qtr]}
			data-result-qtr={qtr}
			data-result-side={side}
			errorText={isError ? 'Weird scores' : undefined}
            className={`add-result-field ${isError ? 'result-field-error' : ''}`}
			type="number"
            inputMode="numeric"
            min={0}
            max={299}
		/>
	)
}

type DateTimePickerProps = {
	fieldId: string
	onIonInput: (e: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) => void
}

const DateTimePicker = (props: DateTimePickerProps) => {

    const id = `datetime-${props.fieldId}`

    return (
        <>
            <IonDatetimeButton
                {...props}
                datetime={id}
            ></IonDatetimeButton>

            <IonModal keepContentsMounted={true}>
                <IonDatetime
					onIonChange={props.onIonInput}
                    {...props}
                    id={id}
                    locale="en-US"
                ></IonDatetime>
            </IonModal>
        </>
    )
}

type PeriodSelectorProps = {
	value: number
	disabled: boolean
	editItemMode: 'add' | 'edit'
	fieldId: string
}

const PeriodSelector = (props: PeriodSelectorProps) => {

	const { editItemMode, fieldId } = props

	//
	// We want to disable this if the season has existing rosters.
	//
	// We have to use 'teamRosters' rather than 'myRosters' here because the user
	// might be editing a season directly from the team/season selector popup.
	//
	// If user is:
	//
	// * editing their current season via the Games -> settings link:
	//   - newTeamId === undefined
	//   - editItemMode === 'edit'
	//   - fieldId === <set>
	//
	// * editing a season via the Team/Season selector -> 3 dots menu:
	//   - newTeamId === <set>
	//   - editItemMode === 'edit'
	//   - fieldId === <set>
	//
	// * Adding a new season via the Team/Season selector:
	//   - newTeamId === <set>
	//   - editItemMode === 'add'
	//   - fieldId === undefined
	//
	const newTeamId = useNewTeamId()

	const { team } = useSection()

	const id = `${newTeamId || team}__${fieldId}`
	const rosters = useTeamRosters(id)

	//
	// You can't change this setting if you're modifying an existing season
	// that has rosters.
	//
	const haveRosters = editItemMode === 'edit' && Object.values(rosters).findIndex(obj => obj.roster?.length > 0) !== -1

	const value = props.value || 4

	const options = [
		{
			value: 1,
			label: '1 Period',
		},
		{
			value: 2,
			label: '2 Halves',
		},
		{
			value: 3,
			label: '3 Periods',
		},
		{
			value: 4,
			label: '4 Quarters',
		},
		{
			value: 5,
			label: '5 Periods',
		},
		{
			value: 6,
			label: '6 Periods',
		},
	]

	return (
		<Selector
			{...props}
			label="Game Duration"
			options={options}
			value={value}
			disabled={props.disabled || haveRosters}
		/>
	)
}

type GameFormatSelectorProps = {
	value: number
	disabled: boolean
	editItemMode: 'add' | 'edit'
	fieldId: string
}

const GameFormatSelector = (props: GameFormatSelectorProps) => {

	const { editItemMode, fieldId } = props

	//
	// See similar code in PeriodSelector
	//
	const newTeamId = useNewTeamId()

	const { team } = useSection()

	const id = `${newTeamId || team}__${fieldId}`
	const rosters = useTeamRosters(id)

	//
	// You can't change this setting if you're modifying an existing season
	// that has rosters.
	//
	const haveRosters = editItemMode === 'edit' && Object.values(rosters).findIndex(obj => obj.roster?.length > 0) !== -1

	const value = props.value || 7

	const options = [
		{
			value: 7,
			label: 'Standard (7 players)',
		},
		{
			value: 6,
			label: '6-a-side (A/C/D)',
		},
		{
			value: 5,
			label: '5-a-side (A/C/D)',
		},
		{
			value: 4,
			label: 'FAST5 (no Wings)',
		},
	]

	return (
		<Selector
			{...props}
			label="Format"
			options={options}
			value={value}
			disabled={props.disabled || haveRosters}
		/>
	)
}

type SelectorProps = {
	label: string
	value: number
	options: Array<{ value: number, label?: string }>
	disabled?: boolean
	onIonInput?: (e: IonSelectCustomEvent<SelectChangeEventDetail>) => void
}

export const Selector = (props: SelectorProps) => {

	const { label, value, options, disabled } = props

	return (
		<IonSelect
			label={label}
			interface="action-sheet"
			value={value}
			onIonChange={props.onIonInput}
			disabled={disabled}
		>
			{
				options.map(obj =>
					<IonSelectOption
						value={obj.value}
						key={obj.value}
					>
						{obj.label ?? obj.value}
					</IonSelectOption>
				)
			}
		</IonSelect>
	)
}

function formatDate(d: Date) {
    const offsetMs = d.getTimezoneOffset() * 60 * 1000
    const dateLocal = new Date(d.getTime() - offsetMs)
    return dateLocal.toISOString().slice(0, 16)
}

export default EditField
