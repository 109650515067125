import React from 'react'
import './decimalnumber.css'

type DecimalNumberProps = {
	value: number | null
	isComparison?: boolean
	className?: string
}

const DecimalNumber = (props: DecimalNumberProps) => {

    const { value, isComparison, className } = props

    // Display '-' if the player has never played this position, otherwise
    // "x.xx", even if it's 0.00.
    if (value ===  null) {
        return null
    }

    const number = splitDecimal(value, 1)

    if (isComparison && value > 0) {
        number.integer = '+' + number.integer
	}

    const myClasses = [ 'decimal-number' ]
    if (className) {
        myClasses.push(className)
    }

	return (
        <div className={myClasses.join(' ')}>
			<div className="number-integer">
				{number.integer}
			</div>
            <div className="number-decimal">
                <div className="number-point">
                    .
                </div>
                {number.decimal}
            </div>
        </div>
	)
}

function splitDecimal(n: number, places = 1): { integer: string, decimal: string } {

	const mult = Math.pow(10, places || 1)

	const val = Math.round(n * mult)

	const [ integer, decimal ] = String(val / mult).split(/\./, 2)

	const decimalStr = (decimal || '0').padEnd(places, '0')

	return {
		integer,
		decimal: decimalStr,
	}
}

export default DecimalNumber
