import React from 'react'
import posed, { PoseGroup } from 'react-pose'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import {
    useEditMode,
    useMyLocks,
	useMyNumPeriods,
	useSubSplit,
	useReady,
	useSetAmDragging,
    useShowNetStats,
	useShowNetStatsCombos,
	useUserHasPro,
	useRecentSubSplit,
} from './data'
import GridCellPosition from './GridCellPosition'
import GridCell from './GridCell'
import PlayerName from './PlayerName'
import { BENCHED } from './definitions'
import { DraggableItem, FixedItem } from './definitionsPoses'
import { RxDragHandleDots2 } from 'react-icons/rx'
import { FaArrowRightLong } from 'react-icons/fa6'

type GridPositionsProps = {
	best: Best
	data: AllRosterStats | undefined | null
	onDrop: (e: React.DragEvent, obj: OnDropObject, isQtr?: boolean) => void
	qtr: number
	showAltBoard: boolean
}

const GridPositions = (props: GridPositionsProps) => {

	const { best, data, onDrop, qtr, showAltBoard } = props

    const { players, roster, nulled, available } = best

	const ready = useReady()
    const editMode = useEditMode()
    const showNetStats = useShowNetStats()
	const showNetStatsCombos = useShowNetStatsCombos()
	const userHasPro = useUserHasPro()
	const setAmDragging = useSetAmDragging()

    const locks = useMyLocks()
	const subSplit = useSubSplit()
	const recentSubSplit = useRecentSubSplit() || [ null, null, null ]

	if (!data)
		return null

    // console.log('GridPositions qtr', qtr, 'with data', data, 'and locks', locks)


	const list: Array<{
		player: PlayerId
		position: string | undefined
		positionName: string
		content: string | JSX.Element
		isNullPosition: boolean
		subStatus: SubStatus
		subPlayer: PlayerId | null
		subAnimation: SubAnimation
		isAvailable: boolean
		isSubAvailable: boolean
		netStatElement: JSX.Element | null
		pairElement: JSX.Element | null
		trioElement: JSX.Element | null
		noRoster: boolean
		i: I
		key: string | number
		onDragEnd: (e: React.DragEvent) => void
	}> = [ ]

	players.forEach(player => {
		const bit = data[player][qtr]
        if (!bit) {
			throw Error("Missing data for player, about to crash... player" + player + "qtr" + qtr + "data" + data + "best" + best)
        }

		const i: I | undefined = data[player][qtr]?.i

		const subIndex = best.subs?.findIndex(posIndex => posIndex === i)
		const subStatus = subIndex === -1 ? 0 : subIndex % 2 ? 2 : 1
		const subPlayer = subIndex === -1 ? null : best.roster[best.subs[subIndex + (subIndex % 2 ? -1 : 1)]]

		const noRoster = !roster.length

		let position = data[player][qtr]?.position
		let positionName = bit.position
		if (noRoster || (subStatus === 2 && showAltBoard)) {
			positionName = BENCHED
		}

		const isNullPosition = roster.length ? nulled.includes(bit.i) : false
		const netStatElement = showNetStats ? (
			<NetStatValue
				value={bit.netStat}
				position={positionName}
				isNullPosition={isNullPosition}
			/>
		) : null

		if (i === undefined) {
			throw Error("Why is i undefined?... player" + player + "qtr" + qtr + "data" + data + "best" + best)
		}

		//
		// isAvailable refers to the availability of the main player only. This may be
		// different to the availability status of a sub, if the position is split.
		//
		const isAvailable = available[player]
		const isSubAvailable = subPlayer ? available[subPlayer] : true

		const pairElement = (showAltBoard && showNetStatsCombos && userHasPro && bit.pair) ? (
			<PairElement
				position={positionName}
				value={bit.pair}
			/>
		) : null

		const trioElement = (showAltBoard && showNetStatsCombos && userHasPro && bit.trio) ? (
			<PairElement
				position={positionName}
				value={bit.trio}
				offset={bit.trioIndex}
				isTrio={true}
			/>
		) : null

        // console.log('player', player, roster.length, i, qtr, data[player], data[player][qtr], positionName, 'trioElement', trioElement)

		const key = i

		const onDragEnd = (e: React.DragEvent) => {
			console.log('GridPositions onDragEnd - column player ', player, 'position', positionName, 'qtr', qtr, 'i', i)
			// This is necessary to prevent weird double-triggering on Mobile Web.
			e.preventDefault()
			return onDrop(e, { i })
		}

		let subAnimation: SubAnimation = null
		const subAnimationType = i === recentSubSplit[1] ? 1 : i === recentSubSplit[2] ? 2 : undefined
		if (subAnimationType) {
			subAnimation = [ recentSubSplit[0] ? 'created' : 'removed', subAnimationType ]
		}

		let content
		if (noRoster) {
			content = ''
		} else if (showAltBoard) {
			content = <PlayerName playerId={player} subPlayerId={subPlayer} />
		} else {
			content = <PositionName positionName={positionName} subStatus={subStatus} />
		}

		list.push({
			player,
			position,
			positionName,
			content,
			isNullPosition,
			isAvailable,
			isSubAvailable,
			subStatus,
			subPlayer,
			subAnimation,
			netStatElement,
			pairElement,
			trioElement,
			noRoster,
			i,
			key,
			onDragEnd,
		})
	})

	if (showAltBoard) {
		list.sort((a, b) => Number(a.i) - Number(b.i))
	}

    // console.log('list', qtr, list.length, list)

	const allowDrag = (editMode && ready && roster.length) ? true : false

    const Element = allowDrag ? DraggableItem : FixedItem

	const onDragStart = () => {
		console.log('onDragStart')
		setAmDragging(true)
		Haptics.impact({ style: ImpactStyle.Light })
	}

	let contents = list.map(item => (
		<Element
			key={item.key}
			className={
                allowDrag ? 'posed-element' : `fixed-element ${item.isAvailable ? '' : 'fixed-element-unavailable'} ${item.positionName === BENCHED ? 'fixed-element--' : ''}`
            }
			onDragStart={onDragStart}
			onDragEnd={item.onDragEnd}
		>
			<GridCellPosition
				i={item.i}
                content={item.content}
				positionName={item.positionName}
				isNullPosition={item.isNullPosition}
				isAvailable={item.isAvailable}
				isSubAvailable={item.isSubAvailable}
				isLocked={typeof item.i === 'number' && locks[item.i]}
				subStatus={item.subStatus}
				isSubSplitting={item.i === subSplit}
				subAnimation={item.subAnimation}
			>
				{item.netStatElement}
				{item.pairElement}
				{item.trioElement}
			</GridCellPosition>
		</Element>
	))

	return (
        <div className="column column-positions">
			<GridPositionsHeader
				allowDrag={allowDrag}
				qtr={qtr}
				onDrop={onDrop}
			/>
			<div className="column-positions-without-header">
				<PoseGroup>
					{contents}
				</PoseGroup>
			</div>
		</div>
	)
}

type GridPositionsHeaderProps = {
	allowDrag: boolean
	qtr: number
	onDrop: (e: React.DragEvent, obj: OnDropObject, isQtr?: boolean) => void
}

const GridPositionsHeader = (props: GridPositionsHeaderProps) => {

	const { allowDrag, qtr } = props

	const setAmDragging = useSetAmDragging()
	const numPeriods = useMyNumPeriods()

	const key = `qtr-header-${qtr}`
	const content = `${numPeriods === 4 ? 'Q' : 'P'}${qtr + 1}`

	if (!allowDrag) {
		return (
			<FixedItem className="fixed-element" key={key}>
				<GridCell
					type="header"
					content={content}
				/>
			</FixedItem>
		)
	}

	const DraggableQtr = posed.div({
		draggable: 'x',
		init: { scale: 1 },
		drag: { scale: 1.1, left:'-5%' },
	})

	const onDragStart = () => {
		Haptics.impact({ style: ImpactStyle.Light })
		setAmDragging(true)
	}

	const onDragEnd = (e: React.DragEvent) => {
		console.log('onDragEnd - column header qtr ', qtr)
		return props.onDrop(e, { qtr }, true)
	}

	return (
		<DraggableQtr
			key={key}
			className="posed-element"
			onDragStart={onDragStart}
			onDragEnd={onDragEnd}
		>
			<GridCell
				type="header"
				qtr={qtr}
				content={
					<>
						<RxDragHandleDots2 className="drag-handle" />
						{content}
					</>
				}
			/>
		</DraggableQtr>
	)
}

type PositionNameProps = {
	positionName: FieldPosition
	subStatus: SubStatus
}

export const PositionName = (props: PositionNameProps) => {

	const { positionName, subStatus } = props

	if (subStatus === 1) {
		return (
			<>
				{positionName}
				<FaArrowRightLong className="icon-sub" />
			</>
		)
	} else if (subStatus === 2) {
		return (
			<>
				<FaArrowRightLong className="icon-sub" />
				{positionName}
			</>
		)
	} else {
		return (
			<>
				{positionName}
			</>
		)
	}
}

type NetStatValueProps = {
	value: number | null | undefined
	position: string
	isNullPosition?: boolean
}

const NetStatValue = (props: NetStatValueProps) => {

	if (props.value === null || props.value === undefined)
		return null

	let str = Number.parseFloat(String(props.value)).toFixed(1)

    if (str === '-0.0') {
        str = '0.0'
    }

    let myClass
    if (str.charAt(0) === '-') {
        myClass = 'negative'
    } else {
		str = `+${str}`
        myClass = 'positive'
	}

	return (
		<div className={`netStatValue netStatValue-${myClass} cell-position-${props.position}`}>
			{str}
		</div>
	)
}

type PairElementProps = {
	value: number
	position: string
	offset?: number
	isTrio?: boolean
}

const PairElement = (props: PairElementProps) => {

	return (
		<div className={`pair-element ${props.isTrio ? 'trio-element' : ''} ${props.offset ? 'trio-element-offset-' + props.offset : ''}`}>
			<NetStatValue position={props.position} value={props.value} />
		</div>
	)
}

export default GridPositions
