import React from 'react'
import {
	IonButton,
    IonIcon,
} from '@ionic/react'
import './editbutton.css'

type EditButtonProps = {
	show: boolean
	ready: boolean
	editMode?: boolean
	text?: string
	icon?: string | JSX.Element
	onClick: (e: React.MouseEvent) => void
	className?: string
}

const EditButton = (props: EditButtonProps) => {

	if (!props.show)
		return null

    const myClasses = [ 'button-edit' ]
    if (props.className) {
        myClasses.push(props.className)
    }
    if (props.editMode) {
        myClasses.push('enabled', 'edit-mode')
    }

	const editButtonText = props.text || 'Edit'

    let editButtonIcon = null
	const { icon } = props
    if (icon) {
        if (typeof icon === 'string') {
            editButtonIcon = <IonIcon icon={icon} slot="start" />
        } else {
            editButtonIcon = icon
            myClasses.push('button-my-icon')
        }
    }

	const fill = props.editMode ? 'clear' : 'solid'

	return (
		<IonButton
			fill={fill}
            className={myClasses.join(' ')}
			disabled={!props.ready}
			onClick={e => {
				e.stopPropagation()
				e.preventDefault()
				props.onClick(e)
			}}
		>
			{editButtonIcon}
			<span className="roster-button-text">
				{editButtonText}
			</span>
		</IonButton>
	)
}

export default EditButton
