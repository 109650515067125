import React from 'react'
import {
    useMyPlayerName,
} from './data'
import {FaArrowRightLong} from 'react-icons/fa6'

type PlayerNameProps = {
	playerId?: PlayerId | null
	subPlayerId?: PlayerId | null
	allowBlank: true
} | {
	playerId: PlayerId
	allowBlank?: false
	subPlayerId?: PlayerId | null
}

/*
 | {
	roster: RosterData
	subs: Subs,
	i: number
}
*/

const PlayerName = (props: PlayerNameProps) => {

    const { playerId, subPlayerId } = props

    let playerName = useMyPlayerName(playerId || '')

    if (!playerName) {
        if (props.allowBlank) {
            playerName = ''
        } else if (playerId) {
            playerName = playerId
        } else {
            playerName = '???'
        }
    }

    const myClasses = [ 'player-name' ]

    if (playerName.length >= 14) {
        myClasses.push('very-long-name')
    } else if (playerName.length >= 9) {
        myClasses.push('long-name')
    }

	let content = (
		<div className={myClasses.join(' ')}>
			{playerName}
		</div>
	)

	if (subPlayerId) {
		content = (
			<>
				{content}
				<FaArrowRightLong className="icon-sub" />
				<PlayerName playerId={subPlayerId} />
			</>
		)
	}

	return content
}

export default PlayerName
