import React, { useEffect, useState, useCallback } from 'react'
import {
	IonButton,
} from '@ionic/react'
import { doc, updateDoc } from "firebase/firestore"
import { db } from './firebase'
import { v4 as uuid } from 'uuid'
import LoadingBar from './LoadingBar'
import ShareButton from './ShareButton'
import {
	useClub,
} from './data'
import { getUrl } from './util'
import './invitationlink.css'

//
// Generate link - valid for 180 days. Can cancel it.
//
// Need to store emails etc in a sub-document of '/clubs' - e.g. '/clubs/admin' -
// with restricted read access, so random internet people can't view it.
//
// Generate privateCode and store in /clubs/admin, which is what gets (privately)
// sent to people who want to join, while also storing a hashed version publicCode
// in /clubs, which anyone can read. When someone tries to join, we hash their
// supplied code and check if it matches publicCode.
//
// Also publicly store codeExpiryTime.
//
// HOWEVER -- we can't allow this app to determine whether the user is permitted
// to join or not, can we? Because someone could just discover the endpoints and
// send "confirmed" requests to Firebase.
//
// So I probably need to write a Firebase Cloud Function to handle this.
//
// In App:
// - When club is created, also create /admin subdocument with user names (writable by this admin)
// - Allow admin to promote another user to admin
//
// Cloud Function:
// - Direct: Generate random invite code, store in /clubs/admin
// - Check whether join code is correct; if so, join new user to club
//

const EXPIRY_LENGTH		= 180 * 86400 * 1000

const InvitationLink = () => {

    const [ show, setShow ] = useState(false)

    return (
        <div className="invitation-link">
            <IonButton
                onClick={() => setShow(!show)}
            >
                Invite to Club...
            </IonButton>
            <InvitationLinkCode
                show={show}
                setShow={setShow}
            />
        </div>
    )
}

type InvitationLinkCodeProps = {
	show: boolean,
	setShow: (show: boolean) => void,
}

const InvitationLinkCode = (props: InvitationLinkCodeProps) => {

    const { show, setShow } = props

    const club = useClub()

	const [ code, setCode ] = useState<string | null>(null)
	const [ codeExpiry, setCodeExpiry ] = useState(0)

    const { id, inviteCodes } = club || { }

	const setInviteCodes = useCallback(async (newInviteCodes: ClubInviteCode[]) => {
		if (!id) {
			throw new Error("No club ID")
		}
		try {
			await updateDoc(doc(db, '/clubs', id), {
				inviteCodes: newInviteCodes,
			})
			console.log("Doc updated", newInviteCodes)
		} catch (err) {
			console.error("Failed to update doc", err)
		}
	}, [ id ])

	useEffect(() => {

		let timer: ReturnType<typeof setTimeout>

		if (show && !code) {
			if (inviteCodes) {
				inviteCodes.forEach(obj => {
					const { code, expiry } = obj
					if (expiry - Date.now() > 7 * 86400 * 1000) {
						setCode(code)
						setCodeExpiry(expiry)
                        return
                    }
                })
            }

            //
            // There are no codes that are valid for at least 7 days from now, so generate a
            // new one - but not immediately: leave time for a
            // new render to call setCode() from inviteCodes, if
            // we have one already - this will cancel the timer.
            //

            timer = setTimeout(() => {
                const code = uuid()
                const expiry = Date.now() + EXPIRY_LENGTH
                const newInviteCodes = [ { code, expiry } ]

                // Keep any codes that aren't expired
                if (inviteCodes) {
                    newInviteCodes.push(...inviteCodes.filter(obj => obj.expiry > Date.now()))
                }

                setInviteCodes(newInviteCodes)

            }, 30)
        }

        return () => clearTimeout(timer)

    }, [ show, code, inviteCodes, setInviteCodes ])

	if (!club)
		return null

    if (!show)
        return null

    const revokeLink = () => {
        if (code && inviteCodes) {
            const newInviteCodes = inviteCodes.filter(obj => obj.code !== code)

            setShow(false)
            setInviteCodes(newInviteCodes)
            setCode(null)
        }
    }

    const page = code && `/join-club/${id}/${code}`

    const url = (page && getUrl({ page })) || undefined

    return (
        <div className="club-invitation-link-container">
            <p>
                Anyone can join your club with this link:
            </p>
            <LoadingBar loading={!code} variant="indeterminate" />
            {
                code ? (
                    <>
                        <p>
                            <a
                                className="club-invitation-link"
                                href={url}
                            >
                                {url}
                            </a>
                        </p>
                        <p>
                            <ShareButton
                                url={url}
                                title={`Join ${club.name}`}
                                size="default"
                                fill="solid"
                                expand="block"
                            >
                                Share Link
                            </ShareButton>
                        </p>
                        <p>
                            This link can be re-used by multiple people until
							{' '}
							{
								new Date(codeExpiry)?.toLocaleDateString(undefined, {
									year: 'numeric',
									month: 'long',
									day: 'numeric',
								}) || '???'
							}
							, after which you must regenerate it.
                        </p>
                        <p className="revoke-link-container">
                            <IonButton
                                className="button-cancel"
                                onClick={revokeLink}
                                fill="clear"
                                color="danger"
                                size="small"
                            >
                                Revoke Link
                            </IonButton>
                        </p>
                    </>
                ) : (
                    <p>
                        Generating link...
                    </p>
                )
            }
        </div>
    )
}

export default InvitationLink
