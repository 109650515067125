import "./stats.css"

import { FaCalendarTimes, FaStreetView } from 'react-icons/fa'
import {
    IonIcon,
    IonItem,
	IonLabel,
    IonRippleEffect,
    IonRouterLink,
    IonSegment,
    IonSegmentButton,
    IonToggle,
} from '@ionic/react'
import { analyticsSharp, peopleSharp, ribbonSharp } from 'ionicons/icons'
import React, { useState } from 'react'

import { MoreInfoLink } from './Help'
import { queryFieldPositionColors, queryFieldPositions, queryFieldPositionsPlusBench } from './definitions'
import { parseData, labelRound } from './util'
import {
    useCanViewNetStats,
    useIncludeFutureRostersState,
    useMyGameFormat,
    useMyGames,
    useMyNetStats,
	useMyNumPeriods,
    useMyPlayers,
    useMyRosters,
	useMyTeam,
} from './data'
import BestQtrs from './BestQtrs'
import DecimalNumber from './DecimalNumber'
import FadeText from './FadeText'
import LoadingSpinner from './LoadingSpinner'
import Pairs from './Pairs'
import PlayerName from './PlayerName'
import orange from './orange.svg'

const modes = {
	positions: {
		order: 1,
        label: 'Positions',
        title: 'Positions Played',
        icon: <FaStreetView />,
		displaysNetStats: false,
    },
	netStats: {
		order: 2,
        label: 'NetStats',
        title: 'NetStats',
        icon: <IonIcon icon={analyticsSharp} />,
        displaysNetStats: true,
    },
	best: {
		order: 3,
        label: 'Best',
        title: 'Best Quarters',
        icon: <IonIcon icon={ribbonSharp} />,
        displaysNetStats: true,
    },
	pairs: {
		order: 4,
        label: 'Pairs',
        title: 'Combinations',
        icon: <IonIcon icon={peopleSharp} />,
        displaysNetStats: true,
    },
	orange: {
		order: 5,
        label: 'Captain / Absent',
        title: 'Absentees & Leadership',
        icon: <FaCalendarTimes />,
		displaysNetStats: false,
    }
} as const

const DEFAULT_MODE = 'positions'

const netStatsModes = {
    average: {
        order: 1,
        label: <>Average</>,
        text: "Average net goal difference per position. High numbers are associations with unusually good team results, and may indicate a player should be tried there more often. Scores are adjusted for fixture difficulty, to prevent bias from who was present for easier or tougher games.",
    },
    season: {
        order: 2,
        label: <>Season</>,
        text: "Total net goal difference per position this season. Higher numbers are better. Strongly negative numbers may indicate a player being repeatedly played in a sub-optimal position. Scores around zero mean average team performance. A player who invariably plays the same position must score zero, no matter how talented, as the average result with her in that position is no different to the average overall. Adjusted for fixture difficulty, to prevent bias from who was present for easier or tougher games.",
    },
} as const

const bestModes = {
    bestQtrs: {
        order: 1,
        label: <>Overall</>,
        text: "Net goal difference in a quarter (goals scored minus goals conceded).",
    },
    attack: {
        order: 2,
        label: <>Attack</>,
        text: "Goals scored in a quarter."
    },
    defence: {
        order: 3,
        label: <>Defence</>,
        text: "Goals conceded in a quarter.",
    },
} as const

const pairsModes = {
    pairs: {
        order: 1,
        label: <>Pairs</>,
    },
    trios: {
        order: 2,
        label: <>Trios</>,
    },
} as const

const orangeModes = {
    absent: {
        order: 1,
        label: <>Absent</>
    },
    orangeman: {
        order: 2,
        label: <>Captain</>,
    },
} as const

const DEFAULT_NETSTATS_MODE 	= 'average' as const
const DEFAULT_BEST_MODE 		= 'bestQtrs' as const
const DEFAULT_PAIRS_MODE 		= 'pairs' as const
const DEFAULT_ORANGE_MODE 		= 'absent' as const
const DEFAULT_POSITIONS_MODE 	= 'total' as const

const Stats = () => {
	const players = useMyPlayers()
    const games = useMyGames()
    const rosters = useMyRosters()
	const numPeriods = useMyNumPeriods()
	const gameFormat = useMyGameFormat()
    const netStats = useMyNetStats()

	const team = useMyTeam()

	const [ mode, setMode ] = useState<keyof typeof modes>(DEFAULT_MODE)
	const [ netStatsMode, setNetStatsMode ] = useState<keyof typeof netStatsModes>(DEFAULT_NETSTATS_MODE)
	const [ bestMode, setBestMode ] = useState<keyof typeof bestModes>(DEFAULT_BEST_MODE)
	const [ orangeMode, setOrangeMode ] = useState<keyof typeof orangeModes>(DEFAULT_ORANGE_MODE)
	const [ pairsMode, setPairsMode ] = useState<keyof typeof pairsModes>(DEFAULT_PAIRS_MODE)
	const [ positionsMode, setPositionsMode ] = useState<'total'|'benched'>(DEFAULT_POSITIONS_MODE)

    const canViewNetStats = useCanViewNetStats()

    const [ includeFutureRosters, setIncludeFutureRosters ] = useIncludeFutureRostersState()

	const togglePositionsMode = () => setPositionsMode(positionsMode === 'total' ? 'benched' : 'total')

    const loading = players === null

	const thisMode = modes[mode]

    const stats = parseData({
        players,
        games,
        rosters,
		numPeriods,
		gameFormat,
    }, includeFutureRosters)

    // console.log('Stats', stats)
	// console.log('netStats', netStats)

    let subSegmentBar
    let futureRosters
	let headerText = null
	let header = null
    let content = null
	let footerText = null

    if (mode === 'netStats') {
		const sortedNetStatsModes = Object.entries(netStatsModes).sort(([ , valueA ], [ , valueB ]) => valueA.order - valueB.order).map(([ key ]) => key) as Array<keyof typeof netStatsModes>

        subSegmentBar = (
            <IonSegment
                className="stats-options-netStats-segment"
				onIonChange={(e: CustomEvent) => {
					const value = e.detail.value
                    if (value !== undefined && value in netStatsModes) {
                        setNetStatsMode(value as keyof typeof netStatsModes)
                    }
                }}
                value={netStatsMode}
            >
                {
                    sortedNetStatsModes.map(modeId => (
                        <IonSegmentButton key={modeId} value={modeId}>
                            <IonLabel>
                                {netStatsModes[modeId].label}
                            </IonLabel>
                        </IonSegmentButton>
                    ))
                }
            </IonSegment>
        )
    } else if (mode === 'best') {
		const sortedBestModes = Object.entries(bestModes).sort(([ , valueA ], [ , valueB ]) => valueA.order - valueB.order).map(([ key ]) => key) as Array<keyof typeof bestModes>

        subSegmentBar = (
            <IonSegment
                className="stats-options-best-segment"
				onIonChange={(e: CustomEvent) => {
					const value = e.detail.value
					if (value !== undefined && value in bestModes) {
                        setBestMode(value as keyof typeof bestModes)
                    }
                }}
                value={bestMode}
            >
                {
                    sortedBestModes.map(modeId => (
                        <IonSegmentButton key={modeId} value={modeId}>
                            <IonLabel>
                                {bestModes[modeId].label}
                            </IonLabel>
                        </IonSegmentButton>
                    ))
                }
            </IonSegment>
        )
    } else if (mode === 'pairs') {
		const sortedPairsModes = Object.entries(pairsModes).sort(([ , valueA ], [ , valueB ]) => valueA.order - valueB.order).map(([ key ]) => key) as Array<keyof typeof pairsModes>

        subSegmentBar = (
            <IonSegment
                className="stats-options-pairs-segment"
				onIonChange={(e: CustomEvent) => {
					const value = e.detail.value
					if (value !== undefined && value in pairsModes) {
                        setPairsMode(value as keyof typeof pairsModes)
                    }
                }}
                value={pairsMode}
            >
                {
                    sortedPairsModes.map(modeId => (
                        <IonSegmentButton key={modeId} value={modeId}>
                            <IonLabel>
                                {pairsModes[modeId].label}
                            </IonLabel>
                        </IonSegmentButton>
                    ))
                }
            </IonSegment>
        )
    } else if (mode === 'orange') {
		const sortedOrange = Object.entries(orangeModes).sort(([ , valueA ], [ , valueB ]) => valueA.order - valueB.order).map(([ key ]) => key) as Array<keyof typeof orangeModes>

        subSegmentBar = (
            <IonSegment
                className="stats-options-orange-segment"
				onIonChange={(e: CustomEvent) => {
					const value = e.detail.value
                    if (value !== undefined && value in orangeModes) {
                        setOrangeMode(value as keyof typeof orangeModes)
                    }
                }}
                value={orangeMode}
            >
                {
                    sortedOrange.map(modeId => (
                        <IonSegmentButton key={modeId} value={modeId}>
                            <IonLabel>
                                {orangeModes[modeId].label}
                            </IonLabel>
                        </IonSegmentButton>
                    ))
                }
            </IonSegment>
        )

    }

    if (mode === 'positions' || mode === 'orange') {

        futureRosters = (
            <IonItem lines="none" className="includefuturerostersbar">
                <IonToggle
					checked={includeFutureRosters}
                    name="includeFutureRosters"
                    onIonChange={() => setIncludeFutureRosters(!includeFutureRosters)}
                    slot="end"
                >
					Include future rosters
				</IonToggle>

            </IonItem>
        )
    }

	if (!team) {

		content = null

	} else if (loading) {

        content = <LoadingSpinner />

    } else if (!Object.keys(stats).length) {

        content = (
            <div className="roster-warning">
                No players have been added yet.
                {' '}
                <IonRouterLink
                    routerLink="/players"
                >
                    Add some players.
                </IonRouterLink>
            </div>
        )

    } else {
		const sortedPlayers = Object.entries(players).sort(([ , valueA ], [ , valueB ]) => valueA.name ? valueA.name.localeCompare(valueB.name) : -1).map(([ key ]) => key)

		let max: number = 0
        if (mode === 'netStats') {
            const field = netStatsMode === 'season' ? 'raw' : 'normalized'
			max = Object.values(netStats).reduce<number>((teamMax, obj) => {
                const pMax = Math.max(...(obj[field] as number[]))
                return pMax > teamMax ? pMax : teamMax
            }, 0)

        } else if (mode === 'positions') {
            max = Math.max(...Object.values(stats).map(row => row.positions).flat())
        }

        const headerDivs = [ ]

        if (mode === 'orange' && orangeMode === 'orangeman') {
            headerDivs.push(
                <div
                    key="orangeman"
                    className="cell cell-position summaryline-header-text"
                >
                    <img
                        src={orange}
                        alt="Oranges"
                        title="Oranges"
                    />
                </div>
            )
        } else if (mode === 'orange' && orangeMode === 'absent') {
            headerDivs.push(
                <div
                    key="absent"
                    className="cell cell-position summaryline-header-text"
                >
                    <FaCalendarTimes
                        className="roster-no"
                        title="Absent"
                    />
                </div>
            )
        } else if (mode === 'pairs') {
            headerDivs.push(
                <div
                    key="pairs-p1"
                    className="cell cell-position cell-spacer"
                />,
                <div
                    key="pairs-p2"
                    className="cell cell-position cell-spacer"
                />,
            )
            if (pairsMode === 'trios') {
                headerDivs.push(
                    <div
                        key="pairs-p3"
                        className="cell cell-position cell-spacer"
                    />
                )
            }
            headerDivs.push(
                <div
                    key="pairs-total"
                    className="summaryline-header-text"
                >
                    <IonIcon icon={analyticsSharp} /> Season
                </div>,
                <div
                    key="pairs-avg"
                    className="summaryline-header-text"
                >
                    <IonIcon icon={analyticsSharp} /> Avg
                </div>,
            )
        } else {
			const fieldPositions = queryFieldPositions(gameFormat)

            fieldPositions.forEach((positionName, index) =>
                {
                    headerDivs.push(
                        <div
                            key={index}
                            className={`cell cell-position cell-position-${positionName}`}
                        >
                            {positionName}
                        </div>
                    )
                })
            if (mode === 'positions') {
                headerDivs.push(
                    <div
                        key="total"
                        className="cell cell-position summaryline-header-text positions-toggle-mode"
						onClick={togglePositionsMode}
                    >
						<FadeText>
							{positionsMode === 'total' ? 'T' : '-'}
						</FadeText>
                    </div>
                )
            } else if (mode === 'best') {
                headerDivs.push(
                    <div
                        key="total"
                        className="stats-total-diff"
                    />
                )
            } else {
                // netStats mode
                headerDivs.push(
                    <div
                        key="bench"
                        className="cell cell-position cell-position--"
                    >
                        -
                    </div>
                )
            }
        }

        let showContent = true

        if (thisMode.displaysNetStats) {
            if (!canViewNetStats) {

                showContent = false

                headerText = (
                    <p className="stats-header-text">
                        These stats can only be viewed by the team coach.
                    </p>
                )

            } else if (!netStats || !Object.keys(netStats).length) {

                showContent = false

                headerText = (
                    <p className="stats-header-text">
                        Available when you've entered some quarter-by-quarter scores.
                        {' '}
                        <IonRouterLink routerLink="/help#stats">
                            Info...
                        </IonRouterLink>
                    </p>
                )

            }
        }

        if (showContent) {
            header = (
                <div className="summaryline summaryline-header">
                    <div className="bestqtr" />
                    {
                        headerDivs.map(div => div)
                    }
                </div>
            )

            if (mode === 'best') {
                content = <BestQtrs type={bestMode} />
            } else if (mode === 'pairs') {
                content = <Pairs mode={pairsMode} />
            } else {

                content = (
                    <div>
                        {
                            sortedPlayers.map(playerId =>
                                <PlayerSummary
                                    key={playerId}
                                    playerId={playerId}
                                    stats={stats[playerId]}
                                    netStats={netStats[playerId]}
                                    games={games || { }}
                                    mode={mode}
									gameFormat={gameFormat}
                                    netStatsMode={netStatsMode}
                                    orangeMode={orangeMode}
									positionsMode={positionsMode}
									togglePositionsMode={togglePositionsMode}
                                    max={max}
                                />
                            )
                        }
                    </div>
                )
            }

            if (mode === 'netStats') {
                footerText = netStatsModes[netStatsMode].text
            } else if (mode === 'best') {
                footerText = bestModes[bestMode].text
            } else if (mode === 'positions') {
                footerText = 'Number of quarters played in a position.'
            }
        }
    }

	const sortedModes = Object.entries(modes).sort(([ , valueA ], [ , valueB ]) => valueA.order - valueB.order).map(([ key ]) => key)

    return (
        <section className={`stats stats-mode-${mode} ${mode === 'netStats' ? 'stats-nmode-' + netStatsMode : ''} stats-gameformat-${gameFormat}`}>

            <div className="stats-options">
                <IonSegment
                    className="filter-buttons"
					onIonChange={(e: CustomEvent) => {
						const value = e.detail.value
						if (value !== undefined && value in modes) {
							setMode(value as keyof typeof modes)
						}
					}}
                    value={mode}
                >
					{
						sortedModes.map(mode => {
							const modeData = modes[mode as keyof typeof modes]
							return (
								<IonSegmentButton key={mode} value={mode}>
									<IonLabel
										className={`stats-option stats-option-${mode}`}
										title={modeData.label}
									>
										{modeData.icon}
									</IonLabel>
								</IonSegmentButton>
							)
						})
					}
				</IonSegment>
			</div>

			<h2 className="stats-section-title">
				<FadeText>
					{thisMode?.title}
				</FadeText>
			</h2>

			{futureRosters}

			{subSegmentBar}
			{headerText}
			{header}
			{content}

			{
				footerText && (
					<p className="info">
						{footerText}
						<MoreInfoLink
							helpSection="stats"
							helpSubSection={mode}
							text="More info..."
						/>
					</p>
				)
			}

		</section>
	)
}

type PlayerSummaryProps = {
	playerId: string
	stats: PlayerStats
	games: Games
	netStats: PlayerNetStats
	mode: string
	gameFormat: GameFormat
	netStatsMode: string
	orangeMode: string
	positionsMode: string
	togglePositionsMode: () => void
	max: number
}

const PlayerSummary = (props: PlayerSummaryProps) => {
	const { playerId, stats, games, netStats, mode, gameFormat, netStatsMode, orangeMode, positionsMode, togglePositionsMode } = props

	const fieldPositions = queryFieldPositions(gameFormat)
	const fieldPositionColors = queryFieldPositionColors(gameFormat)

	// console.log('PlayerSummary', props, fieldPositionColors)

    let total = 0
    for (let i = 0; i < fieldPositions.length; i++) {
        total += stats.positions[i]
    }

	// console.log('PlayerSummary stats', stats)

    const divs = [ ]

    if (mode === 'orange' && orangeMode === 'orangeman') {

        divs.push(
            <div key="orangeman">
                <FadeText>
                    {stats.orangemanGames.length || '-'}
                </FadeText>
            </div>,
            <div key="orangeman-games" className="orangeman-games">
                <FadeText>
                    {
                        compileRoundNames({
                            games,
                            rounds: stats.orangemanGames,
                        })
                    }
                </FadeText>
            </div>
        )
    } else if (mode === 'orange' && orangeMode === 'absent') {
        divs.push(
            <div key="absent">
                <FadeText>
                    {stats.absentGames.length || '-'}
                </FadeText>
            </div>,
            <div key="absent-games" className="absent-games">
                <FadeText>
                    {
                        compileRoundNames({
                            games,
                            rounds: stats.absentGames
                        })
                    }
                </FadeText>
            </div>
        )
    } else if (mode === 'netStats') {
        // console.log('netStats', netStats)
        if (netStats) {
			queryFieldPositionsPlusBench(gameFormat).forEach((position, index) => {
				const style: {
					backgroundColor?: string
					border?: string
				} = { }

				const n = (netStatsMode === 'season') ? netStats.raw[index] : netStats.normalized[index]

				const hexOpacityValue = props.max ? Math.max(0, Math.min(1, (n || 0) / props.max)) : 0
				const hexOpacity = Math.floor(255 * hexOpacityValue).toString(16).padStart(2, '0')
				style.backgroundColor = `${fieldPositionColors[index]}${hexOpacity}`

				if (n !== null && n < 0) {
					const borderOpacityValue = props.max ? Math.max(0, Math.min(1, 0.75 * Math.abs(n) / props.max)) : 0
					const borderOpacity = Math.floor(255 * borderOpacityValue).toString(16).padStart(2, '0')
					style.border = `1px solid ${fieldPositionColors[index]}${borderOpacity}`
				}

				divs.push(
					<FadeText
						isBlock={true}
						contentKey={String(n)}
						key={index}
					>
						<div
							className={`netStat-cell cell-position-${position}`}
							style={style}
						>
							<DecimalNumber value={n} className="number" />
						</div>
					</FadeText>
				)
			}
										   )
		}
	} else {
		fieldPositions.forEach((position, index) => {
			const n = stats.positions[index]
			const opacity = props.max ? (n / props.max) : 0
			const hexOpacity = Math.floor(255 * opacity).toString(16).padStart(2, '0')

			const str = String(n || '').replace(/\.5$/, '½').replace(/^0½$/, '½')

			divs.push(
				<div
					key={index}
					className={`netStat-cell cell-position-${position}`}
					style={{
						backgroundColor: `${fieldPositionColors[index]}${hexOpacity}`,
					}}
				>
					<FadeText
						className="number"
					>
						{str}
					</FadeText>
				</div>
			)
		})
		divs.push(
			<div
				key="total"
				className="netStat-cell positions-toggle-mode"
				onClick={togglePositionsMode}
			>
				<FadeText>
					{String(positionsMode === 'total' ? total : (stats.qtrsBenched || '-')).replace(/\.5$/, '½').replace(/^0½$/, '½')}
				</FadeText>
			</div>
		)
	}

	return (
		<div className="summaryline">
			<IonRouterLink
				routerLink={`/players/${playerId}`}
				className="stats-player"
			>
				<PlayerName playerId={playerId} />
				<IonRippleEffect />
			</IonRouterLink>
			{
				divs.map(div => div)
			}
		</div>
	)
}

type CompileRoundNamesProps = {
	games: Games
	rounds: string[]
}

function compileRoundNames(props: CompileRoundNamesProps) {

	const { games, rounds } = props

    if (!games || !rounds || !rounds.length) {
        return null
    }

    if (rounds.length === 1) {
        return labelRound(games[rounds[0]].round)
    }
    let str = rounds.map(gameId => games[gameId].round).join(', ')
    if (String(str).match(/^\d/)) {
        str = `Rounds ${str}`
    }
    return str
}

export default Stats
