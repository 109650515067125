import React from 'react'
import {
    useMyGame,
	useMyNumPeriods,
} from './data'
import {
	formatResult,
} from './functions'

const DisplayScores = () => {

    const game = useMyGame()
	const numPeriods = useMyNumPeriods()

	let content = null

	if (game?.result) {

		const result = formatResult(game.result, numPeriods)

		// Create array of numbers from 0 to numPeriods,
		// i.e. [ 0, 1, 2, 3 ]
		const qtrs = [ ...Array(numPeriods).keys() ]

        //
        // If we only have Final scores, don't show a qtr by qtr breakdown.
        //
        const haveQtrByQtrScores = qtrs.filter(qtr => qtr < numPeriods - 1 && result.me[qtr] !== null && result.them[qtr] !== null).length

		content = (
			<div className="undergrid undergrid-scores">
				<div className={`grid grid-${numPeriods}`}>
					<div className="column column-players">
						<div className="fixed-element">
                            <DisplayScore
                                className="cell cell-position cell-void"
                                result={result}
                                final={true}
								numPeriods={numPeriods}
                            />
						</div>
					</div>
					{
                        haveQtrByQtrScores ? qtrs.map(qtr => (
							<div
								key={qtr}
								className="column column-positions"
							>
								<div className="fixed-element">
                                    <DisplayScore
                                        className="cell cell-position cell-void"
                                        result={result}
                                        qtr={qtr}
										numPeriods={numPeriods}
                                    />
								</div>
							</div>
                        )) : null
					}
				</div>
			</div>
		)
	}

	let editContent = null

	return (
		<>
			{content}
			{editContent}
		</>
	)
}

type DisplayScoreProps = {
	result: Result | undefined
	numPeriods: number
	qtr?: number
	final?: boolean
	className?: string
}

export const DisplayScore = (props: DisplayScoreProps) => {

    const { result, qtr, final, numPeriods, className } = props

    if (!result)
        return null

	let myScore: number | null = null
	let theirScore: number | null = null

	if (final) {
		myScore = result.me[numPeriods - 1] ?? null
		theirScore = result.them[numPeriods - 1] ?? null
	} else if (!qtr) {
		myScore = result.me[0] ?? null
		theirScore = result.them[0] ?? null
	} else {
		const currentMyScore = result.me[qtr] ?? null
		const previousMyScore = result.me[qtr - 1] ?? null
		const currentTheirScore = result.them[qtr] ?? null
		const previousTheirScore = result.them[qtr - 1] ?? null

		// If any of the required values are null, return null
		if (currentMyScore === null || previousMyScore === null || currentTheirScore === null || previousTheirScore === null) {
			return null
		}

		myScore = currentMyScore - previousMyScore
		theirScore = currentTheirScore - previousTheirScore
	}

	if (myScore === null || theirScore === null || Number.isNaN(myScore) || Number.isNaN(theirScore)) {
		return null
	}

    const outcome = myScore > theirScore ? 'positive' : myScore < theirScore ? 'negative' : 'equal'

    const outcomes = {
        positive: 'Won',
        equal: 'Drew',
        negative: 'Lost',
    }

    const myClasses = [ 'cell-netstat', 'cell-netstat-scoresummary', `cell-netstat-${outcome}` ]
    if (className) {
        myClasses.push(className)
    }

    return (
        <div className={myClasses.join(' ')}>
            {
                final && (
                    <div className="result-outcome">
                        {outcomes[outcome]}
                    </div>
                )
            }

            <div className="result-scoreline">
                {myScore} - {theirScore}
            </div>
        </div>
    )
}

export default DisplayScores
