import React from 'react'
import {
    IonButton,
    useIonAlert,
} from '@ionic/react'
import GoButton from './GoButton'
import EditButton from './EditButton'
import UndoRosterButton from './UndoRosterButton'
import {
    useCanEditItem,
    useEditModeState,
    useMyGame,
    useReady,
    useShowPositionPreferencesState,
} from './data'
import { TiThSmallOutline } from 'react-icons/ti'
import { FaStreetView } from 'react-icons/fa'
import './controlbox.css'

type ControlBoxProps = {
	haveSetPositions: boolean
	showAltBoard: boolean
	onToggleAltBoard: () => void
	onAutoRoster: () => void
}

const ControlBox = (props: ControlBoxProps) => {

	const { haveSetPositions, showAltBoard, onToggleAltBoard, onAutoRoster } = props

    const [ editMode, setEditMode ] = useEditModeState()
    const canEditItem = useCanEditItem()
    const ready = useReady()
    const game = useMyGame()

    const [ showPositionPreferences, setShowPositionPreferences ] = useShowPositionPreferencesState()

    const [ presentAlert ] = useIonAlert()

    /*
	const onPrint = e => {
		if (showPositionPreferences) {
			onTogglePositionPreferences()
			setTimeout(() => window.print(), 100)
		} else if (editMode) {
			onEdit()
			setTimeout(() => window.print(), 100)
		} else {
			window.print()
		}
	}
    */

	const onSetEditMode = (newValue: boolean) => {
		setEditMode(newValue)
		setShowPositionPreferences(false)
	}

    return (
        <div className="controlbox">

            <div className="controlbox-left">

                <EditButton
                    show={canEditItem}
                    ready={ready}
                    editMode={editMode}
					text={editMode ? 'Done' : undefined}
                    onClick={() => {
                        const isPastGame = game && game.date < Date.now()
                        if (isPastGame && !editMode) {
                            presentAlert({
                                header: 'Past Game',
                                message: 'This game is in the past. Are you sure you want to change the roster?',
                                buttons: [
                                    {
                                        text: 'Cancel',
                                        role: 'cancel',
                                    },
                                    {
                                        text: 'Edit',
                                        role: 'confirm',
                                        handler: () => onSetEditMode(true),
                                    },
                                ],
                            })
                        } else {
                            onSetEditMode(!editMode)
                        }
                    }}
                />
            </div>

            <div className="controlbox-center">

                <UndoRosterButton />

                <IonButton
                    className={'button-preferences button-react-icon' + (showPositionPreferences ? ' enabled' : '') + (haveSetPositions ? ' have-set-positions' : '')}
                    onClick={() => setShowPositionPreferences(!showPositionPreferences)}
                    title="Set some required player positions"
                    color="light"
                >
                    <FaStreetView />
                    <span className="roster-button-text">
                        Positions
                    </span>
                </IonButton>

                <IonButton
                    className={'button-toggleview button-react-icon ' + (showAltBoard ? 'enabled' : '')}
                    onClick={onToggleAltBoard}
                    title="Change how you view the roster"
                    color="light"
                >
                    <TiThSmallOutline />
                    <span className="roster-button-text">
                        View
                    </span>
                </IonButton>

            </div>

            <div className="controlbox-right">
                <GoButton
                    onAutoRoster={onAutoRoster}
                />
            </div>

        </div>
    )
}

export default ControlBox

