import React, { useState } from 'react'
import {
	IonButton,
    IonIcon,
    IonItem,
	IonLabel,
} from '@ionic/react'
import { Provider, providers, unlinkProvider } from './providers'
import {
	useUser,
    useSetUser,
} from './data'
import { DisplayUser } from './Profile'
import { ErrorMessage, PhoneNumberModal } from './Login'
import LoadingBar from './LoadingBar'
import DeleteAccountButton from './DeleteAccountButton'
import { userObject } from './util'
import {
    checkmarkSharp,
} from 'ionicons/icons'
import './signons.css'
import {AskToVerifyEmail} from './MyTeams'

//
// Set to true to let people unlink accounts. Which I haven't
// enabled because it's probably more trouble than it's worth, but it does
// seem to work.
//
const ALLOW_UNLINKINGS      = false // true // TODO

//
// I don't think I'll allow people to add new EMAIL
// sign-in methods, just because it's a pain to manage creating
// new accounts - you have to send verification emails. But
// I might want to add this later.
//
const SignOns = () => {

    const user = useUser()

    const [ loading, setLoading ] = useState(false)
	const [ err, setErr ] = useState<string | null>(null)

    console.log('SignOns with user', user)

    if (!user)
        return null

    const myProviderIds = user.providerData.map(provider => provider.providerId)

    return (
        <section className="signons">

            <LoadingBar loading={loading} variant="indeterminate" />

            <DisplayUser />

			<AskToVerifyEmail />

			<p>
				You can sign in with:
			</p>
			{
				providers.sort((a, b) => Number(myProviderIds.includes(b.id)) - Number(myProviderIds.includes(a.id))).map(provider => (
                    <Method
                        key={provider.id}
                        method={provider}
                        isUsed={myProviderIds.includes(provider.id)}
						providerData={user.providerData.filter(p => p.providerId === provider.id)?.[0]}
                        setLoading={setLoading}
                        setErr={setErr}
                    />
                ))
            }
            <ErrorMessage err={err} />
            <p>
                You don't need more than one way to sign in, but a
                backup can be a life-saver.
            </p>

			<div className="display-user-block">
				<IonLabel>
					User ID
				</IonLabel>
				<IonLabel className="user-uid">
					{user.uid}
				</IonLabel>
			</div>

			<DeleteAccountButton />

        </section>
    )
}

type MethodProps = {
	method: Provider
	isUsed: boolean
	providerData: ProviderData
	setLoading: (loading: boolean) => void
	setErr: (err: string|null) => void
}

const Method = (props: MethodProps) => {

    const { method, isUsed, setLoading, setErr } = props

    const setUser = useSetUser()

	const [ showSubModal, setShowSubModal ] = useState<string|null>(null)

    const { icon, disabled, name, type, link } = method

    //
    // If we don't allow logins/links on this platform, don't list it.
    //
    if (!isUsed && !link)
        return null

	const mySetErr = (msg: string|null) => {
		const niceMessages: {
			[key: string]: string
		} = {
			'auth/invalid-verification-code': 'The verification code you entered is not correct.',

			'auth/provider-already-linked': `This ${type} is already enabled as your AutoRoster login.`,

			// This error might be if the account is standalone, as opposed to one of multiple linked credentials?
            'auth/credential-already-in-use': `This ${type} is already registered to a different AutoRoster user, so cannot be linked to this one.`,

			// Whereas this one is if the account is linked to other credentials.
			'auth/account-exists-with-different-credential': `This ${type} is already linked to a different account.`,
        }

		const niceMessage = msg ? niceMessages[msg] : msg
        setErr(niceMessage || msg)
    }

    const myOnClick = async () => {

        let newUser

        if (isUsed && ALLOW_UNLINKINGS) {

            setLoading(true)

            try {
                newUser = await unlinkProvider(method.id)
            } catch (err) {
                console.log('Linking failed', err)
            }

            setLoading(false)

		} else if (link) {

			if (typeof link === 'function') {

				setLoading(true)

				try {
					newUser = await link({
						setErr: mySetErr,
					})
				} catch (err) {
					console.log('Adding signon failed', err)
				}

				setLoading(false)

			} else if (method.id === 'phone') {
				console.log('ok show submodal phone')
				setShowSubModal('phone')
				// This triggers the display of the phone modal,
				// which will manage the process from there, including
				// calling setUser() on success.

			} else {
				console.error("Strange attempt to link", method.id)
			}
		}

        if (newUser) {
            console.log('After linking, new user', newUser)
            setUser(userObject(newUser))
        }

    }

    return (
        <IonItem>
            <IonIcon
                icon={icon}
                slot="start"
            />
			<IonLabel>
				{name}
			</IonLabel>
			<IonLabel className="provider-user" slot="end">
				{props.providerData?.email || props.providerData?.phoneNumber}
			</IonLabel>
			{
				isUsed && <IonIcon icon={checkmarkSharp} slot="end" />
			}
            {
                (!isUsed || ALLOW_UNLINKINGS) &&
                    <IonButton
                        className="signon-add-button"
                        slot="end"
                        size="default"
                        expand="block"
                        onClick={myOnClick}
                        disabled={disabled}
                    >
                        {isUsed ? 'Unlink' : 'Add'}
                    </IonButton>
            }
			{
				method.id === 'phone' && (
					<>
						<PhoneNumberModal
							show={showSubModal === 'phone'}
							setShow={(v: boolean) => setShowSubModal(v ? 'phone' : null)}
							amLinking={true}
							setErr={mySetErr}
						/>
						<div id="recaptcha-container" />
					</>

				)
			}


		</IonItem>
    )
}

export default SignOns
