import './connector.css'

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonModal,
  IonSearchbar,
  IonToolbar,
} from '@ionic/react'
import {
  QueryClient,
  QueryClientProvider,
  useQueries,
  useQuery,
} from '@tanstack/react-query'
import { matchSorter } from 'match-sorter'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Selector } from './EditField'
import { fetchJson, formatDate } from './util'
import { useDisableSync, useEnableSync } from './functions'
import {
  useMyRelevantConnector,
  useMyRelevantTeam,
  useSetShouldSyncFixture,
  useShowTeamSelection,
} from './data'
import netballConnectImage from './media/netball-connect.png'
import {IonSearchbarCustomEvent, SearchbarInputEventDetail} from '@ionic/core'
import {cloudDoneOutline} from 'ionicons/icons'

const queryClient = new QueryClient()

type ConnectorProps = {
	show: boolean
	onDismiss: () => void
}

const Connector = (props: ConnectorProps) => {

	const { show, onDismiss } = props

	const modal = useRef<HTMLIonModalElement>(null)

	return (
		<IonModal
			ref={modal}
			isOpen={show}
			onWillDismiss={onDismiss}
		>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="end">
						<IonButton onClick={() => modal.current?.dismiss()}>
							Cancel
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<QueryClientProvider client={queryClient}>
					<ConnectorContent
						onDismiss={onDismiss}
					/>
				</QueryClientProvider>
			</IonContent>
		</IonModal>
	)
}

type ConnectorContentProps = {
	onDismiss: () => void
}

const ConnectorContent = (props: ConnectorContentProps) => {
	const { onDismiss } = props

	const connector = useMyRelevantConnector()

	return (
		<div className="connector-content">
			<h1>
				Season Sync (Beta) <IonIcon icon={cloudDoneOutline} />
			</h1>
			<p>
				If you play in a supported league, AutoRoster can
				import your fixture and keep it up to date automatically.
			</p>
			{
				connector ? (
					<DisplayConnector onDismiss={onDismiss} />
				) : (
					<SelectConnector />
				)
			}
		</div>
	)
}

type DisplayConnectorProps = {
	onDismiss: () => void
}

const DisplayConnector = (props: DisplayConnectorProps) => {
	const { onDismiss } = props

	const connector = useMyRelevantConnector()

	const disableSync = useDisableSync()
	const setShouldSyncFixture = useSetShouldSyncFixture()

	const showTeamSelection = useShowTeamSelection()

	// console.log('connector', connector)

	const matches = useQuery({
		queryKey: [ 'matches', connector?.id ],
		enabled: connector?.type === 'NetballConnect',
		queryFn: () => fetchJson<NetballConnectMatches>(`https://api-netball.squadi.com/livescores/round/matches?competitionId=${connector?.params?.competitionId}&teamIds=[${connector?.params?.id}]&ignoreStatuses=[1]`),
	})

	// console.log('matches', matches.data, matches.data?.rounds)

	if (!connector || !matches?.data?.rounds)
		return null

	const [ round ] = matches.data.rounds

	if (!round)
		return null

	const [ match ] = round.matches

	// console.log('match', match)

	if (!match)
		return null

	const me = match.team1Id === connector.params.id ? match.team1 : match.team2

	// const netballGraderUrl = `https://netball.squiggle.com.au/${connector.params.orgName}/${connector.params.compName}/${connector.params.divId}`

	const syncNowButton = showTeamSelection ? null : (
		<IonButton
			className="sync-now-button"
			size="small"
			onClick={() => {
				setShouldSyncFixture(true)
				onDismiss()
			}}
		>
			Sync now
		</IonButton>
	)

	const content = (connector.type === 'NetballConnect') ? (
		<div className="connector">
			<div className="sync-enabled-line">
				<IonIcon icon={cloudDoneOutline} className="sync-enabled" />
				<span>
					Sync enabled via
				</span>
				<img className="netball-connect-image" src={netballConnectImage} alt="NetballConnect" />
				{syncNowButton}
			</div>
			<TeamButton
				teamName={me.name}
				logoUrl={me.logoUrl}
				divName={round.division.name}
				compName={connector.params.compName}
			/>
			<IonButton
				className="nc-sync-button"
				color="danger"
				fill="clear"
				size="default"
				expand="block"
				slot="end"
				onClick={disableSync}
			>
				Stop Syncing
			</IonButton>
		</div>
	) : (
		<div>
			Unknown Connector
		</div>
	)

	return (
		<div className="connector-content">
			{content}
		</div>
	)
}

const SelectConnector = () => {

	const team = useMyRelevantTeam()

//	const setShowConnector = useSetShowConnector()

	const firstYear = 2019
	const thisYear = (new Date()).getFullYear()

	const [ year, setYear ] = useState(thisYear)
	const [ organization, setOrganization ] = useState<string>()
	const [ connectorTeam, setConnectorTeam ] = useState<ConnectorTeam|undefined>()

	const [ matchingOrgs, setMatchingOrgs ] = useState<NetballConnectOrganizations>()
	const [ matchingTeams, setMatchingTeams ] = useState<NetballConnectAugmentedTeams>()

	const enableSync = useEnableSync()

	const orgSearchRef = useRef<HTMLIonSearchbarElement>(null)
	const teamSearchRef = useRef<HTMLIonSearchbarElement>(null)

	const numYears = (new Date()).getFullYear() - firstYear + 2

	const options = Array(numYears).fill(null).map((_, index) => ({ value: firstYear + index }))

	const onInputYear = (e: CustomEvent) => {
		const target = e.target as HTMLInputElement
		setYear(Number(target.value))
	}

	//
	// A list of every netball club & league in NetballConnect
	//
	const organizations = useQuery({
		queryKey: [ 'organizations', year ],
		queryFn: () => fetchJson<NetballConnectOrganizations>(`https://api-netball.squadi.com/users/api/organisations/all`),
	})

	// console.log('organizations', organizations, organizations.data, organizations.isPending, organizations.error)

	const myOrganisation = organizations.data?.find(obj => obj.organisationUniqueKey === organization)
	// console.log('myOrganisation', myOrganisation)

	//
	// 'competitions'
	//
	// e.g. 2024 Autumn Season Parkville
	//
	// The same team may be in multiple comps.
	//
	// Once we know the organization (i.e. club/league), we can get a list of all its
	// comps, then look up all the teams in those comps.
	//
	const competitions = useQuery({
		queryKey: [ 'competitions', organization, year ],
		enabled: !!organization,
		queryFn: () => fetchJson<NetballConnectCompetitions>(`https://api-netball.squadi.com/livescores/competitions/list?organisationUniqueKey=${organization}&yearRefId=${year - firstYear + 1}`),
	})

	// console.log('competitions', competitions.data, competitions.isPending, competitions.error)

	//
	// 'teams'
	//
	// e.g. Kensington Kites
	//
	// The same team may be in multiple comps.
	//
	const teams = useQueries({
		queries: competitions.data?.map(comp => ({
			queryKey: [ 'teams', comp.id ],
			enabled: !!competitions.data.length,
			queryFn: () => fetchJson<NetballConnectTeams>(`https://api-netball.squadi.com/livescores/teams/list?competitionId=${comp.id}`),
			select: (data: NetballConnectTeams) => {
				// console.log('woo data', data, comp.id, comp)
				return data.map(obj => {
					const augmentedObj: NetballConnectAugmentedTeam = {
						...obj,
						competitionId: comp.id
					}
					return augmentedObj
				})
			},
		})) || [ ],
		combine: results => {
			// console.log('combine results', results)
			return ({
				data: results.map(result => result.data).filter(v => !!v),
				isPending: results.some(result => result.isPending),
				error: results.find(result => result.error),
			})
		},
	})

	const teamsData = (myOrganisation && !competitions.isPending && !teams.isPending && teams.data?.flat()) || undefined

	const allTeams: NetballConnectAugmentedTeams | undefined = teamsData?.sort((a, b) => Number(a?.id) - Number(b?.id))

	// console.log('teams', teams, teams?.data, teams?.isPending, teams?.error, teamsData, allTeams)

	// console.log('allTeams', allTeams)
	// console.log('ncTeam', ncTeam)

	const matches = useQuery({
		queryKey: [ 'matches', connectorTeam?.id ],
		enabled: !!connectorTeam,
		queryFn: () => fetchJson<NetballConnectMatches>(`https://api-netball.squadi.com/livescores/round/matches?competitionId=${connectorTeam!.competitionId}&teamIds=[${connectorTeam!.id}]&ignoreStatuses=[1]`),
	})

	// console.log('matches', matches.data, matches.data?.rounds)

	const isPending = organizations.isPending || competitions.isPending || teams.isPending || matches.isPending
	const error = organizations.error || competitions.error || teams.error || matches.error

	const findMatchingOrgs = (value: string) => {
		if (!organizations.data)
			return
		// console.log('findMatchingOrgs', value, organizations.data)
		const lcValue = value?.toLowerCase()
		const matching = organizations.data.filter(d => !value || d.name.toLowerCase().indexOf(lcValue) !== -1)
		const sortedMatching = matchSorter(matching, value, { keys: [ 'name' ] })
		// console.log('sortedMatching', value, sortedMatching)
		setMatchingOrgs(sortedMatching.slice(0, 50))
	}

	const findMatchingTeams = useCallback((value?: string) => {
		const str = value
		// console.log('findMatchingTeams', str, value, allTeams)
		if (allTeams) {
			const lcStr = str?.toLowerCase()

			const matching = allTeams.filter(d => {
				if (!d)
					return false
				if (lcStr && d.name.toLowerCase().indexOf(lcStr) === -1)
					return false
				if (myOrganisation?.organisationTypeRefId === 4 && myOrganisation?.name !== d.linkedCompetitionOrganisation?.name) {
					return false
				}
				return true
			})

			let sortedMatching: Array<NetballConnectAugmentedTeam> | undefined

			if (str) {
				sortedMatching = matchSorter(matching, str, { keys: [ 'name' ] })
			} else {
				// No input string provided - try to match the team name, but if we don't,
				// display all teams.
				if (team?.name) {
					sortedMatching = matchSorter(matching, team.name, { keys: [ 'name' ] })
				}
				if (!sortedMatching?.length) {
					sortedMatching = matching.sort((a, b) => a.name.localeCompare(b.name))
				}
			}

			//console.log('sortedMatching', str, team?.name, sortedMatching)

			setMatchingTeams(sortedMatching.slice(0, 50))

			teamSearchRef.current?.setFocus()
		}
	}, [ allTeams, myOrganisation, team ])

	useEffect(() => {
		setMatchingTeams(undefined)
	}, [ year ])

	useEffect(() => {
		if (!matchingTeams) {
			findMatchingTeams()
		}
	}, [ matchingTeams, findMatchingTeams ])

	if (!myOrganisation) {
		orgSearchRef.current?.setFocus()
	}

	const onSelectOrganisation = (obj: NetballConnectOrganization) => {
		setOrganization(obj.organisationUniqueKey)
		findMatchingTeams()
	}

	const onSelectTeam = (obj: NetballConnectAugmentedTeam | undefined) => {
		if (!obj) {
			setConnectorTeam(undefined)
		} else {
			if (!myOrganisation || !competitions.data || !obj.division) {
				console.error('missing data', myOrganisation, competitions.data, obj.division)
				return
			}
			const relevantComp = competitions.data.find(comp => comp.id === obj.competitionId)
			if (!relevantComp) {
				console.error('no relevant comp', obj.competitionId, competitions.data)
				return
			}
			const newNCTeam: ConnectorTeam = {
				...obj,
				orgName: myOrganisation.name,
				compName: relevantComp.name,
				divName: obj.division.name,
				divId: obj.division.id,
				teamName: obj.name,
			}

			setConnectorTeam(newNCTeam)
		}
	}

	// console.log('connectorTeam', connectorTeam)

	return (
		<div className="select-connector">
			<p>
				{isPending ? 'Pending' : error ? `Error: ${error}` : null}
			</p>
			<p className="connectors-supported">
				Currently supported:
				{' '}
				<img className="netball-connect-image" src={netballConnectImage} alt="NetballConnect" />
			</p>

			<IonList>

				<IonItem>

					<Selector
						label="Year"
						value={year}
						options={options}
						onIonInput={onInputYear}
					/>
				</IonItem>

				{
					organization ? (
						<IonItem
							onClick={() => {
								setOrganization(undefined)
								setMatchingOrgs(undefined)
								setMatchingTeams(undefined)
							}}
						>
							<div>
								Club / League
							</div>
							<div slot="end">
								{organizations.data?.find(obj => obj.organisationUniqueKey === organization)?.name}
							</div>
						</IonItem>
					) : (
						<>
							<SearchBar
								placeholder="League or club..."
								onText={text => findMatchingOrgs(text)}
								onEnter={() => {
									if (matchingOrgs) {
										onSelectOrganisation(matchingOrgs[0])
									}
								}}
								ref={orgSearchRef}
							/>
							<IonList lines="none">
								{
									matchingOrgs?.map(obj => (
										<IonItem key={obj.organisationUniqueKey}>
											<IonButton
												fill="outline"
												onClick={e => {
													e.preventDefault()
													e.stopPropagation()
													onSelectOrganisation(obj)
												}}
											>
												{obj.name}
											</IonButton>
										</IonItem>
									))
								}
							</IonList>
						</>
					)
				}

				{
					connectorTeam ? (
						<IonItem
							onClick={() => {
								setConnectorTeam(undefined)
								findMatchingTeams()
							}}
						>
							<div>
								Team
							</div>
							<div slot="end">
								<TeamButton
									onClick={() => {
										setConnectorTeam(undefined)
									}}
									{...connectorTeam}
								/>
							</div>
						</IonItem>
					) : organization ? (
						<>
							<SearchBar
								placeholder="Team..."
								onText={text => findMatchingTeams(text)}
								onEnter={() => {
									if (matchingTeams) {
										onSelectTeam(matchingTeams[0])
									}
								}}
								ref={teamSearchRef}
							/>
							<IonList lines="none">
								{
									matchingTeams?.length === 0 ? (
										<IonItem>
											No matching teams.
										</IonItem>
									) : matchingTeams?.map(obj => (
										<IonItem key={obj.id}>
											<TeamButton
												key={obj.id}
												onClick={(e: React.MouseEvent) => {
													e.preventDefault()
													e.stopPropagation()
													onSelectTeam(obj)
												}}
												compName={competitions.data?.find(comp => comp.id === obj.competitionId)?.name || 'Unknown Team'}
												divName={obj.division.name}
												teamName={obj.name}
												{...obj}
											/>
										</IonItem>
									))
								}
							</IonList>
						</>
					) : null
				}

				{
					(connectorTeam && matches.data) ? (
						<div className="nc-matches-list">
							{
								matches.data.rounds.map(obj => {
									// Some rounds have 'match' array of 0 length
									if (!obj.matches.length)
										return null
									const [ match ] = obj.matches
									const me = `team${match.team1Id === connectorTeam.id ? 1 : 2}`
									const oppo = me === 'team1' ? 'team2' : 'team1'
									return (
										<div
											className="nc-match"
											key={match.id}
										>
											<div className="field-round">
												<div>
													{match.round?.name}
												</div>
											</div>
											<div className="field-opponent">
												{match[oppo]?.name}
											</div>
											<div className="field-date">
												{formatDate(match.startTime)}
											</div>
											&bull;
											<div className="field-venue">
												{match.venueCourt?.name}
											</div>
											{
												/*
												 * Let's not do match scores until we can get qtr-by-qtrs.
												 *
												 {
												 match.matchStatus === 'ENDED' ? (
												 <>
												 &mdash;
												 <div className="field-result">
												 {
												 match[`${me}Score`] > match[`${oppo}Score`] ?
												 'Won' :
												 match[`${me}Score`] < match[`${oppo}Score`] ?
												 'Lost' :
												 'Drew'
												 }
												 {' '}
												 <b>
												 {match[`${me}Score`]}
												 </b>
												 -
												 <b>
												 {match[`${oppo}Score`]}
												 </b>
												 </div>
												 </>

												 ) : null
												 }
												 */
											}
										</div>
									)
								})
							}
						</div>
					) : null
				}

				{
					connectorTeam ? (
						<IonButton
							className="nc-sync-button"
							size="default"
							expand="block"
							slot="end"
							onClick={async () => {
								await enableSync(connectorTeam)
							}}
						>
							Enable Online Sync
						</IonButton>

					) : null
				}
			</IonList>

		</div>
	)
}

type SearchBarProps = {
	placeholder: string
	onText: (text: string) => void
	onEnter: () => void
}

const SearchBar = React.forwardRef<HTMLIonSearchbarElement, SearchBarProps>((props: SearchBarProps, ref) => {
	const { placeholder, onText, onEnter } = props

	return (
		<IonSearchbar
			ref={ref}
			placeholder={placeholder}
			onIonInput={(e: IonSearchbarCustomEvent<SearchbarInputEventDetail>) => onText(e.detail.value || '')}
			onKeyPress={e => {
				if (e.code === 'Enter' || e.code === 'NumpadEnter') {
					onEnter()
				}
			}}
		/>
	)
})
SearchBar.displayName = 'SearchBar'

type TeamButtonProps = {
	teamName: string
	logoUrl?: string
	divName: string
	compName: string
	onClick?: (e: React.MouseEvent) => void
}

const TeamButton = (props: TeamButtonProps) => {

	const { teamName, logoUrl, divName, compName, onClick } = props

	// console.log('TeamButton', name, logoUrl, divName, compName)

	return (
		<IonButton
			className="nc-team-button"
			fill="outline"
			onClick={onClick}
		>
			{
				logoUrl ?
					<img src={logoUrl} className="nc-team-picker" alt="" />
					: null
			}
			<div className="nc-team-text">
				<div className="nc-team-name">
					{teamName}
				</div>
				<div className="nc-team-org">
					{compName}
				</div>
				<div className="nc-team-div">
					{divName}
				</div>
			</div>

		</IonButton>
	)
}

export default Connector
