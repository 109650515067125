import './myseasons.css'

import { checkmarkSharp, trashOutline, settingsOutline, cloudDoneOutline, add } from 'ionicons/icons'
import {
	useIonAlert,
	IonButton,
	IonIcon,
	IonLabel,
} from '@ionic/react'
import React, { useEffect, useState } from 'react'

import { GameField } from './Games'
import { Warning } from './Roster'
import {
  useActiveTab,
  useAmViewingOwnTeam,
  useCanEditItem,
  useDeleteItem,
  useHaveValidSeason,
  useMyGames,
  useMyNumPeriods,
  useMyRosters,
  useMySeason,
  useMyTeam,
  useNewSeasonConnectorState,
  useSection,
  useSetAmAddingNewSeason,
  useSetNewTeamId,
  useSetShowTeamSelection,
  useShouldSyncFixtureState,
  useTeamSeasons,
} from './data'
import { ucFirst } from './util'
import { useDisableSync, useImportChanges, useSaveItem, useSyncFixture } from './functions'
import Connector from './Connector'
import EditButton from './EditButton'
import EditItem from './EditItem'
import List from './List'
import ModalWithCancel from './ModalWithCancel'
import netballConnectImage from './media/netball-connect.png'

const type: ItemTypeDefinition<Season> = {
	name: 'seasons',
	unit: 'season',
	fields: [ 'name', 'gameFormat', 'numPeriods', 'connector' ],
	optionalFields: [ 'connector' ],
	defaultValue: ({ field }) => {
		if (field === 'numPeriods') {
			return 4
		}
		if (field === 'gameFormat') {
			return 7
		}
		return undefined
	},
	disableFields: ({ field, mode, isSeasonSettings }) => {
		if (field === 'connector' && !isSeasonSettings && mode === "edit") {
			return true
		}
		return false
	},
	pathname: '/games',
	maxWithoutPro: 1,
	formatField: props => <SeasonField {...props} />,
	// List newer seasons first
	sortFunction: (a, b) => ((b.created || 0) - (a.created || 0)) || String(a.name).localeCompare(b.name),
}

type MySeasonsProps = {
	teamId: TeamId
}

const MySeasons = (props: MySeasonsProps) => {

    const seasons = useTeamSeasons(props.teamId)

	return (
		<List
            data={seasons}
			type={type}
		/>
	)
}

type SeasonFieldProps = {
	field: keyof Season
	value: Season[keyof Season]
}

const SeasonField = (props: SeasonFieldProps) => {

	if (props.field === 'name') {
		return (
			<>
				{props.value as Season['name']}
			</>
		)
	}

	return null
}

type EditSeasonProps = {
	seasonEditMode: boolean
	setSeasonEditMode: (v: boolean) => void
	loading: boolean
}

//
// Provide a button or edit field at the bottom of the Games list
//
export const EditSeason = (props: EditSeasonProps) => {

	const { seasonEditMode, setSeasonEditMode, loading } = props

	const canEdit = useCanEditItem('season')
	const section = useSection()
	const season = useMySeason()
	const deleteSeason = useDeleteItem<Season>()
	const haveValidSeason = useHaveValidSeason()
	const saveItem = useSaveItem<Season>()
    const [ presentAlert ] = useIonAlert()

	if (!loading && !haveValidSeason) {
		return <Warning>This season has been deleted.</Warning>
	}

	if (!canEdit || !season)
		return null

	const { name } = season

	if (!seasonEditMode) {

		const icon = season.connector ? cloudDoneOutline : settingsOutline

		return (
			<EditButton
				className="season-edit-button"
				text={name}
				icon={icon}
				show={true}
				ready={true}
				editMode={true}
				onClick={() => setSeasonEditMode(true)}
			/>
		)
	}

	const handleChange = async (data: Item) => {

        //
        // Write to Firebase
        //
        try {
			if (section.season) {
				// Respond immediately
				setSeasonEditMode(false)
				await saveItem('season', data)
			}
		} catch (err) {
            console.error("Failed to update doc", err)
            // Switch back to edit mode to show it didn't work
            setSeasonEditMode(true)
        }
	}

	const item: Season = {
		// id: section.season,
		...season,
	}

	return (
		<div className="season-edit-container">
			<EditItem<Season>
				mode="edit"
				editMode={seasonEditMode}
				type={type}
				item={item}
				isSeasonSettings={true}
				className="edit-season-settings"
				submitButtonContent={
					<>
						<IonIcon icon={checkmarkSharp} /> Update
					</>
				}
				onSubmit={handleChange}
				onCancel={() => setSeasonEditMode(false)}
			/>
			<div className="season-edit-delete-button">
				<IonButton
					className="item-menu-item item-menu-delete"
					onClick={async () => {
						const alertData = await deleteSeason({ type, item })
						presentAlert(alertData)
						setSeasonEditMode(false)
					}}
					color="danger"
					fill="clear"
					size="small"
				>
					<IonIcon icon={trashOutline} slot="start" />
					<IonLabel>
						Delete Season
					</IonLabel>
				</IonButton>
			</div>
			<AddNewSeasonForCurrentTeam />
		</div>
	)
}

type ConnectorSelectorProps = {
	value: Connector
	disabled: boolean
	isSeasonSettings: boolean
	onIonInput: (e: { target: { value: Connector | null } }) => void
}

export const ConnectorSelector = (props: ConnectorSelectorProps) => {

	const { value, disabled, isSeasonSettings, onIonInput } = props
	const connector = value

	const [ showConnector, setShowConnector ] = useState(false)

	const [ newConnector, setNewConnector ] = useNewSeasonConnectorState()

	//
	// When we set a Connector for a new season, load that here.
	//
	// Problem: This overrides the actual settings when trying to edit
	// a season from the team/season selector popup menu. So I think we
	// just disable editing a Connector in that way. HOWEVER it will still
	// show the wrong connector because it will set it to null...
	//
	// Bug: If you create a new season, set a connector, then try to clear it
	// before saving, it sticks because we don't transmit the null value below.
	// TODO: I think we need to figure out whether this component should listen
	// to newConnector (which is for new seasons only) or not.
	//
	useEffect(() => {
		if (!isSeasonSettings && !disabled) {
			console.log('Got a newConnector', newConnector, disabled)
			setShowConnector(false)
			onIonInput({
				target: {
					value: newConnector,
				}
			})
		}
		// FIXME
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ isSeasonSettings, disabled, newConnector ]) // , onIonInput ])

	// Clear connector on unmount
	useEffect(() => {
		return () => setNewConnector(null)
	}, [ setNewConnector ])

	const onClick = () => {
		setShowConnector(true)
	}

	return (
		<>
			<div className="connector-sync">
				Fixture Sync
			</div>
			<IonButton
				slot="end"
				fill="clear"
				onClick={onClick}
				className="connector-sync-button"
			>
				{
					connector?.type === 'NetballConnect' ? (
						<>
							<img className="netball-connect-image" src={netballConnectImage} alt="NetballConnect" />
							<IonIcon icon={cloudDoneOutline} className="sync-enabled" />
						</>
					) : (
						<>
							Connect...
						</>
					)
				}
			</IonButton>

			{/* This is the pop-up for selecting a Connector */}
			<Connector
				show={showConnector}
				onDismiss={() => setShowConnector(false)}
			/>
		</>
	)
}

export const SyncConnector = () => {

	const season = useMySeason()
	const numPeriods = useMyNumPeriods()

	const activeTab = useActiveTab()

	const amViewingOwnTeam = useAmViewingOwnTeam()

	const syncFixture = useSyncFixture()
	const disableSync = useDisableSync()
	const importChanges = useImportChanges()

	const [ shouldSyncFixture, setShouldSyncFixture ] = useShouldSyncFixtureState()

	const [ changes, setChanges ] = useState<Changes|undefined>()

	const connector: Connector|undefined = season?.connector

	const [ lastCheckedTime, setLastCheckedTime ] = useState<Date|undefined>()
	const [ lastCheckedId, setLastCheckedId ] = useState(connector?.id)

	const [ presentAlert ] = useIonAlert()

	const MIN_BETWEEN_FETCHES = 5 * 60 * 1000		// 5 minutes

	// console.log('SyncConnector', activeTab, season, connector, changes, activeTab, lastCheckedTime, lastCheckedId)

	useEffect(() => {

		const timer = setTimeout(() => {
			if (connector && activeTab === 'games' && amViewingOwnTeam) {
				if (connector.params?.id !== lastCheckedId || !lastCheckedTime || new Date().getTime() - lastCheckedTime.getTime() > MIN_BETWEEN_FETCHES) {
					console.log("SYNC")
					setShouldSyncFixture(true)
				} else {
					console.log("NO SYNC")
				}
			}
			setLastCheckedId(connector?.params?.id)
		}, 2000)

		return () => clearTimeout(timer)
	}, [ connector, activeTab, amViewingOwnTeam, lastCheckedTime, lastCheckedId, MIN_BETWEEN_FETCHES, setShouldSyncFixture ])

	useEffect(() => {
		async function doSyncFixture() {
			if (connector) {
				const data = await syncFixture(connector, numPeriods)
				setChanges(data ?? undefined)
			}
		}

		if (shouldSyncFixture) {
			setShouldSyncFixture(false)
			setLastCheckedTime(new Date())
			void doSyncFixture()
		}
	}, [ shouldSyncFixture, connector, numPeriods, setShouldSyncFixture, syncFixture ])

	if (!connector)
		return null

	const icons = {
		add: '🟢',
		delete: '🔴',
		modify: '🟡',
	} as const

	const changesSummary = {
		add: 0,
		delete: 0,
		modify: 0,
	}

	changes?.forEach(change => {
		let type: keyof typeof changesSummary
		if ('add'in change) {
			type = 'add'
		} else if ('delete' in change) {
			type = 'delete'
		} else {
			type = 'modify'
		}
		changesSummary[type] += 1
	})

	const changesSummaryList = Object.entries(changesSummary)
			.filter(([ _key, value ]) => value > 0)
			.map(( [ key, value ]) => `${icons[key as keyof typeof icons]} ${ucFirst(key)} ${value} game${value === 1 ? '' : 's'}`)

	const onImportChanges = async () => {
		if (changes) {
			await importChanges(changes)
			setChanges(undefined)
		}
	}

	const clickImportAll = () => {

		if (changesSummary.delete) {
			presentAlert({
				header: 'WARNING: Really delete?',
				message: `This import will DELETE one or more of your games, including any associated rosters.`,
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: 'Accept Changes',
						handler: onImportChanges,
					},
				],
			})
		} else {
			onImportChanges()
		}
	}

	const clickDisableSync = () => {
		presentAlert({
			header: 'Disconnecting',
			message: "Your fixture will no longer auto-update. You can re-enable syncing via your Season settings.",
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Stop Syncing',
					handler: disableSync,
				},
			],
		})
	}

	return (
		<ModalWithCancel
			show={connector && !!changes?.length}
			setShow={() => setChanges(undefined)}
		>
			<div className="fixture-sync-modal">
				<h1>
					Season Sync (Beta) <IonIcon icon={cloudDoneOutline} />
				</h1>
				<p>
					Found updated fixture data for <b>{connector.params.teamName} ({connector.params.compName})</b>. Import these changes?
				</p>
				<DisplayFixtureChanges
					changes={changes}
				/>
				<div className="sync-connector-buttons">
					<IonButton
						expand="block"
						onClick={clickImportAll}
					>
						<div className="sync-connector-accept-button">
							<div className="sync-connector-change-text">
								Accept Changes:
							</div>
							<div className="sync-connector-change-list">
								{
									changesSummaryList && changesSummaryList.map((str, index) => (
										<div key={index}>
											{str}
										</div>
									))
								}
							</div>
						</div>

					</IonButton>
					<IonButton
						color="danger"
						fill="clear"
						onClick={clickDisableSync}
					>
						Stop Syncing
					</IonButton>
				</div>
			</div>
		</ModalWithCancel>
	)
}

type DisplayFixtureChangesProps = {
	changes: Changes | undefined
}

const DisplayFixtureChanges = (props: DisplayFixtureChangesProps) => {

	const { changes } = props

	const games = useMyGames()

	if (!changes || !games)
		return null

	return (
		<div className="connector-fixture-changes">
			{
				changes.map((changedGame, index) => {
					if ('delete' in changedGame) {
						return (
							<div
								key={index}
								className="autoroster-item game connector-item-delete-game"
							>
								<div className="connector-delete-game">
									Deleted Game
								</div>
								<DisplayGame
									game={changedGame.game}
									delete={changedGame.delete}
									isFixtureChange={true}
								/>
							</div>
						)
					}

					let data: Array<FieldChange>
					let game

					if ('changes' in changedGame) {
						data = changedGame.changes
						game = games[changedGame.myGameId]

					} else {
						// For added games, don't display 'id', 'created' - only content we care about
						const noDisplay = [ 'id', 'created', 'round' ]
						data = Object.entries(changedGame.game).filter(([ field, _value ]) => !noDisplay.includes(field)).map(([ field, newValue ]) => ({
							field: field as keyof Game,
							newValue,
							oldValue: undefined,
						}))
						game = changedGame.game
					}

					let content = (
						<>
							{
								data.map(change => (
									<div
										key={change.field}
										className="connector-change"
									>
										<div className="connector-change-label">
											New {change.field}
										</div>
										<div
											className={`connector-change-field`}
										>
											<GameField
												field={change.field}
												value={change.newValue}
												isFixtureChange={true}
											/>
										</div>
									</div>
								))
							}
						</>
					)

					if ('add' in changedGame) {
						return (
							<div
								key={index}
								className="autoroster-item game connector-item-add-game"
							>
								<div className="connector-add-game">
									Added Game
								</div>

								<div className="field-round">
									<GameField
										field="round"
										value={game.round}
									/>
								</div>
								{content}
							</div>
						)
					}

					return (
						<div
							className="autoroster-item game connector-item-modify-game"
							key={changedGame.myGameId}
						>
							<DisplayGame
								game={game}
								isFixtureChange={true}
							/>
							{content}
						</div>
					)
				})
			}
		</div>
	)
}

type DisplayGameProps = {
	game: Game | Omit<Game, 'id'>
	delete?: string
	isFixtureChange?: boolean
}

const DisplayGame = (props: DisplayGameProps) => {

	const { game } = props

	const rosters = useMyRosters()

	if (!game)
		return null

	const fields = [ 'round', 'opponent', 'date', 'venue', 'result' ] as const

	return (
		<div className="item-content">
			{
				fields.map(field => (
					<div
						key={field}
						className={`field-${field}`}
					>
						<GameField
							field={field}
							value={game[field]}
							item={{
								...game,
								id: ('id' in game ? game.id : 'temp'),
							}}
							setEditMode={() => null}
							isFixtureChange={props.isFixtureChange}
						/>
					</div>
				))
			}
			{
				props.delete ? (
					<div className="connector-delete-game-warning">
						⚠️  This game will be deleted.
					</div>
				) : null
			}
			{
				(props.delete && props.game.result) ? (
					<div className="connector-delete-game-warning">
						⚠️  Scores will be lost.
					</div>
				) : null
			}
			{
				(props.delete && rosters[props.delete]) ? (
					<div className="connector-delete-game-warning">
						⚠️  Roster will be lost.
					</div>
				) : null
			}
		</div>
	)
}

export const AddNewSeasonForCurrentTeam = () => {
	const team = useMyTeam()
	const setNewTeamId = useSetNewTeamId()
	const setShowTeamSelection = useSetShowTeamSelection()
	const setAmAddingNewSeason = useSetAmAddingNewSeason()

	if (!team)
		return null

	return (
		<div className="season-edit-add-button">
			<IonButton
				className="item-menu-item item-menu-delete"
				onClick={() => {
					console.log('add new season for current team')
					setNewTeamId(team.id)
					setShowTeamSelection(true)
					setAmAddingNewSeason(true)
				}}
				fill="clear"
				size="small"
			>
				<IonIcon icon={add} slot="start" />
				<IonLabel>
					Add New Season for {team.name}
				</IonLabel>
			</IonButton>
		</div>
	)
}

export default MySeasons
