import React, { useRef, useEffect } from 'react'
import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonModal,
	IonToolbar,
} from '@ionic/react'
import MyTeams from './MyTeams'
import MySeasons from './MySeasons'
import {
    useAmActiveTab,
    useNewTeamIdState,
	useSection,
	useSetAmAddingNewSeason,
	useShowTeamSelectionState,
	useUser,
} from './data'
import { arrowBack } from 'ionicons/icons'

const Teams = () => {

	const user = useUser()
	const section = useSection()
    const [ newTeamId, setNewTeamId ] = useNewTeamIdState()
	const [ showTeamSelection, setShowTeamSelection ] = useShowTeamSelectionState()
	const setAmAddingNewSeason = useSetAmAddingNewSeason()

    const userIsJoiningClub = useAmActiveTab('join-club')

	const modal = useRef<HTMLIonModalElement>(null)

	const mode = !newTeamId ? 'team' : 'season'

    // console.log('Teams', showTeamSelection)

    const onDismiss = () => {
		setNewTeamId(null)
        setShowTeamSelection(false)
		setAmAddingNewSeason(false)
    }

	//
	// Auto-set 'showTeamSelection' in certain situations.
	//
	useEffect(() => {
		if (showTeamSelection === null && !userIsJoiningClub && (!section.team || !section.season) && user) {
			setShowTeamSelection(true)
		}
	}, [ showTeamSelection, section, userIsJoiningClub, setShowTeamSelection, user ])

	//
	// Get rid of any team selection popup so the user can see the "Join Club" page.
	//
	useEffect(() => {
		if (userIsJoiningClub) {
			setShowTeamSelection(false)
		}
	}, [ userIsJoiningClub, setShowTeamSelection ])

	// showTeamSelection can be null, but isOpen MUST be true or false
	const isOpen = !!showTeamSelection

	return (
		<IonModal
			ref={modal}
			isOpen={isOpen}
			onWillDismiss={onDismiss}
		>
			<IonHeader>
				<IonToolbar>
					{
						mode === 'season' && (
							<IonButtons slot="start">
								<IonButton onClick={() => setNewTeamId(null)}>
									<IonIcon icon={arrowBack} />
								</IonButton>
							</IonButtons>
						)
					}
					<IonButtons slot="end">
						<IonButton
							onClick={() => modal.current?.dismiss()}
						>
							Cancel
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{
					!newTeamId ? <MyTeams /> : <MySeasons teamId={newTeamId} />
				}
			</IonContent>
		</IonModal>
	)
}

export default Teams
