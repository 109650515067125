import { initializeApp, getApp } from "firebase/app"
import { Capacitor } from '@capacitor/core'
import { getFirestore, setLogLevel } from "firebase/firestore"
import { Auth, getAuth, initializeAuth, indexedDBLocalPersistence } from "firebase/auth"
import { getFunctions, httpsCallable } from "firebase/functions"
import { FirebaseAnalytics } from '@capacitor-firebase/analytics'
import { connectFunctionsEmulator } from "firebase/functions"

import { config } from './firebaseCredentials'

//
// For testing Firebase Functions V2 via the local Functions emulator, i.e.:
//   cd functions-v2
//   npm run serve
//
const USE_FIREBASE_FUNCTIONS_EMULATOR = false

const databaseLogging = 'error' // 'debug'

type CloudFunctionResponse = {
    ok?: boolean
    code?: string
    error?: string
    uid?: string
    clubId?: string
}

// per github suggestion
//
// https://github.com/firebase/firebase-js-sdk/issues/5019
//
let auth: Auth | null = null

export function getFirebaseAuth() {
    // console.log('getFirebaseAuth()!', auth)
    if (!auth) {
        if (Capacitor.isNativePlatform()) {
            auth = initializeAuth(getApp(), {
                persistence: indexedDBLocalPersistence,
            })
        } else {
            auth = getAuth()
        }
        // auth.onAuthStateChanged(newUser => console.log('new user', newUser))
    }
    // console.log('auth is now', auth)
    return auth
}

const firebaseApp = initializeApp(config)

export const db = getFirestore(firebaseApp)
setLogLevel(databaseLogging)

//
// Analytics
//

FirebaseAnalytics.setEnabled({
    enabled: true,
})

export const setAnalyticsUserId = async (userId: UserId) => {
    await FirebaseAnalytics.setUserId({
        userId,
    })
}

/*
export const setAnalyticsUserProperty = async (key: string, value: any) => {
    await FirebaseAnalytics.setUserProperty({
        key,
        value,
    })
}
*/

export const logEvent = async (name: string, params?: any) => {
    console.log('logEvent', name, params)
    await FirebaseAnalytics.logEvent({
		name,
		params,
	})
}

//
// Cloud Functions
//

const functions = getFunctions(firebaseApp, 'australia-southeast1')

if (USE_FIREBASE_FUNCTIONS_EMULATOR && import.meta.env.DEV) {
	connectFunctionsEmulator(functions, "localhost", 5001)
	console.error("***** USING CLOUD FUNCTIONS EMULATOR *****")
	setTimeout(() => {
		console.log('*********************************************')
		console.error("***** USING CLOUD FUNCTIONS EMULATOR *****")
		console.log('*********************************************')
	}, 1500)
}

//
// To test /functions-v2/index.ts -> stripeV2(), which isn't listed below, you need
// to simulate a call from Stripe.
//
// Edit firebase.ts -> USE_FIREBASE_FUNCTIONS_EMULATOR = true
//
//   cd functions-v2
//   npm run serve
//   stripe login
//   stripe listen --forward-to localhost:5001/netball-autoroster/australia-southeast1/stripeV2
//   stripe trigger checkout.session.completed
//
// However to actually do a whole payment test, you need to edit WebPurchasing.tsx -> publishable-key
// to use the test key available here:
//   https://dashboard.stripe.com/test/apikeys
// ...then monitor here:
//  https://dashboard.stripe.com/test/payments
//
export const clubUserLogin = httpsCallable<ClubUserLoginRequest, CloudFunctionResponse>(functions, 'clubUserLoginV2') 				// ok
export const joinClub = httpsCallable<JoinClubRequest, CloudFunctionResponse>(functions, 'joinClubV2') 								// ok
export const leaveClub = httpsCallable<LeaveClubRequest, CloudFunctionResponse>(functions, 'leaveClubV2') 							// ok
export const ejectUserFromClub = httpsCallable<EjectUserRequest, CloudFunctionResponse>(functions, 'ejectUserFromClubV2') 			// ok
export const setClubUserStatus = httpsCallable<SetClubUserStatusRequest, CloudFunctionResponse>(functions, 'setClubUserStatusV2') 	// ok
export const modifyCoachAccess = httpsCallable<ModifyCoachAccessRequest, CloudFunctionResponse>(functions, 'modifyCoachAccessV2') 	// ok
export const errorReport = httpsCallable<ErrorReportRequest, CloudFunctionResponse>(functions, 'errorReportV2') 					// ok
