import React from 'react'
import {
    IonButton,
} from '@ionic/react'
import LoadingSpinner from './LoadingSpinner'
import './loadingspinnerbutton.css'

type LoadingSpinnerButtonProps = {
	busy: boolean
	disabled?: boolean
	onClick: () => void
	size?: "small" | "default" | "large"
	expand?: "full" | "block"
	color?: "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "light" | "medium" | "dark"
	fill?: "clear" | "outline" | "solid" | "default"
}

const LoadingSpinnerButton = (props: React.PropsWithChildren<LoadingSpinnerButtonProps>) => {
    return (
        <IonButton
            className="loading-spinner-button"
            size={props.size || "large"}
            expand={props.expand || "block"}
            onClick={props.onClick}
            disabled={props.disabled || props.busy}
            color={props.color}
            fill={props.fill}
        >
            {
                props.busy ? (
                    <LoadingSpinner />
                ) : props.children
            }
        </IonButton>
    )
}

export default LoadingSpinnerButton
