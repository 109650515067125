import React from 'react'
import {
	IonContent,
	IonPage,
} from '@ionic/react'
import Header from './Header'
import { Link } from 'react-router-dom'
import {
	useMyGameFormat,
	useMyGames,
	useMyNumPeriods,
	useMyPlayer,
	useMyPlayerId,
	useMyRosters,
} from './data'
import { formatDate, gameDateClass } from './util'
import orange from './orange.svg'
import "./player.css"
import { PositionName } from './GridPositions'
import GridCellPosition from './GridCellPosition'
import { findMyPositions } from './util'
import { BENCHED } from './definitions'

const Player = () => {

	const playerId = useMyPlayerId()
	const player = useMyPlayer(playerId || '')
	const games = useMyGames()
	const rosters = useMyRosters()
	const numPeriods = useMyNumPeriods()

	let content
	let title

	if (playerId && player && Object.keys(player).length && games && Object.keys(games).length) {
		title = player.name

		const sortedGameIds = sortGames(games)

		content = (
			<div>
				<div className={`player-games player-games-${numPeriods}`}>
					{
						sortedGameIds.map((gameId, index) =>
							<PlayerRound
								key={index}
								game={games[gameId]}
								gameId={gameId}
								roster={rosters[gameId] || { }}
								playerId={playerId}
							/>
						)
					}
				</div>
			</div>
		)
	}  else {
		title = 'Unknown Player'
		content = (
			<div>
				This player does not exist.
			</div>
		)
	}

	return (
		<IonPage>
			<IonContent className="ion-padding">
				<section className="player">
					<h2>{title}</h2>
					{content}
				</section>
			</IonContent>
			<Header />
		</IonPage>
	)
}

type PlayerRoundProps = {
	gameId: GameId
	game: Game
	roster: Roster
	playerId: PlayerId
}

const PlayerRound = (props: PlayerRoundProps) => {
	const { gameId, game, roster, playerId } = props

	const numPeriods = useMyNumPeriods()
	const gameFormat = useMyGameFormat()

	const myPositions = findMyPositions({
		playerId,
		roster: roster.roster,
		nulled: roster.nulled || [ ],
		subs: roster.subs || [ ],
		players: roster.players,
		numPeriods,
		gameFormat,
	})

	const isFutureGame = game.date && game.date > Date.now()

	// console.log('PlayerRound', myPositions, isFutureGame)

	const roundClasses = [ 'gameline-round' ]
	if (game.round.length >= 7) {
		roundClasses.push('very-long-name')
	} else if (game.round.length >= 4) {
		roundClasses.push('long-name')
	}

	return (
		<Link
			className={'gameline' + (isFutureGame ? ' gameline-future' : '')}
			to={`/games/${gameId}`}
		>
			<div className={roundClasses.join(' ')}>
				{game.round}
			</div>
			{
				myPositions.map(
					(obj, qtr) => {
						//
						// This is from the player's perspective, so we should show BENCHED
						// even if they were assigned positions but marked unavailable (e.g.
						// to use as a fill-in).
						//
						const positionName = obj.isAvailable ? obj.positionName : BENCHED
						const subStatus = obj.isAvailable ? obj.subStatus : 0
						const isNullPosition = obj.isAvailable ? obj.isNullPosition : false
						return <GridCellPosition
							key={qtr}
							i={qtr}
							positionName={positionName}
							content={<PositionName positionName={positionName} subStatus={subStatus} />}
							subStatus={subStatus}
							isNullPosition={isNullPosition}
						/>
					}
				)
			}
			<div>
				{
					roster.orangeman === playerId ? <img className="orange-image" src={orange} alt="Oranges" /> : ''
				}

			</div>

			<div className="player-game">
				<div className="player-game-opponent">
					{game.opponent}
				</div>
				<div className={'player-game-date date-' + gameDateClass(game.date)}>
					{
						formatDate(game.date)
					}
				</div>
			</div>
		</Link>
	)
}

//
// Given an Object of games, return an Array of game IDs from
// oldest to most recent.
//
const sortGames = (games: Games) =>
    Object.entries(games)
        .sort(([_, gameA], [__, gameB]) => {
            if (gameA.date !== undefined && gameB.date !== undefined) {
                return gameA.date - gameB.date
            }

            return Number(gameA.round) - Number(gameB.round)
        })
        .map(([gameId, _]) => gameId)

export default Player
