import React, { useEffect } from 'react'
import { Capacitor } from '@capacitor/core'
import {
    IonButton,
    IonContent,
} from '@ionic/react'
import {
	errorReport,
	logEvent,
} from './firebase'
import { serializeError } from 'serialize-error'
import Icon from './Icon'
import { EmailMax } from './Help'
import {
	useSection,
	useUser,
} from './data'
import packageInfo from '../package.json'
import './errorfallback.css'

type ErrorFallbackProps = {
	error: Error
	resetErrorBoundary: () => void
}

const ErrorFallback = (props: ErrorFallbackProps) => {

	const { error, resetErrorBoundary } = props

	const user = useUser()
	const section = useSection()

	console.log('My error is:', error)

	useEffect(() => {

		const go = async () => {
			console.log("Reporting error to backend API:", error)

			const obj: ErrorReportRequest = {
				error: serializeError(error),
				uid: user?.uid,
				href: window?.location?.href,
				section,
				version: packageInfo?.version,
				nativeType: Capacitor.isNativePlatform() ? 'Native' : 'Web',
			}

			if (import.meta.env.DEV) {
				console.log("Not reporting error due to import.meta.env.DEV:", obj)
				return
			}

			try {
				// Log to my cloud Function... this is maybe not needed if Analytics is better
				const response = await errorReport(obj)
				console.log('Successfully reported error. Response:', response)

				// Also log with Analytics
				const result = await logEvent('error', {
					message: error?.message,
				})
				console.log("Logged error event with Analytics:", result)

			} catch (err) {
				console.error("Problem logging error: ", err)
			}
		}

		if (error) {
			go()
		}
	}, [ error, user, section ])

	return (
		<IonContent
			className="error-boundary ion-padding"
		>
			<div className="error-title">
				Oh no! Something went wrong.
				<Icon />
			</div>
			<div className="let-max-know">
				If this problem persists, please let me know!
				<br />
				<EmailMax
					subject="AutoRoster error"
					text={"Error: " + error?.message}
				/>
			</div>
            <p>
                <IonButton
                    onClick={resetErrorBoundary}
                >
                    Try Again
                </IonButton>
                <IonButton
                    href="/"
                >
                    Reload app
                </IonButton>
            </p>
            <fieldset>
				<legend>Details</legend>
				<p>
					<span className="error-subtitle">
						Error:
					</span>
					{error?.message}
				</p>
				<p>
					<span className="error-subtitle">
						Page:
					</span>
					{window.location.search}
				</p>
				<p>
					<span className="error-subtitle">
						Stack:
					</span>
					{error?.stack}
				</p>
			</fieldset>
		</IonContent>
	)
}

export default ErrorFallback
