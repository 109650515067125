/*
 * This enables React 18:
 *
 * https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
 *
 * HOWEVER it seems to ruin some Popmotion animation: when toggling player
 * availability, it no longer animates so smoothly. This is a pretty big
 * deal because it looks ugly.
 *
 * So I may need to fix that or even migrate to Framer Motion.
 *
 * NOTE that migrating to Framer Motion is a real pain... I abandoned an attempt because
 * so much stuff breaks or just isn't as nice.
 *
 *
 *
import React from 'react'
import { createRoot } from 'react-dom/client'
import IonicApp from './IonicApp'

const container = document.getElementById('root')

const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <React.StrictMode>
        <IonicApp />
    </React.StrictMode>
)
*/

import React from 'react'
import ReactDOM from 'react-dom'
import IonicApp from './IonicApp'

//
// NOTE: Do not switch to createRoot because it breaks navigation (clicking on a game
// or player doesn't trigger SectionUpdater any more)
//
ReactDOM.render(
	<React.StrictMode>
		<IonicApp />
	</React.StrictMode>,
	document.getElementById('root')
)
