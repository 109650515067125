import React from 'react'
import {
    useMyBest,
} from './data'
import PlayerName from './PlayerName'
import { FaCalendarTimes } from 'react-icons/fa'
import './awayplayers.css'

const AwayPlayers = () => {

    const best = useMyBest()
    const { available } = best

	const players = Object.keys(available).filter(player => !available[player])

	if (!players.length)
		return null

	return (
		<div className="awayplayers">
            <FaCalendarTimes className="roster-no" />
            {
                players.map(playerId => <PlayerName key={playerId} playerId={playerId} />)
            }
        </div>
    )
}

export default AwayPlayers
